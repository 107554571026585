<h2 mat-dialog-title>Are you sure you want to save this announcement?</h2>
<mat-dialog-content
  ><div>
    The announcement will be sent <b>{{ remainingTime }}</b> to all active users.
  </div></mat-dialog-content
>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>Close</button>
  <button mat-flat-button color="warn" [mat-dialog-close]="true">Ok</button>
</mat-dialog-actions>
