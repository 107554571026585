<ng-container *ngIf="!isStandalone">
  <mat-card>
    <mat-card-content>
      <ng-container [ngTemplateOutlet]="pageContents"></ng-container>
    </mat-card-content>
    <mat-card-actions>
      <button *ngIf="!isStandalone" mat-stroked-button (click)="goBack()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
          <span>Go back</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-stroked-button (click)="clearSentAvailabilities()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:reset"></mat-icon>
          <span>Clear sent unavailabilities</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="cta" (click)="openSendAvailabilitiesModal()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:paper-plane"></mat-icon>
          <span>Send unavailabilities</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="primary" routerLink="/unavailabilities/new">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:add"></mat-icon>
          <span>New unavailability</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>

<ng-container *ngIf="isStandalone" [ngTemplateOutlet]="pageContents"></ng-container>

<ng-template #pageContents>
  <mat-toolbar class="filter-bar">
    <mat-form-field>
      <mat-label>Filter month</mat-label>
      <input
        matInput
        [matDatepicker]="monthDatepicker"
        [formControl]="filterMonth"
        (dateInput)="monthDatepickerEvent('input', $event)"
        (dateChange)="monthDatepickerEvent('change', $event)"
      />
      <mat-datepicker-toggle matSuffix [for]="monthDatepicker"></mat-datepicker-toggle>
      <mat-datepicker
        #monthDatepicker
        startView="multi-year"
        (monthSelected)="chosenMonthHandler($event, monthDatepicker)"
      ></mat-datepicker>
    </mat-form-field>
    <span class="fill-remaining-space"></span>
    <mat-slide-toggle class="minimize" (change)="filterData()" [formControl]="futureItems"> Only future unavailabilities </mat-slide-toggle>
  </mat-toolbar>

  <table mat-table [dataSource]="dataSource" class="has-actions" matSort #sort="matSort">
    <ng-container matColumnDef="customerName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
      <td mat-cell *matCellDef="let unavailability">
        {{ unavailability.customerName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="startDateTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Start</th>
      <td mat-cell *matCellDef="let unavailability">
        {{ unavailability.period.startDateTime.date | date: 'short' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="endDateTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>End</th>
      <td mat-cell *matCellDef="let unavailability">
        {{ unavailability.period.toDateTime.date | date: 'short' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="serviceAgreement">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [ngSwitch]="isDashboard">
        <span *ngSwitchCase="true" matTooltip="Service agreement">S.A.</span>
        <span *ngSwitchCase="false">Service agreement</span>
      </th>
      <td mat-cell *matCellDef="let unavailability">
        {{ unavailability.serviceAgreementSystemReference }}
        {{ unavailability.serviceAgreementReference ? '- ' + unavailability.serviceAgreementReference : '' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="direction" [ngSwitch]="isDashboard">
      <ng-container *ngSwitchCase="true">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Direction">
          <ph-flex-vertical-center>
            <mat-icon class="rotate-90" svgIcon="ic-old:transfer"></mat-icon>
          </ph-flex-vertical-center>
        </th>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Direction</th>
      </ng-container>

      <td mat-cell *matCellDef="let unavailability">
        <ph-flex-vertical-center>
          <mat-icon
            class="status-up"
            matTooltip="{{ unavailability.direction }}"
            *ngIf="unavailability.direction === 'UPWARDS'"
            svgIcon="ic-old:arrow-up"
          ></mat-icon>
          <mat-icon
            class="status-down"
            matTooltip="{{ unavailability.direction }}"
            *ngIf="unavailability.direction === 'DOWNWARDS'"
            svgIcon="ic-old:arrow-up"
          ></mat-icon>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <ng-container matColumnDef="power">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Power</th>
      <td mat-cell *matCellDef="let unavailability">{{ unavailability.power | capacity }}</td>
    </ng-container>
    <ng-container matColumnDef="sent">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Sent</th>
      <td mat-cell *matCellDef="let unavailability" [ngSwitch]="unavailability.sent">
        <ph-flex-vertical-center>
          <ng-container *ngSwitchCase="true">
            <mat-icon class="status-success" svgIcon="ic-old:check"> </mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <mat-icon class="status-passive" svgIcon="ic-old:close"> </mat-icon>
          </ng-container>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let unavailability">
        <ph-flex-vertical-center>
          <button mat-icon-button matTooltip="Edit" routerLink="/unavailabilities/{{ unavailability.id }}">
            <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
          </button>
          <button mat-icon-button matTooltip="Delete" class="remove" (click)="delete(unavailability)">
            <mat-icon svgIcon="ic-old:trash-o"></mat-icon>
          </button>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [showFirstLastButtons]="true" [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
</ng-template>
