import { Component, OnInit } from '@angular/core';
import moment from 'moment';
import { UntypedFormBuilder } from '@angular/forms';
import { DayAheadServiceService } from '../../shared/deal/dayahead/day-ahead-service.service';
import { DayAheadDealsReceivedInfo } from '../../../store/sse/states/day-ahead/sse-day-ahead.actions';
import { takeUntil } from 'rxjs/operators';
import { SseDayAheadDealsFacade } from '../../../store/sse/states/day-ahead/sse-day-ahead-deals-facade.service';
import { CalendarPeriod, MixinBase, OnDestroyMixin } from 'flex-app-shared';

@Component({
  selector: 'app-day-ahead-deals',
  templateUrl: './day-ahead-deals.component.html',
  styleUrls: ['./day-ahead-deals.component.scss']
})
export class DayAheadDealsComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  retrievingDayAheadDeals: boolean = false;
  dayAheadDealsError: boolean = false;
  dayAheadDealsReceivedResult: DayAheadDealsReceivedInfo = null;

  form = this.fb.group({
    period: CalendarPeriod.toCalendarPeriod(new Date(), new Date()),
    gridPointId: [''],
    customerId: ['']
  });

  constructor(
    private fb: UntypedFormBuilder,
    private dayAheadDealService: DayAheadServiceService,
    private sseDayAheadDealsFacade: SseDayAheadDealsFacade
  ) {
    super();
  }

  getDayAheadDeals(): void {
    this.retrievingDayAheadDeals = true;
    this.dayAheadDealsError = false;
    this.dayAheadDealsReceivedResult = null;

    this.dayAheadDealService
      .retrieveDayAheadDeals(this.form.get('period').value, this.form.get('customerId').value, this.form.get('gridPointId').value)
      .subscribe(
        () => {
          this.retrievingDayAheadDeals = true;
          this.dayAheadDealsReceivedResult = null;
        },
        (error) => {
          this.retrievingDayAheadDeals = false;
          this.dayAheadDealsError = true;
          this.dayAheadDealsReceivedResult = {
            success: false,
            done: true,
            message: error.error.message
          } as DayAheadDealsReceivedInfo;
        }
      );
  }

  ngOnInit(): void {
    this.sseDayAheadDealsFacade.resetInfo();
    this.sseDayAheadDealsFacade.dayAheadDealsReceivedInfo$.pipe(takeUntil(this.onDestroy$)).subscribe((info) => {
      this.dayAheadDealsReceivedResult = info;
      if (info) {
        this.retrievingDayAheadDeals = true;
        if (info.done) {
          this.dayAheadDealsError = !info.success;
          this.retrievingDayAheadDeals = false;
        }
      }
    });
  }
}
