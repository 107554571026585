import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatStep, MatStepper } from '@angular/material/stepper';

import { subDays } from 'date-fns';
import { FlexNgXsFormSync, MixinBase, OnDestroyMixin } from 'flex-app-shared';
import { MeasurementDataUploadFacade } from '../../store/measurement-data-upload/states/measurement-data-upload.facade';
import { UploadedDataDatasource } from './uploaded-data.datasource';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-measurement-data-download',
  templateUrl: './measurement-data-upload.component.html',
  styleUrls: ['./measurement-data-upload.component.scss']
})
export class MeasurementDataUploadComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  displayedColumns = ['start', 'end', 'consumption', 'production', 'aggregatedFiveMinuteData', 'fifteenMinuteData', 'remarks'];

  @ViewChild(MatPaginator, { static: true }) private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) private readonly sort: MatSort;
  @ViewChild(MatStepper, { static: true }) private readonly stepper: MatStepper;

  @FlexNgXsFormSync('measurementDataUpload.dateForm')
  dateForm = this.fb.group({
    date: [null, [Validators.required]]
  });

  @FlexNgXsFormSync('measurementDataUpload.gridPointIdForm')
  gridPointIdForm = this.fb.group({
    customerId: [null, [Validators.required]],
    gridPointId: [null, [Validators.required]]
  });
  maxDate = subDays(new Date(), 1);

  previewDataSource = new UploadedDataDatasource(this);

  constructor(private fb: UntypedFormBuilder, public facade: MeasurementDataUploadFacade) {
    super();
  }

  ngOnInit(): void {
    this.previewDataSource.sort = this.sort;
    this.previewDataSource.paginator = this.paginator;

    // Reset the gridPointId when customerId changes
    // Unfortunately the UpdateFormValue is thrown for the entire form group, and we can't detect which control changed in the state (at time of writing)
    // So it's easier to implement this behavior in the component
    this.gridPointIdForm
      .get('customerId')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.gridPointIdForm.get('gridPointId').reset());
  }

  onFileSelected(fileUploadNode: any): void {
    this.facade.upload(fileUploadNode.files[0]);
  }

  onFileInputButtonClicked(fileInput: HTMLInputElement): void {
    fileInput.value = null;
    fileInput.click();
  }

  /**
   * Return true if the descriptive subtitle should be shown
   * This should happen if the step is not completed yet, or if the step is currently selected
   */
  showDescriptiveSubtitle(step: MatStep): boolean {
    return !step.completed || step === step._stepper.selected;
  }

  showValueSubtitle(step: MatStep): boolean {
    return !this.showDescriptiveSubtitle(step);
  }

  confirm(): void {
    this.facade.confirm(() => {
      this.stepper.reset();
    });
  }

  hasRemarks(row: any): boolean {
    return row?.remarks && Object.keys(row.remarks).length > 0;
  }
}
