import { State } from '@ngxs/store';
import { DeviceContactState } from './states/device-contact/device-contact.state';
import { DeviceContactsState } from './states/device-contacts/device-contacts.state';
import { DeviceState } from './states/device/device.state';
import { DevicesState } from './states/devices/devices.state';
import { Injectable } from '@angular/core';

export const DeviceStates = [DeviceState, DeviceContactsState, DeviceContactState, DevicesState];

@State({
  name: 'deviceStateModule',
  children: DeviceStates
})
@Injectable({
  providedIn: 'root'
})
export class DeviceStateModule {}
