import { State, Store } from '@ngxs/store';
import { AsyncMessagingService } from 'flex-app-shared';
import { SseOperationStartedEvent } from './sse-operation.actions';
import { Injectable } from '@angular/core';

@State({
  name: 'sseOperation',
  defaults: {}
})
@Injectable({
  providedIn: 'root'
})
export class SseOperationState {
  constructor(private asyncMessagingService: AsyncMessagingService, private store: Store) {
    asyncMessagingService.onMessageOfType(SseOperationStartedEvent.sseType).subscribe((event) => {
      store.dispatch(new SseOperationStartedEvent(JSON.parse(event.payload).id));
    });
  }
}
