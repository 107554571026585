<mat-card>
  <mat-card-header>
    <mat-card-title>BRP details</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="partyForm" class="single-column-form">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
        <mat-error *ngIf="partyForm.get('name').errors?.required"> Name is required. </mat-error>
        <mat-error *ngIf="partyForm.get('name').errors?.maxlength"> Name must not be longer than 50 characters. </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Code</mat-label>
        <input matInput formControlName="code" />
        <mat-error *ngIf="partyForm.get('code').errors?.required"> Code is required. </mat-error>
        <mat-error *ngIf="partyForm.get('code').errors?.maxlength"> Code must not be longer than 8 characters. </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>EAN</mat-label>
        <input matInput formControlName="ean" />
        <mat-error *ngIf="partyForm.get('ean').errors?.required"> EAN is required. </mat-error>
        <mat-error *ngIf="partyForm.get('ean').errors?.maxlength || partyForm.get('ean').errors?.minlength">
          EAN must be 13 characters.
        </mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="cta" (click)="onSubmit()" [disabled]="!partyForm.valid">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:save"></mat-icon>
        <span>Save</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
