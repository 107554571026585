import { Component, Inject, OnInit, Optional } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  ApplicationSetting,
  ApplicationSettingsService,
  CapacityApplicationSetting,
  isBooleanApplicationSetting,
  isCapacityApplicationSetting,
  isNumberApplicationSetting,
  isVolumeApplicationSetting,
  VolumeApplicationSetting
} from '../../shared/application-settings/application-settings.service';
import { Capacity, Volume } from 'flex-app-shared';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-application-setting',
  templateUrl: './edit-application-setting.component.html',
  styleUrls: ['./edit-application-setting.component.scss']
})
export class EditApplicationSettingComponent implements OnInit {
  public readonly settingForm = this.fb.group({
    value: ['', [Validators.required]],
    unit: ['']
  });

  public readonly units = {
    capacity: ['kW', 'MW'],
    volume: ['kWh', 'MWh']
  };

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: ApplicationSetting,
    private applicationSettingsService: ApplicationSettingsService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EditApplicationSettingComponent>
  ) {}

  static getValueAndUnit(value: number | string | boolean, unit?: string): number | string | boolean | Volume | Capacity {
    if (unit) return { value, unit } as Volume | Capacity;
    return value;
  }

  static getValue(setting: ApplicationSetting): number | string | boolean {
    if (isCapacityApplicationSetting(setting)) return Capacity.value(setting.value);
    if (isVolumeApplicationSetting(setting)) return setting.value.value;
    if (isBooleanApplicationSetting(setting)) return Boolean(setting.value);
    return setting.value;
  }

  static getUnitForSetting(setting: ApplicationSetting): string | undefined {
    if (isCapacityApplicationSetting(setting) || isVolumeApplicationSetting(setting)) return setting.value.unit;
    return undefined;
  }

  ngOnInit(): void {
    this.settingForm.patchValue({
      value: EditApplicationSettingComponent.getValue(this.data),
      unit: EditApplicationSettingComponent.getUnitForSetting(this.data)
    });
  }

  save(): void {
    const value: any = EditApplicationSettingComponent.getValueAndUnit(this.settingForm.value.value, this.settingForm.value.unit);
    const newSetting: ApplicationSetting = { ...this.data, value };

    this.dialogRef.close(newSetting);
  }

  isNumericValue(): boolean {
    return isNumberApplicationSetting(this.data) || isCapacityApplicationSetting(this.data) || isVolumeApplicationSetting(this.data);
  }

  isBooleanSetting(): boolean {
    return isBooleanApplicationSetting(this.data);
  }

  isSettingWithUnit(setting: ApplicationSetting): setting is VolumeApplicationSetting | CapacityApplicationSetting {
    return isCapacityApplicationSetting(setting) || isVolumeApplicationSetting(setting);
  }

  getUnitsListForSetting(setting: ApplicationSetting): ReadonlyArray<string> | never {
    if (isCapacityApplicationSetting(setting)) return this.units.capacity;
    if (isVolumeApplicationSetting(setting)) return this.units.volume;

    throw new Error(`Can't find proper units type for a setting`);
  }
}
