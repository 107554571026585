import { Recipient } from '../../../../app/shared/recipient/recipient';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DeleteRecipientCommand, LoadRecipientsCommand, ResetRecipientsCommand } from './recipients.actions';
import { RecipientService } from '../../../../app/shared/recipient/recipient.service';
import { delay, tap } from 'rxjs/operators';
import { EntityStateModel, FetchEntity, findParameterValue } from 'flex-app-shared';
import { Observable } from 'rxjs';
import { isNil } from 'lodash-es';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';

export class RecipientsStateModel extends EntityStateModel<Recipient[]> {
  model: Recipient[] = [];
}

@State({
  name: 'recipients',
  defaults: new RecipientsStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class RecipientsState {
  constructor(private activatedRoute: ActivatedRoute, private recipientService: RecipientService) {}

  @Selector()
  public static isBusy(state: RecipientsStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  static getRecipients(state: RecipientsStateModel): Array<Recipient> {
    return state.model;
  }

  @FetchEntity()
  @Action(LoadRecipientsCommand)
  loadRecipients({ patchState }: StateContext<RecipientsStateModel>): Observable<Recipient[]> {
    const selectedCustomerId = findParameterValue(this.activatedRoute.snapshot, 'customerId');

    // TODO (S.Panfilov) It's better to have an endpoint to request filtered recipients by customerId
    return this.recipientService.getAll().pipe(
      tap((recipientsModel) => {
        const model = isNil(selectedCustomerId)
          ? recipientsModel
          : recipientsModel.filter((recipient) => recipient.customerId === selectedCustomerId);
        patchState({ model });
      })
    );
  }

  @Action(ResetRecipientsCommand)
  resetRecipients({ setState }: StateContext<RecipientsStateModel>): void {
    setState(new RecipientsStateModel());
  }

  @Action(DeleteRecipientCommand)
  deleteRecipient({ dispatch }: StateContext<RecipientsStateModel>, event: DeleteRecipientCommand): Observable<any> {
    return this.recipientService.delete(event.recipient).pipe(
      delay(500),
      tap(() => dispatch(new LoadRecipientsCommand()))
    );
  }
}
