import { RestEntity } from 'flex-app-shared';

export class Recipient extends RestEntity {
  readonly pronoun?: string;
  readonly firstName: string;
  readonly lastNamePrefix?: string;
  readonly lastName: string;
  readonly mobileNumber: string;
  readonly email: string;
  readonly isEmployee?: boolean;
  readonly isMonthlyParticipationEmail: boolean;
  readonly customerId?: string;
  readonly useMobileNumber?: boolean;
  readonly useEmail?: boolean;
}
