import { Component, OnInit } from '@angular/core';
import { ConfigService, MixinBase, ObservableInputsMixin, OnDestroyMixin } from 'flex-app-shared';
import { takeUntil } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { Authority, Group, GroupAuthorities, GroupService } from '../shared/user/user.service';

@Component({
  selector: 'app-group-authorities',
  templateUrl: './group-authorities.component.html',
  styleUrls: ['./group-authorities.component.scss']
})
export class GroupAuthoritiesComponent extends ObservableInputsMixin(OnDestroyMixin(MixinBase)) implements OnInit {
  dataSource = new MatTableDataSource();
  groupAuthorities: GroupAuthorities = { groups: [], authorities: [] };

  internalGroupCount: number;
  externalGroupCount: number;
  displayedColumns: string[] = [];
  dirty: boolean = false;
  groupAuthoritiesEditable = false;

  constructor(private groupService: GroupService, private configService: ConfigService) {
    super();
  }

  ngOnInit(): void {
    this.groupService
      .getGroupAuthorities()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((groupAuthorities) => {
        this.dirty = false;
        this.groupAuthorities = groupAuthorities;
        this.dataSource.data = groupAuthorities.authorities;
        this.displayedColumns = this.groupAuthorities.groups.map((it) => it.groupName);
        this.internalGroupCount = this.groupAuthorities.groups.filter((it) => it.groupType === 'INTERNAL').length;
        this.externalGroupCount = this.groupAuthorities.groups.filter((it) => it.groupType === 'EXTERNAL').length;
      });
    this.configService.config$.subscribe((x) => (this.groupAuthoritiesEditable = x.groupAuthoritiesEditable ?? false));
  }

  onCellClick(group: Group, authority: Authority): void {
    if (!this.groupAuthoritiesEditable) {
      return;
    }
    authority.groups[group.groupName] = !authority.groups[group.groupName];
    this.dirty = true;
  }

  save(): void {
    this.groupService.updateGroupAuthorities(this.groupAuthorities).subscribe(() => {
      this.dirty = false;
    });
  }

  reset(): void {
    this.ngOnInit();
  }

  downloadJson(): void {
    this.groupService.downloadGroupAuthoritiesJson().subscribe(() => {});
  }

  downloadSql(): void {
    this.groupService.downloadGroupAuthoritiesSql().subscribe(() => {});
  }
}
