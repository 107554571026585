import { Unavailability, UnavailabilityView } from '../../../../app/shared/unavailability/unavailability';
import { SaveAction } from 'flex-app-shared';

export class ResetUnavailabilityCommand {
  public static readonly type = '[Unavailability] Reset unavailability action';

  constructor(public payload?: Partial<Unavailability>) {}
}

export class LoadUnavailabilityCommand {
  public static readonly type = '[Unavailability] Load unavailability data';

  constructor(public id: string) {}
}

export class UnavailabilityLoadedEvent {
  public static readonly type = '[Unavailability] Set unavailability data';

  constructor(public payload: Unavailability) {}
}

export class SaveUnavailabilityCommand implements SaveAction<UnavailabilityView> {
  public static readonly type = '[Unavailability] Save unavailability data';

  constructor(public payload: UnavailabilityView, public navigateAfterSave: boolean = true) {}
}
