import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-devices',
  templateUrl: './dashboard-devices.component.html',
  styleUrls: ['./dashboard-devices.component.scss']
})
export class DashboardDevicesComponent {
  @Input()
  public readonly isForbidden: boolean = false;

  @Input()
  public readonly isInitialized: boolean = false;

  @Input()
  private readonly customerId: string | undefined = undefined;

  private getUrl(): string {
    if (!this.customerId) return `/devices/new`;
    return `/dashboard/${this.customerId}/devices/new`;
  }
}
