import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'flex-app-shared';
import { distinctUntilChanged } from 'rxjs/operators';
import { DeviceFacade } from '../../store/devices/states/device/device.facade';
import { DevicesFacade } from '../../store/devices/states/devices/devices.facade';
import { Device, DeviceProviderType, DeviceService } from '../shared/device/device.service';

@Component({
  selector: 'app-device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.scss']
})
export class DeviceDetailComponent implements OnInit {
  @Input()
  isStandalone = false;

  isNew = false;
  private prevExternalId = '';

  DeviceProviderType = DeviceProviderType;

  deviceForm = this.builder.group({
    id: '',
    providerType: [DeviceProviderType.SERCOM, [Validators.required]],
    customerId: ['', [Validators.required]],
    externalId: ['', [Validators.required, Validators.pattern(/^RP\d{4}$/i)]]
  });

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private builder: UntypedFormBuilder,
    private customerService: CustomerService,
    private deviceService: DeviceService,
    private deviceFacade: DeviceFacade,
    private devicesFacade: DevicesFacade
  ) {}

  ngOnInit(): void {
    this.getDevice();
  }

  getDevice(): void {
    // TODO: handle device setup via state, not via initForm and such.
    const id = this.route.snapshot.paramMap.get('deviceId');
    if (id === 'new' || id === null) {
      this.isNew = true;
      this.devicesFacade.selectedCustomerId$.pipe(distinctUntilChanged()).subscribe((it) => {
        const device = { customerId: it } as Device;
        this.initForm(id, device);
      });
    } else {
      this.deviceService.getById(id).subscribe((device) => {
        this.initForm(id, device);
        this.prevExternalId = device.externalId;
      });
    }
  }

  onSubmit(): void {
    this.deviceFacade.save();
  }

  goBack(): void {
    this.location.back();
  }

  changeType(): void {
    if (this.deviceForm.get('providerType').value === DeviceProviderType.OTHER) {
      this.deviceForm.get('externalId').setValue('');
    } else {
      this.deviceForm.get('externalId').setValue(this.prevExternalId);
    }
    this.changeValidators();
  }

  allowExternalId(): boolean {
    return this.deviceForm.get('providerType').value === DeviceProviderType.SERCOM;
  }

  private initForm(id: string, device: Device): void {
    this.deviceForm.get('id').setValue(id);
    this.deviceForm.get('providerType').setValue(device.providerType);
    if (!this.isNew && device.providerType === DeviceProviderType.OTHER) {
      this.deviceForm.get('providerType').disable();
    }
    this.deviceForm.get('customerId').setValue(device.customerId);
    this.deviceForm.get('externalId').setValue(device.externalId);
    this.changeValidators();
  }

  private changeValidators(): void {
    const providerType = this.deviceForm.get('providerType').value;
    const externalId = this.deviceForm.get('externalId');
    if (providerType === undefined || providerType === DeviceProviderType.SERCOM) {
      externalId.setValidators([Validators.required, Validators.pattern(/^RP\d{4}$/i)]);
    } else {
      externalId.clearValidators();
    }
    externalId.updateValueAndValidity();
    this.setDeviceExternalIdDisabledState();
  }

  private setDeviceExternalIdDisabledState(): void {
    if (this.deviceForm.get('providerType').value === DeviceProviderType.OTHER) {
      this.deviceForm.get('externalId').disable();
    } else {
      this.deviceForm.get('externalId').enable();
    }
  }
}
