<nav id="navbar" #stickyMenu>
  <section class="flex-row">
    <mat-toolbar class="top-bar-menu">
      <button mat-stroked-button>Summary</button>
      <button mat-stroked-button [matMenuTriggerFor]="invoices">
        Invoices
        <mat-icon svgIcon="ic-old:chevron-down"></mat-icon>
      </button>
      <mat-menu #invoices="matMenu">
        <button mat-menu-item>Create new invoice</button>
        <button mat-menu-item>Invoice overview</button>
      </mat-menu>
      <button mat-stroked-button [matMenuTriggerFor]="contracts">
        Contracts
        <mat-icon svgIcon="ic-old:chevron-down"></mat-icon>
      </button>
      <mat-menu #contracts="matMenu">
        <button mat-menu-item>Create new contract</button>
        <button mat-menu-item>Contract overview</button>
      </mat-menu>
      <button mat-stroked-button>Documents</button>
      <button mat-stroked-button>Accesspoint</button>
      <button mat-stroked-button>Contact information</button>
      <button mat-stroked-button>Users</button>
      <button mat-stroked-button>Tasks</button>
    </mat-toolbar>
  </section>
</nav>

<section class="flex-row">
  <mat-card class="col-1">
    <mat-card-content>
      <div class="flex-row nested">
        <div class="col-1">
          <h3>Contact information</h3>
          <p>
            Reeweg Oost 123 <br />
            3311 CE, Dordrecht <br />
            Nederland
          </p>
          <p>
            010 829 0029 <br />
            +31 06 123 13 111 <br />
            contact&#64;klant.nl
          </p>
        </div>
        <div class="col-1">
          <div class="flex-col">
            <div class="col-1 nested-box">
              <h3>Open Task</h3>
              <p>
                I.e. list of active/recent tasks here. <br />
                Example of task process indicators below.
              </p>
              <div class="process-track">
                <span style="width: 55%" class="track-neutral"></span>
                <span style="width: 25%" class="track-success"></span>
                <span style="width: 13%" class="track-warn"></span>
                <span style="width: 7%" class="track-alert"></span>
              </div>
            </div>
            <div class="col-1 nested-box">
              <h3>Contracten</h3>
              <p>I.e. display of recent contracts or KPI's here.</p>
            </div>
            <div class="col-1 nested-box">
              <h3>Recent documents</h3>
              <p>I.e. list of recent documents.</p>
            </div>
          </div>
        </div>

        <div class="col-1">
          <div class="flex-col">
            <div class="col-1 nested-box">
              <h3>Recent invoices</h3>
              <p>I.e. list of recent documents.</p>
              <div class="process-track">
                <span style="width: 25%" class="track-neutral"></span>
                <span style="width: 55%" class="track-success"></span>
                <span style="width: 5%" class="track-warn"></span>
                <span style="width: 15%" class="track-alert"></span>
              </div>
            </div>
            <div class="col-2 nested-box">
              <h3>Activity</h3>
              <p>I.e. list of recent activity / log.</p>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</section>

<h2>Invoices</h2>
<section class="flex-row">
  <mat-card class="col-1">
    <mat-card-content> Invoices </mat-card-content>
  </mat-card>
</section>

<h2>Contracts</h2>
<section class="flex-row">
  <mat-card class="col-1">
    <mat-card-content> Contracts </mat-card-content>
  </mat-card>
</section>

<section class="flex-row">
  <mat-card class="col-1"> Voorbeeld 1/2 breed </mat-card>
  <mat-card class="col-1"> Voorbeeld 1/2 breed </mat-card>
</section>

<section class="flex-row">
  <mat-card class="col-1"> Voorbeeld 1/3 breed </mat-card>
  <mat-card class="col-1"> Voorbeeld 1/3 breed </mat-card>
  <mat-card class="col-1"> Voorbeeld 1/3 breed </mat-card>
</section>

<section class="flex-row">
  <mat-card class="col-1"> Voorbeeld 1/4 breed </mat-card>
  <mat-card class="col-1"> Voorbeeld 1/4 breed </mat-card>
  <mat-card class="col-1"> Voorbeeld 1/4 breed </mat-card>
  <mat-card class="col-1"> Voorbeeld 1/4 breed </mat-card>
</section>

<section class="flex-row">
  <mat-card class="col-1"> Voorbeeld 1/3 breed. </mat-card>
  <mat-card class="col-2"> Voorbeeld 2/3 breed. </mat-card>
</section>

<section class="flex-row">
  <mat-card class="col-2"> Voorbeeld 2/5 breed. </mat-card>
  <mat-card class="col-1"> Voorbeeld 1/5 breed. </mat-card>
  <mat-card class="col-2"> Voorbeeld 2/5 breed. </mat-card>
</section>
