<mat-card class="widget mat-card dashboard-component -devices" *ngIf="isInitialized">
  <mat-card-title> Devices </mat-card-title>
  <mat-card-content>
    <app-devices [defaultPageSize]="10" [isStandalone]="true" [isDashboard]="true" *ngIf="!isForbidden"></app-devices>
    <app-forbidden-message [isDisabled]="isForbidden"></app-forbidden-message>
  </mat-card-content>

  <mat-card-footer class="fixed-to-bottom-actions-helper">
    <mat-card-actions class="fixed-to-bottom-actions" *ngIf="!isForbidden">
      <button mat-stroked-button routerLink="/devices/">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:overview"></mat-icon>
          <span>Devices overview</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="primary" [routerLink]="getUrl()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:add"></mat-icon>
          <span>New Device</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card-footer>
</mat-card>
