<mat-card>
  <mat-card-content>
    <mat-toolbar class="filter-bar">
      <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
    </mat-toolbar>

    <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let company">
          <a routerLink="/measurement-companies/{{ company.id }}">{{ company.name }}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="measurementProvider">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Provider</th>
        <td mat-cell *matCellDef="let company">{{ company.measurementProvider }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let company">
          <ph-flex-vertical-center>
            <button mat-icon-button matTooltip="Edit" routerLink="/measurement-companies/{{ company.id }}">
              <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
            </button>
            <button mat-icon-button matTooltip="Delete" class="remove" (click)="delete(company)">
              <mat-icon svgIcon="ic-old:trash-o"></mat-icon>
            </button>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
  </mat-card-content>
  <mat-card-actions>
    <button mat-flat-button color="primary" routerLink="/measurement-companies/new">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:add"></mat-icon>
        <span>New measurement company</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
