import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export function registerGlobalAppInsights(appName: 'flex-app-admin' | 'flex-app-customer-portal', router: Router): void {
  // @ts-ignore
  const key = window.instrumentationKey;
  // @ts-ignore
  const prefix = window.instrumentationRolePrefix ?? '';
  if (!key) {
    console.warn('Application Insights NOT loaded');
    return;
  }
  const angularPlugin = new AngularPlugin();
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: key,
      extensions: [angularPlugin as any],
      extensionConfig: {
        [angularPlugin.identifier]: { router }
      }
    }
  });
  appInsights.loadAppInsights();
  appInsights.addTelemetryInitializer((envelop) => {
    envelop.tags['ai.cloud.role'] = prefix + appName;
  });
}
