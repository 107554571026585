import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Battery, BatteryService } from '../shared/battery/battery.service';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Capacity, CustomValidators, Volume } from 'flex-app-shared';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'app-battery-detail',
  templateUrl: './battery-detail.component.html',
  styleUrls: ['./battery-detail.component.scss']
})
export class BatteryDetailComponent implements OnInit {
  batteryIds: string[] = [];

  batteryForm = this.builder.group({
    id: '',
    externalId: ['', [Validators.required, Validators.maxLength(100)]],
    name: ['', [Validators.required, Validators.maxLength(250)]],
    description: ['', Validators.maxLength(500)],
    capacity: ['', [Validators.min(0), Validators.max(100000)]],
    maximumPower: ['', [Validators.min(0), Validators.max(100000), CustomValidators.numberOfDecimals(0, 0)]]
  });

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private builder: UntypedFormBuilder,
    private batteryService: BatteryService
  ) {}

  ngOnInit(): void {
    this.getBattery();
    this.getBatteryIds();
  }

  getBattery(): void {
    const id = this.route.snapshot.paramMap.get('batteryId');
    if (id === 'new') {
      this.initForm(id, new Battery());
    } else {
      this.batteryService.getById(id).subscribe((battery) => this.initForm(id, battery));
    }
  }

  private getBatteryIds(): void {
    this.batteryService.getAllBatteryIds().subscribe((result: string[]) => {
      this.batteryIds = result;
    });
  }

  onSubmit(): void {
    const value = cloneDeep(this.batteryForm.value);
    const result = new Battery();
    result.id = value.id === 'new' ? null : value.id;
    result.name = value.name;
    result.externalId = value.externalId;
    result.description = value.description;
    result.maximumPower = value.maximumPower === '' ? null : Capacity.kW(value.maximumPower);
    result.capacity = value.capacity === '' ? null : new Volume(value.capacity, 'kWh');
    if (this.batteryForm.value.id === 'new') {
      this.batteryService.add(result).subscribe(() => {
        this.batteryForm.reset();
        this.goBack();
      });
    } else {
      this.batteryService.update(result).subscribe(() => {
        this.batteryForm.reset();
        this.goBack();
      });
    }
  }

  goBack(): void {
    this.location.back();
  }

  private initForm(id: string, battery: Battery): void {
    this.batteryForm.get('id').setValue(id);
    this.batteryForm.get('name').setValue(battery.name);
    this.batteryForm.get('description').setValue(battery.description);
    this.batteryForm.get('externalId').setValue(battery.externalId);
    if (battery.capacity) {
      this.batteryForm.get('capacity').setValue(battery.capacity.value);
    }
    if (battery.maximumPower) {
      this.batteryForm.get('maximumPower').setValue(Capacity.asKW(battery.maximumPower));
    }
  }
}
