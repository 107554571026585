import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { R3InvoiceDataState } from './r3-invoice-data.state';
import {
  R3InvoiceDataRecalculateCommand,
  R3InvoiceDataRecalculateForServiceAgreementCommand,
  R3InvoiceDownloadDataCommand
} from './r3-invoice-data.actions';
import { Observable } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class R3InvoiceDataFacade {
  constructor(private store: Store) {}

  displayedColumns$ = this.store.select(R3InvoiceDataState.displayedColumns);
  dateColumns$ = this.store.select(R3InvoiceDataState.dateColumns);

  data$ = this.store.select(R3InvoiceDataState.data);
  busy$ = this.store.select(R3InvoiceDataState.busy);
  busyDownloading$ = this.store.select(R3InvoiceDataState.busyDownloading);
  busyRecalculatingAll$ = this.store.select(R3InvoiceDataState.busyRecalculatingAll);
  canDownload$ = this.store.select(R3InvoiceDataState.canDownload);
  canRecalculate$ = this.store.select(R3InvoiceDataState.canRecalculate);

  downloadData(): void {
    this.store.dispatch(new R3InvoiceDownloadDataCommand());
  }

  recalculate(dialogRef: MatDialogRef<any>): void {
    this.store.dispatch(new R3InvoiceDataRecalculateCommand(dialogRef));
  }

  recalculateForServiceAgreement(serviceAgreementId: string, dialogRef: MatDialogRef<any>): void {
    this.store.dispatch(new R3InvoiceDataRecalculateForServiceAgreementCommand(serviceAgreementId, dialogRef));
  }

  busyRecalculatingFor$(serviceAgreementId: string): Observable<boolean> {
    return this.store.select(R3InvoiceDataState.busyRecalculatingFor(serviceAgreementId));
  }
}
