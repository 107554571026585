import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { get } from 'lodash-es';
import {
  BalanceResponsibleParty,
  BalanceResponsiblePartyService
} from '../shared/balance-responsible-party/balance-responsible-party.service';
import { Capacity, Customer, CustomerService, GridPoint } from 'flex-app-shared';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GridPointFacade } from '../../store/grid-points/grid-point.facade';
import { GridPointsFacade } from '../../store/grid-points/grid-points.facade';
import { Location } from '@angular/common';

@Component({
  selector: 'app-grid-points',
  templateUrl: './grid-points.component.html',
  styleUrls: ['./grid-points.component.scss']
})
export class GridPointsComponent implements OnInit, AfterViewInit, OnDestroy {
  customers: ReadonlyArray<Customer>;
  parties: ReadonlyArray<BalanceResponsibleParty>;
  displayedColumns: ReadonlyArray<string> = [
    'ean',
    'description',
    'customer',
    'brp',
    'measurementCompany',
    'pulseProductionPeak',
    'pulseConsumptionPeak',
    'fiveMinuteDataAvailable',
    'automatic',
    'preQualified',
    'gopacsPreQualified',
    'actions'
  ];
  private readonly onDestroy$ = new Subject<void>();

  @Input()
  public readonly defaultPageSize: number = 30;

  @Input()
  public readonly isStandalone: boolean = false;

  @Input()
  public readonly isDashboard: boolean = false;

  dataSource: MatTableDataSource<GridPoint> = new MatTableDataSource<GridPoint>([]);
  @ViewChild(MatPaginator, { static: false })
  private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) private readonly sort: MatSort;

  constructor(
    public gridPointsFacade: GridPointsFacade,
    private customerService: CustomerService,
    private balanceResponsiblePartyService: BalanceResponsiblePartyService,
    private gridPointFacade: GridPointFacade,
    private router: Router,
    private location: Location
  ) {}

  goBack(): void {
    this.location.back();
  }

  ngOnInit(): void {
    this.gridPointsFacade.init();

    this.gridPointsFacade.gridPoints$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((gridPoints) => (this.dataSource.data = gridPoints.concat()));

    if (this.isStandalone) {
      this.displayedColumns = this.displayedColumns.filter((it) => it !== 'customer');
    } else {
      this.customerService.getAll().subscribe((customers) => (this.customers = customers));
    }

    if (this.isDashboard) {
      this.displayedColumns = this.displayedColumns.filter((it) => it !== 'description');
      this.displayedColumns = this.displayedColumns.filter((it) => it !== 'measurementCompany');
      this.displayedColumns = this.displayedColumns.filter((it) => it !== 'brp');
      this.displayedColumns = this.displayedColumns.filter((it) => it !== 'preQualified');
      this.displayedColumns = this.displayedColumns.filter((it) => it !== 'gopacsPreQualified');
    }

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'description':
          return data.description.toLowerCase();
        case 'customer':
          return data.customerName;
        case 'brp':
          return data.balanceResponsiblePartyName;
        case 'measurementCompany':
          return data.measurementCompanyName;
        case 'automatic':
          return data.measurementMethodAutomatic;
        case 'preQualified':
          return data.preQualified;
        case 'gopacsPreQualified':
          return data.gopacsPreQualified;
        case 'pulseConsumptionPeak':
          return Capacity.asKW(data.pulseConsumptionPeak);
        case 'pulseProductionPeak':
          return Capacity.asKW(data.pulseProductionPeak);
        default:
          return get(data, sortHeaderId);
      }
    };

    this.setFilterPredicate();
    this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.gridPointsFacade.init();
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }

  setFilterPredicate(): void {
    this.dataSource.filterPredicate = (data: GridPoint, filterValue: string) => {
      const { ean, description, customerName, balanceResponsiblePartyName } = data;
      return `${ean} ${description} ${customerName} ${balanceResponsiblePartyName}`
        .toLowerCase()
        .includes(filterValue.trim().toLowerCase());
    };
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  delete(gridPoint: GridPoint): void {
    this.gridPointFacade.delete(gridPoint);
  }

  edit(gridPointId: string): void {
    this.gridPointsFacade.loadGridPoint(gridPointId, true);
  }

  editMeasurementConfig(gridPoint: GridPoint): void {
    // TODO: should also work in customer-stepper
    this.router.navigate([`/grid-points/${gridPoint.id}/measurement-configuration/${gridPoint.customerId}`]);
  }

  retrieveMeasurementData(gridPointId: string): void {
    // TODO: should also work in customer-stepper
    this.router.navigate([`/grid-points/${gridPointId}/retrieve-measurement-data`]);
  }

  downloadGridPointsOverview(): void {
    this.gridPointsFacade.downloadGridPointOverview();
  }
}
