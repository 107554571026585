import { Component, Inject, OnInit, Optional } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PoolPeriodView } from '../shared/pool/pool.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { PoolPeriodFacade } from '../../store/pool/states/pool-period/pool-period.facade';
import { DateAndTime, LocalDate } from 'flex-app-shared';
import { CrossFieldErrorMatcher } from '../shared/common/common';
import moment from 'moment';
import { PoolFacade } from '../../store/pool/states/pool/pool.facade';
import { Subscription } from 'rxjs';
import { clone, orderBy } from 'lodash-es';

function validateDateTime(control: AbstractControl): ValidationErrors | null {
  const startDateAndTime = DateAndTime.toMoment(control.get('startDate').value);
  const toDateAndTime = DateAndTime.toMoment(control.get('endDate').value);

  if (!startDateAndTime || !toDateAndTime) {
    return;
  }

  const startAfterEnd = startDateAndTime.isBefore(toDateAndTime);

  if (!startAfterEnd) {
    return {
      toDateAfterStartDate: true
    };
  }
}

@Component({
  selector: 'app-pool-period-dialog',
  templateUrl: './pool-period-dialog.component.html',
  styleUrls: ['./pool-period-dialog.component.scss']
})
export class PoolPeriodDialogComponent implements OnInit {
  endDateErrorStateMatcher = new CrossFieldErrorMatcher(['', 'endDate.date'], ['toDateAfterStartDate', 'required']);

  form = this.formBuilder.group(
    {
      startDate: this.formBuilder.group({
        date: ['', Validators.required]
      }),
      endDate: this.formBuilder.group({
        date: ['', Validators.required]
      })
    },
    { validators: validateDateTime }
  );

  displayedColumns = ['select', 'ean', 'customer'];
  selection = new SelectionModel<string>(true, []);

  selectionDelta = new Map<string, boolean>();

  isEdit = !!this.data;

  gridPoints: any[] = [];

  resetSelection = false;
  isEclipsed = false;
  subscription: Subscription = null;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: PoolPeriodView,
    public dialogRef: MatDialogRef<PoolPeriodDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private poolPeriodFacade: PoolPeriodFacade,
    private poolFacade: PoolFacade
  ) {}

  ngOnInit(): void {
    if (this.data) {
      // Edit
      this.selection.select(...this.data.gridPointIds);

      this.form.patchValue(this.data.period);
    } else {
      // New
      this.form.patchValue({
        startDate: DateAndTime.fromMoment(moment().add(1, 'month').startOf('month')),
        endDate: DateAndTime.fromMoment(moment().add(1, 'month').endOf('month'))
      });

      this.resetSelection = true;
    }

    this.poolFacade.initPoolPeriod(this.form.value);

    this.poolPeriodFacade.init(this.dialogRef.id);
    this.poolFacade.gridPoints$.pipe(distinctUntilChanged()).subscribe((gridPoints) => {
      this.gridPoints = clone(gridPoints);
      this.selectionDelta.clear();

      if (this.resetSelection) {
        this.resetSelection = false;

        this.selection.clear();
        this.selection.select(...this.gridPoints.filter((gridPoint) => gridPoint.available).map((gridPoint) => gridPoint.id));

        this.gridPoints = this.gridPoints.map((gridPoint) => {
          return {
            ...gridPoint,
            active: this.selection.isSelected(gridPoint.id)
          };
        });
      } else {
        const selection = this.selection;

        this.gridPoints = this.gridPoints.map((gridPoint) => {
          return {
            ...gridPoint,
            isNewlySelected: gridPoint.available && !selection.isSelected(gridPoint.id),
            isNewlyDeselected: !gridPoint.available && selection.isSelected(gridPoint.id),
            active: selection.isSelected(gridPoint.id)
          };
        });
      }

      this.gridPoints = orderBy(
        this.gridPoints,
        ['isNewlySelected', 'isNewlyDeselected', 'active', 'customerName', 'ean'],
        ['desc', 'desc', 'desc', 'asc', 'asc']
      );
    });
    this.form.valueChanges.subscribe((value) =>
      this.poolPeriodFacade.setPeriod({
        period: value,
        gridPointIds: [],
        sent: false
      })
    );
    this.subscription = this.poolPeriodFacade.isEclipsed$.subscribe((isEclipsed) => (this.isEclipsed = isEclipsed));
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.gridPoints.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ? this.selection.clear() : this.gridPoints.forEach((row) => this.selection.select(row.id));
  }

  onSubmit(): void {
    this.poolPeriodFacade.save({
      period: {
        ...this.form.value
      },
      gridPointIds: this.selection.selected,
      sent: false
    });
  }

  isPeriodSensible(): boolean {
    return this.isStartOneHourInFuture() && !this.isEclipsed;
  }

  getPeriodTooltip(): string {
    if (!this.isStartOneHourInFuture()) {
      return 'Only a part of this period can be sent to Tennet, since it starts before one hour in the future.';
    }
    if (this.isEclipsed) {
      return 'One or more existing period(s) are overwritten.';
    }
  }

  resetGridPoints(): void {
    this.resetSelection = true;
    this.poolFacade.initPoolPeriod(this.form.value);
  }

  onCancel(): void {
    this.poolPeriodFacade.close();
  }

  isNewlySelected(gridPoint: { isNewlySelected: boolean }): boolean {
    return gridPoint.isNewlySelected;
  }

  isNewlyDeselected(gridPoint: { isNewlyDeselected: boolean }): boolean {
    return gridPoint.isNewlyDeselected;
  }

  private isStartOneHourInFuture(): boolean {
    const startDate = LocalDate.toMoment(this.form.get('startDate').value);
    return !(startDate && startDate.isBefore(moment().add(1, 'hour')));
  }
}
