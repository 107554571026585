<mat-card>
  <mat-card-header>
    <mat-card-title>Operation details</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="operationForm" class="single-column-form">
      <mat-form-field readonly>
        <mat-label>Reference</mat-label>
        <input matInput readonly formControlName="reference" />
      </mat-form-field>
      <div formGroupName="notificationTime" class="flex-row compact">
        <mat-form-field class="col-1">
          <mat-label>Notification date</mat-label>
          <input matInput [matDatepicker]="notificationDatepicker" formControlName="date" />
          <mat-datepicker-toggle matSuffix [for]="notificationDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #notificationDatepicker></mat-datepicker>
          <mat-error *ngIf="operationForm.get('notificationTime.date').errors?.required"> Notification date is required </mat-error>
        </mat-form-field>
        <mat-form-field class="col-1">
          <mat-label>Notification time</mat-label>
          <input matInput formControlName="time" />
          <mat-hint>Example: 13:00</mat-hint>
          <mat-error *ngIf="operationForm.get('notificationTime.time').errors?.required"> Notification time is required </mat-error>
          <mat-error *ngIf="operationForm.get('notificationTime.time').errors?.invalidTime">
            Notification time should be formatted as hour:minute (hh:mm), for example 01:20
          </mat-error>
        </mat-form-field>
      </div>
      <div formGroupName="startTime" class="flex-row compact">
        <mat-form-field class="col-1">
          <mat-label>Start date</mat-label>
          <input matInput [matDatepicker]="startDatepicker" formControlName="date" />
          <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatepicker></mat-datepicker>
          <mat-error *ngIf="operationForm.get('startTime.date').errors?.required"> Start date is required </mat-error>
        </mat-form-field>
        <mat-form-field class="col-1">
          <mat-label>Start time</mat-label>
          <input matInput formControlName="time" />
          <mat-hint>Example: 13:00</mat-hint>
          <mat-error *ngIf="operationForm.get('startTime.time').errors?.required"> Start time is required </mat-error>
          <mat-error *ngIf="operationForm.get('startTime.time').errors?.invalidTime">
            Start time should be formatted as hour:minute (hh:mm), for example 01:20
          </mat-error>
        </mat-form-field>
      </div>

      <div formGroupName="endTime" class="flex-row compact">
        <mat-form-field class="col-1">
          <mat-label>End date</mat-label>
          <input matInput [matDatepicker]="endDatepicker" formControlName="date" />
          <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatepicker></mat-datepicker>
          <mat-error *ngIf="operationForm.get('endTime.date').errors?.required"> End date is required </mat-error>
        </mat-form-field>
        <mat-form-field class="col-1">
          <mat-label>End time</mat-label>
          <input matInput formControlName="time" />
          <mat-hint>Example: 13:00</mat-hint>
          <mat-error *ngIf="operationForm.get('endTime.time').errors?.required"> End time is required </mat-error>
          <mat-error *ngIf="operationForm.get('endTime.time').errors?.invalidTime">
            End time should be formatted as hour:minute (hh:mm), for example 01:20
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field>
        <mat-label>Direction</mat-label>
        <mat-select formControlName="direction">
          <mat-option value="UPWARDS">Upwards</mat-option>
          <mat-option value="DOWNWARDS">Downwards</mat-option>
        </mat-select>
        <mat-error *ngIf="operationForm.get('direction').errors?.required"> Direction is required </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Requested power</mat-label>
        <ph-flex-number-input formControlName="requestedPower"></ph-flex-number-input>
        <span matTextSuffix>MW</span>
        <mat-error *ngIf="operationForm.get('requestedPower').errors?.required"> Requested power is required </mat-error>
        <mat-error *ngIf="operationForm.get('requestedPower').errors?.min"> Requested power should be at least 0 </mat-error>
        <mat-error *ngIf="operationForm.get('requestedPower').errors?.max"> Requested power should be at most 9999.99 MW</mat-error>
        <mat-error *ngIf="operationForm.get('requestedPower').errors?.invalidNumber"> Not a valid number </mat-error>
        <mat-error *ngIf="operationForm.get('requestedPower').errors?.invalidNumberOfDecimals"> Only two decimals are allowed </mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <span
      *ngIf="showSendOperationStartMessage(); let showSendOperationStartMessage"
      [matTooltip]="showSendOperationStartMessage"
      [matTooltipDisabled]="showSendOperationStartMessage === true"
    >
      <button
        mat-flat-button
        color="primary"
        (click)="manuallySendStartOperationMessage()"
        [disabled]="showSendOperationStartMessage !== true"
      >
        Send start operation message
      </button>
    </span>
    <span
      *ngIf="showSendOperationEndMessage(); let showSendOperationEndMessage"
      [matTooltip]="showSendOperationEndMessage"
      [matTooltipDisabled]="showSendOperationEndMessage === true"
    >
      <button mat-flat-button color="primary" (click)="manuallySendEndOperationMessage()" [disabled]="showSendOperationEndMessage !== true">
        Send end operation message
      </button>
    </span>
    <span
      [matTooltip]="showSendToDevices"
      [matTooltipDisabled]="showSendToDevices === true"
      *ngIf="showSendToDevices(); let showSendToDevices"
    >
      <button mat-flat-button color="primary" [disabled]="showSendToDevices !== true" (click)="sendToDevices()">Send to devices</button>
    </span>
    <button mat-flat-button color="cta" (click)="onSubmit()" [disabled]="operationForm.invalid">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:save"></mat-icon>
        <span>Save</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>

<app-operation-pool></app-operation-pool>

<mat-card *ngIf="isShowMeasurementData">
  <mat-card-header>
    <mat-card-title>Measurements chart</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <app-operation-measurements></app-operation-measurements>
  </mat-card-content>
</mat-card>
