<mat-dialog-content [ngSwitch]="data">
  <p>
    <ng-container *ngSwitchCase="undefined">Are you sure you want to recalculate for <em>all</em> service agreements? </ng-container>
    <ng-container *ngSwitchDefault
      >Are you sure you want to recalculate for customer <em>{{ data.customerName }}</em> with SA system reference
      <em>{{ data.customerSaReference }}</em
      >?
    </ng-container>
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button (click)="close()">Close</button>
  <ph-flex-save-button
    [pending]="facade.busyDownloading$ | async"
    (clickChanged)="confirm()"
    [disabled]="!(facade.canRecalculate$ | async)"
  >
    <ph-flex-vertical-center>
      <mat-icon svgIcon="ic-old:refresh"></mat-icon>
      <span>Recalculate</span>
    </ph-flex-vertical-center>
  </ph-flex-save-button>
</mat-dialog-actions>
