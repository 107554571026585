import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss']
})
export class PageTitleComponent {
  @Input() type: 'toolbar' | 'sidebar' = 'toolbar';
  @Input() page:
    | '/customer-stepper'
    | '/dashboard'
    | '/customers'
    | '/balance-responsible-parties'
    | '/devices'
    | '/grid-points'
    | '/measurement-companies'
    | '/measurement-data-download'
    | '/messages'
    | '/operations'
    | '/service-agreements'
    | '/recipients'
    | '/tso-agreements'
    | '/tso-auctions'
    | '/incident-reserve-notice'
    | '/r3-invoice-data'
    | '/incident-reserve-invoice-results'
    | '/aggregated-pool'
    | '/batteries'
    | '/battery-measurement-data'
    | '/prototype'
    | '/intraday/imbalance-correction'
    | '/intraday/invoice-results'
    | '/messages'
    | '/group-authorities';
}
