import { Injectable } from '@angular/core';
import { filter, first, switchMap } from 'rxjs/operators';
import { AggregatedUnavailabilitiesState } from './aggregated-unavailabilities.state';
import { Store } from '@ngxs/store';
import {
  ClearSentUnavailabilitiesCommand,
  LoadAggregatedUnavailabilitiesCommand,
  SendUnavailabilitiesCommand
} from './aggregated-unavailabilities.actions';

@Injectable({
  providedIn: 'root'
})
export class AggregatedUnavailabilitiesFacade {
  aggregatedUnavailabilities$ = this.store.select(AggregatedUnavailabilitiesState.isInitialized).pipe(
    filter(a => a),
    first(),
    switchMap(() => this.store.select(AggregatedUnavailabilitiesState.getAggregatedUnavailabilities))
  );

  constructor(private store: Store) {}

  clear(): void {
    this.store.dispatch(new ClearSentUnavailabilitiesCommand());
  }

  send(): void {
    this.store.dispatch(new SendUnavailabilitiesCommand());
  }

  init(): void {
    this.store.dispatch(new LoadAggregatedUnavailabilitiesCommand());
  }
}
