import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { AdjustmentDirection, Capacity, DownloadService, Period, RestEntity } from 'flex-app-shared';
import { Observable } from 'rxjs';
import { JsonProperty, JsonObject } from 'typescript-json-serializer';
import moment from 'moment';

@JsonObject()
export class CalendarPeriod {
  @JsonProperty()
  startDate: Date;
  @JsonProperty()
  endDate: Date;
  @JsonProperty()
  timeZone: string;
}

@JsonObject()
export class AggregatedPoolPeriod {
  @JsonProperty()
  period: CalendarPeriod;
  @JsonProperty()
  minimum: Capacity;
  @JsonProperty({ type: Capacity })
  hourlyCapacity: Capacity[];
}

@JsonObject()
export class AggregatedPool extends RestEntity {
  @JsonProperty()
  period: CalendarPeriod;
  @JsonProperty()
  direction: AdjustmentDirection;
  @JsonProperty()
  minimum: Capacity;
  @JsonProperty({ type: AggregatedPoolPeriod })
  poolPeriods: AggregatedPoolPeriod[];
}

@JsonObject()
export class AvailablePowerGridPoint {
  @JsonProperty()
  ean: string;
  @JsonProperty()
  description: string;
}

@JsonObject()
export class AvailablePower {
  @JsonProperty()
  customer: string;
  @JsonProperty()
  serviceAgreement: number;
  @JsonProperty()
  upwardsCapacity: Capacity | null;
  @JsonProperty()
  downwardsCapacity: Capacity | null;
  @JsonProperty()
  upwardsTimetable: boolean;
  @JsonProperty()
  downwardsTimetable: boolean;
  @JsonProperty({ type: AvailablePowerGridPoint })
  gridPoints: AvailablePowerGridPoint[];
  @JsonProperty()
  voluntaryR3Participation: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AggregatedPoolService {
  constructor(private http: HttpClient, private downloadService: DownloadService) {}

  getAggregatedPool(period: Period): Observable<any[]> {
    let params = new HttpParams();
    params = params.set('startDate', moment(period.startDate).format('YYYY-MM-DD'));
    params = params.set('endDate', moment(period.endDate).format('YYYY-MM-DD'));
    return this.http.get<any[]>('/api/v1/aggregated-pools', { params });
  }

  getAvailablePower(period: Period): Observable<any[]> {
    let params = new HttpParams();
    params = params.set('startDate', moment(period.startDate).format('YYYY-MM-DD'));
    params = params.set('endDate', moment(period.endDate).format('YYYY-MM-DD'));
    return this.http.get<any[]>('/api/v1/aggregated-pools/available-power', {
      params
    });
  }

  downloadRecipientData(period: CalendarPeriod): Observable<HttpResponse<Blob>> {
    let params = new HttpParams();
    params = params.set('startDate', moment(period.startDate).format('YYYY-MM-DD'));
    params = params.set('endDate', moment(period.endDate).format('YYYY-MM-DD'));

    return this.downloadService.download(
      this.http.get<Blob>('/api/v1/aggregated-pools/available-power/recipients', {
        params,
        observe: 'response',
        responseType: 'blob' as 'json'
      })
    );
  }
}
