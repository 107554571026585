<mat-card>
  <mat-card-header>
    <mat-card-title>Contacts</mat-card-title>
    <mat-card-subtitle *ngIf="!isStandalone">Device external Id: {{ (device$ | async)?.externalId }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="dataSource" class="has-actions" matSort [trackBy]="trackBy">
      <ng-container matColumnDef="description">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Description</th>
        <td mat-cell *matCellDef="let contact">
          <a href="" (click)="edit(contact); $event.preventDefault()">{{ contact.description }}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="externalId">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>External ID</th>
        <td mat-cell *matCellDef="let contact">{{ contact.externalId }}</td>
      </ng-container>
      <ng-container matColumnDef="invertedSchedules">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Inverted schedules</th>
        <td mat-cell *matCellDef="let contact" [ngSwitch]="contact.invertedSchedules">
          <ph-flex-vertical-center>
            <ng-container *ngSwitchCase="true">
              <mat-icon class="status-success" svgIcon="ic-old:check"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <mat-icon class="status-passive" svgIcon="ic-old:close"></mat-icon>
            </ng-container>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let contact; let i = index">
          <ph-flex-vertical-center>
            <button mat-icon-button matTooltip="Edit" (click)="edit(contact)">
              <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
            </button>
            <button mat-icon-button matTooltip="Delete" class="remove" (click)="delete(contact)">
              <mat-icon svgIcon="ic-old:trash-o"></mat-icon>
            </button>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-card-content>
  <mat-card-actions>
    <button *ngIf="!isStandalone" mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <button *ngIf="isStandalone" mat-stroked-button (click)="hideDeviceDetails()">Cancel</button>
    <button mat-flat-button color="primary" (click)="add()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:add"></mat-icon>
        <span>Add new contact</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
