<ng-container *ngIf="!isStandalone">
  <mat-card>
    <mat-card-content>
      <ng-container [ngTemplateOutlet]="pageContents"></ng-container>
    </mat-card-content>
    <mat-card-actions>
      <button *ngIf="!isStandalone" mat-stroked-button (click)="goBack()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
          <span>Go back</span>
        </ph-flex-vertical-center>
      </button>
      <ph-flex-save-button
        [pending]="busyDownloading$ | async"
        (clickChanged)="downloadData()"
        [disabled]="!(canDownload$ | async)"
        type="stroked"
        color="secondary"
      >
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:download"></mat-icon>
          <span>Download users data</span>
        </ph-flex-vertical-center>
      </ph-flex-save-button>
      <button mat-flat-button color="primary" routerLink="/users/new">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:user-add"></mat-icon>
          <span>New user</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>

<ng-container *ngIf="isStandalone" [ngTemplateOutlet]="pageContents"></ng-container>

<ng-template #pageContents>
  <mat-toolbar class="filter-bar">
    <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
  </mat-toolbar>

  <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
    <ng-container matColumnDef="userName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>User name</th>
      <td mat-cell *matCellDef="let user">
        <a routerLink="/users/{{ user.id }}">{{ user.userName }}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="active">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
      <td mat-cell *matCellDef="let user" [ngSwitch]="user.active">
        <ph-flex-vertical-center>
          <ng-container *ngSwitchCase="true">
            <mat-icon class="status-success" svgIcon="ic-old:check"></mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <mat-icon class="status-passive" svgIcon="ic-old:close"></mat-icon>
          </ng-container>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let user">{{ user.name }}</td>
    </ng-container>
    <ng-container matColumnDef="nickName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nickname</th>
      <td mat-cell *matCellDef="let user">{{ user.nickName }}</td>
    </ng-container>
    <ng-container matColumnDef="tenant">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Tenant</th>
      <td mat-cell *matCellDef="let user">{{ user.tenant }}</td>
    </ng-container>
    <ng-container matColumnDef="lastBackendCall">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last activity</th>
      <td mat-cell *matCellDef="let user">{{ user.lastBackendCall | date : 'dd-MM-yyyy HH:mm' }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let user">
        <ph-flex-vertical-center>
          <button mat-icon-button matTooltip="Edit" routerLink="/users/{{ user.id }}">
            <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
          </button>
          <button mat-icon-button matTooltip="Delete" class="remove" (click)="delete(user)" [phFlexAuthShow]="[MANAGE_USERS]">
            <mat-icon svgIcon="ic-old:trash-o"></mat-icon>
          </button>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async"></tr>
  </table>

  <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
</ng-template>
