import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import {
  LoadOperationPoolCandidatesCommand,
  ResetOperationPoolCandidatesCommand
} from '../operation-pool-candidates/operation-pool-candidates.actions';
import { OperationPoolCandidatesState } from '../operation-pool-candidates/operation-pool-candidates.state';
import {
  DownloadOperationPoolDataCommand,
  RetrieveMeasurementsCommand,
  SaveOperationCommand,
  SendActivatedPoolToTennetCommand,
  SendEndActivationMessageCommand,
  SendOperationToDevicesCommand,
  SendStartActivationMessageCommand
} from './operation.actions';
import { OperationState } from './operation.state';
import { DateAndTime, OperationView } from 'flex-app-shared';

@Injectable({
  providedIn: 'root'
})
export class OperationFacade {
  operation$ = this.store.select(OperationState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(OperationState.getOperation))
  );
  isDisabled$ = this.store.select(OperationState.isBusy);
  saveButtonPending$ = this.store.select(OperationState.isBusySending);
  saveButtonError$ = this.store.select(OperationState.error);
  isMeasurementRetrievalBusy$ = this.store.select(OperationState.isMeasurementRetrievalBusy);

  operationPool$ = this.store.select(OperationState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(OperationState.getOperation)),
    switchMap((operation) => {
      if (operation.id && operation.operationPool.length) {
        return of(operation.operationPool);
      } else {
        return this.store.select(OperationPoolCandidatesState.getPoolCandidates);
      }
    })
  );

  operationHasPool$ = this.store.select(OperationState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(OperationState.getOperation)),
    map((operation) => !!(operation.id && operation.operationPool.length))
  );

  constructor(private store: Store) {}

  save(operation: OperationView): void {
    this.store.dispatch(new SaveOperationCommand(operation));
  }

  sendToDevices(): void {
    this.store.dispatch(new SendOperationToDevicesCommand());
  }

  sendToTennet(): void {
    this.store.dispatch(new SendActivatedPoolToTennetCommand());
  }

  sendStartActivationMessage(): void {
    this.store.dispatch(new SendStartActivationMessageCommand());
  }

  sendEndActivationMessage(): void {
    this.store.dispatch(new SendEndActivationMessageCommand());
  }

  getPoolCandidates({
    direction,
    startTime,
    endTime,
    notificationTime,
    requestedPower
  }: {
    direction: string;
    startTime: DateAndTime;
    endTime: DateAndTime;
    notificationTime: DateAndTime;
    requestedPower: number;
  }): void {
    this.store.dispatch(
      new LoadOperationPoolCandidatesCommand(
        direction,
        {
          startDateTime: startTime,
          toDateTime: endTime
        },
        notificationTime,
        requestedPower
      )
    );
  }

  clearPoolCandidates(): void {
    this.store.dispatch(new ResetOperationPoolCandidatesCommand());
  }

  retrieveMeasurements(): void {
    this.store.dispatch(new RetrieveMeasurementsCommand());
  }

  downloadOperationPoolData(): void {
    this.store.dispatch(new DownloadOperationPoolDataCommand());
  }
}
