import { Component, OnInit, ViewChild } from '@angular/core';
import { TsoAgreement, TsoAgreementService } from '../shared/tso-agreement/tso-agreement.service';
import { get } from 'lodash-es';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Capacity, MixinBase, OnDestroyMixin } from 'flex-app-shared';
import { TsoAgreementsFacade } from '../../store/tso-agreements/tso-agreements-facade.service';
import { Observable, of } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-tso-agreements',
  templateUrl: './tso-agreements.component.html',
  styleUrls: ['./tso-agreements.component.scss'],
  providers: [DatePipe]
})
export class TsoAgreementsComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  readonly displayedColumns: ReadonlyArray<string> = [
    'product',
    'period',
    'direction',
    'power',
    'hourlyFee',
    'maxOperationTime',
    'operationRampUpTime',
    'operationRampDownTime',
    'fromAuction',
    'actions'
  ];
  busyDownloading$: Observable<boolean>;
  busyDeleting$: Observable<boolean>;

  readonly dataSource: MatTableDataSource<TsoAgreement> = new MatTableDataSource<TsoAgreement>([]);

  @ViewChild(MatPaginator, { static: true })
  private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

  constructor(
    private facade: TsoAgreementsFacade,
    private service: TsoAgreementService,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe
  ) {
    super();
  }

  ngOnInit(): void {
    this.facade.init();
    this.getAll();
    this.sort.sort({ disableClear: false, id: 'period', start: 'desc' });
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'power':
          return Capacity.asMW(data.power);
        case 'period':
          return data.period.startDate;
        default:
          return get(data, sortHeaderId);
      }
    };

    this.setFilterPredicate();

    this.facade.tsoAgreements$.pipe(takeUntil(this.onDestroy$)).subscribe((tsoAgreements) => (this.dataSource.data = tsoAgreements));

    this.busyDownloading$ = this.facade.isBusyDownloading$;
    this.busyDeleting$ = this.facade.isBusyDeleting$;
  }

  getAll(): void {
    this.facade.loadAll();
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }

  setFilterPredicate(): void {
    this.dataSource.filterPredicate = (data: TsoAgreement, filter: string) => {
      const { product, period, direction, power, hourlyFee, maxOperationTime } = data;
      const startDate = this.datePipe.transform(period.startDate, 'shortDate');
      const endDate = this.datePipe.transform(period.endDate, 'shortDate');
      const powerVal = `${Capacity.asMW(power)} MW`;
      return `${product} ${startDate} ${endDate} ${endDate} ${direction} ${powerVal} ${hourlyFee} ${maxOperationTime}`
        .toLowerCase()
        .includes(filter.trim().toLowerCase());
    };
  }

  downloadTsoAgreements(): void {
    this.facade.downloadAll();
  }

  delete$(agreement: TsoAgreement): Observable<any> {
    return of(true).pipe(
      switchMap(() => this.facade.delete(agreement)),
      tap(() => this.getAll())
    );
  }
}
