import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { GridPoint, GridPointService, RetrieveMeasurementDataResult, validPeriodValidator } from 'flex-app-shared';
import { CrossFieldErrorMatcher } from '../shared/common/common';

export const maxPeriodLengthOneMonthValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const startDate = new Date(control.get('startDate').value);
  const endDate = new Date(control.get('endDate').value);
  startDate.setMonth(startDate.getMonth() + 1);

  return startDate <= endDate ? { periodExceedsOneMonth: true } : null;
};

@Component({
  selector: 'app-grid-point-retrieve-measurement-data',
  templateUrl: './grid-point-retrieve-measurement-data.component.html',
  styleUrls: ['./grid-point-retrieve-measurement-data.component.scss']
})
export class GridPointRetrieveMeasurementDataComponent implements OnInit {
  gridPoint: GridPoint;
  result: RetrieveMeasurementDataResult;
  busyFetching = false;
  crossFieldErrorMatcher = new CrossFieldErrorMatcher();

  form = this.builder.group(
    {
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]]
    },
    { validators: [maxPeriodLengthOneMonthValidator, validPeriodValidator] }
  );

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private builder: UntypedFormBuilder,
    private gridPointService: GridPointService
  ) {}

  get startDate(): AbstractControl | null {
    return this.form.get('startDate');
  }

  get endDate(): AbstractControl | null {
    return this.form.get('endDate');
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('gridPointId');
    this.gridPointService.getById(id).subscribe((gp) => (this.gridPoint = gp));
  }

  onSubmit(): void {
    this.busyFetching = true;
    this.result = null;
    this.gridPointService.retrieveMeasurementData(this.gridPoint.id, this.form.value).subscribe((result) => {
      this.result = result;
      this.busyFetching = false;
    });
  }

  goBack(): void {
    this.location.back();
  }
}
