import { AdjustmentDirection, Capacity, Omit, PeriodDomain, PeriodView } from 'flex-app-shared';

export class AggregatedUnavailabilityView {
  period: PeriodView;
  direction: AdjustmentDirection;
  capacity: Capacity;
}

export type AggregatedUnavailability = Required<Omit<AggregatedUnavailabilityView, 'period'>> & {
  period: PeriodDomain;
};

export class AggregatedUnavailabilities {
  unavailabilities: AggregatedUnavailability[];
}

export class AggregatedUnavailabilitiesView {
  unavailabilities: AggregatedUnavailabilityView[];
}
