import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { trustedResourceUrl, unwrapResourceUrl } from 'safevalues';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => {
    if ('serviceWorker' in navigator && environment.serviceWorker) {
      navigator.serviceWorker.register(unwrapResourceUrl(trustedResourceUrl`ngsw-worker.js`) as string);
    }
  })
  .catch((err) => console.error(err));
