import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { AnalogInput, AnalogInputService, CustomerService, CustomValidators, MixinBase, OnDestroyMixin } from 'flex-app-shared';
import { ActivatedRoute, Router } from '@angular/router';
import { Device, DeviceService } from '../shared/device/device.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { MatSelectChange } from '@angular/material/select';

export enum Provider {
  SERCOM = 'SERCOM',
  OTHER = 'OTHER'
}

export enum InputKind {
  ANALOG = 'ANALOG'
}

export enum AnalogInputType {
  HEAT_BUFFER = 'HEAT_BUFFER',
  STATE_OF_CHARGE = 'STATE_OF_CHARGE',
  SOLAR_IRRADIANCE = 'SOLAR_IRRADIANCE'
}

export enum AnalogInputSignalType {
  TYPE_0_5V = 'TYPE_0_5V',
  TYPE_0_10V = 'TYPE_0_10V',
  TYPE_0_20MA = 'TYPE_0_20MA'
}

function maxMeasurementValidator(): ValidatorFn {
  return (control: UntypedFormGroup): ValidationErrors | null => {
    const max = maxMeasurementForSignalType(control.root.get('calibration.signalType')?.value);
    if (control.value > max) {
      return { measurementMax: true };
    }
    return null;
  };
}

function minUpperMeasurementValidator(): ValidatorFn {
  return (control: UntypedFormGroup): ValidationErrors | null => {
    const lboundMeasurement = control.root.get('calibration.lboundMeasurement')?.value;
    if (control.value <= lboundMeasurement) {
      return { uboundMeasurementMin: true };
    }
    return null;
  };
}

function minUpperCalibrationValidator(): ValidatorFn {
  return (control: UntypedFormGroup): ValidationErrors | null => {
    const analogInputType = control.root.get('type')?.value;
    if (control.value < minCalibrationForInputType(analogInputType)) {
      return { uboundCalibrationMin: true };
    }
    return null;
  };
}

function maxUpperCalibrationValidator(): ValidatorFn {
  return (control: UntypedFormGroup): ValidationErrors | null => {
    const analogInputType = control.root.get('type')?.value;
    if (control.value > maxCalibrationForInputType(analogInputType)) {
      return { uboundCalibrationMax: true };
    }
    return null;
  };
}

export function maxMeasurementForSignalType(inputSignalType: AnalogInputSignalType): number {
  switch (inputSignalType) {
    case AnalogInputSignalType.TYPE_0_20MA:
      return 20;
    case AnalogInputSignalType.TYPE_0_10V:
      return 10;
    case AnalogInputSignalType.TYPE_0_5V:
    default:
      return 5;
  }
}

export function maxCalibrationForInputType(inputType: AnalogInputType): number {
  switch (inputType) {
    case AnalogInputType.SOLAR_IRRADIANCE:
      return 2000;
    case AnalogInputType.STATE_OF_CHARGE:
    case AnalogInputType.HEAT_BUFFER:
    default:
      return 100;
  }
}

export function minCalibrationForInputType(inputType: AnalogInputType): number {
  switch (inputType) {
    case AnalogInputType.SOLAR_IRRADIANCE:
      return 100;
    case AnalogInputType.STATE_OF_CHARGE:
    case AnalogInputType.HEAT_BUFFER:
    default:
      return 10;
  }
}

@Component({
  selector: 'app-analog-input-detail',
  templateUrl: './analog-input-detail.component.html',
  styleUrls: ['./analog-input-detail.component.scss']
})
export class AnalogInputDetailComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  InputKind = InputKind;
  AnalogInputType = AnalogInputType;
  AnalogInputSignalType = AnalogInputSignalType;
  devices: Device[];

  blockSubmitButton = false;

  isNew = false;

  form = this.builder.group({
    id: 'new',
    kind: [InputKind.ANALOG, Validators.required],
    customerId: ['', Validators.required],
    gridPointId: [''],
    description: ['', [Validators.required, Validators.maxLength(100)]],
    type: [AnalogInputType.HEAT_BUFFER, [Validators.required]],
    providerId: [Provider.SERCOM, [Validators.required]],
    calibration: this.builder.group({
      signalType: [AnalogInputSignalType.TYPE_0_5V, [Validators.required]],
      lboundMeasurement: ['', [Validators.required, Validators.min(0), CustomValidators.numberOfDecimals(0, 2), maxMeasurementValidator()]],
      uboundMeasurement: [
        '',
        [Validators.required, CustomValidators.numberOfDecimals(0, 2), maxMeasurementValidator(), minUpperMeasurementValidator()]
      ],
      uboundCalibration: [
        '',
        [Validators.required, CustomValidators.numberOfDecimals(0, 2), minUpperCalibrationValidator(), maxUpperCalibrationValidator()]
      ]
    }),
    providerProperties: this.builder.group({
      repobox: ['', [Validators.required]],
      powerhouseId: ['', [Validators.required, Validators.maxLength(10)]]
    })
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private builder: UntypedFormBuilder,
    private service: AnalogInputService,
    private customerService: CustomerService,
    private deviceService: DeviceService
  ) {
    super();
  }

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('analogInputId');
    if (id === null || id === 'new') {
      this.form.get('kind').enable();
      this.kindChanged(InputKind.ANALOG);
    } else {
      this.form.get('kind').disable();
      this.service.getById(id).subscribe((input) => {
        this.kindChanged(input.kind);
        this.form.patchValue(input);
      });
    }

    this.form
      .get('customerId')
      .valueChanges.pipe(debounceTime(300), distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe((customerId) => {
        const customerIdFormField = this.form.get('customerId');
        const repoboxFormField = this.form.get('providerProperties.repobox');
        if (customerIdFormField.invalid) {
          return;
        }
        this.deviceService.getByCustomerId(customerId).subscribe((devices) => {
          this.devices = devices
            .filter((device) => device.providerType === this.form.get('providerId').value)
            .map((device) => {
              return { customerId: device.customerId, externalId: device.externalId } as Device;
            });
          if (devices.length === 0 || !devices.find((device) => device.externalId === repoboxFormField.value)) {
            this.form.get('providerProperties.repobox').setValue(null);
          }
        });
        // Change url to trigger filtered reloading of phFlexGridPointDataSource (used in grid point drop down)
        const url = `/analog-inputs/${this.form.get('id').value}/${customerId}`;
        this.router.navigate([url], { replaceUrl: true });
      });

    this.form
      .get('calibration.signalType')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.form.get('calibration.lboundMeasurement').updateValueAndValidity({ onlySelf: true });
        this.form.get('calibration.uboundMeasurement').updateValueAndValidity({ onlySelf: true });
      });

    this.form
      .get('calibration.lboundMeasurement')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.form.get('calibration.uboundMeasurement').updateValueAndValidity({ onlySelf: true });
      });

    this.form
      .get('type')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.form.get('calibration.uboundCalibration').updateValueAndValidity({ onlySelf: true });
      });
  }

  onSubmit(): void {
    const input: AnalogInput = { ...this.form.getRawValue() };
    this.blockSubmitButton = true;
    if (input.id === 'new') {
      input.id = null;
      this.service.create(input).subscribe(
        (_) => this.goBack(),
        () => (this.blockSubmitButton = false)
      );
    } else {
      this.service.update(input).subscribe(
        (_) => this.goBack(),
        () => (this.blockSubmitButton = false)
      );
    }
  }

  goBack(): void {
    this.location.back();
  }

  onKindChanged(event: MatSelectChange): void {
    this.kindChanged(event.value);
  }

  kindChanged(newKind: InputKind): void {
    const calibrationFormGroup = this.form.get('calibration') as UntypedFormGroup;
    const calibrationControls = Object.values(calibrationFormGroup.controls);
    this.form.get('type').setValue(AnalogInputType.HEAT_BUFFER);
    calibrationFormGroup.reset({ signalType: AnalogInputSignalType.TYPE_0_5V });
    calibrationControls.forEach((c) => c.updateValueAndValidity());
  }

  inputTypeUnitOfMeasure(): string {
    return this.form.get('type').value === AnalogInputType.SOLAR_IRRADIANCE ? 'W/m²' : '%';
  }

  signalTypeUnitOfMeasure(): string {
    return this.form.get('calibration.signalType').value === AnalogInputSignalType.TYPE_0_20MA ? 'mA' : 'V';
  }

  maxMeasurement(): number {
    return maxMeasurementForSignalType(this.form.get('calibration.signalType').value);
  }

  maxCalibration(): number {
    return maxCalibrationForInputType(this.form.get('type').value);
  }

  minCalibration(): number {
    return minCalibrationForInputType(this.form.get('type').value);
  }
}
