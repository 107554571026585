import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { RecipientState } from './recipient.state';
import { SaveRecipientCommand } from './recipient.actions';
import { Recipient } from '../../../../app/shared/recipient/recipient';
import { filter, first, switchMap } from 'rxjs/operators';
import { EntityFacade } from 'flex-app-shared';

@Injectable({
  providedIn: 'root'
})
export class RecipientFacade implements EntityFacade {
  recipient$ = this.store.select(RecipientState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(RecipientState.getRecipient))
  );
  isDisabled$ = this.store.select(RecipientState.isBusy);
  saveButtonPending$ = this.store.select(RecipientState.isBusySending);
  saveButtonError$ = this.store.select(RecipientState.error);

  constructor(private store: Store) {}

  save(recipient: Recipient): void {
    this.store.dispatch(new SaveRecipientCommand(recipient));
  }
}
