import { Injectable } from '@angular/core';
import { AdjustmentDirection, Capacity, PeriodDomain, RestClientService, RestEntity } from 'flex-app-shared';
import { Observable } from 'rxjs';
import { Moment } from 'moment';

export class PoolAvailabilityDetail extends RestEntity {
  period: PeriodDomain;
  direction: AdjustmentDirection;
  power: Capacity;
  sourceId: string;
  sourceType: string;
  detail: string;
  gridPoints: string[];
}

export class PoolAvailability extends RestEntity {
  day: Date;
  direction: AdjustmentDirection;
  unit: string;
  firstMinute: string;
  intervalSize: string;
  pulsePower: number[];
  controlPulsePower: number[];
  serviceAgreementPower: number[];
  tsoAgreementPower: number[];
  details: PoolAvailabilityDetail[];
}

@Injectable({
  providedIn: 'root'
})
export class PoolAvailabilityService extends RestClientService<PoolAvailability> {
  constructor() {
    super('/api/v1/poolavailability');
  }

  getByDayAndDirection(day: Moment, direction: AdjustmentDirection): Observable<PoolAvailability> {
    const url = `${this.endpoint}/${day.format('YYYY-MM-DD')}/${direction}`;
    return this.http.get<PoolAvailability>(url);
  }
}
