import { Customer } from 'flex-app-shared';

export class UpdateCustomersCommand {
  public static readonly type = '[Customers] Fetch customers';

  constructor(public userNameFilter?: string) {}
}

export class ResetCustomersCommand {
  public static readonly type = '[Customers] Reset customers';
}

export class DeleteCustomerCommand {
  public static readonly type = '[Customers] Delete customer';

  constructor(public customer: Customer) {}
}
