import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { EntityStateModel, FetchEntity, findParameterValue, PeriodView } from 'flex-app-shared';
import { UnavailabilityService } from '../../../../app/shared/unavailability/unavailability.service';
import { DeleteUnavailabilityCommand, LoadUnavailabilitiesCommand, ResetUnavailabilitiesCommand } from './unavailabilities.actions';
import { Unavailability } from '../../../../app/shared/unavailability/unavailability';
import { Observable } from 'rxjs';
import { CustomerLoadedEvent } from '../../../customers/states/customer/customer.actions';
import { isNil } from 'lodash-es';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';

export class UnavailabilitiesStateModel extends EntityStateModel<Unavailability[]> {
  model: Unavailability[] = [];
  selectedCustomerId: string | null = null;
}

@State({
  name: 'unavailabilities',
  defaults: new UnavailabilitiesStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class UnavailabilitiesState {
  constructor(private activatedRoute: ActivatedRoute, private unavailabilityService: UnavailabilityService) {}

  @Selector()
  public static isBusy(state: UnavailabilitiesStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  static getUnavailabilities(state: UnavailabilitiesStateModel): ReadonlyArray<any> {
    return state.model.map((it) => ({
      ...it,
      period: PeriodView.deserialize(it.period)
    }));
  }

  @FetchEntity()
  @Action(LoadUnavailabilitiesCommand)
  loadUnavailabilities({ patchState }: StateContext<UnavailabilitiesStateModel>): Observable<Unavailability[]> {
    const selectedCustomerId = findParameterValue(this.activatedRoute.snapshot, 'customerId');

    return this.unavailabilityService.getAll().pipe(
      tap((unavailabilitiesModel) => {
        const model = isNil(selectedCustomerId)
          ? unavailabilitiesModel
          : unavailabilitiesModel.filter((unavailability) => unavailability.customerId === selectedCustomerId);
        patchState({ model });
      })
    );
  }

  @Action(ResetUnavailabilitiesCommand)
  resetUnavailabilities({ setState }: StateContext<UnavailabilitiesStateModel>): void {
    setState(new UnavailabilitiesStateModel());
  }

  @Action(DeleteUnavailabilityCommand)
  deleteUnavailability({ dispatch }: StateContext<UnavailabilitiesStateModel>, event: DeleteUnavailabilityCommand): Observable<any> {
    return this.unavailabilityService.delete(event.unavailability).pipe(tap(() => dispatch(new LoadUnavailabilitiesCommand())));
  }

  @Action(CustomerLoadedEvent)
  setCustomerData({ setState, getState, dispatch }: StateContext<UnavailabilitiesStateModel>, { payload }: CustomerLoadedEvent): void {
    setState({
      ...getState(),
      selectedCustomerId: payload.id
    });
  }
}
