import { PoolPeriodView } from '../../../../app/shared/pool/pool.service';

export class RegisterPoolPeriodDialogIdCommand {
  public static readonly type = '[Pool Period] Register pool period dialog id command';

  constructor(public dialogId: string) {}
}

export class ApplyPoolPeriodDialogValueCommand {
  public static readonly type = '[Pool Period] Apply pool period dialogue value command';

  constructor(public poolPeriod: PoolPeriodView) {}
}

export class ClosePoolPeriodDialogCommand {
  public static readonly type = '[Pool Period] Close pool period dialog command';
}
