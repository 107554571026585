import { IncidentReserveInvoiceResults } from '../../app/shared/incident-reserve-invoice-results/incident-reserve-invoice-results.service';

export class UpdateIncidentReserveInvoiceResultsCommand {
  public static readonly type = '[Incident Reserve invoice results] Load invoice results';

  constructor(public date: Date) {}
}

export class IncidentReserveInvoiceResultsUpdatedEvent {
  public static readonly type = '[Incident Reserve invoice results] Invoice results updated';

  constructor(public data: IncidentReserveInvoiceResults[]) {}
}

export class PublishIncidentReserveInvoiceResultsCommand {
  public static readonly type = '[Incident Reserve invoice results] Send results';

  constructor() {}
}

export class RecalculateIncidentReserveInvoiceResultsCommand {
  public static readonly type = '[Incident Reserve invoice results] Recalculate results';

  constructor() {}
}
