import { Injectable } from '@angular/core';
import { RestClientService, RestEntity } from 'flex-app-shared';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export class BalanceResponsibleParty extends RestEntity {
  name: string;
  code: string;
  ean: string;
  defaultBrp: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class BalanceResponsiblePartyService extends RestClientService<BalanceResponsibleParty> {
  constructor() {
    super('/api/v1/balance-responsible-parties');
  }

  setDefault(id: string): Observable<any> {
    const url = `${this.endpoint}/${id}?default`;
    return this.http.put<any>(url, null).pipe(tap(() => this.log(`set to default=${id}`)));
  }
}
