import { Component, Inject, OnInit } from '@angular/core';
import { OperationFacade } from '../../store/operations/states/operation/operation.facade';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-operation-detail-send-to-devices-confirmation-dialog',
  template: `
    <h1 mat-dialog-title>Send to devices</h1>
    <div mat-dialog-content>
      <p>Are you sure you want to send the current configuration to devices?</p>
      <p *ngIf="smsWillBeSent">Note, this action will also send a start activation message to customers and employees.</p>
    </div>
    <div mat-dialog-actions>
      <button mat-flat-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" (click)="confirm()" cdkFocusInitial>Ok</button>
    </div>
  `
})
export class OperationDetailSendToDevicesConfirmationDialogComponent implements OnInit {
  smsWillBeSent: boolean;

  constructor(
    private operationFacade: OperationFacade,
    public dialogRef: MatDialogRef<OperationDetailSendToDevicesConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  confirm(): void {
    this.operationFacade.sendToDevices();
    this.dialogRef.close(true);
  }

  ngOnInit(): void {
    this.smsWillBeSent = !this.data.hasPoolConfiguration;
  }
}
