<h2 mat-dialog-title>Bevestig</h2>
<div mat-dialog-content>
  <div>
    Ik heb een
    <span [ngClass]="{ 'status-play': data.operationType === 'START', 'status-stop': data.operationType === 'END' }">
      <mat-icon [svgIcon]="data.operationType === 'START' ? 'ic-old:play' : 'ic-old:stop'"></mat-icon>
      <b [ngSwitch]="data.operationType">
        <ng-container *ngSwitchCase="'START'">START</ng-container>
        <ng-container *ngSwitchCase="'END'">STOP</ng-container>
        <ng-container *ngSwitchDefault>{{ data.operationType }}</ng-container>
      </b>
    </span>
    instructie ontvangen voor
    <span
      [ngClass]="{
        'status-up': data.direction === adjustmentDirection.UPWARDS,
        'status-down': data.direction === adjustmentDirection.DOWNWARDS
      }"
    >
      <mat-icon [svgIcon]="data.direction === adjustmentDirection.UPWARDS ? 'ic-old:arrow-up' : 'ic-old:arrow-down'"></mat-icon>
      <b>{{ data.direction === adjustmentDirection.UPWARDS ? 'OPREGELEN' : 'AFREGELEN' }}</b> </span
    >.
  </div>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="cancel()">
    <ph-flex-vertical-center>
      <mat-icon svgIcon="ic-old:close"></mat-icon>
      <span>Annuleren</span>
    </ph-flex-vertical-center>
  </button>
  <ph-flex-save-button [pending]="pending" mat-stroked-button (click)="confirm()">
    <ph-flex-vertical-center>
      <mat-icon svgIcon="ic-old:save"></mat-icon>
      <span>Bevestig</span>
    </ph-flex-vertical-center>
  </ph-flex-save-button>
</div>
