import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MeasurementCompany, MeasurementCompanyService } from '../shared/measurement-company/measurement-company.service';
import { GridPointService, MeasurementProviderInfo } from 'flex-app-shared';

@Component({
  selector: 'app-measurement-company-detail',
  templateUrl: './measurement-company-detail.component.html',
  styleUrls: ['./measurement-company-detail.component.scss']
})
export class MeasurementCompanyDetailComponent implements OnInit {
  measurementProviders: MeasurementProviderInfo[] = [];
  form = this.builder.group({
    id: '',
    name: ['', [Validators.required, Validators.maxLength(250)]],
    measurementProvider: ['', [Validators.maxLength(50)]]
  });

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private builder: UntypedFormBuilder,
    private service: MeasurementCompanyService,
    private gridPointService: GridPointService
  ) {}

  ngOnInit(): void {
    this.getMeasurementProviders();
    this.getMeasurementCompany();
  }

  getMeasurementCompany(): void {
    const id = this.route.snapshot.paramMap.get('measurementCompanyId');
    if (id === 'new') {
      this.initForm(id, new MeasurementCompany());
    } else {
      this.service.getById(id).subscribe((party) => this.initForm(id, party));
    }
  }

  onSubmit(): void {
    if (this.form.value.id === 'new') {
      this.service.add(this.form.value).subscribe(() => {
        this.form.reset();
        this.goBack();
      });
    } else {
      this.service.update(this.form.value).subscribe(() => {
        this.form.reset();
        this.goBack();
      });
    }
  }

  goBack(): void {
    this.location.back();
  }

  private initForm(id: string, company: MeasurementCompany): void {
    this.form.get('id').setValue(id);
    this.form.get('name').setValue(company.name);
    this.form.get('measurementProvider').setValue(company.measurementProvider);
  }

  private getMeasurementProviders(): void {
    this.gridPointService.measurementProviders().subscribe((result: MeasurementProviderInfo[]) => {
      this.measurementProviders = result;
      this.measurementProviders.push({
        provider: null,
        description: 'None'
      } as MeasurementProviderInfo);
    });
  }
}
