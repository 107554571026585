import { Component } from '@angular/core';

@Component({
  selector: 'app-one-to-many',
  templateUrl: './one-to-many.component.html',
  styleUrls: ['./one-to-many.component.scss']
})
export class OneToManyComponent {
  public showForm = false;

  toggle(): void {
    this.showForm = !this.showForm;
  }
}
