import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, first, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { CustomersState } from '../customers/states/customers/customers.state';
import {
  DeleteGridPointCommand,
  GridPoint,
  PulseMeasurementConfiguration,
  PulseMeasurementConfigurationState,
  RetrieveContractsForGridPointCommand,
  SaveGridPointCommand
} from 'flex-app-shared';
import { UpdateCustomersCommand } from '../customers/states/customers/customers.actions';
import { LoadDevicesCommand } from '../devices/states/devices/devices.actions';
import { Device } from '../../app/shared/device/device.service';
import { DevicesState } from '../devices/states/devices/devices.state';

@Injectable({
  providedIn: 'root'
})
export class GridPointFacade {
  customers$ = this.store.select(CustomersState.isBusy).pipe(
    filter((a) => !a),
    first(),
    switchMap(() => this.store.select(CustomersState.getCustomers))
  );

  constructor(private store: Store) {}

  save(): void {
    this.store.dispatch(new SaveGridPointCommand());
  }

  delete(gridPoint: GridPoint): void {
    this.store.dispatch(new DeleteGridPointCommand(gridPoint));
  }

  init(): void {
    this.store.dispatch(new UpdateCustomersCommand());
  }

  initDevices(): void {
    this.store.dispatch(new LoadDevicesCommand());
  }

  getAvailablePulseDevices(): Observable<readonly Device[]> {
    return this.store.select(DevicesState.getDevices);
  }

  getPulseConfig(): Observable<PulseMeasurementConfiguration> {
    return this.store.select(PulseMeasurementConfigurationState.getModel);
  }

  retrieveContracts(): void {
    this.store.dispatch(new RetrieveContractsForGridPointCommand())
  }
}
