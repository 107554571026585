import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Capacity, CustomValidators, DateAndTime, OperationView } from 'flex-app-shared';
import { cloneDeep } from 'lodash-es';
import moment, { Moment } from 'moment';
import { first } from 'rxjs/operators';
import { OperationFacade } from '../../store/operations/states/operation/operation.facade';
import { formatDateAndTimeAsISODateTime, formatMomentAsTime, parseISOtoMoment } from '../shared/common/common';
import { OperationDetailSendEndOperationMessageConfirmationDialogComponent } from './operation-detail-send-end-operation-message-confirmation-dialog.component';
import { OperationDetailSendStartOperationMessageConfirmationDialogComponent } from './operation-detail-send-start-operation-message-confirmation-dialog.component';
import { OperationDetailSendToDevicesConfirmationDialogComponent } from './operation-detail-send-to-devices-confirmation-dialog.component';

@Component({
  selector: 'app-operation-detail',
  templateUrl: './operation-detail.component.html',
  styleUrls: ['./operation-detail.component.scss']
})
export class OperationDetailComponent implements OnInit {
  public operationId: string;
  public isShowMeasurementData: boolean = false;
  public operationForm = this.builder.group({
    reference: '',
    notificationTime: this.builder.group({
      date: ['', Validators.required],
      time: ['', [Validators.required, CustomValidators.timeValidator()]]
    }),
    startTime: this.builder.group({
      date: ['', Validators.required],
      time: ['', [Validators.required, CustomValidators.timeValidator()]]
    }),
    endTime: this.builder.group({
      date: ['', Validators.required],
      time: ['', [Validators.required, CustomValidators.timeValidator()]]
    }),
    direction: ['UPWARDS', Validators.required],
    requestedPower: ['', [Validators.required, Validators.min(0), Validators.max(9999.99), CustomValidators.numberOfDecimals(0, 2)]]
  });
  private isEdit = false;
  private hasPoolConfiguration = false;
  private isAutomatic = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private builder: UntypedFormBuilder,
    private operationFacade: OperationFacade,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  private static roundUpTo(valueToRound: number, unitToRoundUpTo: number): number {
    return Math.ceil(valueToRound / unitToRoundUpTo) * unitToRoundUpTo;
  }

  ngOnInit(): void {
    this.operationForm.valueChanges.subscribe((formValue) => {
      if (this.operationForm.get('startTime').valid && this.operationForm.get('endTime').valid) {
        this.operationFacade.operation$.pipe(first()).subscribe((opr) => {
          if (opr.manual && opr.id === null) {
            // Only get pool candidates for new, manual operations. All existing operations, either manual or
            // automatic, should already have a pool.
            this.operationFacade.getPoolCandidates(formValue);
          }
        });
      } else {
        this.operationFacade.clearPoolCandidates();
      }
    });

    this.operationFacade.operation$.pipe(first()).subscribe((o) => {
      this.isEdit = !!o.id;
      // TODO change this to a specific property if available
      this.hasPoolConfiguration = o.operationPool && o.operationPool.length > 0;
      this.fillForm(o);
    });

    this.operationFacade.isDisabled$.subscribe((isDisabled) => {
      if (isDisabled) {
        this.operationForm.disable();
      } else {
        this.operationForm.enable();
      }
    });

    this.operationId = this.route.snapshot.paramMap.get('operationId');
    this.isShowMeasurementData = this.operationId !== 'new';
  }

  goBack(): void {
    this.location.back();
  }

  onSubmit(): void {
    const value = cloneDeep(this.operationForm.value);
    // TODO refactor form model to match domain
    const result = {
      notificationTime: value.notificationTime,
      period: {
        startDateTime: value.startTime,
        toDateTime: value.endTime
      },
      direction: value.direction,
      requestedPower: Capacity.MW(value.requestedPower)
    };

    formatDateAndTimeAsISODateTime(result, ['notificationTime']);

    this.operationFacade.save(result);
  }

  fillForm(operation: OperationView): void {
    const id = this.route.snapshot.paramMap.get('operationId');
    if (id === 'new') {
      // Setting notificationTime to 'now' rounded up to nearest 5 minute interval,
      // startTime to notificationTime + 15 minutes, and
      // endTime to notificationTime + 75 minutes (startTime plus one hour)
      const nowMoment = moment(new Date());
      const roundedUpMinutes = OperationDetailComponent.roundUpTo(nowMoment.minute(), 5);
      const roundedUpMoment = nowMoment.clone().minute(roundedUpMinutes);
      const plus15 = roundedUpMoment.clone().add(15, 'm');
      const plus75 = roundedUpMoment.clone().add(75, 'm');
      this.operationForm.patchValue({
        notificationTime: { date: nowMoment, time: nowMoment.format('HH:mm') },
        startTime: { date: plus15, time: plus15.format('HH:mm') },
        endTime: { date: plus75, time: plus75.format('HH:mm') }
      });
    }

    this.operationForm.patchValue({
      direction: operation.direction,
      reference: operation.reference
    });

    if (operation.notificationTime) {
      this.operationForm.patchValue({
        notificationTime: operation.notificationTime
      });
      initDateTime(this.operationForm.get('notificationTime'), operation.notificationTime);
    }

    // TODO rename field properties so we can reduce if statements
    if (operation.requestedPower) {
      this.operationForm.patchValue({
        requestedPower: Capacity.asMW(operation.requestedPower)
      });
    }

    if (operation.period) {
      this.operationForm.patchValue({
        startTime: operation.period.startDateTime,
        endTime: operation.period.toDateTime
      });
    }

    if (!operation.manual) {
      this.isAutomatic = true;
    }
  }

  sendToDevices(): void {
    const dialogRef = this.dialog.open(OperationDetailSendToDevicesConfirmationDialogComponent, {
      data: { hasPoolConfiguration: this.hasPoolConfiguration }
    });
    dialogRef.afterClosed().subscribe((pressedOk) => {
      if (pressedOk) {
        this.snackBar.open('Configuration sent to devices', null, {
          duration: 5000
        });
        // If the user presses the button twice, they should not get the "will send messages" text
        this.hasPoolConfiguration = true;
      }
    });
  }

  manuallySendStartOperationMessage(): void {
    this.dialog.open(OperationDetailSendStartOperationMessageConfirmationDialogComponent, {});
  }

  manuallySendEndOperationMessage(): void {
    this.dialog.open(OperationDetailSendEndOperationMessageConfirmationDialogComponent, {});
  }

  showSendToDevices(): undefined | string | true {
    if (this.isAutomatic || !this.isEdit) {
      return;
    }
    if (this.operationForm.dirty) {
      return 'Changes need to be saved before sending to Sercom';
    }
    if (this.operationForm.get('endTime.date').value.isBefore(moment.now())) {
      return 'Cannot send operation in the past';
    }
    return true;
  }

  showSendOperationStartMessage(): undefined | string | true {
    if (this.isAutomatic || !this.isEdit) {
      return;
    }
    if (this.operationForm.dirty) {
      return 'Changes need to be saved before sending start operation message';
    }
    const toDateMoment = DateAndTime.toMoment(this.operationForm.get('endTime').value);
    const startDateMoment = DateAndTime.toMoment(this.operationForm.get('startTime').value);
    if (!toDateMoment) {
      return;
    }
    if (toDateMoment.isBefore(moment())) {
      return 'Cannot send start operation message if operation has already ended';
    }
    if (startDateMoment.isAfter(moment().add(6, 'hours'))) {
      return 'Cannot send start operation message if operation does not start within the next 6 hours';
    }
    return true;
  }

  showSendOperationEndMessage(): undefined | string | true {
    if (this.isAutomatic || !this.isEdit) {
      return;
    }
    if (this.operationForm.dirty) {
      return 'Changes need to be saved before sending start operation message';
    }
    const toDateMoment = DateAndTime.toMoment(this.operationForm.get('endTime').value);
    if (!toDateMoment) {
      return;
    }
    if (toDateMoment.isAfter(moment()) || toDateMoment.add(1, 'hour').isBefore(moment())) {
      return 'Sending an operation end message is only allowed if the operation has ended within the last hour';
    }
    return true;
  }
}

function initDateTime(formGroup: AbstractControl, dateTime: string | Date | Moment): void {
  const valueToPatch = {
    date: dateTime,
    time: dateTime
  };
  formatMomentAsTime(valueToPatch, ['time']);
  parseISOtoMoment(valueToPatch, ['date']);

  formGroup.patchValue(valueToPatch);
}
