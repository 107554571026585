<ng-container *ngIf="!isStandalone">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Control Detail</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ng-container [ngTemplateOutlet]="pageContents"></ng-container>
    </mat-card-content>
    <mat-card-actions>
      <button mat-stroked-button type="button" (click)="goBack()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
          <span>Go back</span>
        </ph-flex-vertical-center>
      </button>
      <button
        mat-flat-button
        [color]="submitButtonColor()"
        [class]="submitButtonColor() ? 'save' : ''"
        type="button"
        (click)="onSubmit()"
        [disabled]="controlFacade.isDisabled$ | async"
      >
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:save"></mat-icon>
          <span>Save</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>

<ng-container *ngIf="isStandalone" [ngTemplateOutlet]="pageContents"></ng-container>

<ng-template #pageContents>
  <div class="flex-row">
    <div class="col-1 segment-block">
      <form [formGroup]="form" ngxsForm="controlStateModule.control" class="single-column-form">
        <mat-form-field>
          <mat-label>Description</mat-label>
          <input matInput formControlName="description" [errorStateMatcher]="fnErrorMatcher" />
          <mat-error *ngIf="form.get('description').errors?.required"> Description is required.</mat-error>
          <mat-error *ngIf="form.get('description').errors?.maxlength"> Description must not be longer than 50 characters. </mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="!isStandalone">
          <mat-label>Customer</mat-label>
          <ph-flex-autocomplete
            [errorStateMatcher]="fnErrorMatcher"
            phFlexCustomerDataSource
            [shouldEmitNull]="true"
            [shouldOnlyEmitIds]="true"
            formControlName="customerId"
            [shouldAutoSelectIfOnlyOneResult]="true"
            placeholder="Select customer"
          ></ph-flex-autocomplete>
          <mat-error *ngIf="form.get('customerId').errors?.required"> Customer is required.</mat-error>
          <mat-error *ngIf="form.get('customerId').errors?.invalidCustomer"> Select a customer from the list. </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Direction</mat-label>
          <mat-select formControlName="direction" [errorStateMatcher]="fnErrorMatcher">
            <mat-option value="PRODUCTION">Production</mat-option>
            <mat-option value="CONSUMPTION">Consumption</mat-option>
          </mat-select>
          <mat-error *ngIf="form.get('direction').errors?.required"> Direction is required</mat-error>
        </mat-form-field>
        <mat-form-field formGroupName="power" subscriptSizing="dynamic">
          <mat-label>Power</mat-label>
          <input matInput type="number" formControlName="value" [errorStateMatcher]="fnErrorMatcher" />
          <span matTextSuffix>kW</span>
          <mat-error *ngIf="form.get('power.value').errors?.required"> Requested power is required</mat-error>
          <mat-error *ngIf="form.get('power.value').errors?.min"> Requested power should be at least 0</mat-error>
          <mat-error *ngIf="form.get('power.value').errors?.max"> Requested power should be at most 99.999 kW </mat-error>
        </mat-form-field>
        <div class="formCheckBox">
          <mat-checkbox formControlName="incidentReserveOnly">Dedicated reserve control</mat-checkbox>
        </div>
        <mat-form-field>
          <mat-label>Device contact</mat-label>
          <mat-select formControlName="deviceContactId" [errorStateMatcher]="fnErrorMatcher">
            <mat-option
              *ngFor="let option of flatDeviceContacts$ | async"
              [value]="option.deviceContactId"
              [disabled]="!isDeviceContactSelectable(option)"
            >
              {{ option.deviceExternalId }} {{ option.contactExternalId }} {{ option.contactDescription }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    </div>
    <div class="col-1 segment-block">
      <div class="status-subtle-box-alert-with-arrow" *ngIf="shouldShowGridPointError()">
        <mat-error>Select at least one grid point.</mat-error>
      </div>
      <div class="overflow-table has-shadow">
        <table mat-table class="has-checkboxes" [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row.gridPointId) : null"
                [checked]="selection.isSelected(row.gridPointId)"
              >
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="ean">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>EAN</th>
            <td mat-cell *matCellDef="let gridPoint" [ngClass]="gridPoint.ownedByCustomer ? '' : 'status-alert'">
              <ph-flex-vertical-center>
                <a routerLink="/grid-points/{{ gridPoint.gridPointId }}">{{ gridPoint.ean }}</a>
                <mat-icon
                  *ngIf="!gridPoint.ownedByCustomer"
                  matTooltip="Grid point is not owned by this customer"
                  svgIcon="ic-old:alert-warning"
                >
                </mat-icon>
              </ph-flex-vertical-center>
            </td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
            <td mat-cell *matCellDef="let gridPoint">{{ gridPoint.description }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedGridPointColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedGridPointColumns"></tr>
        </table>
      </div>

      <mat-paginator [showFirstLastButtons]="true" [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
    </div>
  </div>
</ng-template>

<mat-card *ngIf="showTestControlPanel">
  <mat-card-header>
    <mat-card-title>Test Control</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="testControlForm" class="flex-grid cols-2">
      <ng-container formGroupName="period">
        <ng-container formGroupName="startDateTime">
          <mat-form-field class="col-1">
            <mat-label>Start date</mat-label>
            <input matInput [matDatepicker]="startDatepicker" formControlName="date" [min]="today" [max]="tomorrow" />
            <mat-datepicker #startDatepicker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
            <mat-error
              *ngIf="
                testControlForm.get('period.startDateTime.date').errors?.matDatepickerMin ||
                testControlForm.get('period.startDateTime.date').errors?.matDatepickerMax
              "
            >
              End date should be today or tomorrow
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-1">
            <mat-label>Start time</mat-label>
            <input matInput formControlName="time" />
            <mat-hint>Example: 13:00</mat-hint>
            <mat-error *ngIf="testControlForm.get('period.startDateTime.time').errors?.required"> Start time is required </mat-error>
            <mat-error *ngIf="testControlForm.get('period.toDateTime.time').errors?.pattern">
              Start time should be formatted as hour:minute
            </mat-error>
          </mat-form-field>
        </ng-container>

        <ng-container formGroupName="toDateTime">
          <mat-form-field class="col-1">
            <mat-label>End date</mat-label>
            <input matInput [matDatepicker]="endDatepicker" formControlName="date" [min]="toDateTimeMin" [max]="tomorrow" />
            <mat-datepicker #endDatepicker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
            <mat-error
              *ngIf="
                testControlForm.get('period.toDateTime.date').errors?.matDatepickerMin ||
                testControlForm.get('period.toDateTime.date').errors?.matDatepickerMax
              "
            >
              End date should be today or tomorrow
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-1">
            <mat-label>End time</mat-label>
            <input matInput formControlName="time" [errorStateMatcher]="timePeriodCrossFieldErrorMatcher" />
            <mat-hint>Example: 13:00</mat-hint>
            <mat-error *ngIf="testControlForm.get('period.toDateTime.time').errors?.required"> End time is required </mat-error>
            <mat-error *ngIf="testControlForm.get('period.toDateTime.time').errors?.pattern">
              End time should be formatted as hour:minute
            </mat-error>
            <mat-error *ngIf="testControlForm.get('period').errors?.timesWrong"> End time should be greater than start time</mat-error>
          </mat-form-field>
        </ng-container>
      </ng-container>

      <mat-form-field class="col-2">
        <mat-label>State</mat-label>
        <mat-select formControlName="state">
          <mat-option value="ON">ON</mat-option>
          <mat-option value="OFF">OFF</mat-option>
        </mat-select>
        <mat-error *ngIf="testControlForm.get('state').errors?.required"> State is required.</mat-error>
      </mat-form-field>
    </form>
    <mat-card-actions>
      <button mat-stroked-button type="button" (click)="goBack()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
          <span>Go back</span>
        </ph-flex-vertical-center>
      </button>
      <button
        mat-flat-button
        color="primary"
        type="button"
        (click)="onSendToDevice()"
        [disabled]="!testControlForm.valid || (controlFacade.isDisabled$ | async)"
      >
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:paper-plane"></mat-icon>
          <span>Send to device</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
