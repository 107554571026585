import { CustomerStepperStep, StepperFormType, StepState } from '../../../app/customer-stepper/customer-stepper-enums';

export class InitializeStepperCommand {
  public static readonly type = '[CustomerStepper] Initialize';
}

export class DestroyStepperCommand {
  public static readonly type = '[CustomerStepper] Destroy';
}

export class SetCustomerStepperStepStateCommand {
  public static readonly type = '[CustomerStepper] Set customer stepper step state command';

  constructor(public step: CustomerStepperStep, public stepState: StepState) {}
}

export class SetCustomerStepperCurrentStepCommand {
  public static readonly type = '[CustomerStepper] Set customer stepper current step command';

  constructor(public currentStepNo: number, public showForm: boolean = false) {}
}

export class CustomerStepperHideFormCommand {
  public static readonly type = '[CustomerStepper] Hide Form';
}

export class SetFormTypeCommand {
  public static readonly type = '[CustomerStepper] Set Form Type';

  constructor(public formType: StepperFormType = StepperFormType.edit) {}
}

export class AddGridPointCommand {
  public static readonly type = '[CustomerStepper] Add grid point';
}

export class AddDeviceCommand {
  public static readonly type = '[CustomerStepper] Add device';
}

export class AddControlCommand {
  public static readonly type = '[CustomerStepper] Add control';
}

export class SaveAndCompleteCommand {
  public static readonly type = '[CustomerStepper] Save and complete';
}
