<mat-vertical-stepper #stepper [linear]="true" [selectedIndex]="currentStepNo" (selectionChange)="setStepperIndex($event, stepper)">
  <ng-template matStepperIcon="edit">
    <mat-icon svgIcon="ic-old:inline-edit-o"></mat-icon>
  </ng-template>

  <ng-template matStepperIcon="done">
    <mat-icon svgIcon="ic-old:check"></mat-icon>
  </ng-template>

  <ng-template matStepperIcon="error">
    <mat-icon svgIcon="ic-old:alert-error"></mat-icon>
  </ng-template>

  <mat-step [label]="getStep1Label()" [completed]="isStepCompleted(CustomerStepperStep.customerStep)">
    <app-customer-detail [isStandalone]="true"></app-customer-detail>
    <div class="stepper-buttons">
      <button mat-flat-button color="primary" (click)="saveCustomer()" [disabled]="!(isCustomerFormValid$ | async)">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_downward"></mat-icon>
          <span>Save & next</span>
        </ph-flex-vertical-center>
      </button>
    </div>
  </mat-step>

  <mat-step label="Gridpoints" [completed]="isStepCompleted(CustomerStepperStep.gridPointsStep)">
    <h4>Added Gridpoints</h4>
    <app-grid-points [defaultPageSize]="5" [isStandalone]="true" *ngIf="shouldShowGridPoints()"></app-grid-points>
    <ng-container *ngIf="showForm">
      <div class="panel-box">
        <h3>Add Gridpoint</h3>
        <app-grid-point-detail [isStandalone]="true"></app-grid-point-detail>
        <div class="button-bar">
          <button mat-stroked-button (click)="hideForm()">
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:close"></mat-icon>
              <span>Cancel</span>
            </ph-flex-vertical-center>
          </button>
          <button mat-flat-button color="cta" (click)="saveGridPoint()" [disabled]="!(isGridPointFormValid$ | async)">
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:save"></mat-icon>
              <span>Save</span>
            </ph-flex-vertical-center>
          </button>
        </div>
      </div>
    </ng-container>
    <div class="stepper-buttons">
      <button mat-stroked-button matStepperPrevious>
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_upward"></mat-icon>
          <span>Back</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-stroked-button (click)="addGridPoint()" [disabled]="isAddGridPointBlockShown">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:add"></mat-icon>
          <span>Add Gridpoint</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="primary" matStepperNext [disabled]="!isStepCompleted(CustomerStepperStep.gridPointsStep)">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_downward"></mat-icon>
          <span>Next</span>
        </ph-flex-vertical-center>
      </button>
    </div>
  </mat-step>

  <mat-step label="Devices" [completed]="isStepCompleted(CustomerStepperStep.devicesStep)">
    <h4>Added Devices</h4>
    <app-devices [defaultPageSize]="5" [isStandalone]="true"></app-devices>
    <ng-container *ngIf="showForm">
      <div class="panel-box">
        <h3>Add device</h3>
        <app-device-detail [isStandalone]="true"></app-device-detail>
        <div class="button-bar">
          <button mat-stroked-button (click)="hideForm()">
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:close"></mat-icon>
              <span>Cancel</span>
            </ph-flex-vertical-center>
          </button>
          <button mat-flat-button color="cta" (click)="saveDevice()" [disabled]="!(isDeviceFormValid$ | async)">
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:save"></mat-icon>
              <span>Save</span>
            </ph-flex-vertical-center>
          </button>
        </div>
      </div>
    </ng-container>
    <div class="stepper-buttons">
      <button mat-stroked-button matStepperPrevious>
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_upward"></mat-icon>
          <span>Back</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-stroked-button (click)="addDevice()" [disabled]="isAddDeviceBlockShown">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:add"></mat-icon>
          <span>Add device</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="primary" matStepperNext [disabled]="!isStepCompleted(CustomerStepperStep.devicesStep)">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_downward"></mat-icon>
          <span>Next</span>
        </ph-flex-vertical-center>
      </button>
    </div>
  </mat-step>

  <mat-step label="Controls" [completed]="isStepCompleted(CustomerStepperStep.controlsStep)">
    <h4>Added Controls</h4>
    <app-controls [defaultPageSize]="5" [isStandalone]="true"></app-controls>
    <ng-container *ngIf="showForm && formType === FormType.edit">
      <div class="panel-box">
        <h3>Add Control</h3>
        <app-control-unit-detail [isStandalone]="true"></app-control-unit-detail>
        <div class="button-bar">
          <button mat-stroked-button (click)="hideForm()">
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:close"></mat-icon>
              <span>Cancel</span>
            </ph-flex-vertical-center>
          </button>
          <button mat-flat-button color="cta" (click)="saveControl()" [disabled]="!(isControlFormValid$ | async)">
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:save"></mat-icon>
              <span>Save</span>
            </ph-flex-vertical-center>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="showForm && formType === FormType.config">
      <div class="panel-box">
        <app-control-configuration [isStandalone]="true"></app-control-configuration>
        <div class="button-bar">
          <button mat-stroked-button (click)="hideForm()">
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:close"></mat-icon>
              <span>Cancel</span>
            </ph-flex-vertical-center>
          </button>
          <button mat-flat-button color="cta" (click)="configureControl()" [disabled]="!(isControlConfigurationFormValid$ | async)">
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:save"></mat-icon>
              <span>Save</span>
            </ph-flex-vertical-center>
          </button>
        </div>
      </div>
    </ng-container>
    <div class="stepper-buttons">
      <button mat-stroked-button matStepperPrevious>
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_upward"></mat-icon>
          <span>Back</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-stroked-button [disabled]="isAddControlBlockShown" (click)="addControl()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:add"></mat-icon>
          <span>Add control</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="primary" (click)="saveAndComplete()" [disabled]="!isStepCompleted(CustomerStepperStep.controlsStep)">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:save"></mat-icon>
          <span>Finish</span>
        </ph-flex-vertical-center>
      </button>
    </div>
  </mat-step>
</mat-vertical-stepper>
