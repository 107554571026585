<mat-card>
  <mat-card-title *ngIf="!isNew">Service agreement details</mat-card-title>
  <mat-card-title *ngIf="isNew">New Service agreement</mat-card-title>
  <mat-card-content>
    <div class="flex-row">
      <div class="col-1 segment-block flex-grid cols-2 first-col">
        <!-- For segment, add a new flexbox (flex-col + col-1) layout to align the button bar to the bottom, not matter the content heights of block -->
        <ng-container [formGroup]="commonFormGroup">
          <mat-form-field class="col-2">
            <mat-label>Customer</mat-label>
            <ph-flex-autocomplete
              phFlexCustomerDataSource
              [shouldEmitNull]="true"
              [shouldOnlyEmitIds]="true"
              formControlName="customerId"
              [shouldAutoSelectIfOnlyOneResult]="true"
              placeholder="Select customer"
              [errorStateMatcher]="fnErrorMatcher"
            >
            </ph-flex-autocomplete>
            <mat-error *ngIf="commonFormGroup.get('customerId').errors?.required"> Customer is required. </mat-error>
            <mat-error *ngIf="commonFormGroup.get('customerId').errors?.invalidCustomer"> Select a customer from the list. </mat-error>
          </mat-form-field>
          <mat-form-field readonly class="col-2">
            <mat-label>System reference</mat-label>
            <input matInput readonly formControlName="systemReference" />
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label>Reference</mat-label>
            <input matInput formControlName="reference" [errorStateMatcher]="fnErrorMatcher" />
            <mat-error *ngIf="commonFormGroup.get('reference').errors?.maxlength">
              Reference must not be longer than 50 characters.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-2">
            <mat-label id="product">Product</mat-label>
            <mat-select
              formControlName="product"
              [errorStateMatcher]="fnErrorMatcher"
              matTooltip="Product cannot be changed on existing service agreements"
              [matTooltipDisabled]="isNew"
            >
              <mat-option [value]="Product.R3_EMERGENCY_POWER">R3 Emergency Power</mat-option>
              <mat-option [value]="Product.INTRA_DAY">Intra day</mat-option>
            </mat-select>
          </mat-form-field>
          <ng-container formGroupName="period">
            <mat-form-field class="col-1">
              <mat-label>Start date</mat-label>
              <input matInput [matDatepicker]="startDateDatepicker" formControlName="startDate" [errorStateMatcher]="fnErrorMatcher" />
              <mat-datepicker-toggle matSuffix [for]="startDateDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #startDateDatepicker></mat-datepicker>
              <mat-error *ngIf="commonFormGroup.get('period.startDate').errors?.required"> Start date is required. </mat-error>
            </mat-form-field>
            <mat-form-field class="col-1">
              <mat-label>End date</mat-label>
              <input [matDatepicker]="endDateDatepicker" formControlName="endDate" matInput [errorStateMatcher]="crossFieldErrorMatcher" />
              <mat-datepicker-toggle matSuffix [for]="endDateDatepicker"></mat-datepicker-toggle>
              <mat-datepicker #endDateDatepicker></mat-datepicker>
              <mat-error *ngIf="commonFormGroup.get('period.endDate').errors?.required"> End date is required. </mat-error>
              <mat-error *ngIf="commonFormGroup.get('period').errors?.periodWrong"> End date must be after start date. </mat-error>
            </mat-form-field>
          </ng-container>
        </ng-container>
        <ng-container [formGroup]="emergencyPowerFormGroup">
          <ng-container formGroupName="maxCapacityUpward" *ngIf="isEmergencyPowerServiceAgreement()">
            <mat-form-field class="col-2">
              <mat-label>Maximum capacity upward</mat-label>
              <ph-flex-number-input matInput formControlName="value" [errorStateMatcher]="fnErrorMatcher"></ph-flex-number-input>
              <span matTextSuffix>MW</span>
              <mat-error *ngIf="emergencyPowerFormGroup.get('maxCapacityUpward.value').errors?.required">
                Maximum capacity upward is required.
              </mat-error>
              <mat-error *ngIf="emergencyPowerFormGroup.get('maxCapacityUpward.value').errors?.min">
                Maximum capacity upward must be at least 0.
              </mat-error>
              <mat-error *ngIf="emergencyPowerFormGroup.get('maxCapacityUpward.value').errors?.invalidNumber">
                Not a valid number
              </mat-error>
              <mat-error *ngIf="emergencyPowerFormGroup.get('maxCapacityUpward.value').errors?.invalidNumberOfDecimals">
                Only two decimals are allowed
              </mat-error>
            </mat-form-field>
          </ng-container>
          <ng-container formGroupName="maxCapacityDownward" *ngIf="isEmergencyPowerServiceAgreement()">
            <mat-form-field class="col-2">
              <mat-label>Maximum capacity downward</mat-label>
              <ph-flex-number-input formControlName="value" [errorStateMatcher]="fnErrorMatcher"></ph-flex-number-input>
              <span matTextSuffix>MW</span>
              <mat-error *ngIf="emergencyPowerFormGroup.get('maxCapacityDownward.value').errors?.required">
                Maximum capacity downward is required.
              </mat-error>
              <mat-error *ngIf="emergencyPowerFormGroup.get('maxCapacityDownward.value').errors?.min">
                Maximum capacity downward must be at least 0.
              </mat-error>
              <mat-error *ngIf="emergencyPowerFormGroup.get('maxCapacityDownward.value').errors?.invalidNumber">
                Not a valid number
              </mat-error>
              <mat-error *ngIf="emergencyPowerFormGroup.get('maxCapacityDownward.value').errors?.invalidNumberOfDecimals">
                Only two decimals are allowed
              </mat-error>
            </mat-form-field>
          </ng-container>
          <mat-form-field class="col-2" *ngIf="isEmergencyPowerServiceAgreement()">
            <mat-label>Availability fee percentage 24/7</mat-label>
            <input matInput type="number" formControlName="availabilityFeePercBaseLoad" [errorStateMatcher]="fnErrorMatcher" />
            <span matTextSuffix>%</span>
            <mat-error *ngIf="emergencyPowerFormGroup.get('availabilityFeePercBaseLoad').errors?.required">
              Availability fee percentage 24/7 is required.
            </mat-error>
            <mat-error *ngIf="emergencyPowerFormGroup.get('availabilityFeePercBaseLoad').errors?.min">
              Availability fee percentage 24/7 must be at least 0.
            </mat-error>
            <mat-error *ngIf="emergencyPowerFormGroup.get('availabilityFeePercBaseLoad').errors?.max">
              Availability fee percentage 24/7 cannot be greater than 100.
            </mat-error>
            <mat-error *ngIf="emergencyPowerFormGroup.get('availabilityFeePercBaseLoad').errors?.pattern">
              Availability fee percentage 24/7 should only contain digits (maximum 3). No decimals.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="col-2" *ngIf="isEmergencyPowerServiceAgreement()">
            <mat-label>Availability fee time table</mat-label>
            <input matInput type="number" formControlName="availabilityFeePercTimeTable" [errorStateMatcher]="fnErrorMatcher" />
            <span matTextSuffix>%</span>
            <mat-error *ngIf="emergencyPowerFormGroup.get('availabilityFeePercTimeTable').errors?.required">
              Availability fee percentage time table is required.
            </mat-error>
            <mat-error *ngIf="emergencyPowerFormGroup.get('availabilityFeePercTimeTable').errors?.min">
              Availability fee percentage time table must be at least 0.
            </mat-error>
            <mat-error *ngIf="emergencyPowerFormGroup.get('availabilityFeePercTimeTable').errors?.max">
              Availability fee percentage time table cannot be greater than 100.
            </mat-error>
            <mat-error *ngIf="emergencyPowerFormGroup.get('availabilityFeePercTimeTable').errors?.pattern">
              Availability fee percentage time table should only contain digits (maximum 3). No decimals.
            </mat-error>
          </mat-form-field>
        </ng-container>
      </div>
      <div class="col-1 segment-block">
        <h3>Select Grid points</h3>
        <div class="status-subtle-box-alert-with-arrow" *ngIf="showNoGridPointsSelectedErrorMessage()">
          <mat-error>Please select at least one grid point</mat-error>
        </div>
        <div class="overflow-table has-shadow">
          <table mat-table class="has-shadow has-nowrap" [dataSource]="dataSource" matSort>
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (click)="masterToggle($event)"
                  [checked]="isMasterToggleChecked"
                  [indeterminate]="isMasterToggleIndeterminate"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox (change)="toggleRow(row.gridPointId)" [checked]="isRowSelected(row.gridPointId)"> </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="ean">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>EAN</th>
              <td mat-cell *matCellDef="let gridPoint" [ngClass]="gridPoint.ownedByCustomer ? '' : 'status-alert'">
                <ph-flex-vertical-center>
                  <a routerLink="/grid-points/{{ gridPoint.gridPointId }}">{{ gridPoint.ean }}</a>
                  <mat-icon
                    *ngIf="!gridPoint.ownedByCustomer"
                    matTooltip="Grid point is not owned by this customer"
                    svgIcon="ic-old:alert-warning"
                  >
                  </mat-icon>
                </ph-flex-vertical-center>
              </td>
            </ng-container>
            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
              <td mat-cell *matCellDef="let gridPoint">
                {{ gridPoint.description }}
              </td>
            </ng-container>
            <ng-container matColumnDef="incidentReservePreQualified">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Incident reserve PQ</th>
              <td mat-cell *matCellDef="let gridPoint" [ngSwitch]="gridPoint.incidentReservePreQualified">
                <ph-flex-vertical-center>
                  <ng-container *ngSwitchCase="true">
                    <mat-icon class="status-success" svgIcon="ic-old:check"></mat-icon>
                  </ng-container>
                  <ng-container *ngSwitchCase="false">
                    <mat-icon class="status-passive" svgIcon="ic-old:close"></mat-icon>
                  </ng-container>
                </ph-flex-vertical-center>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedGridPointColumns$ | async"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedGridPointColumns$ | async"></tr>
          </table>
        </div>
        <mat-paginator [pageSize]="defaultPageSize" [showFirstLastButtons]="true" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="cta" (click)="onSubmit()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:save"></mat-icon>
        <span>Save</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
