<ng-container *ngIf="!isStandalone">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Device details</mat-card-title>
    </mat-card-header>
    <mat-card-content [ngTemplateOutlet]="pageContents"></mat-card-content>
    <mat-card-actions>
      <button mat-stroked-button type="button" (click)="goBack()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
          <span>Go back</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="cta" type="submit" (click)="onSubmit()" [disabled]="!deviceForm.valid">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:save"></mat-icon>
          <span>Save</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>

<ng-container *ngIf="isStandalone" [ngTemplateOutlet]="pageContents"></ng-container>

<ng-template #pageContents>
  <form [formGroup]="deviceForm" ngxsForm="deviceStateModule.device" class="single-column-form">
    <mat-form-field>
      <mat-label>Provider</mat-label>
      <mat-select formControlName="providerType" (selectionChange)="changeType()">
        <mat-option [value]="DeviceProviderType.SERCOM">Sercom</mat-option>
        <mat-option [value]="DeviceProviderType.OTHER">other</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field [attr.readonly]="!allowExternalId() ? '' : null">
      <mat-label>External ID</mat-label>
      <input matInput formControlName="externalId" [readonly]="!allowExternalId()" />
      <mat-error *ngIf="deviceForm.get('externalId').errors?.required"> External ID is required. </mat-error>
      <mat-error *ngIf="deviceForm.get('externalId').errors?.pattern"> External ID should be RPxxxx, where x is a number </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="!isStandalone">
      <mat-label>Customer</mat-label>
      <ph-flex-autocomplete
        phFlexCustomerDataSource
        [shouldEmitNull]="true"
        [shouldOnlyEmitIds]="true"
        formControlName="customerId"
        [shouldAutoSelectIfOnlyOneResult]="true"
        placeholder="Select customer"
      ></ph-flex-autocomplete>
      <mat-error *ngIf="deviceForm.get('customerId').errors?.required"> Customer is required. </mat-error>
      <mat-error *ngIf="deviceForm.get('customerId').errors?.invalidCustomer"> Select a customer from the list. </mat-error>
    </mat-form-field>
  </form>
</ng-template>
