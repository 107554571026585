import { Injectable } from '@angular/core';
import { RestClientService, RestEntity } from 'flex-app-shared';

export class MeasurementCompany extends RestEntity {
  id: string;
  name: string;
  measurementProvider: string;
}

@Injectable({
  providedIn: 'root'
})
export class MeasurementCompanyService extends RestClientService<MeasurementCompany> {
  constructor() {
    super('/api/v1/measurement-companies');
  }
}
