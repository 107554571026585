import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { DownloadService, MessageService, Volume } from 'flex-app-shared';
import moment, { Moment } from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

const url = '/api/v1/measurements';

export class MeasurementDataGridPointSelection {
  ean: string;
  customerName: string;
  customerId: string;
  description: string;
}

export class Measurement {
  customerName: string;
  ean: string;
  period: {
    startDateTime: string;
    toDateTime: string;
  };
  consumption: Volume;
  production: Volume;
  sum?: Volume;
  remarks?: string;

  aggregatedFiveMinuteData?: Volume;
  fifteenMinuteData?: Volume;
}

@Injectable({
  providedIn: 'root'
})
export class MeasurementDataService {
  constructor(private http: HttpClient, private downloadService: DownloadService, private messageService: MessageService) {}

  getGridPoints(): Observable<MeasurementDataGridPointSelection[]> {
    return this.http
      .get<MeasurementDataGridPointSelection[]>(`${url}/grid-points`)
      .pipe(tap(() => this.messageService.debug('getGridPoints')));
  }

  getMeasurementData(date: string | Date | Moment, eans: ReadonlyArray<string>): Observable<Measurement[]> {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    return this.http.post<Measurement[]>(`${url}/${formattedDate}`, { eans }).pipe(tap(() => this.messageService.debug('measurements')));
  }

  downloadMeasurementData(date: string, eans: ReadonlyArray<string>): Observable<HttpResponse<Blob>> {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    return this.downloadService.download(
      this.http.post<Blob>(
        `${url}/${formattedDate}/excel`,
        { eans },
        {
          observe: 'response',
          responseType: 'blob' as 'json'
        }
      )
    );
  }

  download5MinuteTemplate(date: Date, gridPointId: string): Observable<any> {
    const formattedDate = format(date, 'yyyy-MM-dd');
    return this.downloadService.downloadExcel(`${url}/upload/${formattedDate}/${gridPointId}`);
  }

  upload5MinuteData(date: Date, gridPointId: string, file: Blob): Observable<string> {
    const formattedDate = format(date, 'yyyy-MM-dd');
    const formData = new FormData();
    formData.append('file', file);

    return this.http.post<string>(`${url}/upload/${formattedDate}/${gridPointId}`, formData);
  }

  get5MinuteDataPreview(id: string): Observable<Measurement[]> {
    return this.http.get<Measurement[]>(`${url}/upload/verify/${id}`);
  }

  confirm5MinuteData(id: string): Observable<any> {
    return this.http.put(`${url}/upload/confirm/${id}`, {});
  }

  cancel5MinuteData(id: string): Observable<any> {
    return this.http.put(`${url}/upload/cancel/${id}`, {});
  }

  fetchMeasurements(): Observable<any> {
    return this.http.put<void>(`${url}/fetch`, {});
  }
}
