<ng-container *ngIf="!isStandalone">
  <mat-card>
    <mat-card-content>
      <ng-container [ngTemplateOutlet]="pageContents"></ng-container>
    </mat-card-content>
    <mat-card-actions>
      <button *ngIf="!isStandalone" mat-stroked-button (click)="goBack()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
          <span>Go back</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="primary" routerLink="/recipients/new">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:recipient-add"></mat-icon>
          <span>New recipient</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>

<ng-container *ngIf="isStandalone" [ngTemplateOutlet]="pageContents"></ng-container>

<ng-template #pageContents>
  <mat-toolbar class="filter-bar">
    <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
  </mat-toolbar>

  <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
    <ng-container matColumnDef="pronoun">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Pronoun</th>
      <td mat-cell *matCellDef="let recipient">{{ recipient.pronoun }}</td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
      <td mat-cell *matCellDef="let recipient">
        <a routerLink="/recipients/{{ recipient.id }}">{{ recipient.firstName }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastNamePrefix">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name Prefix</th>
      <td mat-cell *matCellDef="let recipient">
        <a routerLink="/recipients/{{ recipient.id }}">{{ recipient.lastNamePrefix }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
      <td mat-cell *matCellDef="let recipient">
        <a routerLink="/recipients/{{ recipient.id }}">{{ recipient.lastName }} </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="mobileNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Mobile Number</th>
      <td mat-cell *matCellDef="let recipient">{{ recipient.mobileNumber }}</td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
      <td mat-cell *matCellDef="let recipient">{{ recipient.email }}</td>
    </ng-container>

    <ng-container matColumnDef="isEmployee">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Employee</th>
      <td mat-cell *matCellDef="let recipient" [ngSwitch]="recipient.isEmployee">
        <ph-flex-vertical-center>
          <ng-container *ngSwitchCase="true">
            <mat-icon class="status-success" svgIcon="ic-old:check"> </mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <mat-icon class="status-passive" svgIcon="ic-old:close"> </mat-icon>
          </ng-container>
        </ph-flex-vertical-center>
      </td>
    </ng-container>

    <ng-container matColumnDef="isMonthlyParticipationEmail">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Monthly updates</th>
      <td mat-cell *matCellDef="let recipient" [ngSwitch]="recipient.isMonthlyParticipationEmail">
        <ph-flex-vertical-center>
          <ng-container *ngSwitchCase="true">
            <mat-icon class="status-success" svgIcon="ic-old:check"> </mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <mat-icon class="status-passive" svgIcon="ic-old:close"> </mat-icon>
          </ng-container>
        </ph-flex-vertical-center>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let recipient">
        <ph-flex-vertical-center>
          <button mat-icon-button matTooltip="Edit" routerLink="/recipients/{{ recipient.id }}">
            <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
          </button>
          <button mat-icon-button matTooltip="Delete" class="remove" (click)="delete(recipient)">
            <mat-icon svgIcon="ic-old:trash-o"></mat-icon>
          </button>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [showFirstLastButtons]="true" [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
</ng-template>
