<ng-container *ngIf="showContents">
  <mat-sidenav-container>
    <mat-sidenav mode="over" [(opened)]="mobileSidenavOpen">
      <ng-container *ngTemplateOutlet="sidenavMenu"></ng-container>
    </mat-sidenav>
    <mat-sidenav-content>
      <mat-sidenav-container>
        <mat-sidenav mode="side" [opened]="desktopSidenavOpen" disableClose>
          <ng-container *ngTemplateOutlet="sidenavMenu"></ng-container>
        </mat-sidenav>
        <mat-sidenav-content>
          <mat-toolbar class="page-header">
            <button *ngIf="showHamburger" mat-icon-button (click)="toggleMobileSidenav()" class="mobile-hamburger-sidenav-toggle">
              <mat-icon svgIcon="ic-old:bars"></mat-icon>
            </button>
            <app-page-title [page]="currentPageService.matchedPage$ | async" type="toolbar" class="page-title"></app-page-title>
            <span class="spacer"></span>
            <ph-flex-motd-button></ph-flex-motd-button>
            <div class="user-control-panel">
              <div class="user-name">{{ authService.userName$ | async }}</div>
              <button mat-mini-fab [matMenuTriggerFor]="userMenu" color="primary">
                <mat-icon svgIcon="ic-old:user-circle-o"></mat-icon>
              </button>
            </div>
            <mat-menu #userMenu="matMenu">
              <ng-container *ngIf="loggedIn$ | async">
                <button mat-menu-item (click)="changePassword()">
                  <mat-icon svgIcon="ic-old:utils"></mat-icon>
                  Change password
                </button>
                <button mat-menu-item (click)="logout()">
                  <mat-icon svgIcon="ic-old:log-out"></mat-icon>
                  Logout
                </button>
              </ng-container>
            </mat-menu>
          </mat-toolbar>
          <div class="page-content">
            <ph-flex-messages></ph-flex-messages>
            <router-outlet></router-outlet>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-container>
<ph-flex-error *ngIf="error" [errorType]="error" (logout)="logout()"></ph-flex-error>
<ph-flex-connection-status></ph-flex-connection-status>

<ng-template #sidenavMenu>
  <ph-flex-sidenav-menu [menuItems]="menuItems" logoUrl="assets/powerhouse_logo-spacing.svg">
    <ng-template phFlexSidenavMenuItemContentDef let-menuItem>
      <a routerLink="{{ menuItem }}">
        <ph-flex-sidenav-menu-item [selected]="menuItem === (currentPageService.matchedPage$ | async)">
          <app-page-title [page]="menuItem" type="sidebar"></app-page-title>
        </ph-flex-sidenav-menu-item>
      </a>
    </ng-template>
  </ph-flex-sidenav-menu>
</ng-template>
