import { AggregatedUnavailabilities } from '../../../../app/shared/unavailability/aggregated-unavailability';

export class AggregatedUnavailabilitiesResetCommand {
  public static readonly type = '[Aggregated Unavailabilities] Reset aggregated unavailabilities action';
}

export class LoadAggregatedUnavailabilitiesCommand {
  public static readonly type = '[Aggregated Unavailabilities] Load aggregated unavailabilities data';

  constructor() {}
}

export class AggregatedUnavailabilitiesLoadedEvent {
  public static readonly type = '[Aggregated Unavailabilities] Set aggregated unavailabilities data';

  constructor(public payload: AggregatedUnavailabilities) {}
}

export class ClearSentUnavailabilitiesCommand {
  public static readonly type = '[Aggregated Unavailabilities] Clear unavailabilities sent to TenneT';
}

export class SendUnavailabilitiesCommand {
  public static readonly type = '[Aggregated Unavailabilities] Send aggregated unavailabilities to TenneT';
}
