<mat-card>
  <mat-card-header>
    <mat-card-title>Fetch REM measurements</mat-card-title>
  </mat-card-header>
  <mat-card-actions class="fetch-measurements-actions">
    <button mat-stroked-button (click)="fetchMeasurements()">
      <ph-flex-vertical-center
        [ngClass]="{
          'status-success': !sending && success,
          'status-alert': !sending && !success && !(label === 'Retrieve REM Measurements')
        }"
        matTooltip="{{ tooltip ? (tooltip | json) : '' }}"
      >
        <mat-icon svgIcon="ic-old:source-external"></mat-icon>
        <span>{{ label }}</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
