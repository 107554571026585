import { Injectable } from '@angular/core';
import { State } from '@ngxs/store';
import { SseDayAheadState } from './states/day-ahead/sse-day-ahead.state';
import { SseOperationState } from './states/operation/sse-operation.state';
import { SsePoolState } from './states/pool/sse-pool.state';

export const SseStates = [SseOperationState, SsePoolState, SseDayAheadState];

@State({
  name: 'sseStateModule',
  children: SseStates
})
@Injectable({
  providedIn: 'root'
})
export class SseStateModule {}
