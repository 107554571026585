import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { Customer } from 'flex-app-shared';
import { DeleteCustomerCommand, ResetCustomersCommand, UpdateCustomersCommand } from './customers.actions';
import { CustomersState } from './customers.state';

@Injectable({
  providedIn: 'root'
})
export class CustomersFacade {
  constructor(private store: Store) {}

  customers$ = this.store.select(CustomersState.isBusy).pipe(
    filter((a) => !a),
    first(),
    switchMap(() => this.store.select(CustomersState.getCustomers))
  );

  init(): void {
    this.resetCustomers();
    this.loadCustomers();
  }

  delete(customer: Customer): Observable<any> {
    return this.store.dispatch(new DeleteCustomerCommand(customer));
  }

  resetCustomers(): void {
    this.store.dispatch(new ResetCustomersCommand());
  }

  loadCustomers(): void {
    this.store.dispatch(new UpdateCustomersCommand());
  }

  setCustomerFilter(customerFilter: string): void {
    this.store.dispatch(new UpdateCustomersCommand(customerFilter));
  }
}
