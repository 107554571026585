<mat-card>
  <mat-card-header>
    <mat-card-title>Day-ahead market prices</mat-card-title>
  </mat-card-header>
  <mat-card-actions class="day-ahead-market-actions">
    <ph-flex-date-range [formControl]="form.get('period')" subscriptSizing="dynamic"></ph-flex-date-range>

    <button mat-stroked-button (click)="getDayAheadPrices()">
      <ph-flex-vertical-center *ngIf="dayAheadPricesResult && !dayAheadPricesError" class="status-success">
        <mat-icon svgIcon="ic-old:check-circle-o"></mat-icon>
        <span>Retrieved {{ dayAheadPricesResult.daysRetrieved }} day(s) of day ahead prices</span>
      </ph-flex-vertical-center>
      <ph-flex-vertical-center *ngIf="!dayAheadPricesResult && !retrievingPrices && !dayAheadPricesError"
        ><mat-icon svgIcon="ic-old:source-external"></mat-icon><span>Retrieve day ahead prices</span>
      </ph-flex-vertical-center>
      <ph-flex-vertical-center *ngIf="retrievingPrices" class="spinner-button">
        <mat-spinner diameter="19"></mat-spinner>
        <span>Retrieving prices...</span>
      </ph-flex-vertical-center>
      <ph-flex-vertical-center
        *ngIf="dayAheadPricesError"
        matTooltip="{{ dayAheadPricesResult?.errorMessage | json }}"
        class="status-alert"
      >
        <mat-icon svgIcon="ic-old:cancel-circle-o"></mat-icon>
        <span>Error while retrieving day ahead prices!</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
