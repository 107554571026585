/* Common data types */
import { AbstractControl, UntypedFormControl, FormGroupDirective, NgForm, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { get, isArray, isEqual, set, uniq } from 'lodash-es';
import moment from 'moment';
import { Subscription } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';
import { arrayWithDefault } from 'flex-app-shared';

export class CrossFieldErrorMatcher implements ErrorStateMatcher {
  constructor(private paths?: string | string[], private errors?: string[]) {}

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    let targets: ReadonlyArray<any> = [form];
    if (this.paths) {
      this.paths = isArray(this.paths) ? this.paths : [this.paths];
      targets = this.paths.map((path) => (path ? form.form.get(path) : form));
    }
    targets = uniq(targets);
    const targetIsInvalid = targets.some((target) => target.invalid);

    if (this.errors) {
      if (!isArray(this.errors)) {
        this.errors = [this.errors];
      }

      const targetErrors = targets.reduce((errors, target) => {
        if (target.errors) {
          return errors.concat(Object.keys(target.errors));
        }
        return errors;
      }, []);

      const hasTargetError = this.errors.some((errorKey) => targetErrors.includes(errorKey));
      const hasControlError = control.errors && this.errors.some((errorKey) => Object.keys(control.errors).includes(errorKey));

      return control.touched && (hasTargetError || hasControlError);
    }

    return control.touched && (targetIsInvalid || control.invalid);
  }
}

export class CombinedErrorMatcher implements ErrorStateMatcher {
  constructor(public errorStateMatchers: ErrorStateMatcher[]) {}

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return this.errorStateMatchers.some((errorStateMatcher) => errorStateMatcher.isErrorState(control, form));
  }
}

export function parseISOtoMoment(obj: any, properties: ReadonlyArray<string>): void {
  properties.forEach((property) => {
    const value = get(obj, property);
    set(obj, property, moment(value));
  });
}

export function formatMomentAsTime(obj: any, properties: ReadonlyArray<string>): void {
  properties.forEach((property) => {
    const value = get(obj, property);
    set(obj, property, moment(value).format('HH:mm'));
  });
}

export function formatDateAndTimeAsISODateTime(obj: any, properties: ReadonlyArray<string>): void {
  properties.forEach((property) => {
    const value = get(obj, property);
    set(obj, property, moment(`${value.date.format('DD-MM-YYYY')} ${value.time}`, 'DD-MM-YYYY HH:mm').toISOString());
  });
}

/**
 * Modify the ValidatorFn list using some utility functions
 */
export class ValidatorUtil {
  /**
   * Ensure the Validator.Required validator is part of the returned ValidatorFn array
   * Note that you cannot use  control.validator as the input property, since that can be a composed function.
   * You need to keep track of the validators in an array.
   * @param validators ValidatorFn or array of ValidatorFns to start with
   */
  static required(validators: ValidatorFn[] | ValidatorFn | null): ValidatorFn[] {
    const validatorArray = arrayWithDefault(validators);
    const isAlreadyRequired = validatorArray.includes(Validators.required);
    if (isAlreadyRequired) {
      return validatorArray;
    } else {
      return validatorArray.concat(Validators.required);
    }
  }

  /**
   * Ensure the Validator.Required validator is NOT part of the returned ValidatorFn array
   * Note that you cannot use  control.validator as the input property, since that can be a composed function.
   * You need to keep track of the validators in an array.
   * @param validators ValidatorFn or array of ValidatorFns to start with
   */
  static notRequired(validators: ValidatorFn[] | ValidatorFn | null): ValidatorFn[] {
    const validatorArray = arrayWithDefault(validators);
    const isAlreadyRequired = validatorArray.includes(Validators.required);
    if (isAlreadyRequired) {
      return validatorArray.filter((validator) => validator !== Validators.required);
    } else {
      return validatorArray;
    }
  }
}

export class SelectionModelControlAdapter<T> {
  private selectionChangeSubscription: Subscription = null;
  private modelChangeSubscription: Subscription = null;

  /**
   * Store the value that is currently being updated so we can prevent circular updates
   */
  private pendingValue: T[];

  constructor(selectionModel: SelectionModel<T> = new SelectionModel<T>(), control?: AbstractControl) {
    this.setSelectionModel(selectionModel, false);
    if (control) this.setControl(control);
  }

  protected _selectionModel: SelectionModel<T>;

  get selectionModel(): SelectionModel<T> {
    return this._selectionModel;
  }

  protected _control: AbstractControl;

  get control(): AbstractControl {
    return this._control;
  }

  setControl(control: AbstractControl, updateSelectionModel: boolean = true): void {
    this._control = control;
    this.initControlBindings();

    if (updateSelectionModel) {
      // Bring selection model in sync with control value
      this.modelToView();
    } else if (this.control.value) {
      // Notify when a control is passed that has a non-nil value, you might want to call with updateSelectionModel true instead
      console.warn(`Ignoring non-empty control value ${this.control.value}`);
    }
  }

  setSelectionModel(selectionModel: SelectionModel<T>, updateSelectionModel: boolean = true): void {
    this._selectionModel = selectionModel;
    this.initSelectionModelBindings();

    if (updateSelectionModel) {
      // Bring selection model in sync with control value
      this.modelToView();
    } else if (this.control && !isEqual(this.selectionModel.selected, this.control.value)) {
      // Notify when a control is passed that has a non-nil value, you might want to call with updateSelectionModel true instead
      console.warn(
        `Provided selection model ${this.selectionModel.selected} has a different value than current control ${this.control.value}`
      );
    }
  }

  private initControlBindings(): void {
    // Cleanup old subscriptions
    if (this.modelChangeSubscription) {
      this.modelChangeSubscription.unsubscribe();
      this.modelChangeSubscription = null;
    }

    // Init subscriptions
    if (this.control) {
      this.modelChangeSubscription = this.control.valueChanges.subscribe(() => this.modelToView());
    }
  }

  private initSelectionModelBindings(): void {
    // Cleanup old subscription
    if (this.selectionChangeSubscription) {
      this.selectionChangeSubscription.unsubscribe();
      this.selectionChangeSubscription = null;
    }

    // Init subscriptions
    if (this.selectionModel) {
      this.selectionChangeSubscription = this.selectionModel.changed.subscribe(() => this.viewToModel());
    }
  }

  private viewToModel(): void {
    // Ignore all updates when this.pendingValue is truthy
    // Ensure control and selectionModel exist
    if (this.pendingValue || !this.control || !this.selectionModel) {
      return;
    }

    this.pendingValue = this.selectionModel.selected;
    this.control.setValue(this.selectionModel.selected);
    this.control.updateValueAndValidity();
    this.pendingValue = null;
  }

  private modelToView(): void {
    // Ignore all updates when this.pendingValue is truthy
    // Ensure control and selectionModel exist
    if (this.pendingValue || !this.control || !this.selectionModel) {
      return;
    }

    this.pendingValue = this.control.value;
    this.selectionModel.clear();
    if (!(this.control.value === '' && this.control.pristine)) {
      // Do not select a value if the formControl is '' and pristine
      // Otherwise we would select ['']
      this.selectionModel.select(...arrayWithDefault(this.pendingValue));
    }
    this.pendingValue = null;
  }
}
