import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-recipients',
  templateUrl: './dashboard-recipients.component.html',
  styleUrls: ['./dashboard-recipients.component.scss']
})
export class DashboardRecipientsComponent {
  @Input()
  public readonly isForbidden: boolean = false;

  @Input()
  public readonly isInitialized: boolean = false;

  @Input()
  private readonly customerId: string | undefined = undefined;

  private getUrl(): string {
    if (!this.customerId) return `/recipients/new`;
    return `/dashboard/${this.customerId}/recipients/new`;
  }
}
