<ng-container *ngIf="!isStandalone">
  <mat-card>
    <mat-card-title>Customer Details</mat-card-title>
    <mat-card-content [ngTemplateOutlet]="pageContents"></mat-card-content>
    <mat-card-actions>
      <button mat-stroked-button (click)="goBack()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
          <span>Go back</span>
        </ph-flex-vertical-center>
      </button>
      <ph-flex-entity-save-button
        (click)="onSubmit()"
        [disabled]="!customerForm.valid"
        [entityFacade]="customerFacade"
      ></ph-flex-entity-save-button>
    </mat-card-actions>
  </mat-card>
</ng-container>

<ng-container *ngIf="isStandalone" [ngTemplateOutlet]="pageContents"></ng-container>

<ng-template #pageContents>
  <form [formGroup]="customerForm" ngxsForm="customersStateModule.customer" class="single-column-form">
    <mat-form-field>
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
      <mat-error *ngIf="customerForm.get('name').errors?.required"> Name is required. </mat-error>
      <mat-error *ngIf="customerForm.get('name').errors?.maxlength"> Name must not be longer than 50 characters. </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Legal Name</mat-label>
      <input matInput formControlName="legalName" />
      <mat-error *ngIf="customerForm.get('legalName').errors?.required"> Legal name is required. </mat-error>
      <mat-error *ngIf="customerForm.get('legalName').errors?.maxlength"> Legal name must not be longer than 250 characters. </mat-error>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Billing id (from Infinity)</mat-label>
      <input matInput formControlName="billingId" />
      <mat-error *ngIf="customerForm.get('billingId').errors?.required"> Billing id is required. </mat-error>
      <mat-error *ngIf="customerForm.get('billingId').errors?.maxlength"> Billing id must not be longer than 50 characters. </mat-error>
    </mat-form-field>

    <input formControlName="accountManagerId" type="hidden" />
    <mat-form-field>
      <mat-label>Account manager</mat-label>
      <input type="text" matInput [formControl]="accountManagerControl" [matAutocomplete]="auto" />
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAc">
        <mat-option *ngFor="let ac of filteredAccountManagers$ | async" [value]="ac">
          {{ displayAc(ac) }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
</ng-template>
