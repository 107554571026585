<mat-card>
  <mat-card-content>
    <mat-toolbar class="filter-bar">
      <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
    </mat-toolbar>

    <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let party">
          <a routerLink="/balance-responsible-parties/{{ party.id }}">{{ party.name }}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Code</th>
        <td mat-cell *matCellDef="let party">{{ party.code }}</td>
      </ng-container>
      <ng-container matColumnDef="ean">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>EAN</th>
        <td mat-cell *matCellDef="let party">{{ party.ean }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let party">
          <ph-flex-vertical-center>
            <button mat-icon-button matTooltip="Edit" routerLink="/balance-responsible-parties/{{ party.id }}">
              <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
            </button>
            <button mat-icon-button matTooltip="Set default" [disabled]="party.defaultBrp" (click)="setDefault(party)">
              <ng-container *ngIf="party.defaultBrp">
                <mat-icon class="set-default" svgIcon="ic-old:star"></mat-icon>
              </ng-container>
              <ng-container *ngIf="!party.defaultBrp">
                <mat-icon svgIcon="ic-old:star-o"></mat-icon>
              </ng-container>
            </button>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
  </mat-card-content>
  <mat-card-actions>
    <button mat-flat-button color="primary" routerLink="/balance-responsible-parties/new">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:add"></mat-icon>
        <span>New BRP</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
