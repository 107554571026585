<mat-card>
  <mat-card-header>
    <mat-card-title *ngIf="!isNew">TSO agreement details</mat-card-title>
    <mat-card-title *ngIf="isNew">TSO Service agreement</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form" class="flex-row">
      <div class="col-1 segment-block">
        <div class="flex-grid cols-2">
          <mat-form-field class="col-2">
            <mat-label>Product</mat-label>
            <mat-select formControlName="product">
              <mat-option *ngFor="let product of products" [value]="product">
                {{ product }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('product').errors?.required"> Product is required. </mat-error>
          </mat-form-field>

          <ng-container formGroupName="period">
            <mat-form-field class="col-2">
              <mat-label>Period</mat-label>
              <mat-date-range-input [rangePicker]="dateRangePicker">
                <input matStartDate formControlName="startDate" />
                <input matEndDate formControlName="endDate" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="dateRangePicker"></mat-datepicker-toggle>
              <mat-date-range-picker #dateRangePicker></mat-date-range-picker>
              <mat-error *ngIf="form.get('period.startDate').errors?.required"> Start date is required. </mat-error>
              <mat-error *ngIf="form.get('period.endDate').errors?.required"> End date is required. </mat-error>
              <mat-error *ngIf="form.get('period.endDate').errors?.matEndDateInvalid"> End date must be after start date. </mat-error>
            </mat-form-field>
          </ng-container>

          <mat-form-field class="col-2">
            <mat-label>Direction</mat-label>
            <mat-select formControlName="direction">
              <mat-option value="UPWARDS">Upwards</mat-option>
              <mat-option value="DOWNWARDS">Downwards</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('direction').errors?.required"> Direction is required. </mat-error>
          </mat-form-field>

          <ng-container *ngIf="tsoAgreementDirectionAlreadyExists">
            <ph-flex-notification type="warn" fullWidth="true"
              >Note: An agreement for this direction already exists for this period.</ph-flex-notification
            >
          </ng-container>
          <ng-container *ngIf="fromAuctionResult">
            <ph-flex-notification type="info" fullWidth="true">{{ auctionDescription }}</ph-flex-notification>
          </ng-container>
        </div>
      </div>
      <div class="col-1 segment-block">
        <div class="flex-grid cols-2">
          <ng-container formGroupName="power">
            <mat-form-field class="col-2">
              <mat-label>Capacity</mat-label>
              <ph-flex-number-input formControlName="value"></ph-flex-number-input>
              <span matTextSuffix>MW</span>
              <mat-error *ngIf="form.get('power.value').errors?.required"> Capacity is required </mat-error>
              <mat-error *ngIf="form.get('power.value').errors?.min"> Capacity should be at least 5 </mat-error>
              <mat-error *ngIf="form.get('power.value').errors?.max"> Capacity should be less than 1000 MW </mat-error>
              <mat-error *ngIf="form.get('power.value').errors?.invalidNumber"> Not a valid number </mat-error>
              <mat-error *ngIf="form.get('power.value').errors?.invalidNumberOfDecimals"> Only two decimals are allowed </mat-error>
            </mat-form-field>
          </ng-container>

          <mat-form-field class="col-2">
            <mat-label>Hourly fee</mat-label>
            <ph-flex-number-input formControlName="hourlyFee"></ph-flex-number-input>
            <span matTextSuffix>€/MW</span>
            <mat-error *ngIf="form.get('hourlyFee').errors?.required"> Hourly fee is required </mat-error>
            <mat-error *ngIf="form.get('hourlyFee').errors?.invalidNumber"> Not a valid number </mat-error>
            <mat-error *ngIf="form.get('hourlyFee').errors?.invalidNumberOfDecimals"> Only two decimals are allowed </mat-error>
            <mat-error *ngIf="form.get('hourlyFee').errors?.min"> Hourly fee should be at least 0 </mat-error>
          </mat-form-field>

          <mat-form-field class="col-2">
            <mat-label>Maximum operation time</mat-label>
            <mat-select formControlName="maxOperationTime">
              <mat-option *ngFor="let operationTime of operationTimes" [value]="operationTime">
                {{ operationTime }}
              </mat-option>
            </mat-select>
            <span matTextSuffix>Minutes</span>
          </mat-form-field>

          <mat-form-field class="col-2">
            <mat-label>Operation ramp up time</mat-label>
            <mat-select formControlName="operationRampUpTime">
              <mat-option *ngFor="let operationRampUpTime of operationRampUpTimes" [value]="operationRampUpTime">
                {{ operationRampUpTime }}
              </mat-option>
            </mat-select>
            <span matTextSuffix>Minutes</span>
          </mat-form-field>

          <mat-form-field class="col-2">
            <mat-label>Operation ramp down time</mat-label>
            <mat-select formControlName="operationRampDownTime" disabled="true">
              <mat-option *ngFor="let operationRampDownTime of operationRampDownTimes" [value]="operationRampDownTime">
                {{ operationRampDownTime }}
              </mat-option>
            </mat-select>
            <span matTextSuffix>Minutes</span>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="cta" (click)="onSubmit()" [disabled]="form.invalid">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:save"></mat-icon>
        <span>Save</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
