import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { DateFnsParseFormatters, DownloadService, getRetryConfig, Money, normalizeToDate } from 'flex-app-shared';
import { Observable, retry } from 'rxjs';

export enum IncidentReserveInvoiceResultsStatus {
  SENT = 'SENT',
  NOT_SENT = 'NOT_SENT',
  CHANGED = 'CHANGED',
  FAILED = 'FAILED'
}

export class IncidentReserveInvoiceResults {
  serviceAgreementId: string;
  serviceAgreementSystemReference: number;
  customerName: string;
  customerLegalName: string;
  customerBillingId: string;
  downwardsAmount: Money;
  downwardsAmountSent: Money;
  upwardsAmount: Money;
  upwardsAmountSent: Money;
  status: IncidentReserveInvoiceResultsStatus;
  publishedTimestamp: Date;
  reasonFailed: string;
  day: {
    day: string;
    timeZone: string;
  };
  id: string;
}

export class EmailResult {
  successfullySent: number;
  failed: number;
}

@Injectable({
  providedIn: 'root'
})
export class IncidentReserveInvoiceResultsService {
  private endpoint = '/api/v1/incident-reserve/invoice-results';

  constructor(private http: HttpClient, private downloadService: DownloadService) {}

  getInvoiceResults(date: Date): Observable<IncidentReserveInvoiceResults[]> {
    return this.http
      .get<IncidentReserveInvoiceResults[]>(`${this.endpoint}/${format(date, DateFnsParseFormatters.MONTH)}`)
      .pipe(retry(getRetryConfig()));
  }

  publish(date: Date, sendAll: boolean): any {
    let params = new HttpParams();
    params = params.set('yearMonth', format(date, DateFnsParseFormatters.MONTH));
    params = params.set('sendAll', sendAll);
    return this.http.put(`${this.endpoint}/publish`, '', { params });
  }

  recalculate(date: Date): any {
    return this.http.put(`${this.endpoint}/recalculate/${format(date, DateFnsParseFormatters.MONTH)}`, '');
  }

  sendUnprocessedInvoiceResultsEmail(month: Date): any {
    return this.http.put(`${this.endpoint}/email-unprocessed/${format(normalizeToDate(month), DateFnsParseFormatters.MONTH)}`, '');
  }
}
