<mat-card xmlns="http://www.w3.org/1999/html">
  <mat-card-header>
    <mat-card-title>Analog Input Configuration</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form">
      <div class="flex-row">
        <div class="col-1 segment-block">
          <div class="flex-grid cols-1">
            <!-- Base info -->

            <mat-form-field class="col-1">
              <mat-label id="kind">Input type</mat-label>
              <mat-select formControlName="kind" (selectionChange)="onKindChanged($event)">
                <mat-option [value]="InputKind.ANALOG">Analog input</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="col-1">
              <mat-label>Customer</mat-label>
              <ph-flex-autocomplete
                phFlexCustomerDataSource
                [shouldEmitNull]="true"
                [shouldOnlyEmitIds]="true"
                formControlName="customerId"
                [shouldAutoSelectIfOnlyOneResult]="true"
                placeholder="Select customer"
              ></ph-flex-autocomplete>
              <mat-error *ngIf="form.get('customerId').errors?.required"> Customer is required. </mat-error>
              <mat-error *ngIf="form.get('customerId').errors?.invalidCustomer"> Select a customer from the list. </mat-error>
            </mat-form-field>

            <mat-form-field class="col-1">
              <mat-label>Grid point</mat-label>
              <ph-flex-autocomplete
                phFlexGridPointDataSource
                formControlName="gridPointId"
                [shouldOnlyEmitIds]="true"
                [shouldEmitNull]="true"
                [shouldAutoSelectIfOnlyOneResult]="true"
                placeholder="Select grid point"
              ></ph-flex-autocomplete>
            </mat-form-field>

            <mat-form-field class="col-1">
              <mat-label>Description</mat-label>
              <input matInput formControlName="description" />
              <mat-error *ngIf="form.get('description').errors?.required"> Description is required. </mat-error>
              <mat-error *ngIf="form.get('description').errors?.maxlength"> Description must not be longer than 100 characters. </mat-error>
            </mat-form-field>

            <!-- Data Provider -->
            <mat-form-field readonly class="col-1">
              <mat-label>Data provider</mat-label>
              <input matInput readonly formControlName="providerId" />
            </mat-form-field>

            <ng-container formGroupName="providerProperties">
              <mat-form-field class="col-1">
                <mat-label>Repobox</mat-label>
                <mat-select formControlName="repobox">
                  <mat-option *ngFor="let device of devices" [value]="device.externalId">
                    {{ device.externalId }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.get('providerProperties.repobox').errors?.required"> Repobox is required. </mat-error>
              </mat-form-field>
              <mat-form-field class="col-1">
                <mat-label>Powerhouse ID</mat-label>
                <input matInput formControlName="powerhouseId" />
                <mat-error *ngIf="form.get('providerProperties.powerhouseId').errors?.required"> Powerhouse ID is required. </mat-error>
                <mat-error *ngIf="form.get('providerProperties.powerhouseId').errors?.maxlength">
                  Powerhouse ID must not be longer than 10 characters.
                </mat-error>
              </mat-form-field>
            </ng-container>
          </div>
        </div>
        <div class="col-1 segment-block">
          <div class="flex-grid cols-1">
            <!-- Calibration -->
            <mat-form-field class="col-1">
              <mat-label id="type">Analog input subtype</mat-label>
              <mat-select formControlName="type">
                <mat-option [value]="AnalogInputType.HEAT_BUFFER">Heat buffer</mat-option>
                <mat-option [value]="AnalogInputType.STATE_OF_CHARGE">State of charge</mat-option>
                <mat-option [value]="AnalogInputType.SOLAR_IRRADIANCE">Solar irradiance</mat-option>
              </mat-select>
            </mat-form-field>
            <ng-container formGroupName="calibration">
              <mat-form-field class="col-1">
                <mat-label id="signalType">Signal type</mat-label>
                <mat-select formControlName="signalType">
                  <mat-option [value]="AnalogInputSignalType.TYPE_0_5V">0 - 5V</mat-option>
                  <mat-option [value]="AnalogInputSignalType.TYPE_0_10V">0 - 10V</mat-option>
                  <mat-option [value]="AnalogInputSignalType.TYPE_0_20MA">0 - 20mA</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field readonly class="col-1">
                <mat-label>Lower calibration value</mat-label>
                <input matInput readonly value="0" />
                <span matTextSuffix>{{ inputTypeUnitOfMeasure() }}.</span>
              </mat-form-field>

              <mat-form-field class="col-1">
                <mat-label>Lower (0 {{ inputTypeUnitOfMeasure() }}) measurement value</mat-label>
                <ph-flex-number-input formControlName="lboundMeasurement"></ph-flex-number-input>
                <span matTextSuffix>{{ signalTypeUnitOfMeasure() }}.</span>
                <mat-error *ngIf="form.get('calibration.lboundMeasurement').errors?.required">
                  Lower measurement value is required.
                </mat-error>
                <mat-error *ngIf="form.get('calibration.lboundMeasurement').errors?.min">
                  Lower measurement value should be at least 0 {{ signalTypeUnitOfMeasure() }}.
                </mat-error>
                <mat-error *ngIf="form.get('calibration.lboundMeasurement').errors?.invalidNumberOfDecimals">
                  At most 2 decimals are allowed for lower measurement value.
                </mat-error>
                <mat-error *ngIf="form.get('calibration.lboundMeasurement').errors?.invalidNumber">
                  Only numeric characters allowed for lower measurement value.
                </mat-error>
                <mat-error *ngIf="form.get('calibration.lboundMeasurement').errors?.measurementMax" class="field-error">
                  Lower measurement value should be at most {{ maxMeasurement() }} {{ signalTypeUnitOfMeasure() }}.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-1">
                <mat-label>Upper calibration value</mat-label>
                <span matTextSuffix>{{ inputTypeUnitOfMeasure() }}</span>
                <ph-flex-number-input formControlName="uboundCalibration"></ph-flex-number-input>
                <mat-error *ngIf="form.get('calibration.uboundCalibration').errors?.required">
                  Upper calibration value is required.
                </mat-error>
                <mat-error *ngIf="form.get('calibration.uboundCalibration').errors?.invalidNumberOfDecimals">
                  At most 2 decimals are allowed for upper calibration value.
                </mat-error>
                <mat-error *ngIf="form.get('calibration.uboundCalibration').errors?.invalidNumber">
                  Only numeric characters allowed for upper calibration value.
                </mat-error>
                <mat-error *ngIf="form.get('calibration.uboundCalibration').errors?.uboundCalibrationMin" class="field-error">
                  Upper calibration value should be at least {{ minCalibration() }} {{ inputTypeUnitOfMeasure() }}.
                </mat-error>
                <mat-error *ngIf="form.get('calibration.uboundCalibration').errors?.uboundCalibrationMax" class="field-error">
                  Upper calibration value should be at most {{ maxCalibration() }} {{ inputTypeUnitOfMeasure() }}.
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-1">
                <mat-label
                  >Upper ({{ form.get('calibration.uboundCalibration').value }} {{ inputTypeUnitOfMeasure() }}) measurement value
                </mat-label>
                <span matTextSuffix>{{ signalTypeUnitOfMeasure() }}</span>
                <ph-flex-number-input formControlName="uboundMeasurement"></ph-flex-number-input>
                <mat-error *ngIf="form.get('calibration.uboundMeasurement').errors?.required">
                  Upper measurement value is required.
                </mat-error>
                <mat-error *ngIf="form.get('calibration.uboundMeasurement').errors?.invalidNumberOfDecimals">
                  At most 2 decimals are allowed for upper measurement value.
                </mat-error>
                <mat-error *ngIf="form.get('calibration.uboundMeasurement').errors?.invalidNumber">
                  Only numeric characters allowed for upper measurement value.
                </mat-error>
                <mat-error *ngIf="form.get('calibration.uboundMeasurement').errors?.uboundMeasurementMin">
                  Upper measurement value should be greater than lower measurement value ({{
                    form.get('calibration.lboundMeasurement').value
                  }}
                  {{ signalTypeUnitOfMeasure() }}).
                </mat-error>
                <mat-error *ngIf="form.get('calibration.uboundMeasurement').errors?.measurementMax">
                  Upper measurement value should be at most {{ maxMeasurement() }} {{ signalTypeUnitOfMeasure() }}.
                </mat-error>
              </mat-form-field>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="cta" (click)="onSubmit()" [disabled]="!form.valid || blockSubmitButton">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:save"></mat-icon>
        <span>Save</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
