import { PoolPeriodView } from '../../../../app/shared/pool/pool.service';
import { clone } from 'lodash-es';
import { LocalDate } from 'flex-app-shared';

interface PoolPeriodPostStrategy {
  canHandle(newPeriods: PoolPeriodView[], newPeriod: PoolPeriodView): boolean;

  handle(newPeriods: PoolPeriodView[], newPeriod: PoolPeriodView): PoolPeriodView[];
}

interface StrategyResult {
  strategy: string;
  periodsBefore: PoolPeriodView[];
  periodsAfter: PoolPeriodView[];
  periodsRemoved: PoolPeriodView[];
  periodsAdded: PoolPeriodView[];
}

class RemoveEclipsedPeriodsStrategy implements PoolPeriodPostStrategy {
  canHandle(newPeriods: PoolPeriodView[], newPeriod: PoolPeriodView): boolean {
    return newPeriods.some((period) => this.isEclipsed(newPeriod, period));
  }

  handle(newPeriods: PoolPeriodView[], newPeriod: PoolPeriodView): PoolPeriodView[] {
    return newPeriods.filter((period) => !this.isEclipsed(newPeriod, period));
  }

  private isEclipsed(newPeriod: PoolPeriodView, potentiallyEclipsedPeriod: PoolPeriodView): boolean {
    // Check if period is fully eclipsed.
    // Also check equality, since we don't want to filter out the newly added pool period
    return (
      LocalDate.toMoment(newPeriod.period.startDate).isSameOrBefore(
        LocalDate.toMoment(potentiallyEclipsedPeriod.period.startDate),
        'day'
      ) &&
      LocalDate.toMoment(newPeriod.period.endDate).isSameOrAfter(LocalDate.toMoment(potentiallyEclipsedPeriod.period.endDate), 'day') &&
      newPeriod !== potentiallyEclipsedPeriod
    );
  }
}

class SplitFullyOverlappingPeriodStrategy implements PoolPeriodPostStrategy {
  canHandle(newPeriods: PoolPeriodView[], newPeriod: PoolPeriodView): boolean {
    return newPeriods.some((period) => this.isFullyOverlapping(newPeriod, period));
  }

  handle(newPeriods: PoolPeriodView[], newPeriod: PoolPeriodView): PoolPeriodView[] {
    const foundPeriod = newPeriods.find((period) => this.isFullyOverlapping(newPeriod, period));
    newPeriods.push({
      period: {
        startDate: LocalDate.fromMoment(LocalDate.toMoment(newPeriod.period.endDate).add(1, 'day').startOf('day')),
        endDate: LocalDate.clone(foundPeriod.period.endDate)
      },
      gridPointIds: clone(foundPeriod.gridPointIds),
      sent: false
    });

    foundPeriod.period.endDate = LocalDate.fromMoment(LocalDate.toMoment(newPeriod.period.startDate).subtract(1, 'day').endOf('day'));

    return newPeriods;
  }

  private isFullyOverlapping(newPeriod: PoolPeriodView, period: PoolPeriodView): boolean {
    return (
      LocalDate.toMoment(period.period.startDate).isBefore(LocalDate.toMoment(newPeriod.period.startDate), 'day') &&
      LocalDate.toMoment(period.period.endDate).isAfter(LocalDate.toMoment(newPeriod.period.endDate), 'day')
    );
  }
}

class TrimOverlappingPeriodBeforeStrategy implements PoolPeriodPostStrategy {
  canHandle(newPeriods: PoolPeriodView[], newPeriod: PoolPeriodView): boolean {
    return newPeriods.some((period) => this.isOverlappingBefore(newPeriod, period));
  }

  handle(newPeriods: PoolPeriodView[], newPeriod: PoolPeriodView): PoolPeriodView[] {
    const foundPeriod = newPeriods.find((period) => this.isOverlappingBefore(newPeriod, period));
    foundPeriod.period.endDate = LocalDate.fromMoment(LocalDate.toMoment(newPeriod.period.startDate).subtract(1, 'day').endOf('day'));
    return newPeriods;
  }

  private isOverlappingBefore(newPeriod: PoolPeriodView, period: PoolPeriodView): boolean {
    return (
      LocalDate.toMoment(period.period.startDate).isBefore(LocalDate.toMoment(newPeriod.period.startDate), 'day') &&
      LocalDate.toMoment(period.period.endDate).isAfter(LocalDate.toMoment(newPeriod.period.startDate), 'day')
    );
  }
}

class TrimOverlappingPeriodAfterStrategy implements PoolPeriodPostStrategy {
  canHandle(newPeriods: PoolPeriodView[], newPeriod: PoolPeriodView): boolean {
    return newPeriods.some((period) => this.isOverlappingAfter(newPeriod, period));
  }

  handle(newPeriods: PoolPeriodView[], newPeriod: PoolPeriodView): PoolPeriodView[] {
    const foundPeriod = newPeriods.find((period) => this.isOverlappingAfter(newPeriod, period));
    foundPeriod.period.startDate = LocalDate.fromMoment(LocalDate.toMoment(newPeriod.period.endDate).add(1, 'day').startOf('day'));
    return newPeriods;
  }

  private isOverlappingAfter(newPeriod: PoolPeriodView, period: PoolPeriodView): boolean {
    return (
      LocalDate.toMoment(period.period.startDate).isBefore(LocalDate.toMoment(newPeriod.period.endDate), 'day') &&
      LocalDate.toMoment(period.period.endDate).isAfter(LocalDate.toMoment(newPeriod.period.endDate), 'day')
    );
  }
}

class SortPeriodsStrategy implements PoolPeriodPostStrategy {
  canHandle(newPeriods: PoolPeriodView[], newPeriod: PoolPeriodView): boolean {
    return true;
  }

  handle(newPeriods: PoolPeriodView[], newPeriod: PoolPeriodView): PoolPeriodView[] {
    return newPeriods.sort((a, b) => (LocalDate.toMoment(a.period.endDate).isBefore(LocalDate.toMoment(b.period.endDate), 'day') ? -1 : 1));
  }
}

export class PeriodScheduler {
  eclipsedPeriod = false;

  constructor(public poolPeriods: PoolPeriodView[]) {}

  public addPeriod(poolPeriod: PoolPeriodView): void {
    const newPoolPeriod = {
      ...poolPeriod
    };
    const newPoolPeriods = clone(this.poolPeriods);
    const postStrategies: PoolPeriodPostStrategy[] = [];

    const executedStrategies = [];

    // Remove any periods that are completely eclipsed by the new period
    postStrategies.push(new RemoveEclipsedPeriodsStrategy());

    // Sort periods
    postStrategies.push(new SortPeriodsStrategy());

    // Handle overlapping periods
    postStrategies.push(new TrimOverlappingPeriodBeforeStrategy());
    postStrategies.push(new TrimOverlappingPeriodAfterStrategy());
    postStrategies.push(new SplitFullyOverlappingPeriodStrategy());

    // Add new pool
    newPoolPeriods.push(newPoolPeriod);

    // Execute post strategies
    this.poolPeriods = postStrategies.reduceRight((reducedPoolPeriods, strategy) => {
      if (strategy.canHandle(reducedPoolPeriods, newPoolPeriod)) {
        executedStrategies.push(strategy);
        return strategy.handle(reducedPoolPeriods, newPoolPeriod);
      }
      return reducedPoolPeriods;
    }, newPoolPeriods);

    this.eclipsedPeriod = executedStrategies.filter((it) => it instanceof RemoveEclipsedPeriodsStrategy).length > 0;
  }

  public hasEclipsedPeriod(): boolean {
    return this.eclipsedPeriod;
  }
}
