<div class="measurements-chart-container">
  <mat-toolbar class="filter-bar">
    <mat-card-actions class="chart-actions">
      <mat-slide-toggle (change)="flipValues()"> Flip values </mat-slide-toggle>
      <button mat-flat-button color="primary" (click)="downloadData()" [disabled]="downloadDataButtonDisabled()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:download"></mat-icon>
          <span>Download measurement data</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-toolbar>
  <highcharts-chart
    class="measurements-chart"
    [Highcharts]="Highcharts"
    [constructorType]="chartConstructor"
    [options]="chartOptions"
    [(update)]="updateFlag"
    (chartInstance)="chartInstanceCallback($event)"
    style="width: 100%; height: 400px; display: block"
  ></highcharts-chart>
</div>
