import { filter, first, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { UsersState } from './users.state';
import { DeleteUserCommand, DownloadUsersDataCommand, LoadUsersCommand } from './users.actions';
import { Observable } from 'rxjs';
import { User } from '../../../../app/shared/user/user.service';

@Injectable({
  providedIn: 'root'
})
export class UsersFacade {
  constructor(private store: Store) {}

  users$ = this.store.select(UsersState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(UsersState.getUsers))
  );

  canDownload$ = this.store.select(UsersState.canDownload);

  busyDownloading$ = this.store.select(UsersState.isBusyDownloading);

  init(): void {
    this.store.dispatch(new LoadUsersCommand());
  }

  delete(user: User): Observable<any> {
    return this.store.dispatch(new DeleteUserCommand(user));
  }

  downloadData(): void {
    this.store.dispatch(new DownloadUsersDataCommand());
  }
}
