export enum CustomerStepperStep {
  customerStep = 0,
  gridPointsStep = 1,
  devicesStep = 2,
  controlsStep = 3
}

export enum StepState {
  number = 'number',
  edit = 'edit',
  done = 'done',
  error = 'error'
}

export enum StepperFormType {
  edit = 'edit',
  config = 'config'
}
