<mat-card *ngIf="serviceAgreement">
  <mat-card-header>
    <mat-card-title>Availability schedule</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    Customer:
    <span class="status-primary"
      ><strong>{{ serviceAgreement.customerName }}</strong></span
    >
    <br />
    Service agreement:
    <strong
      ><span class="status-primary"
        >{{ serviceAgreement.reference }}
        {{ serviceAgreement.period.startDate | date: 'shortDate' }}
        &mdash;
        {{ serviceAgreement.period.endDate | date: 'shortDate' }}</span
      >
      &nbsp; |&nbsp;
      <mat-icon matTooltip="Up" class="status-up ic-normal" svgIcon="ic-old:arrow-up"></mat-icon>
      <span class="status-up"> {{ serviceAgreement.maxCapacityUpward | capacity: 'kW' }} </span>
      &nbsp; |&nbsp;
      <mat-icon matTooltip="Down" class="status-down ic-normal" svgIcon="ic-old:arrow-down"></mat-icon>
      <span class="status-down"> {{ serviceAgreement.maxCapacityDownward | capacity: 'kW' }} </span>
    </strong>
    <br /><br />
    <table mat-table [dataSource]="availabilityDataSource" class="has-actions" matSort>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Period</th>
        <td mat-cell *matCellDef="let availability">
          {{ availability.period.startDate | date: 'shortDate' }} -
          {{ availability.period.endDate | date: 'shortDate' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
        <td mat-cell *matCellDef="let availability">
          {{ availability.timePeriod.startTime | time: 'shortTime' }} -
          {{ availability.timePeriod.endTime | time: 'shortTime' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="direction">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Direction</th>
        <td mat-cell *matCellDef="let availability" [ngSwitch]="availability.direction">
          <ng-container *ngSwitchCase="'UPWARDS'"
            ><mat-icon matTooltip="Upwards" class="status-up" svgIcon="ic-old:arrow-up"></mat-icon
          ></ng-container>
          <ng-container *ngSwitchCase="'DOWNWARDS'"
            ><mat-icon matTooltip="Downwards" class="status-down" svgIcon="ic-old:arrow-down"></mat-icon
          ></ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let availability">{{ availability.availabilityType }}</td>
      </ng-container>
      <ng-container matColumnDef="power">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Power</th>
        <td mat-cell *matCellDef="let availability">{{ availability.power | capacity }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let availability; let i = index">
          <ph-flex-vertical-center>
            <button mat-icon-button matTooltip="Edit" (click)="editAvailability(availability)">
              <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
            </button>
            <button mat-icon-button matTooltip="Delete" class="remove" (click)="deleteAvailability(availability)">
              <mat-icon svgIcon="ic-old:trash-o"></mat-icon>
            </button>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="primary" (click)="addAvailability()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:add"></mat-icon>
        <span>Add new availability</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="cta" (click)="onSubmit()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:save"></mat-icon>
        <span>Save</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
