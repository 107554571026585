import { Location } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { Capacity } from 'flex-app-shared';
import { get } from 'lodash-es';
import moment, { Moment } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AggregatedUnavailabilitiesFacade } from '../../store/unavailabilites/states/aggregated-unavailabilities/aggregated-unavailabilities.facade';
import { UnavailabilitiesFacade } from '../../store/unavailabilites/states/unavailabilities/unavailabilities.facade';
import { UnavailabilityView } from '../shared/unavailability/unavailability';
import { UnavailabilitiesSendDialogComponent } from '../unavailabilities-send-dialog/unavailabilities-send-dialog.component';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY/MM'
  },
  display: {
    dateInput: 'YYYY/MM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};

@Component({
  selector: 'app-unavailabilities',
  templateUrl: './unavailabilities.component.html',
  styleUrls: ['./unavailabilities.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class UnavailabilitiesComponent implements OnInit, OnDestroy, AfterViewInit {
  displayedColumns = ['customerName', 'startDateTime', 'endDateTime', 'serviceAgreement', 'direction', 'power', 'sent', 'actions'];
  readonly dataSource: MatTableDataSource<UnavailabilityView> = new MatTableDataSource<UnavailabilityView>([]);
  unavailabilities: ReadonlyArray<UnavailabilityView> = [];
  futureItems = new UntypedFormControl(true);
  filterMonth = new UntypedFormControl();
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  private readonly ngUnsubscribe = new Subject<void>();

  @Input()
  public readonly defaultPageSize = 30;

  @Input()
  public readonly isStandalone = false;

  @Input()
  public readonly isDashboard = false;

  constructor(
    private router: Router,
    private unavailabilitiesFacade: UnavailabilitiesFacade,
    private aggregatedAvailabilitiesFacade: AggregatedUnavailabilitiesFacade,
    private dialog: MatDialog,
    private location: Location
  ) {}

  goBack(): void {
    this.location.back();
  }

  ngOnInit(): void {
    this.unavailabilitiesFacade.init();
    this.unavailabilitiesFacade.unavailabilities$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((unavailabilities) => {
      this.unavailabilities = unavailabilities;
      this.filterData();
    });

    if (this.isStandalone) {
      this.displayedColumns = this.displayedColumns.filter((it) => it !== 'customerName');
    }

    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'startDateTime':
          return data.period.startDateTime.date;
        case 'endDateTime':
          return data.period.toDateTime.date;
        case 'serviceAgreement':
          return `${data.serviceAgreementReference} - ${data.serviceAgreementReference}`;
        case 'power':
          return Capacity.asMW(data.power);
        default:
          return get(data, sortHeaderId);
      }
    };
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.unavailabilitiesFacade.init();
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  delete(unavailibilty: UnavailabilityView): void {
    this.unavailabilitiesFacade.delete(unavailibilty);
  }

  monthDatepickerEvent(type: string, $event: MatDatepickerInputEvent<Moment>): void {
    if (!$event.value) {
      this.filterData();
    }
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>): void {
    this.filterMonth.setValue(normalizedMonth);
    datepicker.close();
    this.filterData();
  }

  filterData(): void {
    let filteredData = [...(this.unavailabilities ?? [])];
    if (this.futureItems.value) {
      const now = moment();
      filteredData = filteredData.filter((it) => it.period.toDateTime.date >= now);
    }
    if (this.filterMonth.value) {
      const month = this.filterMonth.value;
      filteredData = filteredData.filter((it) => {
        const startDate = it.period.startDateTime.date;
        return startDate.year() === month.year() && startDate.month() === month.month();
      });
    }
    this.dataSource.data = filteredData;
  }

  openSendAvailabilitiesModal(): void {
    this.dialog.open(UnavailabilitiesSendDialogComponent);
  }

  clearSentAvailabilities(): void {
    this.aggregatedAvailabilitiesFacade.clear();
  }
}
