import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  BalanceResponsibleParty,
  BalanceResponsiblePartyService
} from '../shared/balance-responsible-party/balance-responsible-party.service';
import { Customer, GridPointService } from 'flex-app-shared';
import { GridPointFacade } from '../../store/grid-points/grid-point.facade';

@Component({
  selector: 'app-grid-point-detail',
  templateUrl: './grid-point-detail.component.html',
  styleUrls: ['./grid-point-detail.component.scss']
})
export class GridPointDetailComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private builder: UntypedFormBuilder,
    private balanceResponsiblePartyService: BalanceResponsiblePartyService,
    private gridPointService: GridPointService,
    private gridPointFacade: GridPointFacade
  ) {}

  get customerId(): AbstractControl | null {
    return this.gridPointForm.get('customerId');
  }

  get description(): AbstractControl | null {
    return this.gridPointForm.get('description');
  }

  @Input()
  isStandalone = false;

  parties: ReadonlyArray<BalanceResponsibleParty>;
  defaultParty: BalanceResponsibleParty;
  filteredCustomers: ReadonlyArray<Customer>;
  message: string = '';

  customerInCustomerList: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    if (
      this.filteredCustomers &&
      control.value &&
      !this.filteredCustomers.some((filteredCustomer) => filteredCustomer.id === control.value)
    ) {
      return { invalidCustomer: true };
    }
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  gridPointForm = this.builder.group({
    id: [''],
    ean: ['', [Validators.required, Validators.minLength(18), Validators.maxLength(18)]],
    customerId: ['', [Validators.required, this.customerInCustomerList]],
    balanceResponsiblePartyId: ['', [Validators.required]],
    description: ['', [Validators.required, Validators.maxLength(100)]],
    preQualified: [false],
    gopacsPreQualified: [false]
  });

  ngOnInit(): void {
    if (!this.isStandalone) {
      this.gridPointFacade.init();
    }

    if (!this.isStandalone) {
      this.gridPointFacade.customers$.subscribe((customers) => (this.filteredCustomers = customers));
    }

    this.balanceResponsiblePartyService.getAll().subscribe((parties) => {
      this.parties = parties;
      this.defaultParty = this.parties.find((it) => it.defaultBrp);
      this.getGridPoint();
    });
  }

  getGridPoint(): void {
    // TODO: handle gridPoint setup via state (not here, via new GridPoint and initForm).
    const id = this.getId();
    if (this.isNew()) {
      this.gridPointForm.patchValue({
        balanceResponsiblePartyId: this.defaultParty.id
      });
    } else {
      this.gridPointService.getById(id).subscribe((gridPoint) => this.gridPointForm.patchValue(gridPoint));
    }
  }

  getId(): string {
    return this.isStandalone ? this.gridPointForm.value.id : this.route.snapshot.paramMap.get('gridPointId');
  }

  isNew(): boolean {
    const id = this.getId();
    return id === 'new' || id === null;
  }

  onSubmit(): void {
    this.gridPointFacade.save();
  }

  retrieveContracts(): void {
    this.message = 'Contracts are being retrieved.';
    this.gridPointFacade.retrieveContracts();
    this.message = 'Contracts have been retrieved.';
  }

  goBack(): void {
    this.location.back();
  }
}
