import { Action, Selector, State, StateContext } from '@ngxs/store';
import { CustomerStepperStep, StepperFormType, StepState } from '../../../app/customer-stepper/customer-stepper-enums';
import { ControlLoadedEvent, DeleteControlCommand, ResetControlCommand } from 'flex-app-shared';
import { ControlsLoadedEvent, LoadControlsCommand } from 'flex-app-shared';
import { CustomerLoadedEvent } from '../../customers/states/customer/customer.actions';
import { DeviceContactsLoadedEvent } from '../../devices/states/device-contacts/device-contacts.actions';
import { DeleteDeviceCommand, DeviceLoadedEvent, ResetDeviceCommand } from '../../devices/states/device/device.actions';
import {
  DevicesLoadedEvent,
  HideDeviceDetailsCommand,
  LoadDevicesCommand,
  ShowDeviceDetailsCommand
} from '../../devices/states/devices/devices.actions';
import { DeleteGridPointCommand, GridPointLoadedEvent, ResetGridPointCommand } from 'flex-app-shared';
import { GridPointsLoadedEvent, LoadGridPointsCommand } from 'flex-app-shared';
import {
  AddControlCommand,
  AddDeviceCommand,
  AddGridPointCommand,
  CustomerStepperHideFormCommand,
  DestroyStepperCommand,
  InitializeStepperCommand,
  SaveAndCompleteCommand,
  SetCustomerStepperCurrentStepCommand,
  SetCustomerStepperStepStateCommand,
  SetFormTypeCommand
} from './customer-stepper.actions';
import { GridPoint } from 'flex-app-shared';
import { Device } from '../../../app/shared/device/device.service';
import { Control } from 'flex-app-shared';
import { ResetCustomersCommand } from '../../customers/states/customers/customers.actions';
import { Router } from '@angular/router';
import { LandingPageService } from 'flex-app-shared';
import { Injectable } from '@angular/core';

export class CustomerStepperStateModel {
  currentStepNo = 0;
  stepStates = new Map();
  showForm = false;
  formType: StepperFormType = StepperFormType.edit;
  selectedCustomerId: string | null = null;

  constructor(public initialized: boolean = true) {}
}

@State<CustomerStepperStateModel>({
  name: 'customerStepperState'
})
@Injectable({
  providedIn: 'root'
})
export class CustomerStepperState {
  @Selector()
  public static getCurrentStepNo(state: CustomerStepperStateModel): number {
    return state.currentStepNo;
  }

  @Selector()
  public static showForm(state: CustomerStepperStateModel): boolean {
    return state.showForm;
  }

  @Selector()
  public static formType(state: CustomerStepperStateModel): StepperFormType {
    return state.formType;
  }

  @Selector()
  public static getStepStates(state: CustomerStepperStateModel): Map<any, any> {
    return state.stepStates;
  }

  @Selector()
  public static getState(state: CustomerStepperStateModel): CustomerStepperStateModel {
    return state;
  }

  private static stepStateChanged(currentState: string, newState: string): boolean {
    return currentState !== newState;
  }

  constructor(private landingPageService: LandingPageService, private router: Router) {}

  @Action(InitializeStepperCommand)
  initialize({ setState }: StateContext<CustomerStepperStateModel>): void {
    setState(new CustomerStepperStateModel(true));
  }

  @Action(DestroyStepperCommand)
  destroy({ setState }: StateContext<CustomerStepperStateModel>): void {
    setState(new CustomerStepperStateModel(false));
  }

  @Action(CustomerLoadedEvent)
  handleSetCustomer({ getState, setState, dispatch }: StateContext<CustomerStepperStateModel>, { payload }: CustomerLoadedEvent): void {
    if (getState().initialized) {
      const state = getState();
      setState({
        ...state,
        selectedCustomerId: payload.id,
        showForm: false
      });
      this.router.navigate([`/customer-stepper/${payload.id}`]);

      dispatch([new SetCustomerStepperCurrentStepCommand(CustomerStepperStep.gridPointsStep)]);
    }
  }

  @Action(GridPointLoadedEvent)
  handleSetGridPoint({ getState, setState, dispatch }: StateContext<CustomerStepperStateModel>, action: GridPointLoadedEvent): void {
    if (getState().initialized) {
      const state = getState();
      setState({
        ...state,
        showForm: action.showForm
      });
      this.router.navigate([`${this.router.routerState.snapshot.url}`]);
      dispatch([new SetCustomerStepperStepStateCommand(CustomerStepperStep.gridPointsStep, StepState.done), new LoadGridPointsCommand()]);
    }
  }

  @Action(DeviceLoadedEvent)
  handleSetDevice({ getState, setState, dispatch }: StateContext<CustomerStepperStateModel>, action: DeviceLoadedEvent): void {
    if (getState().initialized) {
      const state = getState();
      setState({
        ...state,
        showForm: action.showForm
      });
      dispatch([new SetCustomerStepperStepStateCommand(CustomerStepperStep.devicesStep, StepState.done), new LoadDevicesCommand()]);

      if (action.showDetails) {
        dispatch([new CustomerStepperHideFormCommand(), new ShowDeviceDetailsCommand(action.payload.id)]);
      } else {
        dispatch(new HideDeviceDetailsCommand());
      }
    }
  }

  @Action(ControlLoadedEvent)
  handleSetControl({ getState, setState, dispatch }: StateContext<CustomerStepperStateModel>, action: ControlLoadedEvent): void {
    if (getState().initialized) {
      const state = getState();
      setState({
        ...state,
        showForm: action.showForm
      });
      dispatch([new SetCustomerStepperStepStateCommand(CustomerStepperStep.controlsStep, StepState.done), new LoadControlsCommand()]);
    }
  }

  @Action(SetCustomerStepperStepStateCommand)
  handleSetCustomerStepperStepState(
    { setState, getState }: StateContext<CustomerStepperStateModel>,
    { step, stepState }: SetCustomerStepperStepStateCommand
  ): void {
    if (getState().initialized) {
      const state = getState();
      if (!state.stepStates) return;

      state.stepStates.set(step, stepState);
      setState({ ...state });
    }
  }

  @Action(SetCustomerStepperCurrentStepCommand)
  handleSetCustomerStepperIndex(
    { setState, getState, dispatch }: StateContext<CustomerStepperStateModel>,
    action: SetCustomerStepperCurrentStepCommand
  ): void {
    if (getState().initialized) {
      const state = getState();
      setState({
        ...state,
        currentStepNo: action.currentStepNo,
        showForm: action.showForm
      });
    }
  }

  @Action(GridPointsLoadedEvent)
  handleSetGridPoints({ dispatch, getState }: StateContext<CustomerStepperStateModel>, { payload }: GridPointsLoadedEvent): void {
    if (getState().initialized && getState().selectedCustomerId) {
      const newState = payload.length > 0 ? StepState.done : StepState.edit;
      const currentState = getState().stepStates.get(CustomerStepperStep.gridPointsStep);
      if (CustomerStepperState.stepStateChanged(currentState, newState)) {
        dispatch(new SetCustomerStepperStepStateCommand(CustomerStepperStep.gridPointsStep, newState));
      }
    }
  }

  @Action(DevicesLoadedEvent)
  handleSetSevices({ dispatch, getState }: StateContext<CustomerStepperStateModel>, { payload }: DevicesLoadedEvent): void {
    if (getState().initialized && getState().selectedCustomerId) {
      const newState = payload.length > 0 ? StepState.done : StepState.edit;
      const currentState = getState().stepStates.get(CustomerStepperStep.devicesStep);
      if (CustomerStepperState.stepStateChanged(currentState, newState)) {
        dispatch(new SetCustomerStepperStepStateCommand(CustomerStepperStep.devicesStep, newState));
      }
    }
  }

  @Action(DeviceContactsLoadedEvent)
  loadDeviceContacts({ dispatch, getState }: StateContext<CustomerStepperStateModel>): void {
    if (getState().initialized) {
      dispatch(new CustomerStepperHideFormCommand());
    }
  }

  @Action(ControlsLoadedEvent)
  handleSetControls({ dispatch, getState }: StateContext<CustomerStepperStateModel>, { payload }: ControlsLoadedEvent): void {
    if (getState().initialized && getState().selectedCustomerId) {
      const newState = payload.length > 0 ? StepState.done : StepState.edit;
      const currentState = getState().stepStates.get(CustomerStepperStep.controlsStep);
      if (CustomerStepperState.stepStateChanged(currentState, newState)) {
        dispatch(new SetCustomerStepperStepStateCommand(CustomerStepperStep.controlsStep, newState));
      }
    }
  }

  @Action(CustomerStepperHideFormCommand)
  hideForm({ setState, getState }: StateContext<CustomerStepperStateModel>): void {
    if (getState().initialized) {
      const state = getState();
      setState({
        ...state,
        showForm: false
      });
    }
  }

  @Action(SetFormTypeCommand)
  setFormType({ setState, getState }: StateContext<CustomerStepperStateModel>, { formType }: SetFormTypeCommand): void {
    if (getState().initialized) {
      const state = getState();
      setState({
        ...state,
        formType
      });
    }
  }

  @Action([DeleteGridPointCommand, DeleteDeviceCommand, DeleteControlCommand])
  deleteGridPoint({ dispatch, getState }: StateContext<CustomerStepperStateModel>): void {
    if (getState().initialized) {
      dispatch(new CustomerStepperHideFormCommand());
    }
  }

  @Action(AddGridPointCommand)
  addGridPoint({ getState, dispatch }: StateContext<CustomerStepperStateModel>): void {
    if (getState().initialized) {
      const selectedCustomerId = getState().selectedCustomerId;
      if (selectedCustomerId) {
        const gridPoint = { customerId: selectedCustomerId } as GridPoint;
        dispatch([
          new ResetGridPointCommand(undefined, true),
          new GridPointLoadedEvent(gridPoint),
          new SetCustomerStepperStepStateCommand(CustomerStepperStep.gridPointsStep, StepState.edit),
          new SetCustomerStepperCurrentStepCommand(CustomerStepperStep.gridPointsStep, true)
        ]);
      }
    }
  }

  @Action(AddDeviceCommand)
  addDevice({ getState, dispatch }: StateContext<CustomerStepperStateModel>): void {
    if (getState().initialized) {
      const selectedCustomerId = getState().selectedCustomerId;
      if (selectedCustomerId) {
        const device = { customerId: selectedCustomerId } as Device;
        dispatch([
          new ResetDeviceCommand(),
          new DeviceLoadedEvent(device),
          new SetCustomerStepperStepStateCommand(CustomerStepperStep.devicesStep, StepState.edit),
          new SetCustomerStepperCurrentStepCommand(CustomerStepperStep.devicesStep, true)
        ]);
      }
    }
  }

  @Action(AddControlCommand)
  addControl({ getState, dispatch }: StateContext<CustomerStepperStateModel>): void {
    if (getState().initialized) {
      const selectedCustomerId = getState().selectedCustomerId;
      if (selectedCustomerId) {
        const control = { customerId: selectedCustomerId } as Control;
        dispatch([
          new ResetControlCommand(),
          new ControlLoadedEvent(control),
          new SetCustomerStepperStepStateCommand(CustomerStepperStep.controlsStep, StepState.edit),
          new SetCustomerStepperCurrentStepCommand(CustomerStepperStep.controlsStep, true)
        ]);
      }
    }
  }

  @Action(SaveAndCompleteCommand)
  saveAndComplete({ dispatch, getState }: StateContext<CustomerStepperStateModel>): void {
    if (getState().initialized) {
      dispatch(new ResetCustomersCommand());
      this.landingPageService.navigateToLandingPage();
    }
  }
}
