import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, first, switchMap } from 'rxjs/operators';

import { Control, ControlsState, DeleteControlCommand, LoadControlCommand, LoadControlsCommand } from 'flex-app-shared';
import { StepperFormType } from '../../app/customer-stepper/customer-stepper-enums';
import { SetFormTypeCommand } from '../customer-stepper/states/customer-stepper.actions';

@Injectable({
  providedIn: 'root'
})
export class ControlsFacade {
  controls$ = this.store.select(ControlsState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(ControlsState.getControls))
  );

  constructor(private store: Store) {}

  init(): void {
    this.loadControls();
  }

  loadControl(controlId: string, showForm: boolean = false): void {
    this.store.dispatch(new SetFormTypeCommand(StepperFormType.edit));
    this.store.dispatch(new LoadControlCommand(controlId, showForm));
  }

  configureControl(controlId: string, showForm: boolean = false): void {
    this.store.dispatch(new SetFormTypeCommand(StepperFormType.config));
    this.store.dispatch(new LoadControlCommand(controlId, showForm));
  }

  loadControls(): void {
    this.store.dispatch(new LoadControlsCommand());
  }

  delete(control: Control): void {
    this.store.dispatch(new DeleteControlCommand(control));
  }
}
