<mat-card>
  <mat-card-content>
    <ph-flex-loading-context [isLoading]="dataSource?.isLoading$ | async">
      <mat-toolbar class="filter-bar">
        <mat-form-field class="period">
          <mat-label>Period</mat-label>
          <mat-select value="PAST_24_HOURS" #searchPeriod>
            <mat-option value="PAST_5_MINUTES">Past 5 minutes</mat-option>
            <mat-option value="PAST_15_MINUTES">Past 15 minutes</mat-option>
            <mat-option value="PAST_30_MINUTES">Past 30 minutes</mat-option>
            <mat-option value="PAST_HOUR">Past hour</mat-option>
            <mat-option value="PAST_4_HOURS">Past 4 hours</mat-option>
            <mat-option value="PAST_12_HOURS">Past 12 hours</mat-option>
            <mat-option value="PAST_24_HOURS">Past 24 hours</mat-option>
            <mat-option value="PAST_48_HOURS">Past 48 hours</mat-option>
            <mat-option value="PAST_3_DAYS">Past 3 days</mat-option>
            <mat-option value="PAST_7_DAYS">Past 7 days</mat-option>
            <mat-option value="PAST_30_DAYS">Past 30 days</mat-option>
            <mat-option value="CUSTOM">Custom</mat-option>
          </mat-select>
        </mat-form-field>
        <ng-container *ngIf="isCustomPeriod()">
          <mat-form-field>
            <mat-label>Start date/time</mat-label>
            <input matInput type="datetime-local" placeholder="start date time" [formControl]="startDateTimeControl" />
          </mat-form-field>
          <mat-form-field>
            <mat-label>To date/time</mat-label>
            <input matInput type="datetime-local" placeholder="to date time" [formControl]="toDateTimeControl" />
          </mat-form-field>
        </ng-container>
        <ph-flex-table-filter placeholder="Search" class="col-2"></ph-flex-table-filter>
        <mat-form-field class="type">
          <mat-label>Type</mat-label>
          <mat-select value="ALL" #messageType>
            <mat-option value="ALL">All types</mat-option>
            <mat-option value="INFO">Information</mat-option>
            <mat-option value="WARN">Warning</mat-option>
            <mat-option value="ERROR">Error</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-4">
          <mat-label>Filter category</mat-label>
          <mat-select [value]="[]" multiple #categorySelect>
            <mat-option *ngFor="let category of categories" [value]="category">{{ category }}</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="minimize">
          <button mat-flat-button color="primary" (click)="doSearch()" [disabled]="isSearchDisabled$ | async">
            <ph-flex-vertical-center><mat-icon svgIcon="ic-old:search"></mat-icon> <span>Search</span></ph-flex-vertical-center>
          </button>
        </div>
      </mat-toolbar>

      <div class="overflow-table">
        <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
          <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Timestamp</th>
            <td mat-cell *matCellDef="let message">{{ message.timestamp | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
          </ng-container>
          <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Category</th>
            <td mat-cell *matCellDef="let message">{{ message.category }}</td>
          </ng-container>
          <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
            <td mat-cell *matCellDef="let message" [ngSwitch]="message.type">
              <ph-flex-vertical-center>
                <ng-container *ngSwitchCase="'WARN'">
                  <mat-icon class="status-warn" matTooltip="Warning" svgIcon="ic-old:alert-warning"></mat-icon>
                </ng-container>
                <ng-container *ngSwitchCase="'INFO'">
                  <mat-icon class="status-info" matTooltip="Information" svgIcon="ic-old:info_outline"></mat-icon>
                </ng-container>
                <ng-container *ngSwitchCase="'ERROR'">
                  <mat-icon class="status-alert" matTooltip="Error" svgIcon="ic-old:alert-error"></mat-icon>
                </ng-container>
              </ph-flex-vertical-center>
            </td>
          </ng-container>
          <ng-container matColumnDef="userId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>UserId</th>
            <td mat-cell *matCellDef="let message">{{ message.userId }}</td>
          </ng-container>
          <ng-container matColumnDef="context">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Context</th>
            <td mat-cell *matCellDef="let message" [title]="message.context">{{ message.context }}</td>
          </ng-container>
          <ng-container matColumnDef="text">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Text</th>
            <td mat-cell *matCellDef="let message">{{ message.text | truncate }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openDialog(row)"></tr>
        </table>
      </div>
    </ph-flex-loading-context>
    <mat-paginator
      [showFirstLastButtons]="true"
      [pageSize]="30"
      [pageSizeOptions]="[10, 20, 30, 50, 100]"
      [disabled]="isSearchDisabled$ | async"
    ></mat-paginator>
  </mat-card-content>
</mat-card>
