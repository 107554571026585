import { State } from '@ngxs/store';
import { CustomerState } from './states/customer/customer.state';
import { CustomersState } from './states/customers/customers.state';
import { Injectable } from '@angular/core';

export const CustomerStates = [CustomerState, CustomersState];

@State({
  name: 'customersStateModule',
  children: CustomerStates
})
@Injectable({
  providedIn: 'root'
})
export class CustomerStateModule {}
