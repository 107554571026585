import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

/**
 * Functions exactly like datePipe, but accepts 'H/HH:M/MM' format values, i.e. 10:00
 * Will create an internal new Date(0,0,0) for formatting purposes
 */
@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(value: any, format: string = 'mediumTime', timezone?: string, locale?: string): string | null {
    if (value == null || value === '' || value !== value) {
      return null;
    }
    let newValue = value;

    // Accept h:m values
    if (/^(\d{1,2}:\d{1,2})$/.test(value)) {
      const [h, m] = value.split(':').map((val: string) => +val);
      newValue = new Date(0, 0, 0, h, m);
    }

    try {
      return formatDate(newValue, format, locale || this.locale, timezone);
    } catch (error) {
      throw new Error(`InvalidPipeArgument: '${newValue}' for pipe 'TimePipe'`);
    }
  }
}
