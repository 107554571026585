import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';
import {
  MeasurementDataUploadConfirmCommand,
  MeasurementDataUploadDownloadTemplateCommand,
  MeasurementDataUploadTemplateCommand
} from './measurement-data-upload.actions';
import { MeasurementDataUploadState } from './measurement-data-upload.state';

@Injectable({
  providedIn: 'root'
})
export class MeasurementDataUploadFacade {
  constructor(private store: Store) {}

  isDownloadBusy$ = this.store.select(MeasurementDataUploadState.isDownloadBusy);
  isUploadBusy$ = this.store.select(MeasurementDataUploadState.isUploadBusy);
  isConfirmBusy$ = this.store.select(MeasurementDataUploadState.isConfirmBusy);
  previewData$ = this.store.select(MeasurementDataUploadState.preview);
  previewDataSummary$ = this.store.select(MeasurementDataUploadState.uploadedDataSummary);

  hasUploaded$ = this.store.select(MeasurementDataUploadState.hasUploadedData);
  uploadFailed$ = this.store.select(MeasurementDataUploadState.uploadFailed).pipe(map((isFailed) => (isFailed ? 'Upload failed' : false)));
  confirmFailed$ = this.store
    .select(MeasurementDataUploadState.confirmFailed)
    .pipe(map((isFailed) => (isFailed ? 'Confirm failed' : false)));
  showNotification$ = this.store.select(MeasurementDataUploadState.showNotification);

  downloadTemplate(): void {
    this.store.dispatch(new MeasurementDataUploadDownloadTemplateCommand());
  }

  upload(file: any): void {
    this.store.dispatch(new MeasurementDataUploadTemplateCommand(file));
  }

  confirm(afterConfirmFn: () => void): void {
    this.store.dispatch(new MeasurementDataUploadConfirmCommand(afterConfirmFn));
  }
}
