<ng-container *ngIf="!isStandalone">
  <mat-card>
    <mat-card-content>
      <ng-container [ngTemplateOutlet]="pageContents"></ng-container>
    </mat-card-content>
    <mat-card-actions>
      <button *ngIf="!isStandalone" mat-stroked-button (click)="goBack()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
          <span>Go back</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="primary" routerLink="/customers/new">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:user-add"></mat-icon>
          <span>New customer</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>

<ng-container *ngIf="isStandalone" [ngTemplateOutlet]="pageContents"></ng-container>

<ng-template #pageContents>
  <mat-toolbar class="filter-bar">
    <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
  </mat-toolbar>

  <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
      <td mat-cell *matCellDef="let customer">
        <a routerLink="/customers/{{ customer.id }}">{{ customer.name }}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="legalName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Legal name</th>
      <td mat-cell *matCellDef="let customer">{{ customer.legalName }}</td>
    </ng-container>
    <ng-container matColumnDef="billingId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Billing id</th>
      <td mat-cell *matCellDef="let customer">{{ customer.billingId }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let customer">
        <ph-flex-vertical-center>
          <button mat-icon-button matTooltip="Edit" routerLink="/customers/{{ customer.id }}">
            <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
          </button>
          <button mat-icon-button matTooltip="Delete" class="remove" (click)="delete(customer)">
            <mat-icon svgIcon="ic-old:trash-o"></mat-icon>
          </button>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [showFirstLastButtons]="true" [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
</ng-template>
