<h2 mat-dialog-title>Change application setting</h2>
<mat-dialog-content class="edit-setting">
  <div class="edit-setting__name">{{ data.name }}</div>

  <form [formGroup]="settingForm" class="single-column-form">
    <mat-form-field *ngIf="!isBooleanSetting()" class="edit-setting__input-container">
      <mat-label>Value</mat-label>
      <ph-flex-number-input formControlName="value" *ngIf="isNumericValue()"></ph-flex-number-input>
      <input matInput formControlName="value" *ngIf="!isNumericValue()" />
      <mat-error *ngIf="settingForm.get('value').errors?.required"> Value is required. </mat-error>
    </mat-form-field>

    <mat-slide-toggle *ngIf="isBooleanSetting()" formControlName="value"> Value </mat-slide-toggle>

    <mat-form-field *ngIf="isSettingWithUnit(data)" class="edit-setting__input-container">
      <mat-label>Unit</mat-label>
      <mat-select formControlName="unit">
        <mat-option *ngFor="let option of getUnitsListForSetting(data)" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>
    <ph-flex-vertical-center><mat-icon svgIcon="ic-old:close"></mat-icon><span>Cancel</span></ph-flex-vertical-center>
  </button>
  <button mat-flat-button color="cta" [disabled]="settingForm.invalid" (click)="save()">
    <ph-flex-vertical-center><mat-icon svgIcon="ic-old:save"></mat-icon><span>Save</span></ph-flex-vertical-center>
  </button>
</mat-dialog-actions>
