import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { get } from 'lodash-es';
import { Capacity, Control, ControlService, ControlView } from 'flex-app-shared';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { ControlsFacade } from '../../store/controls/controls.facade';
import { Location } from '@angular/common';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent implements OnInit, AfterViewInit, OnDestroy {
  displayedColumns: ReadonlyArray<string> = [
    'description',
    'customer',
    'direction',
    'power',
    'enabled',
    'upwardsR3Participation',
    'downwardsR3Participation',
    'actions'
  ];
  readonly dataSource: MatTableDataSource<Control> = new MatTableDataSource<Control>([]);
  @Input()
  public readonly defaultPageSize: number = 30;
  @Input()
  public readonly isStandalone: boolean = false;
  @Input()
  public readonly isDashboard: boolean = false;
  private readonly onDestroy$ = new Subject<void>();
  @ViewChild(MatPaginator, { static: false })
  private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) private readonly sort: MatSort;

  constructor(
    private router: Router,
    private controlService: ControlService,
    private controlsFacade: ControlsFacade,
    private location: Location
  ) {}

  goBack(): void {
    this.location.back();
  }

  ngOnInit(): void {
    this.controlsFacade.init();
    this.controlsFacade.controls$.pipe(takeUntil(this.onDestroy$)).subscribe((controls) => {
      this.dataSource.data = controls.concat();
    });
    this.dataSource.filterPredicate = (data: ControlView, filterValue: string) => {
      const { direction, customerName, description, power } = data;
      return `${direction} ${customerName} ${description} ${Capacity.asKW(power)} kW`
        .toLowerCase()
        .includes(filterValue.trim().toLowerCase());
    };
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'customer':
          return data.customerName;
        case 'power':
          return Capacity.asKW(data.power);
        default:
          return get(data, sortHeaderId);
      }
    };

    if (this.isStandalone) {
      this.displayedColumns = this.displayedColumns.filter((it) => it !== 'customer');
    }

    this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.controlsFacade.init();
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  delete(control: Control): void {
    this.controlsFacade.delete(control);
  }

  toggleState(control: Control): void {
    if (control.enabled) {
      this.controlService.disable(control.id).subscribe(() => this.controlsFacade.loadControls());
    } else {
      this.controlService.enable(control.id).subscribe(() => this.controlsFacade.loadControls());
    }
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  edit(controlId: string): void {
    this.controlsFacade.loadControl(controlId, true);
  }

  configure(controlId: string): void {
    this.controlsFacade.configureControl(controlId, true);
  }
}
