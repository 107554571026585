import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, SelectorOptions, State, StateContext, Store } from '@ngxs/store';
import { isDate, startOfMonth } from 'date-fns';
import { NgXsFormModel } from 'flex-app-shared';
import { switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  IncidentReserveInvoiceResults,
  IncidentReserveInvoiceResultsService
} from '../../app/shared/incident-reserve-invoice-results/incident-reserve-invoice-results.service';
import {
  IncidentReserveInvoiceResultsUpdatedEvent,
  PublishIncidentReserveInvoiceResultsCommand,
  RecalculateIncidentReserveInvoiceResultsCommand,
  UpdateIncidentReserveInvoiceResultsCommand
} from './incident-reserve-invoice-results.actions';

class IncidentReserveInvoiceResultsStateModel {
  filter = NgXsFormModel.defaults({
    selectedDate: startOfMonth(new Date()),
    sendAll: false
  });
  busy = false;
  busyPublishing = false;
  busyRecalculating = false;
  data: IncidentReserveInvoiceResults[] = [];
}

@State({
  name: 'incidentReserveInvoiceResults',
  defaults: new IncidentReserveInvoiceResultsStateModel()
})
@Injectable({
  providedIn: 'root'
})
@SelectorOptions({
  suppressErrors: false
})
export class IncidentReserveInvoiceResultsState {
  static baseRoute = '/incident-reserve-invoice-results';

  constructor(
    private store: Store,
    private router: Router,
    private service: IncidentReserveInvoiceResultsService
  ) {}

  @Selector([IncidentReserveInvoiceResultsState])
  static selectedDate(state: IncidentReserveInvoiceResultsStateModel): Date {
    const selectedDate = state.filter.model.selectedDate;

    if (!isDate(selectedDate)) {
      // The mat datepicker returns a moment, since DateFnsAdapter is not available yet, and native data adapter doesn't do the custom month formatting.
      // @ts-ignore
      return selectedDate.toDate();
    }

    return selectedDate;
  }

  @Selector([IncidentReserveInvoiceResultsState])
  static sendAll(state: IncidentReserveInvoiceResultsStateModel): boolean {
    return state.filter.model.sendAll;
  }

  @Selector([IncidentReserveInvoiceResultsState])
  static busy(state: IncidentReserveInvoiceResultsStateModel): boolean {
    return state.busy;
  }

  @Selector([IncidentReserveInvoiceResultsState])
  static busyPublishing(state: IncidentReserveInvoiceResultsStateModel): boolean {
    return state.busyPublishing;
  }

  @Selector([IncidentReserveInvoiceResultsState])
  static busyRecalculating(state: IncidentReserveInvoiceResultsStateModel): boolean {
    return state.busyRecalculating;
  }

  @Selector([IncidentReserveInvoiceResultsState.busy, IncidentReserveInvoiceResultsState.busyRecalculating])
  static canRecalculate(isBusy: boolean, busyRecalculating: boolean): boolean {
    return !isBusy && !busyRecalculating;
  }

  @Selector([IncidentReserveInvoiceResultsState])
  static initialized(state: IncidentReserveInvoiceResultsStateModel): boolean {
    return !!state.data?.length;
  }

  @Selector([IncidentReserveInvoiceResultsState])
  static data(state: IncidentReserveInvoiceResultsStateModel): IncidentReserveInvoiceResults[] {
    return state.data;
  }

  @Action(RouterNavigation)
  handleRouterNavigation({ setState, dispatch }: StateContext<IncidentReserveInvoiceResultsStateModel>): void {
    const isInitialized = this.store.selectSnapshot(IncidentReserveInvoiceResultsState.initialized);
    if (!this.router.routerState.snapshot.url.startsWith(IncidentReserveInvoiceResultsState.baseRoute)) {
      if (isInitialized) {
        // Reset
        setState(new IncidentReserveInvoiceResultsStateModel());
      }
    } else {
      if (!isInitialized) {
        // Initialize
        dispatch(
          new UpdateIncidentReserveInvoiceResultsCommand(this.store.selectSnapshot(IncidentReserveInvoiceResultsState.selectedDate))
        );
      }
    }
  }

  @Action(UpdateFormValue)
  handleFormUpdate(
    { patchState, dispatch, getState, setState }: StateContext<IncidentReserveInvoiceResultsStateModel>,
    event: UpdateFormValue
  ): any {
    if (
      !event.payload.path.includes('incidentReserveInvoiceResults.filter') ||
      !this.router.routerState.snapshot.url.startsWith(IncidentReserveInvoiceResultsState.baseRoute)
    ) {
      return;
    }

    dispatch(new UpdateIncidentReserveInvoiceResultsCommand(this.store.selectSnapshot(IncidentReserveInvoiceResultsState.selectedDate)));
  }

  @Action(UpdateIncidentReserveInvoiceResultsCommand)
  updateInvoiceDataHandler(
    { dispatch, patchState }: StateContext<IncidentReserveInvoiceResultsStateModel>,
    action: UpdateIncidentReserveInvoiceResultsCommand
  ): any {
    patchState({
      busy: true
    });
    return this.service.getInvoiceResults(action.date).pipe(
      tap({
        error: () => patchState({ busy: false })
      }),
      switchMap((result) => dispatch(new IncidentReserveInvoiceResultsUpdatedEvent(result)))
    );
  }

  @Action(IncidentReserveInvoiceResultsUpdatedEvent)
  handleDataUpdated(
    { patchState }: StateContext<IncidentReserveInvoiceResultsStateModel>,
    { data }: IncidentReserveInvoiceResultsUpdatedEvent
  ): void {
    patchState({
      data,
      busy: false
    });
  }

  @Action(PublishIncidentReserveInvoiceResultsCommand)
  publish({ getState, patchState }: StateContext<IncidentReserveInvoiceResultsStateModel>): any {
    patchState({
      busyPublishing: true
    });

    const selectedDate = this.store.selectSnapshot(IncidentReserveInvoiceResultsState.selectedDate);
    const sendAll = this.store.selectSnapshot(IncidentReserveInvoiceResultsState.sendAll);
    return this.service.publish(selectedDate, sendAll).pipe(
      tap({
        next: () =>
          patchState({
            busyPublishing: false,
            filter: NgXsFormModel.patchModel(getState().filter, {
              sendAll: false
            })
          }),
        error: () => patchState({ busyPublishing: false })
      })
    );
  }

  @Action(RecalculateIncidentReserveInvoiceResultsCommand)
  recalculate({ patchState }: StateContext<IncidentReserveInvoiceResultsStateModel>): any {
    patchState({
      busyRecalculating: true
    });

    const selectedDate = this.store.selectSnapshot(IncidentReserveInvoiceResultsState.selectedDate);
    return this.service.recalculate(selectedDate).pipe(
      tap({
        next: () =>
          patchState({
            busyRecalculating: false
          }),
        error: () => patchState({ busyRecalculating: false })
      })
    );
  }
}
