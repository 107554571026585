<ng-container *ngIf="!isStandalone">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Control Configuration</mat-card-title>
      <mat-card-subtitle>
        {{ controlSubtitle }}
      </mat-card-subtitle>
    </mat-card-header>
    <ng-container [ngTemplateOutlet]="pageContents"></ng-container>
    <mat-card-actions>
      <button mat-stroked-button (click)="goBack()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
          <span>Go back</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="cta" (click)="onSubmit()" [disabled]="!form.valid">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:save"></mat-icon>
          <span>Save</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>

<ng-container *ngIf="isStandalone" [ngTemplateOutlet]="pageContents"></ng-container>

<ng-template #pageContents>
  <mat-card-content>
    <form [formGroup]="form" ngxsForm="controlStateModule.control" class="single-column-form">
      <table class="is-for-grid">
        <tr>
          <td class="icon-cell">
            <ph-flex-vertical-center>
              <mat-icon class="status-up" svgIcon="ic-old:arrow-up"></mat-icon>
            </ph-flex-vertical-center>
          </td>
          <td class="label-cell">Upwards incident reserve:</td>
          <td>
            <mat-checkbox formControlName="upwardsR3Participation"><span [innerHTML]="upwardsLabel"></span></mat-checkbox>
          </td>
        </tr>
        <tr>
          <td class="icon-cell">
            <ph-flex-vertical-center>
              <mat-icon class="status-down" svgIcon="ic-old:arrow-down"></mat-icon>
            </ph-flex-vertical-center>
          </td>
          <td class="label-cell">Downwards incident reserve:</td>
          <td>
            <mat-checkbox formControlName="downwardsR3Participation"><span [innerHTML]="downwardsLabel"></span></mat-checkbox>
          </td>
        </tr>
      </table>

      <mat-form-field>
        <mat-label>Switch cooldown time</mat-label>
        <ph-flex-number-input formControlName="switchCooldownTime"></ph-flex-number-input>
        <span matTextSuffix>Minutes</span>
        <mat-icon
          matSuffix
          class="form-tooltip"
          matTooltip='Number of minutes the control will "cooldown" and remain in the same switch-state after switching due to imbalance. It will never switch during this cooldown time, not even when an imbalance rule would state otherwise.'
          svgIcon="ic-new:help-circle"
        >
        </mat-icon>
        <mat-error *ngIf="form.get('switchCooldownTime').errors?.required"> Switch cooldown time is required </mat-error>
        <mat-error *ngIf="form.get('switchCooldownTime').errors?.min"> Switch cooldown time should be at least 0 minutes </mat-error>
        <mat-error *ngIf="form.get('switchCooldownTime').errors?.max"> Switch cooldown time should be at most 60 minutes </mat-error>
        <mat-error *ngIf="form.get('switchCooldownTime').errors?.invalidNumberOfDecimals">
          No decimals are allowed for Switch cooldown time
        </mat-error>
        <mat-error *ngIf="form.get('switchCooldownTime').errors?.invalidNumber">
          Only numeric characters allowed for Switch cooldown time
        </mat-error>
      </mat-form-field>
      <div class="flex-row label-with-data">
        <div class="col-1"></div>
        <div class="col-1"></div>
      </div>
    </form>
  </mat-card-content>
</ng-template>
