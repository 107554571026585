import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import {
  EmailResult,
  IncidentReserveInvoiceResultsService
} from '../../shared/incident-reserve-invoice-results/incident-reserve-invoice-results.service';

@Component({
  selector: 'app-incident-reserve-unprocessed-invoice-results',
  templateUrl: './incident-reserve-unprocessed-invoice-results.component.html',
  styleUrls: ['./incident-reserve-unprocessed-invoice-results.component.scss']
})
export class IncidentReserveUnprocessedInvoiceResultsComponent {
  sending: boolean = false;
  result: EmailResult;
  error: string;
  currentMonthPeriodControl = this.fb.control(new Date());

  constructor(
    private fb: UntypedFormBuilder,
    private incidentReserveInvoiceResultsService: IncidentReserveInvoiceResultsService
  ) {}

  sendEmails(): void {
    this.sending = true;
    this.result = null;
    this.incidentReserveInvoiceResultsService.sendUnprocessedInvoiceResultsEmail(this.currentMonthPeriodControl.value).subscribe(
      (result) => {
        this.result = result;
        this.sending = false;
      },
      (error) => {
        this.sending = false;
        this.error = error;
      }
    );
  }
}
