import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Announcement } from '../../shared/announcement/announcement.service';
import moment from 'moment';

@Component({
  selector: 'app-announcement-dialog',
  templateUrl: './announcement-dialog.component.html',
  styleUrls: ['./announcement-dialog.component.scss']
})
export class AnnouncementDialogComponent implements OnInit {
  remainingTime: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Announcement) {}

  ngOnInit(): void {
    const validFrom = moment(this.data.validFrom);

    if (moment().isAfter(validFrom)) {
      this.remainingTime = 'immediately';
    } else {
      this.remainingTime = validFrom.fromNow();
    }
  }
}
