import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BatteryFcrPeriod, BatteryService } from '../shared/battery/battery.service';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash-es';
import moment from 'moment';
import { CustomValidators, PeriodView } from 'flex-app-shared';

@Component({
  selector: 'app-battery-fcr-period-detail',
  templateUrl: './battery-fcr-period-detail.component.html',
  styleUrls: ['./battery-fcr-period-detail.component.scss']
})
export class BatteryFcrPeriodDetailComponent implements OnInit {
  fcrPeriodForm = this.builder.group({
    id: '',
    batteryId: '',
    gridPointId: ['', [Validators.required]],
    startTime: this.builder.group({
      date: ['', Validators.required],
      time: ['', [Validators.required, CustomValidators.timeValidator()]]
    }),
    endTime: this.builder.group({
      date: ['', Validators.required],
      time: ['', [Validators.required, CustomValidators.timeValidator()]]
    })
  });

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private builder: UntypedFormBuilder,
    private service: BatteryService
  ) {}

  ngOnInit(): void {
    this.getFcrPeriod();
  }

  getFcrPeriod(): void {
    const batteryId = this.route.snapshot.paramMap.get('batteryId');
    const id = this.route.snapshot.paramMap.get('fcrPeriodId');
    if (id === 'new') {
      this.initForm(batteryId, id, new BatteryFcrPeriod());
    } else {
      this.service.getFcrPeriod(batteryId, id).subscribe((fcrPeriod) => this.initForm(batteryId, id, fcrPeriod));
    }
  }

  onSubmit(): void {
    const value = cloneDeep(this.fcrPeriodForm.value);
    const batteryId = value.batteryId;
    const result = new BatteryFcrPeriod();
    result.id = value.id === 'new' ? null : value.id;
    result.gridPointId = value.gridPointId;
    result.period = PeriodView.serialize({
      startDateTime: value.startTime,
      toDateTime: value.endTime
    });
    if (this.fcrPeriodForm.value.id === 'new') {
      this.service.addFcrPeriod(batteryId, result).subscribe(() => {
        this.fcrPeriodForm.reset();
        this.goBack();
      });
    } else {
      this.service.updateFcrPeriod(batteryId, result).subscribe(() => {
        this.fcrPeriodForm.reset();
        this.goBack();
      });
    }
  }

  goBack(): void {
    this.location.back();
  }

  private initForm(batteryId: string, id: string, fcrPeriod: BatteryFcrPeriod): void {
    this.fcrPeriodForm.get('id').setValue(id);
    this.fcrPeriodForm.get('batteryId').setValue(batteryId);
    this.fcrPeriodForm.get('gridPointId').setValue(fcrPeriod.gridPointId);
    if (id === 'new') {
      const nowMoment = moment(new Date());
      this.fcrPeriodForm.get('startTime').setValue({ date: nowMoment, time: nowMoment.format('HH:mm') });
      this.fcrPeriodForm.get('endTime').setValue({ date: nowMoment, time: nowMoment.format('HH:mm') });
    } else {
      const period = PeriodView.deserialize(fcrPeriod.period);
      this.fcrPeriodForm.get('startTime').setValue(period.startDateTime);
      this.fcrPeriodForm.get('endTime').setValue(period.toDateTime);
    }
  }
}
