import { State } from '@ngxs/store';
import { ServiceAgreementsState } from './states/service-agreements/service-agreements.state';
import { ServiceAgreementState } from './states/service-agreement/service-agreement.state';
import { Injectable } from '@angular/core';

export const ServiceAgreementStates = [ServiceAgreementsState, ServiceAgreementState];

@State({
  name: 'serviceAgreementStateModule',
  children: ServiceAgreementStates
})
@Injectable({
  providedIn: 'root'
})
export class ServiceAgreementStateModule {}
