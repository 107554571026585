import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-gridpoints',
  templateUrl: './dashboard-gridpoints.component.html',
  styleUrls: ['./dashboard-gridpoints.component.scss']
})
export class DashboardGridpointsComponent {
  @Input()
  public readonly isForbidden: boolean = false;

  @Input()
  public readonly isInitialized: boolean = false;

  @Input()
  private readonly customerId: string | undefined = undefined;

  private getUrl(): string {
    if (!this.customerId) return `/grid-points/new`;
    return `/dashboard/${this.customerId}/grid-points/new`;
  }
}
