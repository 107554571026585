import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { LOCALE_ID, NgModule, RendererFactory2 } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';
import {
  ALLOWED_LANDING_PAGES,
  appInjectorProvider,
  AuthorityService,
  AVAILBLE_MENU_ITEMS,
  CONFIG_APPLICATION_TYPE,
  customLocaleEn,
  ErrorHandlerInterceptor,
  FlexApiPrivateModule,
  FlexApplicationUpdateModule,
  FlexAsyncMessagingModule,
  FlexAuthModule,
  FlexAutocompleteModule,
  FlexCoreModule,
  FlexDateFormattersModule,
  FlexDateRangeFixedPeriodModule,
  FlexDateRangeModule,
  FlexErrorMessagesModule,
  FlexIconsModule,
  FlexLoadingContextModule,
  FlexMotdModule,
  FlexNotificationModule,
  FlexNumberInputModule,
  FlexSaveButtonModule,
  FlexSidenavMenuModule,
  FlexTableFilterModule,
  FlexWebSocketModule,
  FRONTEND_APP_INFO,
  PRODUCTION_MODE,
  REQUIRE_AUTHENTICATION,
  SERVICE_WORKER_ENABLED,
  SHOULD_SHOW_CUSTOMER_NAME,
  SUPPORTED_PAGE_TITLE_URIS
} from 'flex-app-shared';
import { HighchartsChartModule } from 'highcharts-angular';
import { first } from 'rxjs/operators';
import { FlexAppInsightsModule } from '../../../flex-app-shared/src/lib/app-insights/app-insights.module';
import { FlexDialogModule } from '../../../flex-app-shared/src/lib/material/dialog/dialog.module';
import { environment } from '../environments/environment';
import { versionInfo } from '../environments/version-info';
import { NgxsStoreModule } from '../store/store.module';
import { AdminComponent } from './admin/admin.component';
import { AnnouncementDialogComponent } from './admin/announcement-dialog/announcement-dialog.component';
import { DayAheadDealsComponent } from './admin/day-ahead-deals/day-ahead-deals.component';
import { DayAheadPricesComponent } from './admin/day-ahead-prices/day-ahead-prices.component';
import { EditApplicationSettingComponent } from './admin/edit-application-setting/edit-application-setting.component';
import { EditTemplateOptionComponent } from './admin/edit-template-option/edit-template-option.component';
import { ImbalancePricesComponent } from './admin/imbalance-prices/imbalance-prices.component';
import { IncidentReserveUnprocessedInvoiceResultsComponent } from './admin/incident-reserve-unprocessed-invoice-results/incident-reserve-unprocessed-invoice-results.component';
import { TsoPoolComponent } from './admin/tso-pool/tso-pool.component';
import { AggregatedPoolComponent } from './aggregated-pool/aggregated-pool.component';
import { AnalogInputDetailComponent } from './analog-input-detail/analog-input-detail.component';
import { AnalogInputsComponent } from './analog-inputs/analog-inputs.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AvailabilityScheduleDialogComponent } from './availability-schedule-dialog/availability-schedule-dialog.component';
import { AvailabilityScheduleComponent } from './availability-schedule/availability-schedule.component';
import { BalanceResponsiblePartiesComponent } from './balance-responsible-parties/balance-responsible-parties.component';
import { BalanceResponsiblePartyDetailComponent } from './balance-responsible-party-detail/balance-responsible-party-detail.component';
import { BatteriesComponent } from './batteries/batteries.component';
import { BatteryDetailComponent } from './battery-detail/battery-detail.component';
import { BatteryFcrPeriodDetailComponent } from './battery-fcr-period-detail/battery-fcr-period-detail.component';
import { BatteryFcrPeriodsComponent } from './battery-fcr-periods/battery-fcr-periods.component';
import { BatteryMeasurementDataComponent } from './battery-measurement-data/battery-measurement-data.component';
import { ContactsDialogComponent } from './contacts-dialog/contacts-dialog.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ControlConfigurationComponent } from './control-configuration/control-configuration.component';
import { ControlDetailSendToDevicesConfirmationDialogComponent } from './control-detail/control-detail-send-to-devices-confirmation-dialog.component';
import { ControlDetailComponent } from './control-detail/control-detail.component';
import { ControlsComponent } from './controls/controls.component';
import { CoreModule } from './core/core.module';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomerStepperComponent } from './customer-stepper/customer-stepper.component';
import { CustomersComponent } from './customers/customers.component';
import { DashboardControlsComponent } from './dashboard/dashboard-controls/dashboard-controls.component';
import { DashboardCustomerComponent } from './dashboard/dashboard-customer/dashboard-customer.component';
import { DashboardDevicesComponent } from './dashboard/dashboard-devices/dashboard-devices.component';
import { DashboardGridpointsComponent } from './dashboard/dashboard-gridpoints/dashboard-gridpoints.component';
import { DashboardRecipientsComponent } from './dashboard/dashboard-recipients/dashboard-recipients.component';
import { DashboardServiceAgreementsComponent } from './dashboard/dashboard-service-agreements/dashboard-service-agreements.component';
import { DashboardUnavailabilitiesComponent } from './dashboard/dashboard-unavailabilities/dashboard-unavailabilities.component';
import { DashboardUsersComponent } from './dashboard/dashboard-users/dashboard-users.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ForbiddenMessageComponent } from './dashboard/forbidden-message/forbidden-message.component';
import { DeviceDetailComponent } from './device-detail/device-detail.component';
import { DevicesComponent } from './devices/devices.component';
import { GridPointDetailComponent } from './grid-point-detail/grid-point-detail.component';
import { GridPointMeasurementConfigurationComponent } from './grid-point-measurement-configuration/grid-point-measurement-configuration.component';
import { PulseMeasurementConfigurationComponent } from './grid-point-measurement-configuration/pulse-measurement-configuration/pulse-measurement-configuration.component';
import { GridPointRetrieveMeasurementDataComponent } from './grid-point-retrieve-measurement-data/grid-point-retrieve-measurement-data.component';
import { GridPointsComponent } from './grid-points/grid-points.component';
import { GroupAuthoritiesComponent } from './group-authorities/group-authorities.component';
import { IncidentReserveInvoiceResultsComponent } from './incident-reserve-invoice-results/incident-reserve-invoice-results.component';
import { EditIncidentReserveContactInformationComponent } from './incident-reserve-notice/incident-reserve-contact-information/edit-incident-reserve-contact-information.component';
import { IncidentReserveNoticeDialogComponent } from './incident-reserve-notice/incident-reserve-notice-dialog/incident-reserve-notice-dialog.component';
import { IncidentReserveNoticeComponent } from './incident-reserve-notice/incident-reserve-notice.component';
import { IntradayModule } from './intraday/intraday.module';
import { MeasurementCompaniesComponent } from './measurement-companies/measurement-companies.component';
import { MeasurementCompanyDetailComponent } from './measurement-company-detail/measurement-company-detail.component';
import { MeasurementDataDownloadComponent } from './measurement-data-download/measurement-data-download.component';
import { MeasurementDataUploadComponent } from './measurement-data-upload/measurement-data-upload.component';
import { MeasurementDataUploadRemarksComponent } from './measurement-data-upload/remarks/measurement-data-upload-remarks.component';
import { MessageOverviewComponent, MessageOverviewDialogComponent } from './message-overview/message-overview.component';
import { OneToManyComponent } from './one-to-many/one-to-many.component';
import { OperationDetailSendEndOperationMessageConfirmationDialogComponent } from './operation-detail/operation-detail-send-end-operation-message-confirmation-dialog.component';
import { OperationDetailSendStartOperationMessageConfirmationDialogComponent } from './operation-detail/operation-detail-send-start-operation-message-confirmation-dialog.component';
import { OperationDetailSendToDevicesConfirmationDialogComponent } from './operation-detail/operation-detail-send-to-devices-confirmation-dialog.component';
import { OperationDetailComponent } from './operation-detail/operation-detail.component';
import { OperationMeasurementsComponent } from './operation-detail/operation-measurements/operation-measurements.component';
import { OperationPoolComponent } from './operation-pool/operation-pool.component';
import { OperationsComponent } from './operations/operations.component';
import { PoolAvailabilityComponent } from './pool-availability/pool-availability.component';
import { PoolPeriodDialogComponent } from './pool-period-dialog/pool-period-dialog.component';
import { CanDeactivateGuard, PoolsComponent } from './pools/pools.component';
import { R3InvoiceDataRecalculateConfirmDialogComponent } from './r3-invoice-data/r3-invoice-data-recalculate-confirm-dialog/r3-invoice-data-recalculate-confirm-dialog.component';
import { R3InvoiceDataComponent } from './r3-invoice-data/r3-invoice-data.component';
import { RecipientsDetailComponent } from './recipient-detail/recipients-detail.component';
import { RecipientsComponent } from './recipients/recipients.component';
import { ServiceAgreementDetailComponent } from './service-agreement-detail/service-agreement-detail.component';
import { ServiceAgreementsComponent } from './service-agreements/service-agreements.component';
import { CallbackComponent } from './shared/callback/callback.component';
import { GridPointAutocompleteComponent } from './shared/grid-point-autocomplete/grid-point-autocomplete.component';
import { OperationMeasurementsSnackbarComponent } from './shared/operation/operation-measurements-snackbar/operation-measurements-snackbar.component';
import { TimePipe } from './shared/time-pipe/time.pipe';
import { TimepickerComponent } from './shared/timepicker/timepicker.component';
import { TruncatePipe } from './shared/truncate/truncate.pipe';
import { TsoAgreementDetailComponent } from './tso-agreement-detail/tso-agreement-detail.component';
import { TsoAgreementsComponent } from './tso-agreements/tso-agreements.component';
import { UnavailabilitiesSendDialogComponent } from './unavailabilities-send-dialog/unavailabilities-send-dialog.component';
import { UnavailabilitiesComponent } from './unavailabilities/unavailabilities.component';
import { UnavailabilityDetailComponent } from './unavailability-detail/unavailability-detail.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UsersComponent } from './users/users.component';
import { FetchMeasurementsComponent } from './admin/retrieve-measurements/fetch-measurements.component';
import { WebsocketTestComponent } from './admin/websocket-test/websocket-test.component';
import 'moment/locale/nl';

registerLocaleData(customLocaleEn, 'nl');

export const menuItems = [
  '/dashboard',
  '/measurement-companies',
  '/measurement-data-download',
  '/measurement-data-upload',
  '/battery-measurement-data',
  '/balance-responsible-parties',
  '/batteries',
  '/analog-inputs',
  '/tso-agreements',
  '/tso-auctions',
  '/pools',
  '/aggregated-pool',
  '/pool-availability',
  '/operations',
  '/r3-invoice-data',
  '/incident-reserve-invoice-results',
  '/intraday/imbalance-correction',
  '/intraday/invoice-results',
  '/intraday/deal-reporting',
  '/messages',
  '/admin',
  '/group-authorities',
  '/incident-reserve-notice'
];

export const extraPageTitlePaths = [
  '/customers',
  '/grid-points',
  '/service-agreements',
  '/controls',
  '/devices',
  '/recipients',
  '/unavailabilities',
  '/users'
];

@NgModule({
  declarations: [
    AppComponent,
    AvailabilityScheduleComponent,
    CustomersComponent,
    CustomerDetailComponent,
    GridPointsComponent,
    GridPointDetailComponent,
    GridPointMeasurementConfigurationComponent,
    GridPointRetrieveMeasurementDataComponent,
    MeasurementDataDownloadComponent,
    MeasurementDataUploadComponent,
    MessageOverviewDialogComponent,
    ServiceAgreementsComponent,
    ServiceAgreementDetailComponent,
    OperationsComponent,
    OperationDetailComponent,
    AvailabilityScheduleDialogComponent,
    TimePipe,
    CallbackComponent,
    MessageOverviewComponent,
    ControlsComponent,
    ControlDetailComponent,
    ControlDetailSendToDevicesConfirmationDialogComponent,
    DevicesComponent,
    DeviceDetailComponent,
    MessageOverviewComponent,
    DeviceDetailComponent,
    CallbackComponent,
    BalanceResponsiblePartiesComponent,
    BalanceResponsiblePartyDetailComponent,
    TsoAgreementsComponent,
    TsoAgreementDetailComponent,
    ContactsComponent,
    ContactsDialogComponent,
    PoolsComponent,
    PoolPeriodDialogComponent,
    UsersComponent,
    GroupAuthoritiesComponent,
    UserDetailComponent,
    OperationPoolComponent,
    OperationDetailSendToDevicesConfirmationDialogComponent,
    RecipientsComponent,
    RecipientsDetailComponent,
    UnavailabilitiesComponent,
    UnavailabilityDetailComponent,
    UnavailabilitiesSendDialogComponent,
    OperationDetailSendStartOperationMessageConfirmationDialogComponent,
    OperationDetailSendEndOperationMessageConfirmationDialogComponent,
    AdminComponent,
    AnnouncementDialogComponent,
    IncidentReserveNoticeComponent,
    IncidentReserveNoticeDialogComponent,
    TimepickerComponent,
    DashboardComponent,
    CustomerStepperComponent,
    OneToManyComponent,
    TruncatePipe,
    R3InvoiceDataComponent,
    EditTemplateOptionComponent,
    AggregatedPoolComponent,
    DashboardCustomerComponent,
    DashboardGridpointsComponent,
    DashboardDevicesComponent,
    DashboardControlsComponent,
    DashboardServiceAgreementsComponent,
    DashboardRecipientsComponent,
    DashboardUnavailabilitiesComponent,
    ForbiddenMessageComponent,
    MeasurementCompaniesComponent,
    MeasurementCompanyDetailComponent,
    ControlConfigurationComponent,
    OperationMeasurementsComponent,
    GridPointAutocompleteComponent,
    PoolAvailabilityComponent,
    PulseMeasurementConfigurationComponent,
    EditApplicationSettingComponent,
    OperationMeasurementsSnackbarComponent,
    BatteriesComponent,
    BatteryDetailComponent,
    BatteryFcrPeriodsComponent,
    BatteryFcrPeriodDetailComponent,
    BatteryMeasurementDataComponent,
    AnalogInputsComponent,
    AnalogInputDetailComponent,
    DayAheadPricesComponent,
    DayAheadDealsComponent,
    EditIncidentReserveContactInformationComponent,
    R3InvoiceDataRecalculateConfirmDialogComponent,
    MeasurementDataUploadRemarksComponent,
    DashboardUsersComponent,
    ImbalancePricesComponent,
    IncidentReserveInvoiceResultsComponent,
    IncidentReserveUnprocessedInvoiceResultsComponent,
    TsoPoolComponent,
    FetchMeasurementsComponent,
    WebsocketTestComponent
  ],
  exports: [TimePipe],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatMenuModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatTreeModule,
    MatCardModule,
    MatListModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatTabsModule,
    MatDialogModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule,
    NgxsStoreModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    PortalModule,
    MatAutocompleteModule,
    MatSortModule,
    MatStepperModule,
    MatRadioModule,
    NgxsFormPluginModule,
    MatSlideToggleModule,
    HighchartsChartModule,
    MatButtonToggleModule,
    MatGridListModule,
    FlexAuthModule.forRoot(),
    FlexCoreModule.forRoot('Flexapp', 'flex-app-admin'),
    FlexDateRangeModule,
    FlexAppInsightsModule.forRoot(environment.enableAppInsights),
    FlexNumberInputModule,
    FlexSaveButtonModule,
    FlexApiPrivateModule.forRoot(),
    FlexApplicationUpdateModule.forRoot(),
    FlexWebSocketModule.forRoot(),
    FlexAsyncMessagingModule.forRootWebsocket(),
    FlexTableFilterModule,
    CoreModule,
    FlexSidenavMenuModule,
    MatSliderModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.serviceWorker }),
    FlexLoadingContextModule,
    FlexErrorMessagesModule,
    FlexAutocompleteModule,
    FlexMotdModule,
    DragDropModule,
    FlexNotificationModule,
    FlexDateFormattersModule,
    IntradayModule,
    FlexIconsModule,
    FlexDateRangeFixedPeriodModule,
    FlexDialogModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'nl'
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHandlerInterceptor,
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' }
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {
        formFieldAppearance: 'fill'
      }
    },
    {
      provide: REQUIRE_AUTHENTICATION,
      useValue: environment.requireAuthentication
    },
    {
      provide: PRODUCTION_MODE,
      useValue: environment.production
    },
    {
      provide: SERVICE_WORKER_ENABLED,
      useValue: environment.serviceWorker
    },
    {
      provide: CONFIG_APPLICATION_TYPE,
      useValue: 'admin'
    },
    {
      provide: FRONTEND_APP_INFO,
      useValue: versionInfo
    },
    {
      provide: ALLOWED_LANDING_PAGES,
      useValue: ['dashboard', 'incident-reserve-notice', 'operations']
    },
    {
      provide: SHOULD_SHOW_CUSTOMER_NAME,
      useValue: true
    },
    {
      provide: SUPPORTED_PAGE_TITLE_URIS,
      useValue: [...menuItems, ...extraPageTitlePaths]
    },
    {
      provide: AVAILBLE_MENU_ITEMS,
      useValue: menuItems
    },
    CanDeactivateGuard,
    appInjectorProvider,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule {
  constructor(
    public rendererFactory: RendererFactory2,
    public authorityService: AuthorityService
  ) {
    if (!environment.requireAuthentication) {
      this.showContent();
    } else {
      this.authorityService.authorities$.pipe(first()).subscribe(() => {
        this.showContent();
        // TODO what to do if this fails or never gets called?
      });
    }
  }

  private showContent(): void {
    // TODO replace preloader with angular preloader after angular is initialized and show a message indicating progress
    const renderer = this.rendererFactory.createRenderer(null, null);
    renderer.addClass(document.getElementById('preloader'), 'hide');
    renderer.removeClass(document.getElementById('app-root'), 'hide');
  }
}
