import * as Highcharts from 'highcharts';
import {
  ChartOptions,
  CreditsOptions,
  PlotOptions,
  SeriesOptionsType,
  TimeOptions,
  TitleOptions,
  TooltipOptions,
  XAxisOptions,
  YAxisOptions
} from 'highcharts';

export enum PoolAvailabilitySeries {
  tsoAgreementPower = 2,
  pulsePower = 3,
  controlPulsePower = 0,
  serviceAgreementPower = 1
}

const chart: ChartOptions = {
  animation: true
};

const plotOptions: PlotOptions = {
  area: {
    stacking: 'normal',
    lineWidth: 0
  }
};

const tooltip: TooltipOptions = {
  pointFormat: '<span> {point.y} MW</span>',
  headerFormat: '<strong>{series.name}:</strong>',
  valueDecimals: 2
};

const legend: any = {};

const xAxis: XAxisOptions = {
  type: 'datetime',
  tickInterval: 60 * 60 * 1000 // 1 hour
};

const yAxis: YAxisOptions | Array<YAxisOptions> = {
  title: {
    text: 'Power'
  }
};

const title: TitleOptions = {
  text: null
};

const series: Array<SeriesOptionsType> = [];

series[PoolAvailabilitySeries.tsoAgreementPower] = {
  name: 'TSO Power (MW)',
  type: 'line',
  turboThreshold: 2000,
  dashStyle: 'LongDash',
  color: '#eda212'
};

series[PoolAvailabilitySeries.serviceAgreementPower] = {
  name: 'Service Agreement Power (MW)',
  type: 'area',
  color: '#27a8d5',
  fillOpacity: 0.7,
  turboThreshold: 2000
};

series[PoolAvailabilitySeries.controlPulsePower] = {
  name: 'Control/Pulse Power (MW)',
  type: 'area',
  fillOpacity: 0.7,
  color: '#0b5fb1',
  turboThreshold: 2000
};

series[PoolAvailabilitySeries.pulsePower] = {
  name: 'Pulse Power (MW)',
  type: 'line',
  color: '#cb4141',
  turboThreshold: 2000
};

const credits: CreditsOptions = {
  enabled: false
};

const time: TimeOptions = {
  useUTC: false
};

export const chartOptions: Highcharts.Options = {
  chart,
  plotOptions,
  tooltip,
  legend,
  xAxis,
  yAxis,
  title,
  series,
  credits,
  time
};
