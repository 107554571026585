import { Component } from '@angular/core';
import { OperationFacade } from '../../store/operations/states/operation/operation.facade';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-operation-detail-send-end-operation-message-confirmation-dialog',
  template: `
    <h1 mat-dialog-title>Send end operation message</h1>
    <div mat-dialog-content>
      <p>Are you sure you want to send an end operation message to every customer that has grid points that are in the current pool?</p>
    </div>
    <div mat-dialog-actions>
      <button mat-flat-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" (click)="confirm()" cdkFocusInitial>Ok</button>
    </div>
  `
})
export class OperationDetailSendEndOperationMessageConfirmationDialogComponent {
  constructor(
    private operationFacade: OperationFacade,
    public dialogRef: MatDialogRef<OperationDetailSendEndOperationMessageConfirmationDialogComponent>
  ) {}

  confirm(): void {
    this.operationFacade.sendEndActivationMessage();
    this.dialogRef.close();
  }
}
