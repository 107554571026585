import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PoolAutoSendResult, TsoPoolService } from '../../shared/tso-pool/tso-pool.service';

@Component({
  selector: 'app-tso-pool',
  templateUrl: './tso-pool.component.html',
  styleUrls: ['./tso-pool.component.scss']
})
export class TsoPoolComponent implements OnDestroy {
  private ngUnsubscribe = new Subject<void>();

  sending: boolean = false;
  sentResult: PoolAutoSendResult = null;
  error: boolean = false;
  lastAutoSendDateTime: string = 'unknown';

  constructor(private tsoPoolService: TsoPoolService) {
    this.getLastAutoSendDateTime();
  }

  autoSendPool(): void {
    this.sending = true;
    this.error = false;
    this.sentResult = null;
    this.tsoPoolService
      .autoSendPool()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (result) => {
          this.sentResult = result;
          this.sending = false;
        },
        error: (error) => {
          this.sentResult = error;
          this.sending = false;
          this.error = true;
        }
      });
  }

  getLastAutoSendDateTime(): void {
    this.tsoPoolService
      .lastAutoSendDateTime()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result) => {
        this.lastAutoSendDateTime = result.lastAutoSendDateTime;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
