import { Injectable } from '@angular/core';
import { RestClientService, RestEntity } from 'flex-app-shared';

export class AccountManager extends RestEntity {
  pronoun: string;
  firstName: string;
  lastNamePrefix: string;
  lastName: string;
  mobileNumber: string;
  email: string;
}

@Injectable({
  providedIn: 'root'
})
export class AccountManagerService extends RestClientService<AccountManager> {
  constructor() {
    super('/api/v1/account-managers');
  }
}
