import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormGroupDirective,
  NgForm,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import {
  ChannelInfo,
  ChannelValidateResult,
  DEFAULT_PULSE_WEIGHT,
  GridPoint,
  GridPointService,
  MeasurementProviderConfig,
  MeasurementProviderValidationConfig,
  PulseMeasurementConfiguration,
  ValidateResult
} from 'flex-app-shared';
import { Dictionary } from 'highcharts';
import { fromPairs, isNil } from 'lodash-es';
import { first, switchMap } from 'rxjs/operators';
import { GridPointFacade } from '../../store/grid-points/grid-point.facade';
import { MeasurementCompany, MeasurementCompanyService } from '../shared/measurement-company/measurement-company.service';

export enum Direction {
  PRODUCTION = 'PRODUCTION',
  CONSUMPTION = 'CONSUMPTION'
}

export enum MeasurementMethodType {
  AUTOMATIC = 'AUTO',
  MANUAL = 'MANUAL'
}

/**
 * Validates correct filling of provider - channel combinations
 * @param forceValidFn function that should return true or false. When true: the form validation is suppressed. Can be used to only validate when the form has finished loading.
 */
function validProviderChannelsValidator(forceValidFn: () => boolean): ValidatorFn {
  return (control: UntypedFormGroup): ValidationErrors | null => {
    if (forceValidFn()) {
      // Prevent showing errors while form is still loading upon first page visit.
      return null;
    }
    const measurement = control.get('measurement').value;
    const companyId = control.get('companyId').value;
    const provider = control.get('provider').value;
    let consumptionChannels: string[] = control
      .get('consumptionChannelsForm')
      ?.value?.map((value) => value.channel?.name)
      .filter((name) => name !== null);
    let productionChannels: string[] = control
      .get('productionChannelsForm')
      ?.value?.map((value) => value.channel?.name)
      .filter((name) => name !== null);
    if (!consumptionChannels) {
      consumptionChannels = [];
    }
    if (!productionChannels) {
      productionChannels = [];
    }
    const allChannels = [...consumptionChannels, ...productionChannels];

    if (measurement === MeasurementMethodType.AUTOMATIC && !companyId) {
      // When measurement = AUTO, a (measurement)company must be selected
      return { mustSelectACompanyWhenMeasurementIsAutomatic: true };
    }

    if (measurement === MeasurementMethodType.AUTOMATIC && (!allChannels || allChannels.length === 0)) {
      // When measurement = AUTO and company that has a provider is selected, at least one channel must be selected as well
      return { mustHaveAtLeastOneChannelWhenCompanyIsSelected: true };
    }

    if (!provider && allChannels && allChannels.length > 0) {
      // When no provider is selected, no channels may be selected.
      return { noChannelsAllowedWhenProviderIsNull: true };
    }

    const noDuplicateConsumptionChannelsAllowed =
      consumptionChannels.length > 1 && new Set<string>(consumptionChannels).size !== consumptionChannels.length;
    const noDuplicateProductionChannelsAllowed =
      productionChannels.length > 1 && new Set<string>(productionChannels).size !== productionChannels.length;
    if (noDuplicateConsumptionChannelsAllowed || noDuplicateProductionChannelsAllowed) {
      return {
        noDuplicateConsumptionChannelsAllowed,
        noDuplicateProductionChannelsAllowed
      };
    } else {
      return null;
    }
  };
}

/**
 * Validates if the value is equal to the provided direction (PRODUCTION/CONSUMPTION)
 * @param direction The direction.
 */
function channelDirectionValidator(direction: Direction): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (control.value && control.value.channel && control.value.channel.direction !== direction) {
      return { invalidChannelDirection: true };
    }
    return null;
  };
}

/**
 * Custom error state matcher to show errors on the form if a channel has an incorrect direction,
 * and the field has not yet been interacted with (i.e. upon initial page load)
 */
class CustomChannelErrorStateMatcher extends ErrorStateMatcher {
  constructor(private direction: Direction) {
    super();
  }

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return super.isErrorState(control, form) || (control && control.value && control.value.direction !== this.direction);
  }
}

@Component({
  selector: 'app-grid-point-measurement',
  templateUrl: './grid-point-measurement-configuration.component.html',
  styleUrls: ['./grid-point-measurement-configuration.component.scss']
})
export class GridPointMeasurementConfigurationComponent implements OnInit {
  Direction = Direction; // To be able to use this enum in the component template
  MeasurementMethodType = MeasurementMethodType; // To be able to use this enum in the component template

  gridPoint: GridPoint = new GridPoint();
  allChannels: ChannelInfo[] = [];
  productionChannels: ChannelInfo[] = [];
  consumptionChannels: ChannelInfo[] = [];
  validationResult: ValidateResult = null;
  measurementCompanies: MeasurementCompany[] = [];
  formLoaded = false;

  consumptionErrorStateMatcher = new CustomChannelErrorStateMatcher(Direction.CONSUMPTION);
  productionErrorStateMatcher = new CustomChannelErrorStateMatcher(Direction.PRODUCTION);

  externalMeasurementConfigurationForm = this.builder.group(
    {
      id: '',
      measurement: '',
      provider: new UntypedFormControl({ value: '', disabled: true }),
      companyId: '',
      fiveMinuteDataAvailable: false
    },
    { validators: validProviderChannelsValidator(() => this.formLoaded === false) }
  );

  consumptionChannelsForm: UntypedFormArray;
  productionChannelsForm: UntypedFormArray;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private builder: UntypedFormBuilder,
    private gridPointService: GridPointService,
    private gridPointFacade: GridPointFacade,
    private measurementCompanyService: MeasurementCompanyService,
    private snackBar: MatSnackBar,
    private fb: UntypedFormBuilder
  ) {}

  get measurement(): AbstractControl {
    return this.externalMeasurementConfigurationForm.get('measurement');
  }

  get companyId(): AbstractControl {
    return this.externalMeasurementConfigurationForm.get('companyId');
  }

  get provider(): AbstractControl {
    return this.externalMeasurementConfigurationForm.get('provider');
  }

  get fiveMinuteDataAvailable(): AbstractControl {
    return this.externalMeasurementConfigurationForm.get('fiveMinuteDataAvailable');
  }

  get selectedConsumptionChannels(): ChannelInfo[] {
    return this.externalMeasurementConfigurationForm
      .get('consumptionChannelsForm')
      ?.value?.map((value) => value.channel)
      .filter((channel) => channel && channel.name !== null);
  }

  get selectedProductionChannels(): ChannelInfo[] {
    return this.externalMeasurementConfigurationForm
      .get('productionChannelsForm')
      ?.value?.map((value) => value.channel)
      .filter((channel) => channel && channel.name !== null);
  }

  ngOnInit(): void {
    this.getGridPoint();
    this.getMeasurementCompanies();
  }

  setFiveMinuteDataAvailableDisabled(): void {
    if (this.measurement.value === MeasurementMethodType.AUTOMATIC) {
      this.fiveMinuteDataAvailable.disable();
    } else {
      this.fiveMinuteDataAvailable.enable();
    }
  }

  onSubmit(): void {
    const config = new MeasurementProviderConfig();
    config.measurementMethod = this.measurement.value;

    const consumptionChannels: ChannelInfo[] = this.consumptionChannelsForm.controls.map((control) => control.value.channel);
    const productionChannels: ChannelInfo[] = this.productionChannelsForm.controls.map((control) => control.value.channel);
    const allChannels = [...consumptionChannels, ...productionChannels].filter((channel) => channel.name !== null); // filter out 'none' items

    config.measurementChannels = allChannels;
    config.measurementCompanyId = this.companyId.value;
    config.fiveMinuteDataAvailable = this.fiveMinuteDataAvailable.value;

    this.gridPointFacade
      .getPulseConfig()
      .pipe(
        first(),
        switchMap((gridPointConfig) => {
          let pulseConfig;
          if (gridPointConfig.pulseEnabled) {
            pulseConfig = this.getPulseConfig(gridPointConfig);
          } else {
            pulseConfig = {
              pulseDeviceId: gridPointConfig.pulseDeviceId
            };
          }

          return this.gridPointService.configureMeasurement(this.gridPoint.id, {
            ...config,
            ...pulseConfig
          });
        })
      )
      .subscribe(() => {
        this.externalMeasurementConfigurationForm.reset();
        this.goBack();
      });
  }

  goBack(): void {
    this.location.back();
  }

  verifyData(): void {
    if (this.externalMeasurementConfigurationForm.get('provider').value) {
      const config = new MeasurementProviderValidationConfig();
      config.channels = [...this.selectedConsumptionChannels, ...this.selectedProductionChannels];
      config.measurementProvider = this.provider.value;
      this.gridPointService.measurementValidate(this.gridPoint.id, config).subscribe((result) => {
        this.validationResult = result;
        const fiveMinData = this.validationResult.fiveMinuteDataAvailable;
        this.externalMeasurementConfigurationForm.get('fiveMinuteDataAvailable').setValue(fiveMinData);
        if (this.gridPoint.fiveMinuteDataAvailable !== fiveMinData) {
          this.snackBar.open(
            `The "5 minute data available"-flag has been changed and saved for this grid point. It is now: ${
              fiveMinData ? '"On"' : '"Off"'
            }`,
            null,
            {
              duration: 5000,
              panelClass: 'default-simple-snackbar'
            }
          );
        }
      });
    } else {
      this.validationResult = null;
    }
  }

  companyChanged(): void {
    const company = this.measurementCompanies.find((c) => c.id === this.companyId.value);
    this.externalMeasurementConfigurationForm.get('provider').setValue(company.measurementProvider);
    this.fetchAvailableChannelsForForm();
  }

  validateMessage(result: ChannelValidateResult): string {
    switch (result.status) {
      case 'OK':
        return `Channel '${result.channel}' is ok.`;
      case 'INVALID_INTERVAL':
        return `Channel '${result.channel}' has data, but wrong interval (${result.interval} minutes).`;
      case 'NO_DATA':
      default:
        return `Channel '${result.channel}' has no data.`;
    }
  }

  compareChannels(optionValue: ChannelInfo, selectedValue: ChannelInfo): boolean {
    return optionValue && selectedValue && optionValue.name === selectedValue.name;
  }

  isNoChannelSelected(): boolean {
    const consumptionChannels: string[] = this.selectedConsumptionChannels?.map((channel) => channel.name);
    const productionChannels: string[] = this.selectedProductionChannels?.map((channel) => channel.name);
    return (!consumptionChannels || consumptionChannels.length === 0) && (!productionChannels || productionChannels.length === 0);
  }

  private getPulseConfig(config: PulseMeasurementConfiguration): Dictionary<string | number | boolean | null | undefined> {
    const consumptionPair = ['pulseConsumptionWeight', 'pulseConsumptionChannelName'];
    const productionPair = ['pulseProductionWeight', 'pulseProductionChannelName'];
    return fromPairs(
      Object.entries(config)
        .filter(([key, value]) => !!value)
        .filter((pair) => !this.isDefaultValueForEmptyChannel(pair, consumptionPair, DEFAULT_PULSE_WEIGHT, config))
        .filter((pair) => !this.isDefaultValueForEmptyChannel(pair, productionPair, DEFAULT_PULSE_WEIGHT, config))
    );
  }

  private isDefaultValueForEmptyChannel(
    [key, value]: ReadonlyArray<any>,
    [expectedKey, channelName]: ReadonlyArray<string>,
    defaultValue: any,
    gridPointConfig: PulseMeasurementConfiguration
  ): boolean {
    const isExpectedKey = key === expectedKey;
    const isDefaultValue = value === defaultValue;
    const isChanelEmpty = isNil(gridPointConfig[channelName]);

    return isExpectedKey && isDefaultValue && isChanelEmpty;
  }

  private getGridPoint(): void {
    const id = this.route.snapshot.paramMap.get('gridPointId');
    this.gridPointService.getById(id).subscribe((gridPoint) => this.initForm(id, gridPoint));
  }

  private initForm(id: string, gridPoint: GridPoint): void {
    this.gridPoint = gridPoint;
    this.externalMeasurementConfigurationForm.get('id').setValue(id);
    this.externalMeasurementConfigurationForm.get('measurement').setValue(gridPoint.measurementMethod);
    this.externalMeasurementConfigurationForm.get('provider').setValue(gridPoint.measurementProvider);
    this.externalMeasurementConfigurationForm.get('companyId').setValue(gridPoint.measurementCompanyId);
    this.externalMeasurementConfigurationForm.get('fiveMinuteDataAvailable').setValue(gridPoint.fiveMinuteDataAvailable);
    this.setSelectedChannels(gridPoint);
    this.fetchAvailableChannelsForForm();
    this.setFiveMinuteDataAvailableDisabled();
  }

  private setSelectedChannels(gridPoint: GridPoint): void {
    if (this.consumptionChannelsForm === undefined) {
      this.consumptionChannelsForm = this.fb.array(
        gridPoint.consumptionMeasurementChannels.map((channel) =>
          this.createFormGroup({
            name: channel.name,
            direction: Direction.CONSUMPTION,
            description: channel.description
          })
        )
      );
      this.externalMeasurementConfigurationForm.addControl('consumptionChannelsForm', this.consumptionChannelsForm);
    }

    if (this.productionChannelsForm === undefined) {
      this.productionChannelsForm = this.fb.array(
        gridPoint.productionMeasurementChannels.map((channel) =>
          this.createFormGroup({
            name: channel.name,
            direction: Direction.PRODUCTION,
            description: channel.description
          })
        )
      );

      this.externalMeasurementConfigurationForm.addControl('productionChannelsForm', this.productionChannelsForm);
    }
  }

  createFormGroup(channel: ChannelInfo): UntypedFormGroup {
    return this.fb.group(
      {
        channel: this.fb.control(channel)
      },
      {
        validators:
          channel.direction === Direction.CONSUMPTION
            ? channelDirectionValidator(Direction.CONSUMPTION)
            : channelDirectionValidator(Direction.PRODUCTION)
      }
    );
  }

  private fetchAvailableChannelsForForm(): void {
    const provider = this.provider.value;
    if (provider) {
      this.gridPointService.availableChannels(this.gridPoint.id, provider).subscribe((result: ChannelInfo[]) => {
        this.allChannels = result;
        this.productionChannels = result.filter((channel) => channel.direction === Direction.PRODUCTION);
        this.consumptionChannels = result.filter((channel) => channel.direction === Direction.CONSUMPTION);
        this.gridPoint.productionMeasurementChannels.forEach((channel: ChannelInfo) => this.addGridPointChannelToSelection(channel));
        if (this.consumptionChannels.length > 0) {
          this.consumptionChannels.push({ name: null, description: 'None', direction: Direction.CONSUMPTION } as ChannelInfo);
        }
        if (this.productionChannels.length > 0) {
          this.productionChannels.push({ name: null, description: 'None', direction: Direction.PRODUCTION } as ChannelInfo);
        }

        this.formLoaded = true;
      });
    } else {
      this.productionChannels = [];
      this.consumptionChannels = [];
      this.formLoaded = true;
    }
  }

  /**
   * When a loaded gridPoint contains a channel of an incorrect direction (i.e. a production-channel in the consumption-property or vice-versa),
   * add it to the list in order to be able to show it in the drop down (in an error state).
   * @param channelName name of the channel
   * @param direction direction of the list it should be part of (production/consumption). If it is not part of this list, it will be added and get an error state.
   */
  private addGridPointChannelToSelection(channel: ChannelInfo): void {
    const channels = channel.direction === Direction.CONSUMPTION ? this.consumptionChannels : this.productionChannels;

    if (channel.name && !channels.find((c) => c.name === channel.name)) {
      const selectedChannel = this.allChannels.find((c) => c.name === channel.name);

      if (!selectedChannel) {
        console.error('Selected channel could not be found');
        return;
      }

      const invalidChannel = {
        name: channel.name,
        description: selectedChannel.description
      } as ChannelInfo;
      switch (channel.direction) {
        case Direction.CONSUMPTION:
          this.consumptionChannels.push(invalidChannel);
          break;
        case Direction.PRODUCTION:
          this.productionChannels.push(invalidChannel);
          break;
      }
    }
  }

  private getMeasurementCompanies(): void {
    this.measurementCompanyService.getAll().subscribe((result: MeasurementCompany[]) => {
      this.measurementCompanies = result;
      this.measurementCompanies.push({ id: null, name: 'None', measurementProvider: null } as MeasurementCompany);
      this.formLoaded = true;
    });
  }

  deleteChannel(formArrayIndex: number, direction: Direction): void {
    if (direction === Direction.PRODUCTION) {
      this.productionChannelsForm.removeAt(formArrayIndex);
    } else {
      this.consumptionChannelsForm.removeAt(formArrayIndex);
    }
  }

  addChannel(direction: Direction): void {
    const formGroup = this.createFormGroup({
      name: null,
      direction,
      description: ''
    });
    if (direction === Direction.PRODUCTION) {
      this.productionChannelsForm.push(formGroup);
    } else {
      this.consumptionChannelsForm.push(formGroup);
    }
  }
}
