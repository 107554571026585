import { EntityStateModel, FetchEntity } from 'flex-app-shared';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { AccountManager, AccountManagerService } from '../../../../app/shared/account-manager/account-manager.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FetchAccountManagersCommand } from './account-managers.actions';
import { Injectable } from '@angular/core';

export class AccountManagersStateModel extends EntityStateModel<AccountManager[]> {
  model: AccountManager[] = [];
}

@State({
  name: 'accountManagers',
  defaults: new AccountManagersStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class AccountManagersState {
  constructor(private accountManagerService: AccountManagerService) {}

  @Selector()
  public static isBusy(state: AccountManagersStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  static getAccountManagers(state: AccountManagersStateModel): Array<AccountManager> {
    return state.model;
  }

  @FetchEntity()
  @Action(FetchAccountManagersCommand, { cancelUncompleted: true })
  fetchAccountManagers({ setState, getState }: StateContext<AccountManagersStateModel>): Observable<AccountManager[]> {
    return this.accountManagerService.getAll().pipe(
      tap(accountManagers => {
        setState({
          ...getState(),
          model: accountManagers,
          isInitialized: true
        });
      })
    );
  }
}
