<mat-card *ngIf="!isStandalone">
  <mat-card-content>
    <ng-container [ngTemplateOutlet]="pageContents"></ng-container>
  </mat-card-content>
  <mat-card-actions>
    <button *ngIf="!isStandalone" mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="primary" routerLink="/devices/new">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:add"></mat-icon>
        <span>New device</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>

<ng-container *ngIf="isStandalone" [ngTemplateOutlet]="pageContents"></ng-container>

<ng-template #pageContents>
  <mat-toolbar class="filter-bar">
    <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
  </mat-toolbar>
  <table
    mat-table
    matSort
    class="has-actions has-expandedrows"
    multiTemplateDataRows
    [dataSource]="dataSource"
    [class.has-hover]="isStandalone"
    [trackBy]="trackBy"
  >
    <ng-container matColumnDef="externalId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>External ID</th>
      <td mat-cell *matCellDef="let device" [ngSwitch]="isStandalone && !isDashboard">
        <a *ngSwitchCase="true" (click)="edit(device.id, $event)">{{ device.externalId }}</a>
        <a *ngSwitchCase="false" class="pointer" routerLink="/devices/{{ device.id }}">{{ device.externalId }}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
      <td mat-cell *matCellDef="let device">{{ findCustomerNameById(device.customerId) }}</td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let device" [ngSwitch]="isStandalone && !isDashboard">
        <ph-flex-vertical-center>
          <button *ngSwitchCase="true" mat-icon-button matTooltip="Edit" (click)="edit(device.id, $event)">
            <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
          </button>
          <button *ngSwitchCase="false" mat-icon-button matTooltip="Edit" routerLink="/devices/{{ device.id }}">
            <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
          </button>
          <button mat-icon-button [matMenuTriggerFor]="tableActions" (click)="handleMenuButtonClick($event)" ngClass="more-menu">
            <mat-icon svgIcon="ic-old:more-menu-v"></mat-icon>
          </button>
          <mat-menu #tableActions="matMenu">
            <button mat-menu-item (click)="editContacts(device.id)">
              <mat-icon svgIcon="ic-old:plug"></mat-icon>
              Edit contacts
            </button>
            <button mat-menu-item class="remove" (click)="delete(device)">
              <mat-icon svgIcon="ic-old:trash-o"></mat-icon>
              Delete
            </button>
          </mat-menu>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let device" [attr.colspan]="displayedColumns.length" class="expanded-table-row">
        <div class="device-contacts" @newDetailExpand *ngIf="device?.id === expandedDeviceId">
          <app-contacts [isStandalone]="isStandalone" class="expanded-table-box"></app-contacts>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [class.active-expanded-row]="row?.id === expandedDeviceId"
      (click)="toggleDeviceContacts(row, expandedDeviceId, $event)"
      class="expandable-table-row"
    ></tr>
    <tr mat-row *matRowDef="let row; columns: expandedColumns" class="detail-row"></tr>
  </table>

  <mat-paginator [showFirstLastButtons]="true" [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
</ng-template>
