import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AdjustmentDirection, Availability, AvailabilityType, CustomValidators, validPeriodValidator } from 'flex-app-shared';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import { CrossFieldErrorMatcher } from '../shared/common/common';
import moment from 'moment';
import { Moment } from 'moment';

const wholeHoursValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const time = control.value;
  if (typeof time === 'string') {
    const elements = time.split(':');
    const minutes = elements[1];
    return minutes === '00' ? null : { wholeHours: true };
  }
  return time.minutes && time.minutes !== 0 ? { wholeHours: true } : null;
};

const validTimesValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const startTime = control.get('startTime').value;
  const endTime = control.get('endTime').value;
  return startTime >= endTime && !endTime.startsWith('00:00') ? { timesWrong: true } : null;
};

@Component({
  selector: 'app-availability-schedule-dialog',
  templateUrl: './availability-schedule-dialog.component.html',
  styleUrls: ['./availability-schedule-dialog.component.scss']
})
export class AvailabilityScheduleDialogComponent implements OnInit {
  form: UntypedFormGroup;

  periodErrorFieldMatcher = new CrossFieldErrorMatcher('period');
  timePeriodErrorFieldMatcher = new CrossFieldErrorMatcher('timePeriod');

  isEdit = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: Availability, public builder: UntypedFormBuilder) {}

  ngOnInit(): void {
    const powerValidators = [Validators.required, Validators.min(0.01), Validators.max(999.99), CustomValidators.numberOfDecimals(0, 2)];
    this.form = this.builder.group({
      period: this.builder.group(
        {
          startDate: ['', Validators.required],
          endDate: ['', Validators.required]
        },
        [validPeriodValidator]
      ),
      timePeriod: this.builder.group(
        {
          startTime: ['', [Validators.required, wholeHoursValidator, Validators.pattern('\\d{1,2}:\\d{1,2}')]],
          endTime: ['', [Validators.required, wholeHoursValidator, Validators.pattern('\\d{1,2}:\\d{1,2}')]]
        },
        [validTimesValidator]
      ),
      direction: [AdjustmentDirection.UPWARDS, Validators.required],
      availabilityType: [AvailabilityType.ASSURED, Validators.required],
      power: this.builder.group(
        {
          value: ['0', powerValidators],
          unit: 'MW'
        },
        []
      )
    });
    this.form.get('availabilityType').valueChanges.subscribe((value) => {
      const powerValueControl = this.form.get('power').get('value') as UntypedFormControl;
      if (value === AvailabilityType.ASSURED) {
        powerValueControl.setValidators(powerValidators);
      } else {
        powerValueControl.setValidators(null);
        powerValueControl.setValue(0);
      }
      powerValueControl.updateValueAndValidity();
    });

    if (this.data) {
      this.form.patchValue(this.data);
      this.isEdit = true;
    }
  }

  isPeriodSensible(): boolean {
    const startDate: Moment = moment(this.form.get('period.startDate').value);
    const endDate: Moment = moment(this.form.get('period.endDate').value);

    // Not all data has been entered;
    if (!startDate || !endDate) {
      return true;
    }

    // If start date is not the first of the month
    if (startDate.date() !== 1) {
      return false;
    }

    // If end date is not the last of the month
    if (endDate.date() !== endDate.daysInMonth()) {
      return false;
    }

    return true;
  }

  isAssuredAvailability(): boolean {
    return this.form.get('availabilityType').value === AvailabilityType.ASSURED;
  }
}
