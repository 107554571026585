import { Device } from '../../../../app/shared/device/device.service';

export class LoadDeviceCommand {
  public static readonly type = '[Device] Load device data';

  constructor(public id: string, public showForm: boolean = false, public showDetails: boolean = false) {}
}

export class ResetDeviceCommand {
  public static readonly type = '[Device] Reset device action';

  constructor(public payload?: Partial<Device>) {}
}

export class DeviceLoadedEvent {
  public static readonly type = '[Device] Device loaded event';

  constructor(public payload: Device, public showForm: boolean = false, public showDetails: boolean = false) {}
}

export class SaveDeviceCommand {
  public static readonly type = '[Device] Save device data';

  constructor(public navigateAfterSave: boolean = true) {}
}

export class DeleteDeviceCommand {
  public static readonly type = '[Device] Delete device';

  constructor(public device: Device) {}
}
