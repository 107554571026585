import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { get } from 'lodash-es';
import { UntypedFormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { Capacity, Operation, OperationService } from 'flex-app-shared';

@Component({
  selector: 'app-operations',
  templateUrl: './operations.component.html',
  styleUrls: ['./operations.component.scss'],
  providers: [DatePipe]
})
export class OperationsComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

  showAll = true;

  readonly dataSource: MatTableDataSource<Operation> = new MatTableDataSource<Operation>([]);
  readonly displayedColumns: ReadonlyArray<string> = [
    'reference',
    'notificationTime',
    'startTime',
    'endTime',
    'direction',
    'requestedPower',
    'flags',
    'actions'
  ];

  constructor(private operationService: OperationService, private fb: UntypedFormBuilder, private datePipe: DatePipe) {}

  ngOnInit(): void {
    this.getOperations();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'requestedPower':
          return Capacity.asMW(data?.requestedPower);
        case 'startTime':
          return data.period.startDateTime;
        case 'endTime':
          return data.period.toDateTime;
        default:
          return get(data, sortHeaderId);
      }
    };

    this.setFilterPredicate();
  }

  private getOperations(): void {
    this.operationService.getAll().subscribe((operations) => (this.dataSource.data = operations.filter((o) => this.showAll || o.active)));
  }

  deactivate(operation: Operation): void {
    this.operationService.deactivate(operation.id).subscribe(() => this.getOperations());
  }

  activate(operation: Operation): void {
    this.operationService.activate(operation.id).subscribe(() => this.getOperations());
  }

  toggleShowAll(): void {
    this.showAll = !this.showAll;
    this.getOperations();
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }

  setFilterPredicate(): void {
    this.dataSource.filterPredicate = (data: Operation, filter: string) => {
      const { active, ended, manual, reference, direction, requestedPower, period, notificationTime, cancelled } = data;
      const notifTime = this.datePipe.transform(notificationTime, 'short');
      const startDateTime = this.datePipe.transform(period.startDateTime, 'short');
      const toDateTime = this.datePipe.transform(period.toDateTime, 'short');
      const isActive = active ? 'active' : 'inactive';
      const isEnded = ended ? 'ended' : 'running';
      const isManual = manual ? 'manual' : 'automatic';
      const isCancelled = cancelled ? 'cancelled' : '';
      const reqPower = requestedPower ? `${Capacity.asMW(requestedPower)} MW` : '';
      return `${reference} ${notifTime} ${startDateTime} ${toDateTime} ${direction} ${reqPower} ${isActive} ${isEnded} ${isManual} ${isCancelled}`
        .toLowerCase()
        .includes(filter.trim().toLowerCase());
    };
  }
}
