import { UnavailabilityView } from '../../../../app/shared/unavailability/unavailability';

export class LoadUnavailabilitiesCommand {
  public static readonly type = '[Unavailabilities] Fetch unavailabilities';
}

export class ResetUnavailabilitiesCommand {
  public static readonly type = '[Unavailabilities] Reset unavailabilities';
}

export class DeleteUnavailabilityCommand {
  public static readonly type = '[Unavailabilities] Delete unavailability';

  constructor(public unavailability: UnavailabilityView) {}
}
