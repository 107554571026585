import { Action, Selector, State, StateContext } from '@ngxs/store';
import { clone } from 'lodash-es';
import { tap } from 'rxjs/operators';
import { Device, DeviceService } from '../../../../app/shared/device/device.service';
import { EntityStateModel, FetchEntity, findParameterValue } from 'flex-app-shared';
import { CustomerLoadedEvent } from '../../../customers/states/customer/customer.actions';
import { LoadDeviceContactsCommand } from '../device-contacts/device-contacts.actions';
import { DevicesLoadedEvent, HideDeviceDetailsCommand, LoadDevicesCommand, ShowDeviceDetailsCommand } from './devices.actions';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';

export class DevicesStateModel extends EntityStateModel<ReadonlyArray<Device>> {
  model: ReadonlyArray<Device> = [];
  selectedCustomerId: string | null = null;
  expandedDeviceId: string | null = null;
}

@State({
  name: 'devices',
  defaults: new DevicesStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class DevicesState {
  @Selector()
  public static getDevices(state: DevicesStateModel): ReadonlyArray<Device> {
    return clone(state.model);
  }

  @Selector()
  public static isBusy(state: DevicesStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isInitialized(state: DevicesStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static isBusySending(state: DevicesStateModel): boolean {
    return state.isBusySending;
  }

  @Selector()
  public static error(state: DevicesStateModel): any {
    return state.sendError;
  }

  @Selector()
  public static getSelectedCustomerId(state: DevicesStateModel): string | null {
    return state.selectedCustomerId;
  }

  @Selector()
  public static getExpandedDeviceId(state: DevicesStateModel): string | null {
    return state.expandedDeviceId;
  }

  constructor(private activatedRoute: ActivatedRoute, private deviceService: DeviceService) {}

  @Action(DevicesLoadedEvent)
  setDevices({ setState, getState }: StateContext<DevicesStateModel>, { payload }: DevicesLoadedEvent): void {
    const state = getState();
    setState({
      ...state,
      model: payload
    });
  }

  @FetchEntity()
  @Action(LoadDevicesCommand)
  loadDevices({ dispatch }: StateContext<DevicesStateModel>): Observable<ReadonlyArray<Device>> | Subscription {
    const selectedCustomerId = findParameterValue(this.activatedRoute.snapshot, 'customerId');

    if (selectedCustomerId) {
      // TODO: we do not (yet) have an endpoint to get devices by customer id, so just get all and filter client side.
      // (we do have an endpoint that looks like get devices by customer id, but actually it is getting device CONTACTS.
      // TODO: new info: FA-804 introduced a working endpoint (getByCustomerId)
      return this.deviceService.getAll().subscribe((devices) => {
        dispatch(new DevicesLoadedEvent(devices.filter((device) => device.customerId === selectedCustomerId)));
      });
    } else {
      return this.deviceService.getAll().pipe(
        tap((devices) => {
          dispatch(new DevicesLoadedEvent(devices));
        })
      );
    }
  }

  @Action(CustomerLoadedEvent)
  setCustomerData({ setState, getState, dispatch }: StateContext<DevicesStateModel>, { payload }: CustomerLoadedEvent): void {
    setState({
      ...getState(),
      selectedCustomerId: payload.id
    });
  }

  @Action(ShowDeviceDetailsCommand)
  showDeviceDetails({ setState, getState, dispatch }: StateContext<DevicesStateModel>, { deviceId }: ShowDeviceDetailsCommand): void {
    setState({
      ...getState(),
      expandedDeviceId: deviceId
    });
    dispatch(new LoadDeviceContactsCommand({ deviceId }));
  }

  @Action(HideDeviceDetailsCommand)
  hideDeviceDetails({ setState, getState }: StateContext<DevicesStateModel>): void {
    setState({
      ...getState(),
      expandedDeviceId: null
    });
  }
}
