import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { get } from 'lodash-es';
import { DeviceFacade } from '../../store/devices/states/device/device.facade';
import { DevicesFacade } from '../../store/devices/states/devices/devices.facade';
import { Customer, CustomerService } from 'flex-app-shared';
import { Device } from '../shared/device/device.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';

const animation = '225ms cubic-bezier(0.4, 0.0, 0.2, 1)';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
  animations: [
    trigger('newDetailExpand', [
      transition(':enter', [style({ height: '0px', minHeight: '0' }), animate(animation, style({ height: '*' }))]),
      transition(':leave', [animate(animation, style({ height: '0px' }))])
    ])
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DevicesComponent implements OnInit, AfterViewInit, OnDestroy {
  customers: ReadonlyArray<Customer>;
  displayedColumns: ReadonlyArray<string> = ['externalId', 'customer', 'actions'];
  expandedColumns: ReadonlyArray<string> = ['expandedDetail'];
  dataSource: MatTableDataSource<Device> = new MatTableDataSource<Device>([]);
  expandedDeviceId: string | null;
  @Input()
  public readonly defaultPageSize: number = 30;
  @Input()
  public readonly isStandalone: boolean = false;
  @Input()
  public readonly isDashboard: boolean = false;
  private readonly onDestroy$ = new Subject<void>();
  @ViewChild(MatPaginator, { static: false })
  private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) private readonly sort: MatSort;

  constructor(
    private customerService: CustomerService,
    private devicesFacade: DevicesFacade,
    private deviceFacade: DeviceFacade,
    private router: Router,
    private location: Location
  ) {}

  goBack(): void {
    this.location.back();
  }

  ngOnInit(): void {
    this.devicesFacade.init();
    this.devicesFacade.devices$.pipe(takeUntil(this.onDestroy$)).subscribe((devices) => {
      this.dataSource.data = devices.concat();
    });

    if (this.isStandalone) {
      this.displayedColumns = this.displayedColumns.filter((it) => it !== 'customer');
    } else {
      this.customerService.getAll().subscribe((customers) => (this.customers = customers));
    }

    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      if (sortHeaderId === 'customer') {
        return this.findCustomerNameById(data.customerId);
      }
      return get(data, sortHeaderId);
    };

    this.devicesFacade.expandedDeviceId$.subscribe((expandedDeviceId) => {
      this.expandedDeviceId = expandedDeviceId;
    });

    this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.devicesFacade.init();
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  delete(device: Device): void {
    this.deviceFacade.delete(device);
  }

  edit(deviceId: string, event: MouseEvent): void {
    event.stopPropagation();
    this.devicesFacade.loadDevice(deviceId, true);
  }

  editContacts(deviceId: string): void {
    if (this.isStandalone) {
      this.devicesFacade.showDeviceDetails(deviceId);
    } else {
      this.router.navigate([`/devices/${deviceId}/contacts`]);
    }
  }

  findCustomerNameById(customerId: string): string {
    if (this.customers) {
      return this.customers.find((c) => c.id === customerId).name;
    }
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  toggleDeviceContacts(device: Device, expandedDeviceId: string, event: MouseEvent): void {
    event.stopPropagation();
    this.devicesFacade.toggleShowDeviceContacts(device.id, expandedDeviceId);
  }

  handleMenuButtonClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  trackBy(index: number, item: Device): any {
    return item?.id ?? index;
  }
}
