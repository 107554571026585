import { User, UserView } from '../../../../app/shared/user/user.service';

export class ResetUserCommand {
  public static readonly type = '[User] Reset user action';
}

export class LoadUserCommand {
  public static readonly type = '[User] Load user data';

  constructor(public id: string) {}
}

export class UserLoadedEvent {
  public static readonly type = '[User] User loaded event';

  constructor(public payload: User) {}
}

export class UpdateUserDetailsCommand {
  public static readonly type = '[User] Save groups for user';

  constructor(public payload: UserView) {}
}

export class CreateUserCommand {
  public static readonly type = '[User] Create user';

  constructor(public payload: UserView, public sendActivationEmail: boolean) {}
}

export class SendActivationEmailCommand {
  public static readonly type = '[User] Send activation email';

  constructor(public userId: string) {}
}

export class ResetPasswordCommand {
  public static readonly type = '[User] Reset password';

  constructor(public userId: string) {}
}

export class ToggleUserActiveCommand {
  public static readonly type = '[User] Toggle User Active';

  constructor(public userId: string, public active: boolean) {}
}
