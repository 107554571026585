<mat-card>
  <mat-card-title>FCR periods for battery: {{ battery?.name }} ({{ battery?.externalId }})</mat-card-title>
  <mat-card-content>
    <mat-toolbar class="filter-bar">
      <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
    </mat-toolbar>

    <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
      <ng-container matColumnDef="ean">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ean</th>
        <td mat-cell *matCellDef="let fcrPeriod">{{ fcrPeriod.ean }}</td>
      </ng-container>
      <ng-container matColumnDef="gridPointDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let fcrPeriod">{{ fcrPeriod.gridPointDescription }}</td>
      </ng-container>
      <ng-container matColumnDef="startTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start time</th>
        <td mat-cell *matCellDef="let fcrPeriod">{{ fcrPeriod.period.startDateTime | date: 'short' }}</td>
      </ng-container>
      <ng-container matColumnDef="endTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>End time</th>
        <td mat-cell *matCellDef="let fcrPeriod">{{ fcrPeriod.period.toDateTime | date: 'short' }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let fcrPeriod">
          <ph-flex-vertical-center>
            <button mat-icon-button matTooltip="Edit" routerLink="/batteries/{{ batteryId }}/fcr-periods/{{ fcrPeriod.id }}">
              <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
            </button>
            <button mat-icon-button matTooltip="Delete" class="remove" (click)="delete(fcrPeriod)">
              <mat-icon svgIcon="ic-old:trash-o"></mat-icon>
            </button>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="primary" routerLink="/batteries/{{ batteryId }}/fcr-periods/new">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:add"></mat-icon>
        <span>New FCR Period</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
