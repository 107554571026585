<mat-card class="operation-pool">
  <mat-card-header>
    <mat-card-title>Operation pool</mat-card-title>
    <mat-card-subtitle>{{ (operationHasPool$ | async) ? 'Already' : 'Not yet' }} sent to devices </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <table mat-table matSort [dataSource]="dataSource">
      <ng-container matColumnDef="customerName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer name</th>
        <td mat-cell *matCellDef="let operationPoolGridPointPeriod">
          {{ operationPoolGridPointPeriod.customerName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="ean">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>EAN</th>
        <td mat-cell *matCellDef="let operationPoolGridPointPeriod">
          {{ operationPoolGridPointPeriod.gridPointEan }}
        </td>
      </ng-container>
      <ng-container matColumnDef="gridPointDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let operationPoolGridPointPeriod">
          {{ operationPoolGridPointPeriod.gridPointDescription }}
        </td>
      </ng-container>
      <ng-container matColumnDef="startDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Participation start</th>
        <td mat-cell *matCellDef="let operationPoolGridPointPeriod">
          {{ operationPoolGridPointPeriod.period.startDateTime.date | date: 'shortTime' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="toDateTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Participation end</th>
        <td mat-cell *matCellDef="let operationPoolGridPointPeriod">
          {{ operationPoolGridPointPeriod.period.toDateTime.date | date: 'shortTime' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="gridPointPower">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Power (MW)</th>
        <td mat-cell *matCellDef="let operationPoolGridPointPeriod">
          {{ operationPoolGridPointPeriod.power | capacityNumber: 'MW' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="availabilityType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Availability Type</th>
        <td mat-cell *matCellDef="let operationPoolGridPointPeriod" [ngSwitch]="operationPoolGridPointPeriod.availabilityType">
          <ng-container *ngSwitchCase="AvailabilityType.ASSURED">Assured</ng-container>
          <ng-container *ngSwitchCase="AvailabilityType.VOLUNTARY">Voluntary</ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="measurementCompanyName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Measurement Company</th>
        <td mat-cell *matCellDef="let operationPoolGridPointPeriod">
          {{ operationPoolGridPointPeriod.measurementCompanyName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="measurementDataStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Measurement data status</th>
        <td mat-cell *matCellDef="let operationPoolGridPointPeriod" [ngSwitch]="operationPoolGridPointPeriod.measurementDataStatus">
          <ng-container *ngSwitchCase="'NO_DATA'">No data</ng-container>
          <ng-container *ngSwitchCase="'FIVE_MINUTE_DATA'">5-min data</ng-container>
          <ng-container *ngSwitchCase="'FIFTEEN_MINUTE_DATA'">15-min data</ng-container>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="primary" [disabled]="buttonRetrieveMeasurementsDisabled" (click)="retrieveMeasurements()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:source-external"></mat-icon>
        <span>Retrieve measurements</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="primary" (click)="downloadData()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:download"></mat-icon>
        <span>Download pool data</span>
      </ph-flex-vertical-center>
    </button>
    <span matTooltip="Send activated pool to TenneT">
      <button mat-flat-button color="cta" [disabled]="buttonSendToTennetDisabled" (click)="sendToTennet()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:paper-plane"></mat-icon>
          <span>Send pool to TenneT</span>
        </ph-flex-vertical-center>
      </button>
    </span>
  </mat-card-actions>
</mat-card>
