import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { IncidentReserveInvoiceResultsState } from './incident-reserve-invoice-results.state';
import {
  PublishIncidentReserveInvoiceResultsCommand,
  RecalculateIncidentReserveInvoiceResultsCommand
} from './incident-reserve-invoice-results.actions';

@Injectable({
  providedIn: 'root'
})
export class IncidentReserveInvoiceResultsFacade {
  constructor(private store: Store) {}

  data$ = this.store.select(IncidentReserveInvoiceResultsState.data);
  busy$ = this.store.select(IncidentReserveInvoiceResultsState.busy);
  canRecalculate$ = this.store.select(IncidentReserveInvoiceResultsState.canRecalculate);

  publish(): void {
    this.store.dispatch(new PublishIncidentReserveInvoiceResultsCommand());
  }

  recalculate(): void {
    this.store.dispatch(new RecalculateIncidentReserveInvoiceResultsCommand());
  }
}
