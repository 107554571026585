import { DownloadGridPointsCommand, EntityStateModel, FetchEntity, GridPointsStateModel, Product } from 'flex-app-shared';
import { ServiceAgreement } from 'flex-app-shared';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { clone, isNil } from 'lodash-es';
import { ServiceAgreementService } from 'flex-app-shared';
import {
  DownloadServiceAgreementsCommand,
  FindAllServiceAgreementsCommand,
  FindServiceAgreementsByCustomerIdAndPeriodCommand,
  ResetServiceAgreementsCommand
} from './service-agreements.actions';
import { tap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { CustomerLoadedEvent } from '../../../customers/states/customer/customer.actions';
import { findParameterValue } from 'flex-app-shared';
import { ActivatedRoute } from '@angular/router';
import { Injectable } from '@angular/core';

export class ServiceAgreementsStateModel extends EntityStateModel<ServiceAgreement[]> {
  model: ServiceAgreement[] = [];
  selectedCustomerId: string | null = null;
  isBusyDownloading: boolean = false;
}

@State({
  name: 'serviceAgreements',
  defaults: new ServiceAgreementsStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class ServiceAgreementsState {
  @Selector()
  public static getServiceAgreements(state: ServiceAgreementsStateModel): ServiceAgreement[] {
    return clone(state.model);
  }

  @Selector()
  public static getIncidentReserveServiceAgreements(state: ServiceAgreementsStateModel): ServiceAgreement[] {
    return clone(state.model).filter((serviceAgreement) => serviceAgreement.product === Product.R3_EMERGENCY_POWER);
  }

  @Selector()
  public static isBusy(state: ServiceAgreementsStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isInitialized(state: ServiceAgreementsStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static isBusySending(state: ServiceAgreementsStateModel): boolean {
    return state.isBusySending;
  }

  @Selector()
  public static error(state: ServiceAgreementsStateModel): any {
    return state.sendError;
  }

  @Selector()
  public static getSelectedCustomerId(state: ServiceAgreementsStateModel): string {
    return state.selectedCustomerId;
  }

  @Selector()
  public static isBusyDownloading(state: ServiceAgreementsStateModel): boolean {
    return state.isBusyDownloading;
  }

  constructor(private activatedRoute: ActivatedRoute, private serviceAgreementService: ServiceAgreementService) {}

  @Action(CustomerLoadedEvent)
  setCustomerData({ setState, getState, dispatch }: StateContext<ServiceAgreementsStateModel>, { payload }: CustomerLoadedEvent): void {
    setState({
      ...getState(),
      selectedCustomerId: payload.id
    });
  }

  @FetchEntity()
  @Action(FindServiceAgreementsByCustomerIdAndPeriodCommand)
  findServiceAgreements(
    { setState, getState }: StateContext<ServiceAgreementsStateModel>,
    { customerId, period }: FindServiceAgreementsByCustomerIdAndPeriodCommand
  ): Observable<ServiceAgreement[]> {
    let observable;
    if (customerId && period.startDateTime && period.startDateTime.date && period.toDateTime && period.toDateTime.date) {
      observable = this.serviceAgreementService.getByCustomerIdAndPeriod(customerId, period);
    } else if (getState().model.length === 0) {
      return;
    } else {
      observable = from([[]]);
    }
    return observable.pipe(
      tap((serviceAgreements: ServiceAgreement[]) => {
        setState({
          ...getState(),
          model: serviceAgreements
        });
      })
    );
  }

  @FetchEntity()
  @Action(FindAllServiceAgreementsCommand)
  findAll({ setState, getState }: StateContext<ServiceAgreementsStateModel>): Observable<ServiceAgreement[]> {
    const selectedCustomerId = findParameterValue(this.activatedRoute.snapshot, 'customerId');
    return this.serviceAgreementService.getAll().pipe(
      tap((serviceAgreements: ServiceAgreement[]) => {
        const model = isNil(selectedCustomerId)
          ? serviceAgreements
          : serviceAgreements.filter((sa) => sa.customerId === selectedCustomerId);
        setState({
          ...getState(),
          model
        });
      })
    );
  }

  @Action(ResetServiceAgreementsCommand)
  resetGridPoints({ setState }: StateContext<ServiceAgreementsStateModel>): void {
    setState(new ServiceAgreementsStateModel());
  }

  @Action(DownloadServiceAgreementsCommand)
  downloadServiceAgreements({ setState, getState }: StateContext<ServiceAgreementsStateModel>): Observable<any> {
    setState({
      ...getState(),
      isBusyDownloading: true
    });

    return this.serviceAgreementService.downloadGridPointOverview().pipe(
      tap({
        complete: () =>
          setState({
            ...getState(),
            isBusyDownloading: false
          })
      })
    );
  }
}
