import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  EntityState,
  EntityStateModel,
  FetchEntity,
  findParameterValue,
  MessageService,
  RouterInitializer,
  SaveEntity,
  AsyncMessagingService
} from 'flex-app-shared';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Recipient } from '../../../../app/shared/recipient/recipient';
import { RecipientService } from '../../../../app/shared/recipient/recipient.service';
import { LoadRecipientCommand, RecipientLoadedEvent, ResetRecipientCommand, SaveRecipientCommand } from './recipient.actions';

export class RecipientStateModel extends EntityStateModel<Recipient> {
  model: Recipient = {
    id: undefined,
    pronoun: '',
    firstName: '',
    lastNamePrefix: '',
    lastName: '',
    mobileNumber: '',
    email: '',
    isEmployee: false,
    isMonthlyParticipationEmail: false,
    customerId: undefined,
    useMobileNumber: true,
    useEmail: false
  };
}

@State<RecipientStateModel>({
  name: 'recipient',
  defaults: new RecipientStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class RecipientState extends EntityState<Recipient> implements RouterInitializer<RecipientStateModel> {
  constructor(
    private recipientService: RecipientService,
    private location: Location,
    private messageService: MessageService,
    private asyncMessagingService: AsyncMessagingService,
    private snackBar: MatSnackBar,
    store: Store
  ) {
    super(store);
  }

  @Selector()
  public static getRecipient(state: RecipientStateModel): Recipient {
    return {
      ...state.model
    };
  }

  @Selector()
  public static isBusy(state: RecipientStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isInitialized(state: RecipientStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static isBusySending(state: RecipientStateModel): boolean {
    return state.isBusySending;
  }

  @Selector()
  public static error(state: RecipientStateModel): string | null {
    return state.sendError;
  }

  @Action(RouterNavigation)
  routerInit({ dispatch, getState, setState }: StateContext<RecipientStateModel>, { routerState }: RouterNavigation): void {
    const recipientId = findParameterValue(routerState, 'recipientId');
    const customerId = findParameterValue(routerState, 'customerId');
    const state = getState();

    if (recipientId && recipientId !== 'new') {
      // Fetch recipient data for id
      dispatch(new LoadRecipientCommand(recipientId));
    } else if (getState().model.id || recipientId === 'new') {
      // If we had recipient data, remove it
      dispatch(new ResetRecipientCommand({ customerId }));
    } else if (state.model.customerId !== customerId) {
      setState({ ...state, model: { ...state.model, customerId } });
    }
  }

  @Action(ResetRecipientCommand)
  resetRecipientData({ setState }: StateContext<RecipientStateModel>, { payload }: ResetRecipientCommand): void {
    const newState = new RecipientStateModel();
    setState({ ...newState, model: { ...newState.model, ...payload } });
  }

  @FetchEntity()
  @Action(LoadRecipientCommand)
  initRecipientData(ctx: StateContext<RecipientStateModel>, { id }: LoadRecipientCommand): Observable<Recipient> {
    return this.recipientService.getById(id).pipe(
      tap(
        (recipient) => ctx.dispatch(new RecipientLoadedEvent(recipient)),
        (err) => this.messageService.error(err.message)
      )
    );
  }

  @Action(RecipientLoadedEvent)
  setRecipientData({ setState, getState }: StateContext<RecipientStateModel>, { payload }: RecipientLoadedEvent): void {
    const state = getState();
    setState({
      ...state,
      model: {
        ...state.model,
        ...payload
      }
    });
  }

  @SaveEntity()
  @Action(SaveRecipientCommand)
  saveRecipient(ctx: StateContext<RecipientStateModel>, action: SaveRecipientCommand): Observable<Recipient> {
    const recipient = action.payload;
    const save$ = recipient.id ? this.recipientService.update(recipient as Recipient) : this.recipientService.add(recipient as Recipient);
    return save$.pipe(
      tap(
        (result) => {
          this.snackBar.open('Welcome SMS/email sent to recipient (if applicable)', 'ok', { duration: 5000 });
          if (action.navigateAfterSave) {
            this.location.back();
          }
        },
        (error) => {
          this.messageService.error(error);
        }
      )
    );
  }
}
