<mat-card>
  <mat-card-content>
    <form [formGroup]="form" class="flex-row filter-bar ph-flex-mat-form-field-group-inline">
      <ng-container formGroupName="period">
        <mat-form-field class="col-1">
          <mat-label>Start date</mat-label>
          <input matInput [matDatepicker]="startDateDatepicker" formControlName="startDate" />
          <mat-datepicker-toggle matSuffix [for]="startDateDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #startDateDatepicker></mat-datepicker>
          <mat-error *ngIf="form.get('period.startDate').errors?.required"> Start date is required. </mat-error>
        </mat-form-field>

        <mat-form-field class="col-1">
          <mat-label>End date</mat-label>
          <input matInput [matDatepicker]="endDateDatepicker" formControlName="endDate" [errorStateMatcher]="crossFieldErrorMatcher" />
          <mat-datepicker-toggle matSuffix [for]="endDateDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #endDateDatepicker></mat-datepicker>
          <mat-error *ngIf="form.get('period.endDate').errors?.required"> End date is required. </mat-error>
          <mat-error *ngIf="form.get('period').errors?.periodWrong"> End date must be after start date. </mat-error>
        </mat-form-field>
      </ng-container>

      <mat-form-field class="col-1">
        <mat-label>Direction</mat-label>
        <mat-select formControlName="direction">
          <mat-option value="UPWARDS">Upwards</mat-option>
          <mat-option value="DOWNWARDS">Downwards</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>

<mat-card>
  <highcharts-chart
    [Highcharts]="Highcharts"
    [constructorType]="chartConstructor"
    [options]="overallChartOptions"
    (chartInstance)="chartInstanceCallback($event)"
    [(update)]="updateFlag"
    style="width: 100%; height: 400px; display: block"
  >
  </highcharts-chart>
</mat-card>

<mat-card>
  <highcharts-chart
    [Highcharts]="Highcharts"
    [constructorType]="chartConstructor"
    [options]="periodChartOptions"
    [(update)]="updateFlag"
    style="width: 100%; height: 400px; display: block"
  >
  </highcharts-chart>
</mat-card>

<mat-card>
  <mat-card-content>
    <mat-toolbar class="filter-bar">
      <mat-card-actions>
        <button mat-stroked-button (click)="downloadData()" [disabled]="downloadDataButtonDisabled()">
          <ph-flex-vertical-center>
            <mat-icon svgIcon="ic-old:download"></mat-icon>
            <span>Download pool data</span>
          </ph-flex-vertical-center>
        </button>
      </mat-card-actions>
    </mat-toolbar>
    <table mat-table [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
        <td mat-cell *matCellDef="let row">{{ row.customer }}</td>
      </ng-container>
      <ng-container matColumnDef="serviceAgreement">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Service agreement</th>
        <td mat-cell *matCellDef="let row">{{ row.serviceAgreement }}</td>
      </ng-container>
      <ng-container matColumnDef="upwardsCapacity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Power Upwards (MW)</th>
        <td mat-cell *matCellDef="let row">
          {{ getDisplayedUpwardsCapacity(row) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="downwardsCapacity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Power Downwards (MW)</th>
        <td mat-cell *matCellDef="let row">
          {{ getDisplayedDownwardsCapacity(row) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="voluntaryR3Participation">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Voluntary participation</th>
        <td mat-cell *matCellDef="let row" [ngSwitch]="row.voluntaryR3Participation">
          <ph-flex-vertical-center>
            <mat-icon *ngSwitchCase="true" class="status-success" svgIcon="ic-old:check"></mat-icon>
            <mat-icon *ngSwitchCase="false" class="status-passive" svgIcon="ic-old:close"> </mat-icon>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
  </mat-card-content>
</mat-card>
