import { State } from '@ngxs/store';
import { UnavailabilityState } from './states/unavailability/unavailability.state';
import { UnavailabilitiesState } from './states/unavailabilities/unavailabilities.state';
import { AggregatedUnavailabilitiesState } from './states/aggregated-unavailabilities/aggregated-unavailabilities.state';
import { Injectable } from '@angular/core';

export const UnavailabilityStates = [UnavailabilityState, UnavailabilitiesState, AggregatedUnavailabilitiesState];

@State({
  name: 'unavailabilityStateModule',
  children: UnavailabilityStates
})
@Injectable({
  providedIn: 'root'
})
export class UnavailabilityStateModule {}
