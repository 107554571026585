import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PoolPeriod, PoolPeriodView } from '../../../../app/shared/pool/pool.service';
import { PoolChangedEvent } from '../pool/pool.actions';
import { ApplyPoolPeriodDialogValueCommand, ClosePoolPeriodDialogCommand, RegisterPoolPeriodDialogIdCommand } from './pool-period.actions';
import { MatDialog } from '@angular/material/dialog';
import { PeriodScheduler } from './pool-period-scheduler';
import { Injectable } from '@angular/core';

export class PoolPeriodStateModel {
  dialogRefId: string = null;
  poolPeriods: PoolPeriod[] = [];
  isEclipsed = false;
}

@State<PoolPeriodStateModel>({
  name: 'poolPeriodState',
  defaults: new PoolPeriodStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class PoolPeriodState {
  @Selector()
  static isEclipsed(state: PoolPeriodStateModel): boolean {
    return state.isEclipsed;
  }

  @Action(PoolChangedEvent)
  setPool({ getState, setState, dispatch }: StateContext<PoolPeriodStateModel>, { payload }: PoolChangedEvent): void {
    if (!payload.poolPeriods) {
      return;
    }
    setState({
      ...getState(),
      poolPeriods: payload.poolPeriods,
      isEclipsed: false
    });
  }

  @Action(RegisterPoolPeriodDialogIdCommand)
  registerPoolPeriodDialogId(
    { getState, setState, dispatch }: StateContext<PoolPeriodStateModel>,
    { dialogId }: RegisterPoolPeriodDialogIdCommand
  ): void {
    setState({
      ...getState(),
      dialogRefId: dialogId,
      isEclipsed: false
    });
  }

  @Action(ApplyPoolPeriodDialogValueCommand)
  applyPoolPeriodDialogValue(
    { getState, setState, dispatch }: StateContext<PoolPeriodStateModel>,
    { poolPeriod }: ApplyPoolPeriodDialogValueCommand
  ): void {
    const scheduler = new PeriodScheduler(getState().poolPeriods.map((pp) => PoolPeriodView.deserialize(pp)));
    scheduler.addPeriod(poolPeriod);
    setState({
      ...getState(),
      isEclipsed: scheduler.hasEclipsedPeriod()
    });
  }

  @Action(ClosePoolPeriodDialogCommand)
  closePoolPeriodDialog({ getState, setState }: StateContext<PoolPeriodStateModel>): void {
    const dialogRef = this.matDialog.getDialogById(getState().dialogRefId);
    if (dialogRef) {
      dialogRef.close();
      setState({
        ...getState(),
        dialogRefId: null
      });
    }
  }

  constructor(private matDialog: MatDialog) {}
}
