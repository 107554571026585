<mat-card>
  <mat-card-header>
    <mat-card-title>Day-ahead market deals</mat-card-title>
  </mat-card-header>
  <mat-card-actions class="day-ahead-market-actions">
    <ng-container [formGroup]="form">
      <mat-form-field subscriptSizing="dynamic">
        <ph-flex-autocomplete
          phFlexCustomerDataSource
          formControlName="customerId"
          [shouldOnlyEmitIds]="true"
          [shouldEmitNull]="true"
          #selectedCustomer
        ></ph-flex-autocomplete>
        <mat-label>Customer</mat-label>
      </mat-form-field>
      <mat-form-field subscriptSizing="dynamic">
        <ph-flex-autocomplete
          phFlexGridPointDataSource
          [phFlexDataSourceFilterByCustomerId]="form.get('customerId').valid && form.get('customerId').value"
          phFlexDataSourceFilterClearValueIfNotSelectable
          phFlexDataSourceFilterRemoveSelectedValue
          formControlName="gridPointId"
          [shouldOnlyEmitIds]="true"
          [shouldEmitNull]="true"
          #selectedGridPoint
        ></ph-flex-autocomplete>
        <mat-label>Gridpoint</mat-label>
      </mat-form-field>
    </ng-container>
    <ph-flex-date-range [formControl]="form.get('period')" subscriptSizing="dynamic"></ph-flex-date-range>
    <button mat-stroked-button (click)="getDayAheadDeals()">
      <ph-flex-vertical-center
        *ngIf="dayAheadDealsReceivedResult && !dayAheadDealsError && !retrievingDayAheadDeals"
        class="status-success"
      >
        <mat-icon *ngIf="dayAheadDealsReceivedResult.done" svgIcon="ic-old:check-circle-o"></mat-icon>
        <span
          >Retrieved {{ dayAheadDealsReceivedResult?.dealsReceived }} day ahead deals for {{ dayAheadDealsReceivedResult?.eansTotal }} grid
          points</span
        >
      </ph-flex-vertical-center>
      <ph-flex-vertical-center *ngIf="!dayAheadDealsReceivedResult && !retrievingDayAheadDeals"
        ><mat-icon svgIcon="ic-old:source-external"></mat-icon><span>Retrieve day ahead deals</span>
      </ph-flex-vertical-center>
      <ph-flex-vertical-center
        *ngIf="retrievingDayAheadDeals"
        class="spinner-button"
        matTooltip="Retrieved {{
          dayAheadDealsReceivedResult ? dayAheadDealsReceivedResult.dealsReceived : '0'
        }} day ahead deals (grid points to process: {{ dayAheadDealsReceivedResult ? dayAheadDealsReceivedResult.eansToProcess : '?' }})"
      >
        <mat-spinner diameter="19"></mat-spinner>
        <span>Retrieving deals...</span>
      </ph-flex-vertical-center>
      <ph-flex-vertical-center
        *ngIf="dayAheadDealsError"
        matTooltip="Retrieved {{ dayAheadDealsReceivedResult?.dealsReceived }} day ahead deals for {{
          dayAheadDealsReceivedResult?.eansTotal
        }} grid points, then got error: {{ dayAheadDealsReceivedResult?.message | json }}"
        class="status-alert"
      >
        <mat-icon svgIcon="ic-old:cancel-circle-o"></mat-icon>
        <span>Error while retrieving day ahead deals!</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
