import { Customer } from 'flex-app-shared';

export class ResetCustomerCommand {
  public static readonly type = '[Customer] Reset customer action';

  constructor() {}
}

export class LoadCustomerCommand {
  public static readonly type = '[Customer] Load customer data';

  constructor(public id: string) {}
}

export class CustomerLoadedEvent {
  public static readonly type = '[Customer] Customer loaded event';

  constructor(public payload: Customer) {}
}

export class SaveCustomerCommand {
  public static readonly type = '[Customer] Save customer data';

  constructor(public navigateAfterSave: boolean = true) {}
}
