import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export class PoolAutoSendResult {
  success: boolean = true;
  message: string = '';
}

export class PoolLastAutoSendDateTimeResult {
  lastAutoSendDateTime: string;
}

@Injectable({
  providedIn: 'root'
})
export class TsoPoolService {
  constructor(private http: HttpClient) {}

  autoSendPool(): Observable<PoolAutoSendResult> {
    return this.http.put<PoolAutoSendResult>('/api/v1/pools/auto-send', {});
  }

  lastAutoSendDateTime(): Observable<PoolLastAutoSendDateTimeResult> {
    return this.http.get<PoolLastAutoSendDateTimeResult>('/api/v1/pools/last-auto-send-datetime');
  }
}
