import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-measurement-data-upload-remarks',
  templateUrl: './measurement-data-upload-remarks.component.html',
  styleUrls: ['./measurement-data-upload-remarks.component.scss']
})
export class MeasurementDataUploadRemarksComponent {
  @Input()
  remarks: any;

  constructor() {}

  error(key: string): any {
    return this.remarks && this.remarks[key];
  }
}
