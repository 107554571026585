import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { MeasurementDataUploadActivate, MeasurementDataUploadDeactivate } from './measurement-data-upload.actions';

@Injectable({ providedIn: 'root' })
export class ActivateMeasurementDataUploadState {
  constructor(private store: Store) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.store.dispatch(new MeasurementDataUploadActivate());
    return true;
  }
}

@Injectable({ providedIn: 'root' })
export class DeactivateMeasurementDataUploadState {
  constructor(private store: Store) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.store.dispatch(new MeasurementDataUploadDeactivate());
    return true;
  }
}
