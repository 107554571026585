import { Pool, PoolPeriodView } from '../../../../app/shared/pool/pool.service';
import { DatePeriodView } from 'flex-app-shared';

export class ResetPoolCommand {
  public static readonly type = '[Pool] Reset pool command';
}

export class LoadPoolCommand {
  public static readonly type = '[Pool] Load pool command';

  constructor() {}
}

export class PoolChangedEvent {
  public static readonly type = '[Pool] Pool changed event';

  constructor(public payload: Partial<Pool>) {}
}

export class SavePoolCommand {
  public static readonly type = '[Pool] Save pool command';

  constructor() {}
}

export class ApplyPoolPeriodCommand {
  public static readonly type = '[Pool] Apply pool period command';

  constructor(public payload: PoolPeriodView) {}
}

export class LoadPoolAvailabilitiesCommand {
  public static readonly type = '[Pool] Load pool availabilities command';

  constructor(public period: DatePeriodView) {}
}

export class SendPoolCommand {
  public static readonly type = '[Pool] Send pool configuration command';

  constructor() {}
}
