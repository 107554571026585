import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  LoadServiceAgreementCommand,
  ResetServiceAgreementCommand,
  SaveServiceAgreementCommand,
  ServiceAgreementLoadedEvent
} from './service-agreement.actions';
import { Product, ServiceAgreementService } from 'flex-app-shared';
import { RouterNavigation } from '@ngxs/router-plugin';
import { ServiceAgreement } from 'flex-app-shared';
import {
  Capacity,
  EntityState,
  EntityStateModel,
  FetchEntity,
  findParameterValue,
  Period,
  RouterInitializer,
  SaveEntity
} from 'flex-app-shared';
import moment from 'moment';
import { Moment } from 'moment';
import { tap } from 'rxjs/operators';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export class ServiceAgreementStateModel extends EntityStateModel<ServiceAgreement> {
  model: ServiceAgreement = {
    customerId: '',
    customerName: '',
    systemReference: '',
    reference: '',
    product: Product.R3_EMERGENCY_POWER,
    period: calculateInitialPeriod(),
    maxCapacityUpward: Capacity.MW(0),
    maxCapacityDownward: Capacity.MW(0),
    availabilityFeePercBaseLoad: 70,
    availabilityFeePercTimeTable: 50,
    gridPoints: [],
    availabilitySchedule: undefined,
    incidentReservePreQualified: undefined
  };
  oldModel: ServiceAgreement | undefined = undefined;
}

@State<ServiceAgreementStateModel>({
  name: 'serviceAgreement',
  defaults: new ServiceAgreementStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class ServiceAgreementState extends EntityState<ServiceAgreement> implements RouterInitializer<ServiceAgreementStateModel> {
  constructor(
    private serviceAgreementService: ServiceAgreementService,
    private snackBar: MatSnackBar,
    private location: Location,
    store: Store
  ) {
    super(store);
  }

  @Selector()
  public static getServiceAgreement(state: ServiceAgreementStateModel): ServiceAgreement {
    return { ...state.model };
  }

  @Selector()
  public static isBusy(state: ServiceAgreementStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isInitialized(state: ServiceAgreementStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static isBusySending(state: ServiceAgreementStateModel): boolean {
    return state.isBusySending;
  }

  @Selector()
  public static error(state: ServiceAgreementStateModel): string | null {
    return state.sendError;
  }

  @Action(RouterNavigation)
  routerInit({ dispatch, getState, setState }: StateContext<ServiceAgreementStateModel>, { routerState }: RouterNavigation): void {
    const serviceAgreementId = findParameterValue(routerState, 'serviceAgreementId');
    const customerId = findParameterValue(routerState, 'customerId');
    const state = getState();

    if (serviceAgreementId && serviceAgreementId !== 'new') {
      // Fetch serviceAgreement data for id
      dispatch(new LoadServiceAgreementCommand(serviceAgreementId));
    } else if (state.model.id || serviceAgreementId === 'new') {
      // If we had serviceAgreement data, remove it
      dispatch(new ResetServiceAgreementCommand({ customerId }));
    } else if (state.model.customerId !== customerId) {
      setState({
        ...state,
        model: { ...state.model, customerId, period: calculateInitialPeriod() }
      });
    }
  }

  @Action(ResetServiceAgreementCommand)
  resetCustomers({ setState }: StateContext<ServiceAgreementStateModel>, { payload }: ResetServiceAgreementCommand): void {
    const newState = new ServiceAgreementStateModel();
    setState({ ...newState, model: { ...newState.model, ...payload } });
  }

  @FetchEntity()
  @Action(LoadServiceAgreementCommand)
  loadServiceAgreement(
    { setState, getState, dispatch }: StateContext<ServiceAgreementStateModel>,
    { id }: LoadServiceAgreementCommand
  ): Observable<ServiceAgreement> {
    return this.serviceAgreementService.getById(id).pipe(tap((res) => dispatch(new ServiceAgreementLoadedEvent(res))));
  }

  @Action(ServiceAgreementLoadedEvent)
  setServiceAgreement({ setState, getState }: StateContext<ServiceAgreementStateModel>, { payload }: ServiceAgreementLoadedEvent): void {
    const state = getState();
    setState({
      ...state,
      model: payload,
      oldModel: payload
    });
  }

  @SaveEntity()
  @Action(SaveServiceAgreementCommand)
  saveServiceAgreement(
    { setState, getState, dispatch }: StateContext<ServiceAgreementStateModel>,
    { payload, navigateAfterSave }: SaveServiceAgreementCommand
  ): Observable<ServiceAgreement> {
    const obs$ = !payload.id ? this.serviceAgreementService.add(payload) : this.serviceAgreementService.update(payload);
    return obs$.pipe(
      tap(() => {
        navigateAfterSave
          ? this.location.back()
          : this.snackBar.open(this.getMessageForSubmit(payload.id, payload, getState().oldModel), null, {
              duration: 5000,
              panelClass: 'default-simple-snackbar'
            });
      })
    );
  }

  private getMessageForSubmit(id: string, serviceAgreement: Partial<ServiceAgreement>, oldModel: ServiceAgreement): string {
    if (!id) {
      return 'Service agreement created.';
    } else {
      let message = 'Service agreement saved.';
      if (
        moment(oldModel.period.startDate).isBefore(serviceAgreement.period.startDate) ||
        moment(oldModel.period.endDate).isAfter(serviceAgreement.period.endDate)
      ) {
        message = 'The availability schedule was shortened to match the new service agreement period.';
      }
      if (
        moment(oldModel.period.startDate).isAfter(serviceAgreement.period.startDate) ||
        moment(oldModel.period.endDate).isBefore(serviceAgreement.period.endDate)
      ) {
        message = 'The availability schedule was extended to match the new service agreement period.';
      }
      if (
        Capacity.asKW(oldModel.maxCapacityUpward) !== Capacity.asKW(serviceAgreement.maxCapacityUpward) ||
        Capacity.asKW(oldModel.maxCapacityDownward) !== Capacity.asKW(serviceAgreement.maxCapacityDownward)
      ) {
        message =
          (message !== '' ? message + ' ' : '') +
          'The availability schedule was not updated to match the new service agreement capacity. ' +
          'Please update the values manually if necessary.';
      }
      return message;
    }
  }
}

function calculateInitialPeriod(): Period {
  const startDate = moment();
  startDate.add(1, 'month').startOf('month');
  return new Period(startDate.toDate(), getCalculatedEndDate(startDate).toDate());
}

function getCalculatedEndDate(value: Moment): Moment {
  const endDate = moment(value);
  endDate.add(1, 'year');
  endDate.subtract(1, 'month');
  endDate.endOf('month');
  return endDate;
}
