import { Injectable } from '@angular/core';
import { Capacity, PaginatedResponse } from 'flex-app-shared';
import { JsonProperty, JsonObject } from 'typescript-json-serializer';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Moment } from 'moment';

@JsonObject()
export class BatteryMeasurement {
  @JsonProperty()
  timestamp: Date;
  @JsonProperty()
  activePowerIn: Capacity;
  @JsonProperty()
  activePowerOut: Capacity;
  @JsonProperty()
  activePowerConverter: Capacity;
  @JsonProperty()
  stateOfCharge: number;
  @JsonProperty()
  frequencyIn: number;
  @JsonProperty()
  frequencyOut: number;
}

@Injectable({
  providedIn: 'root'
})
export class BatteryMeasurementService {
  constructor(private http: HttpClient) {}

  getBatteries(date: Moment): Observable<string[]> {
    const dateString = date.format('YYYY-MM-DD');
    return this.http.get<any[]>(`/api/v1/battery-measurements/batteries/${dateString}`);
  }

  getMeasurements(
    pageIndex: number,
    pageSize: number,
    sort: string,
    date: Moment,
    batteryId: string
  ): Observable<PaginatedResponse<BatteryMeasurement>> {
    const dateString = date.format('YYYY-MM-DD');
    return this.http.get<PaginatedResponse<BatteryMeasurement>>(
      `/api/v1/battery-measurements/${batteryId}/${dateString}?page=${pageIndex}&pageSize=${pageSize}&sort=${sort}`
    );
  }

  getAggregatedMeasurements(date: Moment, batteryId: string): Observable<BatteryMeasurement[]> {
    const dateString = date.format('YYYY-MM-DD');
    return this.http.get<BatteryMeasurement[]>(`/api/v1/battery-measurements/${batteryId}/${dateString}?aggregated`);
  }
}
