import { Device } from '../../../../app/shared/device/device.service';

export class LoadDevicesCommand {
  public static readonly type = '[Devices] Load devices data';
}

export class DevicesLoadedEvent {
  public static readonly type = '[Devices] Devices loaded event';

  constructor(public payload: ReadonlyArray<Device>) {}
}

export class ShowDeviceDetailsCommand {
  public static readonly type = '[Device] Show Device details';

  constructor(public deviceId: string) {}
}

export class HideDeviceDetailsCommand {
  public static readonly type = '[Device] Hide Device details';
}
