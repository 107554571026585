import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MeasurementDataService } from '../../shared/measurement-data/measurement-data.service';

@Component({
  selector: 'app-fetch-measurements',
  templateUrl: './fetch-measurements.component.html',
  styleUrls: ['./fetch-measurements.component.scss']
})
export class FetchMeasurementsComponent implements OnDestroy {
  sending: boolean = false;
  success: boolean = false;
  label: string = 'Retrieve REM Measurements';
  tooltip: string = '';
  private readonly onDestroy$ = new Subject<void>();

  constructor(private service: MeasurementDataService) {}

  fetchMeasurements(): void {
    this.sending = true;
    this.success = false;
    this.label = 'Starting...';
    this.tooltip = '';

    this.service
      .fetchMeasurements()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe({
        next: (result) => {
          setTimeout(() => {
            this.sending = false;
            this.success = true;
            this.label = 'Started REM Measurement retrieval';
            this.tooltip = '';
          }, 1000);
        },
        error: (error) => {
          this.sending = false;
          this.success = false;
          this.label = 'Error';
          this.tooltip = error;
        }
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
