import { DeviceContact } from '../../../../app/shared/device/device.service';

export class ResetDeviceContactsCommand {
  public static readonly type = '[Device Contacts] Reset device contacts action';
}

export class LoadDeviceContactsCommand {
  public static readonly type = '[Device Contacts] Load device contacts data';

  public deviceId;
  public customerId;

  constructor({ deviceId, customerId }: { deviceId?: string; customerId?: string }) {
    this.deviceId = deviceId;
    this.customerId = customerId;
  }
}

export class DeviceContactsLoadedEvent {
  public static readonly type = '[Device Contacts] Set device contacts data';

  constructor(public payload: DeviceContact[]) {}
}

export class EditDeviceContactCommand {
  public static readonly type = '[Device Contact] Edit device contact data';

  constructor(public contact?: DeviceContact) {}
}

export class DeleteDeviceContactCommand {
  public static readonly type = '[Device Contact] Delete device contact data';

  constructor(public contact: DeviceContact) {}
}
