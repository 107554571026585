<mat-card class="widget mat-card dashboard-component -service-agreement" *ngIf="isInitialized">
  <mat-card-title> Service Agreements </mat-card-title>
  <mat-card-content>
    <app-service-agreements [defaultPageSize]="10" [isStandalone]="true" [isDashboard]="true" *ngIf="!isForbidden"></app-service-agreements>
    <app-forbidden-message [isDisabled]="isForbidden"></app-forbidden-message>
  </mat-card-content>

  <mat-card-footer class="fixed-to-bottom-actions-helper">
    <mat-card-actions class="fixed-to-bottom-actions" *ngIf="!isForbidden">
      <button mat-stroked-button routerLink="/service-agreements/">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:overview"></mat-icon>
          <span>Service Agreements overview</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="primary" [routerLink]="getUrl()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:add"></mat-icon>
          <span>New Service Agreement</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card-footer>
</mat-card>
