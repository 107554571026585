import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { EntityFacade } from 'flex-app-shared';
import { CloseDeviceContactDialogCommand, SaveDeviceContactCommand } from './device-contact.action';
import { DeviceContactState } from './device-contact.state';

@Injectable({
  providedIn: 'root'
})
export class DeviceContactFacade implements EntityFacade {
  deviceContact$ = this.store.select(DeviceContactState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(DeviceContactState.getDeviceContact))
  );

  isEdit$ = this.store.select(DeviceContactState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(DeviceContactState.getDeviceContact)),
    map((deviceContact) => !!deviceContact.id)
  );
  saveButtonPending$ = this.store.select(DeviceContactState.isBusySending);
  saveButtonError$ = this.store.select(DeviceContactState.error);

  constructor(private store: Store) {}

  save(): void {
    this.store.dispatch(new SaveDeviceContactCommand());
  }

  closeDialog(): void {
    this.store.dispatch(new CloseDeviceContactDialogCommand());
  }
}
