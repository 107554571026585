import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TsoAgreement, TsoAgreementService } from '../shared/tso-agreement/tso-agreement.service';
import { parseISOtoMoment } from '../shared/common/common';
import {
  AdjustmentDirection,
  CalendarPeriod,
  Capacity,
  CustomValidators,
  MixinBase,
  OnDestroyMixin,
  Period,
  Product
} from 'flex-app-shared';
import { isEmpty, isEqual } from 'lodash-es';
import { TsoAgreementsFacade } from '../../store/tso-agreements/tso-agreements-facade.service';
import { map, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { NumberRange, RangeMap } from 'range-ts';

@Component({
  selector: 'app-tso-agreement-detail',
  templateUrl: './tso-agreement-detail.component.html',
  styleUrls: ['./tso-agreement-detail.component.scss']
})
export class TsoAgreementDetailComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  products: ReadonlyArray<Product> = [Product.R3_EMERGENCY_POWER];
  operationTimes: ReadonlyArray<number> = [60, 120];
  operationRampUpTimes: ReadonlyArray<number> = [10, 15];
  operationRampDownTimes: ReadonlyArray<number> = [20];
  tsoAgreementDirectionAlreadyExists: boolean = false;
  fromAuctionResult: boolean = false;
  auctionDescription: string = null;

  tsoAgreementsUpwardsRangeMap$ = this.tsoAgreementFacade.tsoAgreements$.pipe(
    map((tsoAgreements) =>
      this.createRangeMapFromTsoAgreements(tsoAgreements.filter((tsoAgreement) => tsoAgreement.direction === AdjustmentDirection.UPWARDS))
    ),
    shareReplay(1)
  );

  tsoAgreementsDownwardsRangeMap$ = this.tsoAgreementFacade.tsoAgreements$.pipe(
    map((tsoAgreements) =>
      this.createRangeMapFromTsoAgreements(tsoAgreements.filter((tsoAgreement) => tsoAgreement.direction === AdjustmentDirection.DOWNWARDS))
    ),
    shareReplay(1)
  );

  form = this.builder.group({
    id: '',
    product: ['', [Validators.required]],
    month: '',
    period: this.builder.group({
      startDate: ['', [Validators.required]],
      endDate: ['', [Validators.required]]
    }),
    direction: '',
    power: this.builder.group({
      value: ['', [Validators.required, Validators.min(5), Validators.max(999.999), CustomValidators.numberOfDecimals(0, 2)]],
      unit: 'MW'
    }),
    hourlyFee: ['', [Validators.required, CustomValidators.numberOfDecimals(0, 2), Validators.min(0)]],
    maxOperationTime: '',
    operationRampUpTime: '',
    operationRampDownTime: ''
  });

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private builder: UntypedFormBuilder,
    private service: TsoAgreementService,
    private tsoAgreementFacade: TsoAgreementsFacade
  ) {
    super();
  }

  get product(): AbstractControl | null {
    return this.form.get('product');
  }

  get period(): AbstractControl | null {
    return this.form.get('period') as UntypedFormGroup;
  }

  get startDate(): AbstractControl | null {
    return this.period.get('startDate');
  }

  get endDate(): AbstractControl | null {
    return this.period.get('endDate');
  }

  get direction(): AbstractControl | null {
    return this.form.get('direction');
  }

  get power(): AbstractControl | null {
    return this.form.get('power').get('value');
  }

  get hourlyFee(): AbstractControl | null {
    return this.form.get('hourlyFee');
  }

  get maxOperationTime(): AbstractControl | null {
    return this.form.get('maxOperationTime');
  }

  get operationRampUpTime(): AbstractControl | null {
    return this.form.get('operationRampUpTime');
  }

  get operationRampDownTime(): AbstractControl | null {
    return this.form.get('operationRampDownTime');
  }

  get isNew(): boolean {
    return this.form.value.id === 'new';
  }

  ngOnInit(): void {
    this.getTsoAgreement();
    this.tsoAgreementFacade.init();

    this.form.valueChanges
      .pipe(
        startWith(this.form.value),
        switchMap((formValue) => {
          return (
            formValue.direction === AdjustmentDirection.UPWARDS ? this.tsoAgreementsUpwardsRangeMap$ : this.tsoAgreementsDownwardsRangeMap$
          ).pipe(
            map((tsoRangeMap) => {
              let filteredRangeMap = tsoRangeMap;

              if (formValue.id && filteredRangeMap.asMapOfRanges().size > 1) {
                const filteredEntries: [string[], NumberRange[]][] = [...tsoRangeMap.asMapOfValues().entries()].map(([key, value]) => [
                  key.filter((currentKey) => currentKey !== formValue.id),
                  value
                ]);

                filteredRangeMap = new RangeMap<string[]>();
                filteredEntries.forEach((entry) => {
                  entry[1].forEach((period) => filteredRangeMap.putCoalescing(period, entry[0]));
                });
              }

              return [formValue, filteredRangeMap];
            })
          );
        }),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([formValue, tsoAgreementsRangeMapForDirection]) => {
        const period = CalendarPeriod.toNumberRange(formValue.period);
        const subRangeMap = tsoAgreementsRangeMapForDirection.subRangeMap(period);
        this.tsoAgreementDirectionAlreadyExists = [...subRangeMap.asMapOfValues().keys()].filter((value) => !!value.length).length > 0;
      });
  }

  getTsoAgreement(): void {
    const id = this.route.snapshot.paramMap.get('tsoAgreementId');
    if (id !== 'new') {
      this.service.getById(id).subscribe((agreement) => this.initForm(id, agreement));
      return;
    }
    const agr = new TsoAgreement();
    agr.product = Product.R3_EMERGENCY_POWER;

    // Set start date on first day of the next month
    const start = new Date();
    start.setMonth(start.getMonth() + 1);
    start.setDate(1);

    // Set end date to the last date of next month
    const end = new Date();
    end.setMonth(start.getMonth() + 1);
    end.setDate(1);
    end.setDate(end.getDate() - 1);

    agr.period = new Period(start, end);
    agr.direction = AdjustmentDirection.UPWARDS;
    agr.power = Capacity.MW(5);
    agr.hourlyFee = 0;
    agr.maxOperationTime = 60;
    agr.operationRampUpTime = 15;
    agr.operationRampDownTime = 20;
    this.initForm(id, agr);
  }

  onSubmit(): void {
    if (this.form.value.id === 'new') {
      this.service.add(this.form.value).subscribe(() => this.goBack());
    } else {
      this.service.update(this.form.value).subscribe(() => this.goBack());
    }
  }

  goBack(): void {
    this.location.back();
  }

  private createRangeMapFromTsoAgreements(tsoAgreements: TsoAgreement[]): RangeMap<string[]> {
    const rangeMap = new RangeMap<string[]>(isEqual);
    rangeMap.put(NumberRange.all(), []);
    tsoAgreements.forEach((tsoAgreement) => {
      const subRangePeriod = CalendarPeriod.toNumberRange(tsoAgreement.period);
      const subRangeMap = rangeMap.subRangeMap(subRangePeriod).asMapOfRanges();

      [...subRangeMap.entries()].forEach(([key, value]) => {
        rangeMap.putCoalescing(key, [...value, tsoAgreement.id]);
      });
    });
    return rangeMap;
  }

  private initForm(id: string, item: TsoAgreement): void {
    if (isEmpty(item?.period?.startDate)) {
      const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
      item.period.startDate = tomorrow;
      item.period.endDate = tomorrow;
    }
    parseISOtoMoment(item, ['period.startDate', 'period.endDate']);
    this.form.get('id').setValue(id);
    this.product.setValue(item.product);
    this.startDate.setValue(item.period.startDate);
    this.endDate.setValue(item.period.endDate);
    this.direction.setValue(item.direction);
    this.power.setValue(Capacity.asMW(item.power));
    this.hourlyFee.setValue(item.hourlyFee);
    this.maxOperationTime.setValue(item.maxOperationTime);
    this.operationRampUpTime.setValue(item.operationRampUpTime);
    this.operationRampDownTime.setValue(item.operationRampDownTime);
    this.direction.valueChanges.subscribe((it) => {
      if (!this.operationRampUpTime.touched) {
        if (it === 'UPWARDS') {
          this.operationRampUpTime.setValue(15);
        } else {
          this.operationRampUpTime.setValue(10);
        }
      }
    });
    this.fromAuctionResult = item.fromAuctionResult;
    this.auctionDescription = item.auctionDescription;
  }
}
