import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Location } from '@angular/common';
import {
  AdjustmentDirection,
  EntityState,
  EntityStateModel,
  FetchEntity,
  findParameterValue,
  MessageService,
  PeriodView,
  RouterInitializer
} from 'flex-app-shared';
import { tap } from 'rxjs/operators';
import { Unavailability, UnavailabilityView } from '../../../../app/shared/unavailability/unavailability';
import { UnavailabilityService } from '../../../../app/shared/unavailability/unavailability.service';
import {
  LoadUnavailabilityCommand,
  ResetUnavailabilityCommand,
  SaveUnavailabilityCommand,
  UnavailabilityLoadedEvent
} from './unavailability.actions';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export class UnavailabilityStateModel extends EntityStateModel<Unavailability> {
  model: Unavailability = {
    id: null,
    customerId: null,
    customerName: null,
    serviceAgreementId: null,
    serviceAgreementSystemReference: null,
    serviceAgreementReference: null,
    direction: AdjustmentDirection.UPWARDS,
    power: null,
    period: null,
    sent: false
  };
}

@State<UnavailabilityStateModel>({
  name: 'unavailability',
  defaults: new UnavailabilityStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class UnavailabilityState extends EntityState<UnavailabilityView> implements RouterInitializer<UnavailabilityStateModel> {
  constructor(
    private unavailabilityService: UnavailabilityService,
    private location: Location,
    private messageService: MessageService,
    store: Store
  ) {
    super(store);
  }

  @Selector()
  public static getUnavailability(state: UnavailabilityStateModel): Unavailability {
    return {
      ...state.model,
      period: PeriodView.deserialize(state.model.period)
    } as any;
  }

  @Selector()
  public static isBusy(state: UnavailabilityStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isInitialized(state: UnavailabilityStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static isBusySending(state: UnavailabilityStateModel): boolean {
    return state.isBusySending;
  }

  @Selector()
  public static error(state: UnavailabilityStateModel): string | null {
    return state.sendError;
  }

  @Action(RouterNavigation)
  routerInit({ dispatch, getState, setState }: StateContext<UnavailabilityStateModel>, { routerState }: RouterNavigation): void {
    const unavailabilityId = findParameterValue(routerState, 'unavailabilityId');
    const customerId = findParameterValue(routerState, 'customerId');
    const state = getState();

    if (unavailabilityId && unavailabilityId !== 'new') {
      // Fetch unavailability data for id
      dispatch(new LoadUnavailabilityCommand(unavailabilityId));
    } else if (getState().model.id || unavailabilityId === 'new') {
      // If we had unavailability data, remove it
      dispatch(new ResetUnavailabilityCommand({ customerId }));
    } else if (state.model.customerId !== customerId) {
      setState({ ...state, model: { ...state.model, customerId } });
    }
  }

  @Action(ResetUnavailabilityCommand)
  resetUnavailabilityData({ setState }: StateContext<UnavailabilityStateModel>, { payload }: ResetUnavailabilityCommand): void {
    const newState = new UnavailabilityStateModel();
    setState({ ...newState, model: { ...newState.model, ...payload } });
  }

  @Action(LoadUnavailabilityCommand)
  @FetchEntity()
  initUnavailabilityData(ctx: StateContext<UnavailabilityStateModel>, { id }: LoadUnavailabilityCommand): Observable<Unavailability> {
    return this.unavailabilityService
      .getById(id)
      .pipe(tap((unavailability) => ctx.dispatch(new UnavailabilityLoadedEvent(unavailability))));
  }

  @Action(UnavailabilityLoadedEvent)
  setUnavailabilityData({ setState, getState }: StateContext<UnavailabilityStateModel>, { payload }: UnavailabilityLoadedEvent): void {
    const state = getState();
    setState({
      ...state,
      model: {
        ...state.model,
        ...payload
      }
    });
  }

  @Action(SaveUnavailabilityCommand)
  saveUnavailability(ctx: StateContext<UnavailabilityStateModel>, action: SaveUnavailabilityCommand): Observable<Unavailability> {
    const unavailability = {
      ...action.payload,
      id: ctx.getState().model.id
    };

    const saveObservable = unavailability.id
      ? this.unavailabilityService.update(unavailability)
      : this.unavailabilityService.add(unavailability);

    return saveObservable.pipe(tap(() => this.location.back()));
  }
}
