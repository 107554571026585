import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorityService, DownloadService, MANAGE_EXTERNAL_USERS, MANAGE_USERS, MessageService } from 'flex-app-shared';
import { combineLatest, Observable, switchMap } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

export class GroupAuthorities {
  groups: Group[];
  authorities: Authority[];
}

export class Group {
  id: string;
  groupName: string;

  groupType: string;

  description: string;
}

export class Authority {
  authority: string;
  groups: Map<string, boolean>;
}

export class User {
  id: string;
  userName: string;
  name: string = '';
  nickName: string;
  firstName?: string;
  surname?: string;
  phoneNumber?: string;
  tenant: string;
  employee: boolean;
  active: boolean;
  groups: Group[] = [];
  customerIds: string[] = [];
  newUser: boolean = false;
  activationEmailLastSent?: string = null;
  lastBackendCall?: string = null;
}

export class UserView {
  id: string;
  userName: string;
  name: string;
  nickName: string;
  firstName?: string;
  surname?: string;
  phoneNumber?: string;
  employee: boolean;
  active: boolean;
  groupIds: string[];
  customerIds: string[];
  newUser: boolean = false;
  activationEmailLastSent?: string = null;
  lastBackendCall?: string = null;
}

export enum B2CResultType {
  ACTIVATION_EMAIL = 'ACTIVATION_EMAIL',
  RESET_PASSWORD = 'RESET_PASSWORD',
  ACTIVATE_USER = 'ACTIVATE_USER',
  DEACTIVATE_USER = 'DEACTIVATE_USER'
}

export class UserB2CResult {
  type: B2CResultType;
  success: boolean;
  message: string;
  info: Map<string, string>;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  endpoint = '/api/v1/security/users';
  externalEndpoint = '/api/v1/security/external-users';

  endpoint$: Observable<string> = combineLatest([
    this.authorityService.hasAuthorities(MANAGE_USERS),
    this.authorityService.hasAuthorities(MANAGE_EXTERNAL_USERS)
  ]).pipe(
    map(([hasManageUsers, hasManageExternalUsers]) => {
      if (hasManageUsers) {
        return this.endpoint;
      }
      if (hasManageExternalUsers) {
        return this.externalEndpoint;
      }
    }),
    filter((a) => !!a)
  );

  constructor(
    private http: HttpClient,
    private authorityService: AuthorityService,
    private messageService: MessageService,
    private downloadService: DownloadService
  ) {}

  getAll(): Observable<User[]> {
    return this.endpoint$.pipe(switchMap((endpoint) => this.http.get<User[]>(endpoint)));
  }

  getByCustomerId(customerId: string): Observable<User[]> {
    return this.endpoint$.pipe(switchMap((endpoint) => this.http.get<User[]>(`${endpoint}?customerId=${customerId}`)));
  }

  getById(id: string): Observable<User> {
    return this.endpoint$.pipe(
      map((endpoint) => `${endpoint}/${id}`),
      switchMap((endpoint) => this.http.get<User>(endpoint))
    );
  }

  create(
    name: string,
    nickName: string,
    firstName: string,
    surname: string,
    phoneNumber: string,
    employee: boolean,
    groupIds: string[],
    customerIds: string[],
    sendActivationEmail: boolean
  ): Observable<UserView> {
    const requestParams = sendActivationEmail ? new HttpParams({ fromObject: { sendActivationEmail } }) : undefined;

    return this.endpoint$.pipe(
      switchMap((endpoint) =>
        this.http.post<UserView>(
          endpoint,
          {
            name,
            nickName,
            firstName,
            surname,
            phoneNumber,
            employee,
            groupIds,
            customerIds
          },
          {
            params: requestParams
          }
        )
      ),
      tap((result) => {
        if (sendActivationEmail && !result.activationEmailLastSent) {
          // Activation should have been sent, but wasn't
          this.messageService.error('Activation email was NOT sent, due to an error.');
        }
      })
    );
  }

  updateDetails(id: string, employee: boolean, groupIds: string[], customerIds: string[]): Observable<User> {
    return this.endpoint$.pipe(
      map((endpoint) => `${endpoint}/${id}`),
      switchMap((endpoint) => this.http.put<User>(endpoint, { employee, groupIds, customerIds }))
    );
  }

  deleteUser(id: string): Observable<any> {
    return this.endpoint$.pipe(
      map((endpoint) => `${endpoint}/${id}`),
      switchMap((endpoint) => this.http.delete(endpoint))
    );
  }

  sendActivationEmail(id: string): Observable<UserB2CResult> {
    const url = `${this.endpoint}/${id}/activation`;
    return this.http.put<UserB2CResult>(url, {});
  }

  resetPassword(id: string): Observable<UserB2CResult> {
    const url = `${this.endpoint}/${id}/reset-password`;
    return this.http.put<UserB2CResult>(url, {});
  }

  toggleUserActive(id: string, active: boolean): Observable<UserB2CResult> {
    const url = this.getToggleUserActiveUrl(id, active);
    return this.http.put<UserB2CResult>(url, {});
  }

  private getToggleUserActiveUrl(id: string, active: boolean): string {
    if (active) {
      return `${this.endpoint}/${id}/activate`;
    } else {
      return `${this.endpoint}/${id}/deactivate`;
    }
  }

  downloadExcel(): any {
    return this.downloadService.downloadExcel(`${this.endpoint}`);
  }
}

@Injectable({
  providedIn: 'root'
})
export class GroupService {
  private endpoint = '/api/v1/security';

  constructor(private http: HttpClient, private downloadService: DownloadService) {}

  getAll(): Observable<Group[]> {
    return this.http.get<Group[]>(`${this.endpoint}/groups`);
  }

  getById(id: string): Observable<Group> {
    const url = `${this.endpoint}/groups/${id}`;
    return this.http.get<Group>(url);
  }

  getGroupAuthorities(): Observable<GroupAuthorities> {
    return this.http.get<GroupAuthorities>(`${this.endpoint}/group-authorities`);
  }

  downloadGroupAuthoritiesJson(): any {
    return this.downloadService.download(
      this.http.get<Blob>(`${this.endpoint}/group-authorities`, {
        observe: 'response',
        responseType: 'blob' as 'json'
      })
    );
  }

  downloadGroupAuthoritiesSql(): any {
    return this.downloadService.download(
      this.http.get<Blob>(`${this.endpoint}/group-authorities/sql`, {
        observe: 'response',
        responseType: 'blob' as 'json'
      })
    );
  }

  updateGroupAuthorities(groupAuthorities: GroupAuthorities): Observable<any> {
    return this.http.put(`${this.endpoint}/group-authorities`, groupAuthorities);
  }
}
