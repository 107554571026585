<ng-container *ngIf="!isListeningForEvents">Data retrieval process is started. This may take a while.</ng-container>

<ng-container *ngIf="isListeningForEvents" [ngSwitch]="currentEvent?.amountRetrieved > 0">
  <div *ngSwitchCase="true">
    Data retrieval process is busy, amount of measurements retrieved: {{ currentEvent.amountRetrieved }}.
    <button mat-flat-button color="primary" (click)="snackBarRef.dismiss()">
      <ph-flex-vertical-center><mat-icon svgIcon="ic-old:check"></mat-icon><span>Ok</span></ph-flex-vertical-center>
    </button>
  </div>
  <div *ngSwitchCase="false">
    Data retrieval process is starting, please wait...
    <button mat-flat-button color="primary" (click)="snackBarRef.dismiss()">
      <ph-flex-vertical-center><mat-icon svgIcon="ic-old:close"></mat-icon><span>Close</span></ph-flex-vertical-center>
    </button>
  </div>
</ng-container>
