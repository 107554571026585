import { PeriodView } from 'flex-app-shared';

export class FindAllServiceAgreementsCommand {
  public static readonly type = '[Service Agreements] Fetch all service agreements';
}

export class FindServiceAgreementsByCustomerIdAndPeriodCommand {
  public static readonly type = '[Service Agreements] Fetch service agreements by customer id and period';

  constructor(public customerId?: string, public period?: PeriodView) {}
}

export class ResetServiceAgreementsCommand {
  public static readonly type = '[Service Agreements] Reset service agreements';
}

export class DownloadServiceAgreementsCommand {
  public static readonly type = '[Service Agreements] Download service agreements';
}
