import { Injectable } from '@angular/core';
import { PeriodView, RestClientService, AsyncMessagingService } from 'flex-app-shared';
import { Observable } from 'rxjs';
import { AggregatedUnavailabilities } from './aggregated-unavailability';
import { Unavailability, UnavailabilityView } from './unavailability';

@Injectable({
  providedIn: 'root'
})
export class UnavailabilityService extends RestClientService<UnavailabilityView, Unavailability> {
  constructor() {
    super('/api/v1/unavailabilities');
  }

  add(value: UnavailabilityView): Observable<Unavailability> {
    return this.http.post<Unavailability>(this.endpoint, {
      ...value,
      period: PeriodView.serialize(value.period)
    });
  }

  update(value: UnavailabilityView): Observable<Unavailability> {
    const url = `${this.endpoint}/${value.id}`;
    return this.http.put<Unavailability>(url, {
      ...value,
      period: PeriodView.serialize(value.period)
    });
  }

  show(): Observable<AggregatedUnavailabilities> {
    const url = `${this.endpoint}/show`;
    return this.http.get<AggregatedUnavailabilities>(url);
  }

  clear(): Observable<any> {
    const url = `${this.endpoint}/clear`;
    return this.http.post(url, null);
  }

  send(): Observable<AggregatedUnavailabilities> {
    const url = `${this.endpoint}/send`;
    return this.http.post<AggregatedUnavailabilities>(url, null);
  }
}
