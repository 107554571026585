<ng-container [ngSwitch]="isNew">
  <div class="customer-info __spacer" *ngSwitchDefault>
    <div class="labeled-data">
      <span class="label">Username:</span>
      <span>{{ user.name }}</span>
    </div>

    <div class="labeled-data">
      <span class="label">User ID:</span>
      <span>{{ user.userName }}</span>
    </div>

    <div class="labeled-data">
      <span class="label">Phone number:</span>
      <span>{{ user.phoneNumber ? user.phoneNumber : 'none' }}</span>
    </div>

    <div class="labeled-data">
      <span class="label">Active:</span>
      <span>
        <mat-icon *ngIf="user.active" class="status-success" svgIcon="ic-old:check"> </mat-icon>
        <mat-icon *ngIf="!user.active" class="status-passive" svgIcon="ic-old:close"> </mat-icon>
      </span>
    </div>

    <div class="labeled-data">
      <span class="label">Onboarding email sent:</span>
      <span *ngIf="user.activationEmailLastSent">
        {{ user.activationEmailLastSent | date : 'dd-MM-yyyy HH:mm' }}
      </span>
      <span *ngIf="!user.activationEmailLastSent">No</span>
    </div>

    <div class="labeled-data">
      <span class="label">Last activity:</span>
      <span *ngIf="user.lastBackendCall">
        {{ user.lastBackendCall | date : 'dd-MM-yyyy HH:mm' }}
      </span>
      <span *ngIf="!user.lastBackendCall">Unknown</span>
    </div>
  </div>

  <mat-card *ngIf="!isNew">
    <mat-card-header>
      <mat-card-title>User actions</mat-card-title>
    </mat-card-header>
    <mat-card-actions class="user-detail-actions">
      <span matTooltip="User should create account using the onboarding mail first" [matTooltipDisabled]="!user.newUser">
        <div *ngIf="!user.active">
          <ph-flex-save-button
            [disabled]="user.newUser"
            (clickChanged)="activate()"
            [pending]="isBusyActivatingUser$ | async"
            [minDisabledTimeAfterClick]="minButtonDisabledMillis"
            pendingLabel="Activating"
            color="primary"
            class="user-action-button"
            [error]="activateUserError$ | async"
          >
            Activate user
          </ph-flex-save-button>
        </div>
        <div *ngIf="user.active">
          <ph-flex-save-button
            [disabled]="user.newUser"
            (clickChanged)="deactivate()"
            [pending]="isBusyDeactivatingUser$ | async"
            [minDisabledTimeAfterClick]="minButtonDisabledMillis"
            pendingLabel="Deactivating"
            color="primary"
            class="user-action-button"
            [error]="deactivateUserError$ | async"
          >
            Deactivate user
          </ph-flex-save-button>
        </div>
      </span>

      <span matTooltip="User should create account using the onboarding mail first" [matTooltipDisabled]="!user.newUser">
        <ph-flex-save-button
          [disabled]="user.newUser"
          (clickChanged)="resetPassword()"
          [pending]="isBusyResettingPassword$ | async"
          [minDisabledTimeAfterClick]="minButtonDisabledMillis"
          pendingLabel="Resetting"
          color="primary"
          class="user-action-button"
          [error]="resetPasswordError$ | async"
        >
          Reset user password
        </ph-flex-save-button>
      </span>

      <div>
        <ph-flex-save-button
          (clickChanged)="sendOnboardingEmail()"
          [pending]="isBusySendingEmail$ | async"
          [disabled]="!user.newUser"
          [minDisabledTimeAfterClick]="minButtonDisabledMillis"
          pendingLabel="Sending email"
          color="primary"
          [error]="onboadingEmailError$ | async"
        >
          Send onboarding email
        </ph-flex-save-button>
      </div>
    </mat-card-actions>
  </mat-card>

  <mat-card *ngSwitchCase="true" [formGroup]="form">
    <mat-card-header>
      <mat-card-title>
        <ng-container>Create new user</ng-container>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content *ngIf="isNew">
      <mat-form-field>
        <mat-label>Full name</mat-label>
        <input matInput formControlName="nickName" [errorStateMatcher]="errorMatcher" />
        <mat-error>
          <ph-flex-form-error-messages [control]="form.get('nickName')" descriptiveName="full name"></ph-flex-form-error-messages>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>First name</mat-label>
        <input matInput formControlName="firstName" [errorStateMatcher]="errorMatcher" />
        <mat-error>
          <ph-flex-form-error-messages [control]="form.get('firstName')" descriptiveName="first name"></ph-flex-form-error-messages>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Surname</mat-label>
        <input matInput formControlName="surname" [errorStateMatcher]="errorMatcher" />
        <mat-error>
          <ph-flex-form-error-messages [control]="form.get('surname')" descriptiveName="surname"></ph-flex-form-error-messages>
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Phone number</mat-label>
        <input matInput formControlName="phoneNumber" [errorStateMatcher]="errorMatcher" />
        <mat-error>
          <ph-flex-form-error-messages [control]="form.get('phoneNumber')" descriptiveName="phone number"></ph-flex-form-error-messages>
        </mat-error>
        <mat-error *ngIf="form.get('phoneNumber').errors?.invalidPhoneNumberFormat">
          Phone number should have the format: +31 612345678.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>E-mail address</mat-label>
        <input matInput formControlName="name" [errorStateMatcher]="errorMatcher" />
        <mat-error>
          <ph-flex-form-error-messages [control]="form.get('name')" descriptiveName="e-mail"></ph-flex-form-error-messages>
        </mat-error>
      </mat-form-field>
    </mat-card-content>
  </mat-card>
</ng-container>

<mat-card>
  <mat-card-header>
    <mat-card-title>Groups</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-toolbar class="filter-bar">
      <ph-flex-table-filter (filterChanged)="applyGroupFilter($event)" placeholder="Search groups"></ph-flex-table-filter>
    </mat-toolbar>

    <table mat-table [dataSource]="groupDataSource" matSort #groupSort="matSort">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? groupMasterToggle() : null"
            [checked]="groupSelection.hasValue() && isGroupAllSelected()"
            [indeterminate]="groupSelection.hasValue() && !isGroupAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? groupSelection.toggle(row.id) : null"
            [checked]="groupSelection.isSelected(row.id)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="groupName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Group</th>
        <td mat-cell *matCellDef="let group">{{ group.groupName }}</td>
      </ng-container>
      <ng-container matColumnDef="groupType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Group type</th>
        <td mat-cell *matCellDef="let group">{{ group.groupType }}</td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let group">{{ group.description }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="groupDisplayedColumns$ | async"></tr>
      <tr mat-row *matRowDef="let row; columns: groupDisplayedColumns$ | async"></tr>
    </table>

    <mat-paginator
      #groupPaginator
      [showFirstLastButtons]="true"
      [pageSize]="defaultPageSize"
      [pageSizeOptions]="[10, 20, 30, 50, 100]"
    ></mat-paginator>
  </mat-card-content>
</mat-card>
<mat-card>
  <mat-card-header>
    <mat-card-title>Customers</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-toolbar class="filter-bar checkbox-row" [phFlexAuthShow]="[MANAGE_USERS]">
      <mat-checkbox [(ngModel)]="user.employee" [disabled]="customerSelection.hasValue()"
        >User has access to all customer data
      </mat-checkbox>
    </mat-toolbar>
    <mat-toolbar class="filter-bar">
      <ph-flex-table-filter (filterChanged)="applyCustomerFilter($event)" placeholder="Search customers"></ph-flex-table-filter>
    </mat-toolbar>
    <table mat-table [dataSource]="customerDataSource" matSort #customerSort="matSort">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? customerMasterToggle() : null"
            [checked]="customerSelection.hasValue() && isCustomerAllSelected()"
            [indeterminate]="customerSelection.hasValue() && !isCustomerAllSelected()"
            [disabled]="customerDataSource.filteredData?.length !== customerDataSource.data?.length"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? customerSelection.toggle(row.id) : null"
            [checked]="customerSelection.isSelected(row.id)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
        <td mat-cell *matCellDef="let customer">{{ customer.name }}</td>
      </ng-container>
      <ng-container matColumnDef="legalName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Legal name</th>
        <td mat-cell *matCellDef="let customer">{{ customer.legalName }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="customerDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: customerDisplayedColumns"></tr>
    </table>

    <mat-paginator
      #customerPaginator
      [showFirstLastButtons]="true"
      [pageSize]="defaultPageSize"
      [pageSizeOptions]="[10, 20, 30, 50, 100]"
    ></mat-paginator>
  </mat-card-content>
  <mat-card-actions>
    <mat-checkbox *ngIf="isNew" [(ngModel)]="sendActivationEmailOnCreate">Send activation email</mat-checkbox>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>

    <ph-flex-save-button [pending]="isBusy$ | async" (clickChanged)="onSubmit()" [disabled]="this.form.invalid && isNew" color="cta">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:save"></mat-icon>
        <span>Save</span>
      </ph-flex-vertical-center>
    </ph-flex-save-button>
  </mat-card-actions>
</mat-card>
