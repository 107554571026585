import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, retry } from 'rxjs';
import { IncidentReserveInvoiceData } from './r3-invoice-data';
import { AdjustmentDirection, DownloadService, getRetryConfig } from 'flex-app-shared';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class R3InvoiceDataService {
  private endpoint = '/api/v1/incident-reserve/invoice-data';

  constructor(private http: HttpClient, private downloadService: DownloadService) {}

  getInvoiceData(date: Date, direction: AdjustmentDirection): Observable<IncidentReserveInvoiceData[]> {
    return this.http
      .get<IncidentReserveInvoiceData[]>(`${this.endpoint}/${format(date, 'yyyy-MM')}/${direction}`)
      .pipe(retry(getRetryConfig()));
  }

  downloadExcel(date: Date, direction: AdjustmentDirection): any {
    return this.downloadService.downloadExcel(`${this.endpoint}/${format(date, 'yyyy-MM')}/${direction}`);
  }

  recalculate(date: Date, direction: AdjustmentDirection): any {
    return this.http.put(`${this.endpoint}/${format(date, 'yyyy-MM')}/${direction}`, '');
  }

  recalculateForServiceAgreement(date: Date, direction: AdjustmentDirection, serviceAgreementId: string): any {
    return this.http.put(`${this.endpoint}/${format(date, 'yyyy-MM')}/${direction}?serviceAgreementId=${serviceAgreementId}`, '');
  }
}
