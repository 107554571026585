<mat-card>
  <mat-card-content>
    <mat-toolbar class="filter-bar">
      <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
    </mat-toolbar>
    <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
      <ng-container matColumnDef="period">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Period</th>
        <td mat-cell *matCellDef="let poolPeriod">
          <a href="#" (click)="edit(poolPeriod); $event.preventDefault()">
            {{ poolPeriod.period.startDate.date | date : 'shortDate' }}
            - {{ poolPeriod.period.endDate.date | date : 'shortDate' }}
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="gridPoints">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Grid Points</th>
        <td mat-cell *matCellDef="let poolPeriod">
          {{ poolPeriod.gridPointIds.length }}
        </td>
      </ng-container>
      <ng-container matColumnDef="sent">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>Sent</th>
        <td mat-cell *matCellDef="let poolPeriod" [ngSwitch]="poolPeriod.isSent">
          <ph-flex-vertical-center>
            <ng-container *ngSwitchCase="true">
              <mat-icon class="status-success" svgIcon="ic-old:check"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <mat-icon class="status-passive" svgIcon="ic-old:close"></mat-icon>
            </ng-container>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let poolPeriod">
          <ph-flex-vertical-center>
            <button mat-icon-button matTooltip="Edit" (click)="edit(poolPeriod)">
              <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
            </button>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="sendPool()" [disabled]="hasUnsavedData()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:paper-plane"></mat-icon>
        <span>Send configuration</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="primary" (click)="addPoolSchedule()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:cube"></mat-icon>
        <span>Add new pool schedule</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="cta" mat-stroked-button (click)="onSubmit()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:save"></mat-icon>
        <span>Save</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
