import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  Control,
  ControlGridPoint,
  ControlSchedule,
  ControlState,
  DeleteControlCommand,
  GridPoint,
  GridPointsState,
  isUUid,
  LoadGridPointsByCustomerIdCommand,
  SaveControlCommand,
  SaveControlConfigurationCommand,
  SendScheduleCommand,
  UpdateFlatDeviceContactsCommand
} from 'flex-app-shared';
import { orderBy } from 'lodash-es';
import { combineLatest, of, timer } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { GridPointSelection } from '../../app/control-detail/control-detail.component';
import { DeviceService } from '../../app/shared/device/device.service';
import { UpdateCustomersCommand } from '../customers/states/customers/customers.actions';

@Injectable({
  providedIn: 'root'
})
export class ControlFacade {
  control$ = this.store.select(ControlState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(ControlState.getControl))
  );

  gridPoints$ = this.store.select(GridPointsState.isBusy).pipe(
    filter((a) => !a),
    first(),
    switchMap(() => this.store.select(GridPointsState.getGridPoints))
  );

  gridPointSelection$ = combineLatest([this.control$.pipe(first()), this.gridPoints$]).pipe(
    map((results) => {
      const controlGridPoints = results[0].gridPoints ? results[0].gridPoints : [];
      const customerGridPoints = results[1];
      return this.constructGridPointSelection(controlGridPoints, customerGridPoints);
    })
  );
  isDisabled$ = this.store.select(ControlState.isBusy);
  saveButtonPending$ = this.store.select(ControlState.isBusySending);
  saveButtonError$ = this.store.select(ControlState.error);
  private controlDeviceContactId: string;

  flatDeviceContacts$ = this.control$.pipe(
    switchMap((control) => {
      if (isUUid(control.customerId)) {
        // Prevent a bunch of canceled requests by using a 1ms delay
        return timer(1).pipe(switchMap(() => this.deviceService.getDeviceContactsByCustomerId(control.customerId)));
      } else {
        return of([]);
      }
    }),
    map((flatDeviceContacts) =>
      orderBy(flatDeviceContacts, [
        (it) => {
          return it.deviceContactId === this.controlDeviceContactId ? 'A' : it.firstControlDescription ? 'B' : 'A';
        },
        'deviceExternalId',
        'contactExternalId',
        'contactDescription'
      ])
    )
  );

  constructor(private store: Store, private deviceService: DeviceService) {
    this.control$.subscribe((result) => {
      this.controlDeviceContactId = result.deviceContactId;
    });
  }

  save(): void {
    this.store.dispatch(new SaveControlCommand());
  }

  saveConfiguration(): void {
    this.store.dispatch(new SaveControlConfigurationCommand());
  }

  delete(control: Control): void {
    this.store.dispatch(new DeleteControlCommand(control));
  }

  init(): void {
    this.store.dispatch(new UpdateCustomersCommand());
  }

  customerChanged(customerId: string): void {
    this.store.dispatch(new LoadGridPointsByCustomerIdCommand(customerId));
    this.store.dispatch(new UpdateFlatDeviceContactsCommand(customerId)); // TODO fix infinite loop
  }

  sendSchedule(schedule: ControlSchedule): void {
    this.store.dispatch(new SendScheduleCommand(schedule));
  }

  private constructGridPointSelection(
    controlGridPoints: ControlGridPoint[],
    customerGridPoints: ReadonlyArray<GridPoint>
  ): GridPointSelection[] {
    const customerGridPointSelection = customerGridPoints.map((it) => {
      return {
        gridPointId: it.id,
        ean: it.ean,
        description: it.description,
        ownedByCustomer: true,
        incidentReservePreQualified: it.preQualified
      };
    });
    const customerGridPointIds = customerGridPointSelection.map((it) => it.gridPointId);
    const otherGridPoints = controlGridPoints
      .filter((gp) => !customerGridPointIds.includes(gp.gridPointId))
      .map((gp) => {
        return {
          gridPointId: gp.gridPointId,
          ean: gp.ean,
          description: gp.description,
          ownedByCustomer: false,
          incidentReservePreQualified: false
        };
      });
    const gridPoints = customerGridPointSelection.concat(otherGridPoints);
    return gridPoints.sort((g1, g2) => g1.description.localeCompare(g2.description));
  }
}
