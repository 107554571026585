import { Injectable } from '@angular/core';
import { UpdateFormValue } from '@ngxs/form-plugin';
import { Action, Selector, SelectorOptions, State, StateContext } from '@ngxs/store';
import { NgXsFormModel, normalizeToDate } from 'flex-app-shared';
import { tap } from 'rxjs/operators';
import { Measurement, MeasurementDataService } from '../../../app/shared/measurement-data/measurement-data.service';
import {
  MeasurementDataUploadActivate,
  MeasurementDataUploadCancelCommand,
  MeasurementDataUploadConfirmCommand,
  MeasurementDataUploadDeactivate,
  MeasurementDataUploadDownloadTemplateCommand,
  MeasurementDataUploadPreviewUpdatedEvent,
  MeasurementDataUploadTemplateCommand,
  MeasurementDataUploadUpdatePreviewCommand
} from './measurement-data-upload.actions';

interface PreviewSummary {
  totalRows: number;
  errorRows: number;
}

export class MeasurementDataUploadStateModel {
  active = false;

  dateForm = NgXsFormModel.defaults({
    date: null
  });

  gridPointIdForm = NgXsFormModel.defaults({
    customerId: null,
    gridPointId: null
  });

  downloading: boolean;

  uploading: boolean;
  uploadFailed: boolean;

  confirming: boolean;
  confirmFailed: boolean;
  confirmed: boolean;

  preview: Measurement[];
  uploadId: string; // ID referring to the current preview data

  previewPending: boolean;
}

@State({
  name: 'measurementDataUpload',
  defaults: new MeasurementDataUploadStateModel()
})
@Injectable({
  providedIn: 'root'
})
@SelectorOptions({
  suppressErrors: false
})
export class MeasurementDataUploadState {
  @Selector([MeasurementDataUploadState])
  static preview(state: MeasurementDataUploadStateModel): Measurement[] {
    return state.preview;
  }

  @Selector([MeasurementDataUploadState])
  static hasUploadedData(state: MeasurementDataUploadStateModel): boolean {
    return !!state.uploadId;
  }

  @Selector([MeasurementDataUploadState.preview])
  static uploadedDataSummary(previewData: Measurement[]): PreviewSummary {
    if (!previewData) {
      return null;
    }

    return {
      totalRows: previewData.length,
      errorRows: previewData.filter((row) => Object.keys(row.remarks).length > 0).length
    };
  }

  @Selector([MeasurementDataUploadState])
  static showNotification(state: MeasurementDataUploadStateModel): boolean {
    return state.confirmed;
  }

  @Selector([MeasurementDataUploadState])
  static uploadFailed(state: MeasurementDataUploadStateModel): boolean {
    return state.uploadFailed;
  }

  @Selector([MeasurementDataUploadState])
  static confirmFailed(state: MeasurementDataUploadStateModel): boolean {
    return state.confirmFailed;
  }

  @Selector([MeasurementDataUploadState])
  static isDownloadBusy(state: MeasurementDataUploadStateModel): boolean {
    return state.downloading;
  }

  @Selector([MeasurementDataUploadState])
  static isUploadBusy(state: MeasurementDataUploadStateModel): boolean {
    return state.uploading;
  }

  @Selector([MeasurementDataUploadState])
  static isConfirmBusy(state: MeasurementDataUploadStateModel): boolean {
    return state.confirming;
  }

  /**
   * True if preview data should be updated, and is in the process of doing so.
   */
  @Selector([MeasurementDataUploadState])
  static previewPending(state: MeasurementDataUploadStateModel): boolean {
    return state.previewPending;
  }

  constructor(private service: MeasurementDataService) {}

  @Action(MeasurementDataUploadDownloadTemplateCommand)
  handleMeasurementDataUploadDownloadTemplateCommand({ patchState, getState }: StateContext<MeasurementDataUploadStateModel>): any {
    const { date } = getState().dateForm.model;
    const { gridPointId } = getState().gridPointIdForm.model;

    patchState({
      downloading: true,
      confirmed: false
    });

    this.service.download5MinuteTemplate(normalizeToDate(date), gridPointId).subscribe({
      next: () =>
        patchState({
          downloading: false
        }),
      error: () =>
        patchState({
          downloading: false
        })
    });
  }

  @Action(MeasurementDataUploadTemplateCommand)
  handleMeasurementDataUploadTemplateCommand(
    { patchState, getState, dispatch }: StateContext<MeasurementDataUploadStateModel>,
    { data }: MeasurementDataUploadTemplateCommand
  ): any {
    if (getState().uploadId) {
      // If we upload new data, and we already have an uploadId stored, cancel it.
      dispatch(new MeasurementDataUploadCancelCommand(getState().uploadId));
    }

    patchState({
      uploading: true,
      uploadFailed: false,
      previewPending: true,
      confirmFailed: false, // Reset confirm since the uploaded file will change
      confirmed: false
    });
    const { date } = getState().dateForm.model;
    const { gridPointId } = getState().gridPointIdForm.model;
    return this.service.upload5MinuteData(normalizeToDate(date), gridPointId, data).pipe(
      tap({
        next: (uploadId) =>
          patchState({
            uploading: false
          }),
        error: () =>
          patchState({
            uploading: false,
            uploadFailed: true,
            previewPending: false
          })
      }),
      tap((result) => {
        dispatch(new MeasurementDataUploadUpdatePreviewCommand(result));
      })
    );
  }

  @Action(MeasurementDataUploadUpdatePreviewCommand)
  handleMeasurementDataUploadUpdatePreviewCommand(
    { patchState, dispatch }: StateContext<MeasurementDataUploadStateModel>,
    { id }: MeasurementDataUploadUpdatePreviewCommand
  ): any {
    patchState({
      uploadId: id
    });
    return this.service.get5MinuteDataPreview(id).pipe(tap((data) => dispatch(new MeasurementDataUploadPreviewUpdatedEvent(data))));
  }

  @Action(MeasurementDataUploadPreviewUpdatedEvent)
  handleMeasurementDataUploadPreviewUpdatedEvent(
    { patchState }: StateContext<MeasurementDataUploadStateModel>,
    { data }: MeasurementDataUploadPreviewUpdatedEvent
  ): any {
    patchState({
      preview: data,
      previewPending: false
    });
  }

  @Action(MeasurementDataUploadConfirmCommand)
  handleMeasurementDataUploadConfirmCommand(
    { getState, patchState }: StateContext<MeasurementDataUploadStateModel>,
    { afterConfirmFn }: MeasurementDataUploadConfirmCommand
  ): any {
    const uploadId = getState().uploadId;

    if (!uploadId) {
      return;
    }

    patchState({
      confirming: true,
      confirmFailed: false
    });

    return this.service.confirm5MinuteData(uploadId).pipe(
      tap({
        error: () =>
          patchState({
            confirming: false,
            confirmFailed: true
          }),
        next: () => {
          // Reset state, but set confirmed to true
          patchState({
            // ...new MeasurementDataUploadStateModel(),

            confirming: false,
            confirmed: true
          });
          afterConfirmFn();
        }
      })
    );
  }

  @Action(MeasurementDataUploadCancelCommand)
  handleMeasurementDataUploadCancelCommand(
    { getState, patchState }: StateContext<MeasurementDataUploadStateModel>,
    { id }: MeasurementDataUploadCancelCommand
  ): any {
    if (getState().uploadId === id) {
      patchState({
        uploadId: undefined
      });
    }

    return this.service.cancel5MinuteData(id);
  }

  @Action(UpdateFormValue)
  handleUpdateFormValue({ getState, patchState }: StateContext<MeasurementDataUploadStateModel>, event: UpdateFormValue): void {
    if (!getState().active) {
      return;
    }

    if (event.payload.path.startsWith('measurementDataUpload')) {
      // Reset uploadId and upload error
      patchState({
        uploadId: null,
        uploadFailed: false
      });
    }
  }

  @Action(MeasurementDataUploadActivate)
  activate({ patchState }: StateContext<MeasurementDataUploadStateModel>): void {
    patchState({
      active: true
    });
  }

  @Action(MeasurementDataUploadDeactivate)
  deactivate({ setState }: StateContext<MeasurementDataUploadStateModel>): void {
    setState(new MeasurementDataUploadStateModel());
  }
}
