import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class Announcement {
  enabled: boolean;
  title: string;
  body: string;
  validFrom: string;
}

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
  constructor(private http: HttpClient) {}

  get(): Observable<Announcement> {
    return this.http.get<Announcement>(`/api/v1/announcement`);
  }

  save(announcement: Announcement): Observable<void> {
    return this.http.put<void>(`/api/v1/announcement`, announcement);
  }
}
