<ng-container *ngIf="!isStandalone">
  <mat-card>
    <mat-card-content>
      <ng-container [ngTemplateOutlet]="pageContents"></ng-container>
    </mat-card-content>
    <mat-card-actions>
      <button *ngIf="!isStandalone" mat-stroked-button (click)="goBack()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
          <span>Go back</span>
        </ph-flex-vertical-center>
      </button>
      <button
        mat-stroked-button
        [disabled]="serviceAgreementsFacade.isBusyDownloading$ | async"
        (click)="downloadServiceAgreementsOverview()"
      >
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:download"></mat-icon>
          <span>Download data</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="primary" routerLink="/service-agreements/new">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:add"></mat-icon>
          <span>New service agreement</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>

<ng-container *ngIf="isStandalone" [ngTemplateOutlet]="pageContents"></ng-container>

<ng-template #pageContents>
  <mat-toolbar class="filter-bar">
    <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
  </mat-toolbar>

  <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
    <ng-container matColumnDef="customerName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
      <td mat-cell *matCellDef="let serviceAgreement">
        {{ serviceAgreement.customerName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="systemReference">
      <th mat-header-cell *matHeaderCellDef mat-sort-header [ngSwitch]="isDashboard">
        <span *ngSwitchCase="true" matTooltip="System reference">#</span>
        <span *ngSwitchCase="false">System reference</span>
      </th>
      <td mat-cell *matCellDef="let serviceAgreement">
        <a routerLink="/service-agreements/{{ serviceAgreement.id }}">{{ serviceAgreement.systemReference }}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="reference">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference</th>
      <td mat-cell *matCellDef="let serviceAgreement">
        <a routerLink="/service-agreements/{{ serviceAgreement.id }}">{{ serviceAgreement.reference }}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="product">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
      <td mat-cell *matCellDef="let serviceAgreement" [ngSwitch]="isDashboard">
        <ph-flex-vertical-center *ngSwitchCase="true">
          <mat-icon
            *ngIf="ServiceAgreement.isEmergencyPowerServiceAgreement(serviceAgreement)"
            class="status-secondary"
            matTooltip="Emergency power"
            svgIcon="ic-old:noodvermogen"
          >
          </mat-icon>
          <mat-icon
            *ngIf="ServiceAgreement.isIntraDayServiceAgreement(serviceAgreement)"
            class="status-secondary"
            matTooltip="Intra day"
            svgIcon="ic-old:intraday"
          >
          </mat-icon>
        </ph-flex-vertical-center>
        <ng-container *ngSwitchCase="false">
          {{ serviceAgreement.product }}
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Period</th>
      <td mat-cell *matCellDef="let serviceAgreement">
        {{ serviceAgreement.period.startDate | date: 'shortDate' }}
        - {{ serviceAgreement.period.endDate | date: 'shortDate' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="incidentReservePreQualified">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Incident reserve PQ</th>
      <td mat-cell *matCellDef="let serviceAgreement" [ngSwitch]="serviceAgreement.product">
        <ph-flex-vertical-center *ngSwitchCase="'R3_EMERGENCY_POWER'">
          <ng-container [ngSwitch]="serviceAgreement.incidentReservePreQualified">
            <ng-container *ngSwitchCase="true">
              <mat-icon class="status-success" svgIcon="ic-old:check"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <mat-icon class="status-passive" svgIcon="ic-old:close"></mat-icon>
            </ng-container>
          </ng-container>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let serviceAgreement">
        <ph-flex-vertical-center>
          <button mat-icon-button matTooltip="Edit" routerLink="/service-agreements/{{ serviceAgreement.id }}">
            <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
          </button>
          <button mat-icon-button [matMenuTriggerFor]="tableActions" ngClass="more-menu">
            <mat-icon svgIcon="ic-old:more-menu-v"></mat-icon>
          </button>
          <mat-menu #tableActions="matMenu">
            <button
              *ngIf="ServiceAgreement.isEmergencyPowerServiceAgreement(serviceAgreement)"
              mat-menu-item
              routerLink="/service-agreements/{{ serviceAgreement.id }}/availability-schedule"
            >
              <ph-flex-vertical-center>
                <mat-icon svgIcon="ic-old:clock-o"></mat-icon>
                <span>Edit availability</span>
              </ph-flex-vertical-center>
            </button>
            <button mat-menu-item class="remove" (click)="delete(serviceAgreement)">
              <ph-flex-vertical-center>
                <mat-icon svgIcon="ic-old:trash-o"></mat-icon>
                <span>Delete</span>
              </ph-flex-vertical-center>
            </button>
          </mat-menu>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [showFirstLastButtons]="true" [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
</ng-template>
