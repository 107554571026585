import { Location } from '@angular/common';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { toUpper } from 'lodash-es';
import {
  Customer,
  CustomerService,
  EntityState,
  EntityStateModel,
  FetchEntity,
  findParameterValue,
  FormStatus,
  MessageService,
  NgxsFormState,
  RouterInitializer
} from 'flex-app-shared';
import { CustomerLoadedEvent, LoadCustomerCommand, ResetCustomerCommand, SaveCustomerCommand } from './customer.actions';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

export class CustomerStateModel extends EntityStateModel<Customer> implements NgxsFormState<Customer> {
  model: Customer = {
    id: undefined,
    name: '',
    legalName: '',
    billingId: '',
    accountManagerId: ''
  };
  dirty: boolean | null;
  status: FormStatus | null;
}

@State<CustomerStateModel>({
  name: 'customer',
  defaults: new CustomerStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class CustomerState extends EntityState<Customer> implements RouterInitializer<CustomerStateModel> {
  constructor(private customerService: CustomerService, private location: Location, private messageService: MessageService, store: Store) {
    super(store);
  }

  @Selector()
  public static getCustomer(state: CustomerStateModel): Customer {
    return {
      ...state.model
    };
  }

  @Selector()
  public static getCustomerId(state: CustomerStateModel): string {
    return state.model.id;
  }

  @Selector()
  public static isBusy(state: CustomerStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isInitialized(state: CustomerStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static isBusySending(state: CustomerStateModel): boolean {
    return state.isBusySending;
  }

  @Selector()
  public static error(state: CustomerStateModel): string | null {
    return state.sendError;
  }

  @Selector()
  public static isFormDirty(state: CustomerStateModel): boolean {
    return state.dirty;
  }

  @Selector()
  public static isFormValid(state: CustomerStateModel): boolean {
    return state.status === FormStatus.VALID;
  }

  @Action(RouterNavigation)
  routerInit({ dispatch, getState }: StateContext<CustomerStateModel>, { routerState }: RouterNavigation): void {
    const customerId = findParameterValue(routerState, 'customerId');

    if (customerId && customerId !== 'new') {
      // Fetch customer data for id
      dispatch(new LoadCustomerCommand(customerId));
    } else {
      dispatch(new ResetCustomerCommand());
    }
  }

  @FetchEntity()
  @Action(LoadCustomerCommand)
  initCustomerData(ctx: StateContext<CustomerStateModel>, { id }: LoadCustomerCommand): Observable<Customer> {
    return this.customerService.getById(id).pipe(
      tap(
        (customer) => ctx.dispatch(new CustomerLoadedEvent(customer)),
        (err) => this.messageService.error(err.message)
      )
    );
  }

  @Action(CustomerLoadedEvent)
  setCustomerData({ setState, getState }: StateContext<CustomerStateModel>, { payload }: CustomerLoadedEvent): void {
    const state = getState();
    setState({
      ...state,
      model: {
        ...state.model,
        ...payload
      }
    });
  }

  @FetchEntity()
  @Action(SaveCustomerCommand)
  saveCustomer({ getState, dispatch }: StateContext<CustomerStateModel>, action: SaveCustomerCommand): Observable<Customer> {
    let customer = getState().model as Customer;
    customer = { ...customer, name: toUpper(customer.name) };
    const saveObservable = customer.id ? this.customerService.update(customer) : this.customerService.add(customer);
    return saveObservable.pipe(
      tap(
        (result) => (action.navigateAfterSave ? this.location.back() : dispatch(new CustomerLoadedEvent(result))),
        (err) => this.messageService.error(err.message)
      )
    );
  }

  @Action(ResetCustomerCommand)
  resetCustomerData({ setState }: StateContext<CustomerStateModel>): void {
    setState(new CustomerStateModel());
  }
}
