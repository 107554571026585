import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class EmailTemplate {
  name: string;
  publishedAt: string;
  slug: string;
}

export type TemplateConfigurationType = 'EMAIL' | 'SMS';

export class TemplateConfiguration {
  id: string;
  identifier: string;
  type: TemplateConfigurationType;
  language: string;
  templateName?: string;
  templateContent?: string;
  subject?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TemplateService {
  constructor(private http: HttpClient) {}

  getAvailableMailTemplates(): Observable<ReadonlyArray<EmailTemplate>> {
    return this.http.get<EmailTemplate[]>(`/api/v1/mail/templates`);
  }

  getAllTemplateConfigurations(): Observable<ReadonlyArray<TemplateConfiguration>> {
    return this.http.get<TemplateConfiguration[]>(`/api/v1/config/templates`);
  }

  updateTemplateConfiguration(templateConfiguration: TemplateConfiguration): Observable<object> {
    return this.http.put(`/api/v1/config/templates/${templateConfiguration.id}`, templateConfiguration);
  }
}
