import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Availability, Capacity, ServiceAgreement, ServiceAgreementService } from 'flex-app-shared';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AvailabilityScheduleDialogComponent } from '../availability-schedule-dialog/availability-schedule-dialog.component';
import { cloneDeep, get, isEqual } from 'lodash-es';
import moment from 'moment';

@Component({
  selector: 'app-availability-schedule',
  templateUrl: './availability-schedule.component.html',
  styleUrls: ['./availability-schedule.component.scss']
})
export class AvailabilityScheduleComponent implements OnInit {
  serviceAgreement: ServiceAgreement;
  Capacity = Capacity;

  displayedColumns = ['date', 'time', 'direction', 'type', 'power', 'actions'];
  availabilityDataSource: MatTableDataSource<Availability> = new MatTableDataSource<Availability>([]);

  @ViewChild(MatPaginator, { static: false }) private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) private readonly sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    public dialog: MatDialog,
    private serviceAgreementService: ServiceAgreementService
  ) {}

  ngOnInit(): void {
    this.updateAvailabilities();
  }

  updateAvailabilities(): void {
    const serviceAgreementId = this.route.snapshot.paramMap.get('serviceAgreementId');
    this.serviceAgreementService.getById(serviceAgreementId).subscribe((s) => {
      this.serviceAgreement = s;
      this.availabilityDataSource.data = [...s.availabilitySchedule.availabilities];
      setTimeout(() => {
        this.availabilityDataSource.sort = this.sort;
        this.availabilityDataSource.paginator = this.paginator;
        this.availabilityDataSource.sortingDataAccessor = (data, sortHeaderId) => {
          switch (sortHeaderId) {
            case 'date':
              // Support both moments and non-moments
              return moment(data.period.startDate).toISOString();
            case 'time':
              return data.timePeriod.startTime;
            case 'power':
              return Capacity.asMW(data.power);
            default:
              return get(data, sortHeaderId);
          }
        };
      });
    });
  }

  addAvailability(): void {
    const dialogRef = this.dialog.open(AvailabilityScheduleDialogComponent);
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const clonedAvailabilities = this.serviceAgreement.availabilitySchedule.availabilities.slice(0);
        clonedAvailabilities.push(result);
        this.serviceAgreement.availabilitySchedule.availabilities = clonedAvailabilities;
        this.availabilityDataSource.data = clonedAvailabilities;
      }
    });
  }

  editAvailability(availability: Availability): void {
    const oldAvailability = cloneDeep(availability);

    const dialogRef = this.dialog.open(AvailabilityScheduleDialogComponent, {
      data: availability
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const newAvailabilities = this.serviceAgreement.availabilitySchedule.availabilities.map((currentAvailability) => {
          if (isEqual(oldAvailability, currentAvailability)) {
            return result;
          }
          return currentAvailability;
        });

        this.serviceAgreement.availabilitySchedule.availabilities = newAvailabilities;
        this.availabilityDataSource.data = newAvailabilities;
      }
    });
  }

  deleteAvailability(availability: Availability): void {
    const newAvailabilities = this.serviceAgreement.availabilitySchedule.availabilities.filter(
      (currentAvailability) => !isEqual(currentAvailability, availability)
    );

    this.serviceAgreement.availabilitySchedule.availabilities = newAvailabilities;
    this.availabilityDataSource.data = newAvailabilities;
  }

  goBack(): void {
    this.location.back();
  }

  onSubmit(): void {
    this.serviceAgreementService
      .saveAvailability(this.serviceAgreement.id, this.serviceAgreement.availabilitySchedule)
      .subscribe(() => this.goBack());
  }
}
