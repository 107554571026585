<mat-card>
  <mat-card-content>
    <mat-vertical-stepper>
      <ng-template matStepperIcon="edit">
        <mat-icon svgIcon="ic-old:inline-edit-o"></mat-icon>
      </ng-template>

      <ng-template matStepperIcon="done">
        <mat-icon svgIcon="ic-old:check"></mat-icon>
      </ng-template>
      <mat-step [stepControl]="form" label="Provide a measurement date">
        <form *ngIf="form" [formGroup]="form" class="single-column-form">
          <mat-form-field class="compact">
            <mat-label>Date</mat-label>
            <input matInput [matDatepicker]="datePicker" formControlName="date" />
            <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
            <mat-datepicker #datePicker></mat-datepicker>
            <mat-error *ngIf="form.get('date').errors?.required"> Date is required. </mat-error>
            <mat-error *ngIf="form.get('date').errors?.dateAfterToday"> Date cannot be after today. </mat-error>
          </mat-form-field>
          <div>
            <button mat-flat-button color="primary" matStepperNext>
              <ph-flex-vertical-center><mat-icon svgIcon="ic-old:arrow_downward"></mat-icon> <span>Next</span></ph-flex-vertical-center>
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step [stepControl]="filterFormGroup" label="Specify gridpoints">
        <mat-toolbar class="filter-bar">
          <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
        </mat-toolbar>

        <table mat-table [dataSource]="gridPointsDataSource" class="has-checkboxes" matSort #gridPointSort="matSort">
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
              >
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>
          <ng-container matColumnDef="ean">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>EAN</th>
            <td mat-cell *matCellDef="let gridPoint">{{ gridPoint.ean }}</td>
          </ng-container>
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
            <td mat-cell *matCellDef="let gridPoint">{{ gridPoint.description }}</td>
          </ng-container>
          <ng-container matColumnDef="customer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
            <td mat-cell *matCellDef="let gridPoint">{{ gridPoint.customerName }}</td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedGridPointColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedGridPointColumns"></tr>
        </table>

        <mat-paginator
          [showFirstLastButtons]="true"
          [pageSize]="30"
          [pageSizeOptions]="[10, 20, 30, 50, 100]"
          #gridPointPaginator
        ></mat-paginator>
        <div>
          <button mat-stroked-button color="primary" matStepperPrevious>
            <ph-flex-vertical-center><mat-icon svgIcon="ic-old:arrow_upward"></mat-icon><span>Previous</span></ph-flex-vertical-center>
          </button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Back</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-stroked-button (click)="viewData()" [disabled]="selection.isEmpty() || form.invalid">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:view"></mat-icon>
        <span>View measurement data</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="primary" (click)="downloadData()" [disabled]="selection.isEmpty() || form.invalid">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:download"></mat-icon>
        <span>Download measurement data</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>

<mat-card *ngIf="showData">
  <mat-card-header>
    <mat-card-title>Measurement data</mat-card-title>
    <mat-card-subtitle>{{ getFormattedSelectedEANs() }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="measurementsDataSource" matSort #measurementSort="matSort">
      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
        <td mat-cell *matCellDef="let measurement">{{ measurement.customerName }}</td>
      </ng-container>
      <ng-container matColumnDef="ean">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>EAN</th>
        <td mat-cell *matCellDef="let measurement">{{ measurement.ean }}</td>
      </ng-container>
      <ng-container matColumnDef="start">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start</th>
        <td mat-cell *matCellDef="let measurement">{{ measurement.period.startDateTime | date: 'short' }}</td>
      </ng-container>
      <ng-container matColumnDef="end">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>End</th>
        <td mat-cell *matCellDef="let measurement">{{ measurement.period.toDateTime | date: 'short' }}</td>
      </ng-container>
      <ng-container matColumnDef="consumption">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption (kWh)</th>
        <td mat-cell *matCellDef="let measurement">{{ measurement.consumption?.value | number: '1.3' }}</td>
      </ng-container>
      <ng-container matColumnDef="production">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Production (kWh)</th>
        <td mat-cell *matCellDef="let measurement">{{ measurement.production?.value | number: '1.3' }}</td>
      </ng-container>
      <ng-container matColumnDef="sum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sum (kWh)</th>
        <td mat-cell *matCellDef="let measurement">{{ measurement.sum?.value | number: '1.3' }}</td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedMeasurementColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedMeasurementColumns"></tr>
    </table>

    <mat-paginator
      [showFirstLastButtons]="true"
      [pageSize]="30"
      [pageSizeOptions]="[10, 20, 30, 50, 100]"
      #measurementPaginator
    ></mat-paginator>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center><mat-icon svgIcon="ic-old:close"></mat-icon><span>Close</span></ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
