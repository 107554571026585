<mat-card *ngIf="gridPoint">
  <mat-card-header>
    <mat-card-title>Retrieve measurement data</mat-card-title>
    <mat-card-subtitle>{{ gridPoint.ean }} - {{ gridPoint.description }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form" class="single-column-form">
      <mat-form-field>
        <mat-label>Start date</mat-label>
        <input matInput [matDatepicker]="startDateDatepicker" formControlName="startDate" />
        <mat-datepicker-toggle matSuffix [for]="startDateDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #startDateDatepicker></mat-datepicker>
        <mat-error *ngIf="form.get('startDate').errors?.required"> Start date is required. </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>End date</mat-label>
        <input matInput [matDatepicker]="endDateDatepicker" formControlName="endDate" [errorStateMatcher]="crossFieldErrorMatcher" />
        <mat-datepicker-toggle matSuffix [for]="endDateDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #endDateDatepicker></mat-datepicker>
        <mat-error *ngIf="form.get('endDate').errors?.required"> End date is required. </mat-error>
        <mat-error *ngIf="form.errors?.periodExceedsOneMonth"> Period may not exceed one month. </mat-error>
        <mat-error *ngIf="form.errors?.periodWrong"> End date must be after start date. </mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="primary" (click)="onSubmit()" [disabled]="!form.valid || busyFetching">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:download"></mat-icon>
        <span>Retrieve data</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>

<mat-card *ngIf="busyFetching">
  <mat-card-content> Busy fetching measurements. </mat-card-content>
</mat-card>

<mat-card *ngIf="!busyFetching && result">
  <mat-card-content>
    Request sent to fetch {{ result.numberOfMeasurements }} measurements for {{ result.ean }} between
    {{ result.period.startDateTime | date: 'shortDate' }} and {{ result.period.toDateTime | date: 'shortDate' }}.
  </mat-card-content>
</mat-card>
