<mat-card>
  <mat-card-header>
    <mat-card-title>Unavailability Details</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form" class="flex-grid cols-2">
      <mat-form-field class="col-2">
        <mat-label>Customer</mat-label>
        <ph-flex-autocomplete
          phFlexCustomerDataSource
          [shouldEmitNull]="true"
          [shouldOnlyEmitIds]="true"
          formControlName="customerId"
          [shouldAutoSelectIfOnlyOneResult]="true"
          placeholder="Select customer"
        ></ph-flex-autocomplete>
        <mat-error *ngIf="form.get('customerId').errors?.required"> Customer is required. </mat-error>
        <mat-error *ngIf="form.get('customerId').errors?.invalidCustomer"> Select a customer from the list. </mat-error>
      </mat-form-field>

      <ng-container formGroupName="period">
        <ng-container formGroupName="startDateTime">
          <mat-form-field class="col-1">
            <mat-label>Start date</mat-label>
            <input matInput [matDatepicker]="startDatepicker" formControlName="date" />
            <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatepicker></mat-datepicker>
            <mat-error *ngIf="form.get('period.startDateTime.date').errors?.required"> Start date is required </mat-error>
          </mat-form-field>
          <mat-form-field class="col-1">
            <mat-label>Start time</mat-label>
            <input matInput formControlName="time" />
            <mat-hint>Example: 13:00</mat-hint>
            <mat-error *ngIf="form.get('period.startDateTime.time').errors?.required"> Start time is required </mat-error>
            <mat-error *ngIf="form.get('period.toDateTime.time').errors?.pattern">
              Start time should be formatted as hour:minute
            </mat-error>
          </mat-form-field>
        </ng-container>

        <ng-container formGroupName="toDateTime">
          <mat-form-field class="col-1">
            <mat-label>End date</mat-label>
            <input matInput [matDatepicker]="endDatepicker" formControlName="date" />
            <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatepicker></mat-datepicker>
            <mat-error *ngIf="form.get('period.toDateTime.date').errors?.required"> End date is required </mat-error>
          </mat-form-field>
          <mat-form-field class="col-1">
            <mat-label>End time</mat-label>
            <input matInput formControlName="time" />
            <mat-hint>Example: 13:00</mat-hint>
            <mat-error *ngIf="form.get('period.toDateTime.time').errors?.required"> End time is required </mat-error>
            <mat-error *ngIf="form.get('period.toDateTime.time').errors?.pattern"> End time should be formatted as hour:minute </mat-error>
          </mat-form-field>
        </ng-container>
      </ng-container>

      <mat-form-field class="col-2">
        <mat-label>Service agreement</mat-label>
        <mat-select formControlName="serviceAgreementId">
          <mat-option *ngFor="let serviceAgreement of serviceAgreements$ | async" [value]="serviceAgreement.id">
            {{ serviceAgreement.systemReference }} {{ serviceAgreement.reference ? '- ' + serviceAgreement.reference : '' }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('serviceAgreementId').errors?.required"> Service agreement is required. </mat-error>
        <mat-error *ngIf="form.get('serviceAgreementId').errors?.invalidServiceAgreement">
          No service agreement found that covers the selected period.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-2">
        <mat-label>Direction</mat-label>
        <mat-select formControlName="direction">
          <mat-option value="UPWARDS">Upwards</mat-option>
          <mat-option value="DOWNWARDS">Downwards</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('direction').errors?.required"> Direction is required. </mat-error>
      </mat-form-field>

      <ng-container formGroupName="power">
        <mat-form-field class="col-2">
          <mat-label>Power</mat-label>
          <input matInput type="number" formControlName="value" />
          <span matTextSuffix>MW</span>
          <mat-error *ngIf="form.get('power.value').errors?.required"> Power is required </mat-error>
          <mat-error *ngIf="form.get('power.value').errors?.min"> Power should be greater than 0 </mat-error>
          <mat-error *ngIf="form.get('power.value').errors?.pattern"> Only two decimals are allowed </mat-error>
        </mat-form-field>
      </ng-container>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="cta" (click)="onSubmit()" [disabled]="!form.valid || (saveButtonDisabled$ | async)">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:save"></mat-icon>
        <span>Save</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
