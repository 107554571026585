<mat-card>
  <mat-card-content>
    <mat-toolbar class="filter-bar">
      <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
      <span class="fill-remaining-space"></span>
      <mat-slide-toggle (click)="toggleShowAll()" class="minimize"> Show only active operations </mat-slide-toggle>
    </mat-toolbar>
    <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Reference</th>
        <td mat-cell *matCellDef="let operation">
          <a routerLink="/operations/{{ operation.id }}">{{ operation.reference }}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="notificationTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Notification time</th>
        <td mat-cell *matCellDef="let operation">{{ operation.notificationTime | date : 'short' }}</td>
      </ng-container>
      <ng-container matColumnDef="startTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start time</th>
        <td mat-cell *matCellDef="let operation">{{ operation.period.startDateTime | date : 'short' }}</td>
      </ng-container>
      <ng-container matColumnDef="endTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>End time</th>
        <td mat-cell *matCellDef="let operation">{{ operation.period.toDateTime | date : 'short' }}</td>
      </ng-container>
      <ng-container matColumnDef="direction">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Direction</th>
        <td mat-cell *matCellDef="let operation" [ngSwitch]="operation.direction">
          <ph-flex-vertical-center>
            <ng-container *ngSwitchCase="'UPWARDS'"
              ><mat-icon matTooltip="Upwards" class="status-up" svgIcon="ic-old:arrow-up"></mat-icon
            ></ng-container>
            <ng-container *ngSwitchCase="'DOWNWARDS'"
              ><mat-icon matTooltip="Downwards" class="status-down" svgIcon="ic-old:arrow-down"></mat-icon
            ></ng-container>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <ng-container matColumnDef="requestedPower">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Requested power</th>
        <td mat-cell *matCellDef="let operation">{{ operation.requestedPower | capacity }}</td>
      </ng-container>
      <ng-container matColumnDef="flags">
        <th mat-header-cell *matHeaderCellDef>Flags</th>
        <td mat-cell *matCellDef="let operation">
          <mat-chip-set>
            <mat-chip selected disabled [ngClass]="operation.ended ? '' : 'active'">{{ operation.ended ? 'ended' : 'running' }}</mat-chip>
            <mat-chip selected disabled>{{ operation.manual ? 'manual' : 'automatic' }}</mat-chip>
            <mat-chip selected disabled *ngIf="operation.cancelled">
              {{ operation.cancelled ? 'cancelled' : '' }}
            </mat-chip>
          </mat-chip-set>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let operation">
          <ph-flex-vertical-center>
            <ng-container *ngIf="!operation.active">
              <button mat-icon-button class="bigger" matTooltip="Activate" (click)="activate(operation)">
                <mat-icon svgIcon="ic-old:toggle-off"></mat-icon>
              </button>
            </ng-container>
            <ng-container *ngIf="operation.active">
              <button mat-icon-button class="mat-success bigger" matTooltip="Deactivate" (click)="deactivate(operation)">
                <mat-icon svgIcon="ic-old:toggle-on"></mat-icon>
              </button>
              <button mat-icon-button matTooltip="Edit" routerLink="/operations/{{ operation.id }}">
                <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
              </button>
            </ng-container>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
  </mat-card-content>
  <mat-card-actions>
    <button mat-flat-button color="primary" routerLink="/operations/new">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:add"></mat-icon>
        <span>Create new operation</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
