import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Customer } from 'flex-app-shared';
import { CustomersFacade } from '../../store/customers/states/customers/customers.facade';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { get } from 'lodash-es';
import { Location } from '@angular/common';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnDestroy, OnInit, AfterViewInit {
  readonly onDestroy$ = new Subject<void>();
  readonly displayedColumns: ReadonlyArray<string> = ['name', 'legalName', 'billingId', 'actions'];
  readonly dataSource: MatTableDataSource<Customer> = new MatTableDataSource<Customer>([]);

  @Input() isStandalone = false;
  @Input() defaultPageSize = 30;

  @ViewChild(MatPaginator, { static: false }) private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) private readonly sort: MatSort;

  constructor(private customersFacade: CustomersFacade, private location: Location) {}

  goBack(): void {
    this.location.back();
  }

  ngOnInit(): void {
    this.customersFacade.init();
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      if (sortHeaderId === 'billingId') {
        return `${data.billingId}`;
      }
      return get(data, sortHeaderId);
    };

    this.customersFacade.customers$.pipe(takeUntil(this.onDestroy$)).subscribe((customers) => {
      this.dataSource.data = customers;
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  delete(customer: Customer): void {
    this.customersFacade.delete(customer);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }
}
