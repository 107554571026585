<section class="injected-page-title-and-nav">
  <section class="flex-row">
    <app-dashboard-customer
      class="col-1"
      [customerIdFromURL]="customerId"
      [isForbidden]="(permissions.customer | async) === false"
      (customerSelected)="onCustomerSelected($event)"
    >
    </app-dashboard-customer>
  </section>

  <section class="dashboard internal cols-3">
    <app-dashboard-service-agreements
      class="col-1"
      [customerId]="customerId"
      [isInitialized]="isCardsInitialized"
      [isForbidden]="(permissions.serviceAgreements | async) === false"
    >
    </app-dashboard-service-agreements>
    <app-dashboard-gridpoints
      class="col-1"
      [customerId]="customerId"
      [isInitialized]="isCardsInitialized"
      [isForbidden]="(permissions.gridpoints | async) === false"
    >
    </app-dashboard-gridpoints>

    <app-dashboard-controls
      class="col-1"
      [customerId]="customerId"
      [isInitialized]="isCardsInitialized"
      [isForbidden]="(permissions.controls | async) === false"
    >
    </app-dashboard-controls>
    <app-dashboard-devices
      class="col-1"
      [customerId]="customerId"
      [isInitialized]="isCardsInitialized"
      [isForbidden]="(permissions.devices | async) === false"
    >
    </app-dashboard-devices>

    <app-dashboard-recipients
      class="col-1"
      [customerId]="customerId"
      [isInitialized]="isCardsInitialized"
      [isForbidden]="(permissions.recipients | async) === false"
    >
    </app-dashboard-recipients>
    <app-dashboard-unavailabilities
      class="col-1"
      [customerId]="customerId"
      [isInitialized]="isCardsInitialized"
      [isForbidden]="(permissions.unavailabilities | async) === false"
    >
    </app-dashboard-unavailabilities>
    <app-dashboard-users
      class="col-1"
      [customerId]="customerId"
      [isInitialized]="isCardsInitialized"
      [isForbidden]="(permissions.users | async) === false"
    >
    </app-dashboard-users>
  </section>
</section>
