<mat-card>
  <mat-card-content>
    <ph-flex-notification type="success" *ngIf="facade.showNotification$ | async">Measurements saved.</ph-flex-notification>
    <mat-vertical-stepper linear>
      <ng-template matStepperIcon="edit">
        <mat-icon svgIcon="ic-old:inline-edit-o"></mat-icon>
      </ng-template>

      <ng-template matStepperIcon="done">
        <mat-icon svgIcon="ic-old:check"></mat-icon>
      </ng-template>
      <mat-step [stepControl]="dateForm" label="Specify date" #step1>
        <div *matStepLabel class="step-label">
          <span class="title">Calendar day</span>
          <span class="subtitle">
            <ng-container *ngIf="showValueSubtitle(step1)">{{ dateForm.get('date').value | date }}</ng-container>
            <ng-container *ngIf="showDescriptiveSubtitle(step1)">Select date</ng-container>
          </span>
        </div>
        <mat-form-field class="compact">
          <mat-label>Date</mat-label>
          <input matInput [matDatepicker]="datePicker" [formControl]="dateForm.get('date')" [max]="maxDate" />
          <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
          <mat-datepicker #datePicker></mat-datepicker>
          <mat-error *ngIf="dateForm.get('date').hasError('required')"> Date is required.</mat-error>
          <mat-error *ngIf="dateForm.get('date').hasError('matDatepickerMax')"> Date cannot be after today.</mat-error>
        </mat-form-field>
        <div>
          <button mat-flat-button color="primary" matStepperNext type="button">
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:arrow_downward"></mat-icon>
              <span>Next</span>
            </ph-flex-vertical-center>
          </button>
        </div>
      </mat-step>

      <mat-step [stepControl]="gridPointIdForm" label="Specify grid point" #step2>
        <div *matStepLabel class="step-label">
          <span class="title">Grid point</span>
          <span class="subtitle">
            <ng-container *ngIf="showValueSubtitle(step2)">{{
              gridPointIdForm.get('gridPointId').value | gridPoint$ | async
            }}</ng-container>
            <ng-container *ngIf="showDescriptiveSubtitle(step2)">Select grid point</ng-container>
          </span>
        </div>
        <div class="horizontal-form-fields">
          <mat-form-field class="ph-flex-mat-form-field-wide">
            <mat-label>Customer</mat-label>
            <ph-flex-autocomplete
              phFlexCustomerDataSource
              [shouldEmitNull]="true"
              [shouldOnlyEmitIds]="true"
              [formControl]="gridPointIdForm.get('customerId')"
              [shouldAutoSelectIfOnlyOneResult]="true"
              placeholder="Select customer"
            ></ph-flex-autocomplete>
            <mat-error>
              <ph-flex-form-error-messages [control]="gridPointIdForm.get('customerId')"></ph-flex-form-error-messages>
            </mat-error>
          </mat-form-field>
          <mat-form-field class="ph-flex-mat-form-field-wide">
            <mat-label>Grid Point</mat-label>
            <ph-flex-autocomplete
              phFlexGridPointDataSource
              [phFlexDataSourceFilterByCustomerId]="gridPointIdForm.get('customerId').value"
              [shouldEmitNull]="true"
              [shouldOnlyEmitIds]="true"
              [formControl]="gridPointIdForm.get('gridPointId')"
              [shouldAutoSelectIfOnlyOneResult]="true"
              [disabled]="gridPointIdForm.get('customerId').invalid"
              placeholder="Select grid point"
            ></ph-flex-autocomplete>
            <mat-error>
              <ph-flex-form-error-messages [control]="gridPointIdForm.get('gridPointId')"></ph-flex-form-error-messages>
            </mat-error>
          </mat-form-field>
        </div>
        <div class="button-container">
          <button mat-stroked-button color="primary" matStepperPrevious>
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:arrow_upward"></mat-icon>
              <span>Previous</span>
            </ph-flex-vertical-center>
          </button>
          <button mat-flat-button color="primary" matStepperNext type="button">
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:arrow_downward"></mat-icon>
              <span>Next</span>
            </ph-flex-vertical-center>
          </button>
        </div>
      </mat-step>
      <mat-step label="Download template">
        <div class="step-content">
          <ph-flex-save-button (click)="facade.downloadTemplate()" [pending]="facade.isDownloadBusy$ | async"
            >Download template
          </ph-flex-save-button>
        </div>
        <div class="button-container">
          <button mat-stroked-button color="primary" matStepperPrevious>
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:arrow_upward"></mat-icon>
              <span>Previous</span>
            </ph-flex-vertical-center>
          </button>
          <button mat-flat-button color="primary" matStepperNext type="button">
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:arrow_downward"></mat-icon>
              <span>Next</span>
            </ph-flex-vertical-center>
          </button>
        </div>
      </mat-step>
      <mat-step label="Upload data" [completed]="facade.hasUploaded$ | async">
        <div class="step-content">
          <ph-flex-save-button
            (click)="onFileInputButtonClicked(fileInput)"
            [pending]="facade.isUploadBusy$ | async"
            [error]="facade.uploadFailed$ | async"
            >Upload data
          </ph-flex-save-button>
          <input hidden (change)="onFileSelected(fileInput)" #fileInput type="file" id="file" />
        </div>
        <div class="button-container">
          <button mat-stroked-button color="primary" matStepperPrevious>
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:arrow_upward"></mat-icon>
              <span>Previous</span>
            </ph-flex-vertical-center>
          </button>
          <button mat-flat-button color="primary" matStepperNext type="button" [disabled]="!(facade.hasUploaded$ | async)">
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:arrow_downward"></mat-icon>
              <span>Next</span>
            </ph-flex-vertical-center>
          </button>
        </div>
      </mat-step>
      <mat-step label="Verify data">
        <div class="step-content">
          <ng-container *ngIf="facade.previewDataSummary$ | async; let summary">
            <ph-flex-notification type="warn" *ngIf="summary.errorRows > 0"
              >Validation error on {{ summary.errorRows }} of {{ summary.totalRows }} rows.
            </ph-flex-notification>
            <ph-flex-notification *ngIf="summary.errorRows === 0">No Validation errors</ph-flex-notification>
          </ng-container>
          <table mat-table [dataSource]="previewDataSource" matSort>
            <ng-container matColumnDef="start">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Start</th>
              <td mat-cell *matCellDef="let measurement">{{ measurement.period.startDateTime | date: 'short' }}</td>
            </ng-container>
            <ng-container matColumnDef="end">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>End</th>
              <td mat-cell *matCellDef="let measurement">{{ measurement.period.toDateTime | date: 'short' }}</td>
            </ng-container>
            <ng-container matColumnDef="consumption">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption (kWh)</th>
              <td mat-cell *matCellDef="let measurement">{{ measurement.consumption?.value | number: '1.3' }}</td>
            </ng-container>
            <ng-container matColumnDef="production">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Production (kWh)</th>
              <td mat-cell *matCellDef="let measurement">{{ measurement.production?.value | number: '1.3' }}</td>
            </ng-container>
            <ng-container matColumnDef="aggregatedFiveMinuteData">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Agg. 5-min data (kWh)</th>
              <td mat-cell *matCellDef="let measurement">{{ measurement.aggregatedFiveMinuteData?.value | number: '1.3' }}</td>
            </ng-container>
            <ng-container matColumnDef="fifteenMinuteData">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>15-min data (kWh)</th>
              <td mat-cell *matCellDef="let measurement">{{ measurement.fifteenMinuteData?.value | number: '1.3' }}</td>
            </ng-container>
            <ng-container matColumnDef="remarks">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Remarks</th>
              <td mat-cell *matCellDef="let measurement">
                <app-measurement-data-upload-remarks [remarks]="measurement.remarks"></app-measurement-data-upload-remarks>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.has-error]="hasRemarks(row)"></tr>
          </table>
          <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]" #paginator></mat-paginator>
        </div>
        <div class="button-container">
          <button mat-stroked-button color="primary" matStepperPrevious>
            <ph-flex-vertical-center>
              <mat-icon svgIcon="ic-old:arrow_upward"></mat-icon>
              <span>Previous</span>
            </ph-flex-vertical-center>
          </button>
          <ph-flex-save-button (click)="confirm()" [pending]="facade.isConfirmBusy$ | async" [error]="facade.confirmFailed$ | async">
            Confirm
          </ph-flex-save-button>
        </div>
      </mat-step>
    </mat-vertical-stepper>
  </mat-card-content>
</mat-card>
