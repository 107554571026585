import { Injectable } from '@angular/core';
import { ServiceAgreementsState } from './service-agreements.state';
import { filter, first, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { DownloadServiceAgreementsCommand, FindAllServiceAgreementsCommand } from './service-agreements.actions';

@Injectable({
  providedIn: 'root'
})
export class ServiceAgreementsFacade {
  serviceAgreements$ = this.store.select(ServiceAgreementsState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(ServiceAgreementsState.getServiceAgreements))
  );
  isBusyDownloading$ = this.store.select(ServiceAgreementsState.isBusyDownloading);

  constructor(private store: Store) {}

  init(): void {
    this.store.dispatch(new FindAllServiceAgreementsCommand());
  }

  loadServiceAgreements(): void {
    this.store.dispatch(new FindAllServiceAgreementsCommand());
  }

  downloadServiceAgreementsOverview(): void {
    this.store.dispatch(new DownloadServiceAgreementsCommand());
  }
}
