import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, first, switchMap } from 'rxjs/operators';
import { Device } from '../../../../app/shared/device/device.service';
import { DeleteDeviceCommand, SaveDeviceCommand } from './device.actions';
import { DeviceState } from './device.state';

@Injectable({
  providedIn: 'root'
})
export class DeviceFacade {
  device$ = this.store.select(DeviceState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(DeviceState.getDevice))
  );

  constructor(private store: Store) {}

  save(): void {
    this.store.dispatch(new SaveDeviceCommand());
  }

  delete(device: Device): void {
    this.store.dispatch(new DeleteDeviceCommand(device));
  }
}
