import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDatepicker } from '@angular/material/datepicker';
import { AdjustmentDirection, FlexNgXsFormSync, MixinBase, OnDestroyMixin, TableFilterComponent } from 'flex-app-shared';
import { R3InvoiceDataFacade } from '../../store/r3-invoice-data/r3-invoice-data.facade';
import { R3InvoiceDataDatasource } from './r3-invoice-data.datasource';
import { MatDialog } from '@angular/material/dialog';
import {
  R3InvoiceDataRecalculateConfirmDialogComponent,
  R3InvoiceDataRecalculateConfirmDialogData
} from './r3-invoice-data-recalculate-confirm-dialog/r3-invoice-data-recalculate-confirm-dialog.component';
import { IncidentReserveInvoiceData } from '../shared/r3-invoice-data/r3-invoice-data';

@Component({
  selector: 'app-r3-invoice-data',
  templateUrl: './r3-invoice-data.component.html',
  styleUrls: ['./r3-invoice-data.component.scss']
})
export class R3InvoiceDataComponent extends OnDestroyMixin(MixinBase) implements AfterViewInit {
  AdjustmentDirection = AdjustmentDirection;

  @FlexNgXsFormSync('r3InvoiceData.filter', { initialSyncOnlyTruthyFormValues: true })
  form = this.builder.group({
    selectedDate: ['', [Validators.required]],
    direction: ['']
  });

  dataSource = new R3InvoiceDataDatasource(this);

  displayedColumns$ = this.facade.displayedColumns$;
  dateColumns$ = this.facade.dateColumns$;

  @ViewChild(TableFilterComponent)
  private readonly tableFilter: TableFilterComponent;

  @ViewChild(MatPaginator, { static: true })
  private readonly paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  private readonly sort: MatSort;

  constructor(private builder: UntypedFormBuilder, public facade: R3InvoiceDataFacade, public dialog: MatDialog) {
    super();
  }

  chosenDateHandler(selectedMoment: Date, datepicker: MatDatepicker<any>): void {
    this.form.get('selectedDate').setValue(selectedMoment);
    datepicker.close();
  }

  openConfirmDialog(row?: IncidentReserveInvoiceData): void {
    const data: R3InvoiceDataRecalculateConfirmDialogData = row
      ? {
          serviceAgreementId: row.serviceAgreementId,
          customerName: row.customerName,
          customerSaReference: row.serviceAgreementSystemReference
        }
      : undefined;

    this.dialog.open(R3InvoiceDataRecalculateConfirmDialogComponent, {
      data
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filter = this.tableFilter;
  }
}
