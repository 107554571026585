import { LocalTime } from './timepicker.component';
import moment from 'moment';

export function getLocalTime(time: string, separator: string = ':'): LocalTime {
  if (!moment(time, 'HH:mm').isValid()) throw new Error(`Invalid time provided: ${time}. Provided separator is ${separator}`);
  const [hours, minutes] = time.split(separator);

  return {
    hours: makeTwoDigits(hours),
    minutes: makeTwoDigits(minutes)
  };
}

export function makeTwoDigits(val: string): string {
  return val.length === 1 ? '0' + val : val;
}

export function isShouldIncreaseDate(time: LocalTime, unit: 'hours' | 'minutes'): boolean {
  const hours = makeTwoDigits(time.hours);
  const minutes = makeTwoDigits(time.minutes);

  switch (unit) {
    case 'hours':
      return hours === '23';
    case 'minutes':
      return hours === '23' && minutes === '59';
  }
}

export function isShouldDecreaseDate(time: LocalTime, unit: 'hours' | 'minutes'): boolean {
  const hours = makeTwoDigits(time.hours);
  const minutes = makeTwoDigits(time.minutes);

  switch (unit) {
    case 'hours':
      return hours === '00';
    case 'minutes':
      return hours === '00' && minutes === '00';
  }
}
