<mat-card>
  <mat-card-header>
    <mat-card-title>Imbalance prices</mat-card-title>
  </mat-card-header>
  <mat-card-actions class="imbalance-prices-actions">
    <ph-flex-date-range [formControl]="form.get('period')" subscriptSizing="dynamic"></ph-flex-date-range>

    <button mat-stroked-button (click)="getImbalancePrices()">
      <ph-flex-vertical-center *ngIf="imbalancePricesResult && !imbalancePricesError" class="status-success">
        <mat-icon svgIcon="ic-old:check-circle-o"></mat-icon>
        <span>Retrieved {{ imbalancePricesResult.daysRetrieved }} day(s) of imbalance prices</span>
      </ph-flex-vertical-center>
      <ph-flex-vertical-center *ngIf="!imbalancePricesResult && !retrievingPrices && !imbalancePricesError"
        ><mat-icon svgIcon="ic-old:source-external"></mat-icon><span>Retrieve imbalance prices</span>
      </ph-flex-vertical-center>
      <ph-flex-vertical-center *ngIf="retrievingPrices" class="spinner-button">
        <mat-spinner diameter="19"></mat-spinner>
        <span>Retrieving prices...</span>
      </ph-flex-vertical-center>
      <ph-flex-vertical-center
        *ngIf="imbalancePricesError"
        matTooltip="{{ imbalancePricesResult?.errorMessage | json }}"
        class="status-alert"
      >
        <mat-icon svgIcon="ic-old:cancel-circle-o"></mat-icon>
        <span>Error while retrieving imbalance prices!</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
