<mat-card>
  <mat-card-content>
    <mat-toolbar class="filter-bar">
      <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
    </mat-toolbar>
    <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
      <ng-container matColumnDef="kind">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let input">Analog input</td>
      </ng-container>
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let input">
          <a routerLink="/analog-inputs/{{ input.id }}">{{ input.description }}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Subtype</th>
        <td mat-cell *matCellDef="let input">
          <div *ngIf="input.type === AnalogInputType.HEAT_BUFFER">Heat buffer</div>
          <div *ngIf="input.type === AnalogInputType.SOLAR_IRRADIANCE">Solar irradiance</div>
          <div *ngIf="input.type === AnalogInputType.STATE_OF_CHARGE">State of charge</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
        <td mat-cell *matCellDef="let input">{{ input.customerName }}</td>
      </ng-container>
      <ng-container matColumnDef="inputSignalType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Input Signal Type</th>
        <td mat-cell *matCellDef="let input">
          {{ signalTypes.analogInputSignalType[input.calibration?.signalType] || 'n/a' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="provider">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Provider</th>
        <td mat-cell *matCellDef="let input">{{ input.providerId }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let input">
          <ph-flex-vertical-center>
            <button mat-icon-button matTooltip="Edit" routerLink="/analog-inputs/{{ input.id }}">
              <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
            </button>
            <button mat-icon-button matTooltip="Delete" class="remove" (click)="delete(input)">
              <mat-icon svgIcon="ic-old:trash-o"></mat-icon>
            </button>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
  </mat-card-content>
  <mat-card-actions>
    <button mat-flat-button color="primary" routerLink="/analog-inputs/new">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:add"></mat-icon>
        <span>New input</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
