import { Recipient } from '../../../../app/shared/recipient/recipient';
import { SaveAction } from 'flex-app-shared';

export class ResetRecipientCommand {
  public static readonly type = '[Recipient] Reset recipient action';

  constructor(public payload?: Partial<Recipient>) {}
}

export class LoadRecipientCommand {
  public static readonly type = '[Recipient] Load recipient data';

  constructor(public id: string) {}
}

export class RecipientLoadedEvent {
  public static readonly type = '[Recipient] Recipient loaded event';

  constructor(public payload: Recipient) {}
}

export class SaveRecipientCommand implements SaveAction<Recipient> {
  public static readonly type = '[Recipient] Save recipient data';

  constructor(public payload: Partial<Recipient>, public navigateAfterSave: boolean = true) {}
}
