import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Capacity, Volume } from 'flex-app-shared';
import { Observable } from 'rxjs';

export enum SettingDataType {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
  VOLUME = 'VOLUME',
  CAPACITY = 'CAPACITY'
}

export interface IApplicationSettings {
  name: string;
  dataType: SettingDataType;
  value: string | number | boolean | Volume | Capacity;
}

interface NumberApplicationSetting extends IApplicationSettings {
  name: string;
  dataType: SettingDataType.NUMBER;
  value: number;
}

export interface StringApplicationSetting extends IApplicationSettings {
  name: string;
  dataType: SettingDataType.TEXT;
  value: string;
}

export interface BooleanApplicationSetting extends IApplicationSettings {
  name: string;
  dataType: SettingDataType.BOOLEAN;
  value: boolean;
}

export interface VolumeApplicationSetting extends IApplicationSettings {
  name: string;
  dataType: SettingDataType.VOLUME;
  value: Volume;
}

export interface CapacityApplicationSetting extends IApplicationSettings {
  name: string;
  dataType: SettingDataType.CAPACITY;
  value: Capacity;
}

export function isNumberApplicationSetting(settings: IApplicationSettings): settings is NumberApplicationSetting {
  return settings.dataType === SettingDataType.NUMBER;
}

export function isStringApplicationSetting(settings: IApplicationSettings): settings is StringApplicationSetting {
  return settings.dataType === SettingDataType.TEXT;
}

export function isBooleanApplicationSetting(settings: IApplicationSettings): settings is BooleanApplicationSetting {
  return settings.dataType === SettingDataType.BOOLEAN;
}

export function isVolumeApplicationSetting(settings: IApplicationSettings): settings is VolumeApplicationSetting {
  return settings.dataType === SettingDataType.VOLUME;
}

export function isCapacityApplicationSetting(settings: IApplicationSettings): settings is CapacityApplicationSetting {
  return settings.dataType === SettingDataType.CAPACITY;
}

export type ApplicationSetting =
  | NumberApplicationSetting
  | StringApplicationSetting
  | BooleanApplicationSetting
  | VolumeApplicationSetting
  | CapacityApplicationSetting;

@Injectable({
  providedIn: 'root'
})
export class ApplicationSettingsService {
  constructor(private http: HttpClient) {}

  getAll(): Observable<ReadonlyArray<ApplicationSetting>> {
    return this.http.get<ReadonlyArray<ApplicationSetting>>(`/api/v1/config/settings`);
  }

  save(setting: ApplicationSetting): Observable<object> {
    return this.http.put(`/api/v1/config/settings/${setting.name}`, setting);
  }
}
