<mat-card>
  <mat-card-header>
    <mat-card-title>Measurement company details</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form" class="single-column-form">
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
        <mat-error *ngIf="form.get('name').errors?.required"> Name is required. </mat-error>
        <mat-error *ngIf="form.get('name').errors?.maxlength"> Name must not be longer than 250 characters. </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Provider</mat-label>
        <mat-select formControlName="measurementProvider">
          <mat-option *ngFor="let provider of measurementProviders" [value]="provider.provider">
            {{ provider.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center><mat-icon svgIcon="ic-old:arrow_back"></mat-icon><span>Go back</span></ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="cta" (click)="onSubmit()" [disabled]="!form.valid">
      <ph-flex-vertical-center><mat-icon svgIcon="ic-old:save"></mat-icon><span>Save</span></ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
