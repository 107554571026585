<mat-card [class.active]="currentActiveIncidentReserveDirection$ | async" class="incident-reserve-status">
  <mat-card-content>
    <h3>Activatiestatus</h3>
    <div class="description" *ngIf="!(currentActiveIncidentReserveDirection$ | async)">
      <div>
        <mat-chip-set>
          <mat-chip class="chip-inactive" selected>Niet bezig</mat-chip>
        </mat-chip-set>
      </div>
      Geen activatie bezig. Laatste activatie is gestopt op &nbsp;
      <b
        >{{ lastIncidentReserveNotificationTime$ | async | date : 'shortDate' }} om
        {{ lastIncidentReserveNotificationTime$ | async | date : 'shortTime' }}</b
      >.
    </div>
    <div class="description" *ngIf="currentActiveIncidentReserveDirection$ | async">
      <div>
        <mat-chip-set>
          <mat-chip class="chip-active" selected>Bezig</mat-chip>
        </mat-chip-set>
      </div>
      <p>
        Activatie voor
        <ng-container [ngSwitch]="currentActiveIncidentReserveDirection$ | async">
          <b *ngSwitchCase="direction.DOWNWARDS" class="icon-b">
            <mat-icon class="status-down" svgIcon="ic-old:arrow-down"></mat-icon>
            afregelend</b
          >
          <b *ngSwitchCase="direction.UPWARDS" class="icon-b">
            <mat-icon class="status-up" svgIcon="ic-old:arrow-up"></mat-icon>
            opregelend</b
          >
        </ng-container>
        noodvermogen <b>bezig</b>. Gestart op <b>{{ lastIncidentReserveNotificationTime$ | async | date : 'shortDate' }}</b> om
        <b>{{ lastIncidentReserveNotificationTime$ | async | date : 'shortTime' }}</b
        >. Je hoeft <b>geen activatie te starten</b> op verzoek van Tennet. Dit is op de achtergrond al door het systeem afgehandeld.
      </p>
    </div>
  </mat-card-content>
</mat-card>
<mat-card>
  <form [formGroup]="form">
    <mat-card-content>
      <div class="info-box">
        <mat-icon svgIcon="ic-old:info_outline"></mat-icon>
        <p>
          Bij elke afwijking van het normale proces, graag eerst het proces zo goed mogelijk doorlopen en daarna onmiddelijk telefonisch
          contact opnemen met Powerhouse. Benader hiervoor één van de volgende contactpersonen:
        </p>
        <ol>
          <li *ngFor="let contact of contacts$ | async">
            {{ contact.name }} (<a href="tel:{{ contact.phone }}">{{ contact.phone }}</a
            >)
          </li>
        </ol>
        <button (click)="openEditContacts()" mat-stroked-button [phFlexAuthShow]="[VIEW_ADMIN]">Bijwerken contacten</button>
      </div>
      <mat-divider class="spacer__medium"></mat-divider>
      <section>
        <h3 id="operation-type-radio-label">Start of stop van activatie</h3>
        <mat-radio-group formControlName="operationType" class="vertical" aria-labelledby="operation-type-radio-label">
          <mat-radio-button [value]="operationType.start">
            <mat-icon class="status-play" svgIcon="ic-old:play"></mat-icon>
            Start
          </mat-radio-button>
          <mat-radio-button [value]="operationType.end">
            <mat-icon class="status-stop" svgIcon="ic-old:stop"></mat-icon>
            Stop
          </mat-radio-button>
        </mat-radio-group>
        <div class="info-box">
          <mat-icon svgIcon="ic-old:info_outline"></mat-icon>
          <p>
            Een instructie voor een activatie danwel afroep voor noodvermogen door Tennet begint normaal gezien met een
            <em>START </em> melding. Meestal (maar niet altijd) bellen ze binnen een uur nog een keer met een <em>STOP</em> melding.
          </p>
        </div>
      </section>

      <mat-divider class="spacer__medium"></mat-divider>

      <section>
        <h3 id="direction-radio-label">Selecteer richting</h3>
        <mat-radio-group formControlName="direction" class="vertical" aria-labelledby="direction-radio-label">
          <mat-radio-button [value]="direction.UPWARDS">
            <mat-icon class="status-up" svgIcon="ic-old:arrow-up"></mat-icon>
            Opregelend noodvermogen
          </mat-radio-button>
          <mat-radio-button [value]="direction.DOWNWARDS">
            <mat-icon class="status-down" svgIcon="ic-old:arrow-down"></mat-icon>
            Afregelend noodvermogen
          </mat-radio-button>
        </mat-radio-group>
        <div class="info-box">
          <mat-icon svgIcon="ic-old:info_outline"></mat-icon>
          <p>Tennet geeft aan of wij meer of minder elektriciteit moeten produceren. Ze kunnen hier verschillende termen voor gebruiken.</p>
        </div>
      </section>
    </mat-card-content>
  </form>

  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="cta" (click)="showConfirmPopup()" [disabled]="form.invalid">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:save"></mat-icon>
        <span>Registreer</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
