export const environment = {
  production: true,
  requireAuthentication: true,
  serviceWorker: true,
  enableAppInsights: true
};

// Enable error reporting for zone js even in production, since we aren't expecting many errors anyway.
import 'zone.js/plugins/zone-error'; // Included with Angular CLI.
// import 'zone.js/plugins/zone-patch-rxjs';
