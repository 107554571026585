import { MessageType, AsyncMessage } from 'flex-app-shared';

export class DayAheadDealsReceivedInfo {
  public eansProcessed: number = 0;
  public eansTotal: number = 0;
  public eansToProcess: number = 0;
  public dealsReceived: number = 0;
  public success: boolean = false;
  public done: boolean = false;
  public message: string = null;
}

export class SseDayAheadDealsReceivedEvent {
  public static readonly type = '[SSE] Day ahead deals received event';
  public static readonly sseType = MessageType.DayAheadDealsReceivedSseEvent;

  constructor(public message: AsyncMessage) {}
}

export class ResetDayAheadDealsReceivedInfo {
  public static readonly type = '[SSE] Reset day ahead deals info';
}
