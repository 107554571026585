import { Component, OnInit, ViewChild } from '@angular/core';
import { AggregatedUnavailabilitiesFacade } from '../../store/unavailabilites/states/aggregated-unavailabilities/aggregated-unavailabilities.facade';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AggregatedUnavailabilityView } from '../shared/unavailability/aggregated-unavailability';
import { get } from 'lodash-es';

@Component({
  selector: 'app-unavailabilities-send-dialog',
  templateUrl: './unavailabilities-send-dialog.component.html',
  styleUrls: ['./unavailabilities-send-dialog.component.scss']
})
export class UnavailabilitiesSendDialogComponent implements OnInit {
  aggregatedUnavailabilities$ = this.aggregatedUnavailabilitiesFacade.aggregatedUnavailabilities$;

  readonly displayedColumns = ['period', 'direction', 'power'];
  readonly dataSource: MatTableDataSource<AggregatedUnavailabilityView> = new MatTableDataSource<AggregatedUnavailabilityView>([]);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  constructor(
    private aggregatedUnavailabilitiesFacade: AggregatedUnavailabilitiesFacade,
    public dialogRef: MatDialogRef<UnavailabilitiesSendDialogComponent>
  ) {}

  ngOnInit(): void {
    this.aggregatedUnavailabilitiesFacade.init();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      if (sortHeaderId === 'period') {
        return data.period.startDateTime.date;
      }
      return get(data, sortHeaderId);
    };

    this.aggregatedUnavailabilities$.subscribe((unavalabilities) => (this.dataSource.data = unavalabilities.unavailabilities));
  }

  send(): void {
    this.aggregatedUnavailabilitiesFacade.send();
    this.dialogRef.close();
  }
}
