import { Location } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterStateSnapshot } from '@angular/router';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  EntityStateModel,
  FetchEntity,
  LocalDate,
  MessageType,
  RouterInitializer,
  SaveEntity,
  AsyncMessagingService
} from 'flex-app-shared';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Pool, PoolAvailabilityGridPoint, PoolPeriodView, PoolService, PoolView } from '../../../../app/shared/pool/pool.service';
import { PeriodScheduler } from '../pool-period/pool-period-scheduler';
import {
  ApplyPoolPeriodCommand,
  LoadPoolAvailabilitiesCommand,
  LoadPoolCommand,
  PoolChangedEvent,
  ResetPoolCommand,
  SavePoolCommand,
  SendPoolCommand
} from './pool.actions';

class PoolStateModel extends EntityStateModel<Pool> {
  dirty = false;
  model: Pool = {
    poolPeriods: []
  };
  availableGridPoints: PoolAvailabilityGridPoint[] = [];
}

@State<PoolStateModel>({
  name: 'poolState',
  defaults: new PoolStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class PoolState implements RouterInitializer<PoolStateModel> {
  constructor(
    private poolService: PoolService,
    protected store: Store,
    private matDialogService: MatDialog,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    private location: Location,
    private asyncMessagingService: AsyncMessagingService
  ) {}

  @Selector()
  public static isDirty(state: PoolStateModel): boolean {
    return state.dirty;
  }

  @Selector()
  public static getPool(state: PoolStateModel): PoolView {
    return {
      ...state.model,
      poolPeriods: state.model.poolPeriods
        .map((poolPeriod) => {
          return PoolPeriodView.deserialize(poolPeriod);
        })
        .sort((a, b) => -LocalDate.compare(a.period.startDate, b.period.startDate))
    };
  }

  @Selector()
  public static getAvailableGridPoints(state: PoolStateModel): PoolAvailabilityGridPoint[] {
    return state.availableGridPoints;
  }

  @Selector()
  public static isBusy(state: PoolStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isInitialized(state: PoolStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static isBusySending(state: PoolStateModel): boolean {
    return state.isBusySending;
  }

  @Selector()
  public static error(state: PoolStateModel): string | null {
    return state.sendError;
  }

  @Action(RouterNavigation)
  routerInit({ getState, dispatch }: StateContext<PoolStateModel>, { routerState }: RouterNavigation<RouterStateSnapshot>): void {
    if (routerState.url.startsWith('/pools')) {
      dispatch(new LoadPoolCommand());
    }
  }

  @Action(ResetPoolCommand)
  resetPool({ setState }: StateContext<PoolStateModel>): void {
    setState(new PoolStateModel());
  }

  @FetchEntity()
  @Action(LoadPoolCommand)
  loadPool({ dispatch }: StateContext<PoolStateModel>): Observable<Pool> | void {
    return this.poolService.getMainPool().pipe(tap((pool) => dispatch(new PoolChangedEvent(pool))));
  }

  @SaveEntity()
  @Action(SavePoolCommand)
  savePool({ getState, setState }: StateContext<PoolStateModel>): Observable<Pool> {
    const pool = {
      ...getState().model
    };

    const observable = pool.id ? this.poolService.update(pool) : this.poolService.add(pool);
    return observable.pipe(
      tap(() => {
        setState({
          ...getState(),
          dirty: false
        });
        this.snackBar.open('Pool configuration saved', 'ok', {
          duration: 3000
        });
        this.store.dispatch(new LoadPoolCommand());
      })
    );
  }

  @Action(PoolChangedEvent)
  setPoolData({ setState, getState }: StateContext<PoolStateModel>, { payload }: PoolChangedEvent): void {
    const state = getState();
    setState({
      ...state,
      model: {
        ...state.model,
        ...payload
      }
    });
  }

  @Action(ApplyPoolPeriodCommand)
  applyPoolPeriod({ getState, setState, dispatch }: StateContext<PoolStateModel>, { payload }: ApplyPoolPeriodCommand): void {
    const periodScheduler = new PeriodScheduler(getState().model.poolPeriods.map((period) => PoolPeriodView.deserialize(period)));

    periodScheduler.addPeriod(payload);

    setState({
      ...getState(),
      dirty: true
    });
    dispatch(
      new PoolChangedEvent({
        poolPeriods: periodScheduler.poolPeriods.map((poolPeriod) => {
          return PoolPeriodView.serialize(poolPeriod);
        })
      })
    );
  }

  @Action(LoadPoolAvailabilitiesCommand)
  @FetchEntity()
  loadPoolAvailabilities(
    { getState, setState, dispatch }: StateContext<PoolStateModel>,
    { period }: LoadPoolAvailabilitiesCommand
  ): Observable<PoolAvailabilityGridPoint[]> {
    setState({
      ...getState()
    });

    return this.poolService.findAvailability(period).pipe(
      tap((result) => {
        setState({
          ...getState(),
          availableGridPoints: result
        });
      })
    );
  }

  @Action(SendPoolCommand)
  sendPool(): void {
    this.poolService.sendPool().subscribe(
      (v) => {
        if (!this.asyncMessagingService.isConnected) {
          this.snackBar.open('Pool configuration has been sent to TenneT, check messages for confirmation.', 'ok');
          return;
        }

        switch (v.type) {
          case MessageType.Response:
            this.snackBar.open('Sending pool configuration to TenneT', 'ok');
            break;
          case MessageType.TsoMonthlyPoolUpdateFailedSseEvent:
            this.snackBar.open('Error: Sending pool to TenneT failed', 'ok');
            break;
          case MessageType.TsoMonthlyPoolUpdatedSseEvent:
            this.snackBar.open('Pool configuration was accepted by TenneT', 'ok', { duration: 5000 });
            break;
        }
        this.store.dispatch(new LoadPoolCommand());
      },
      () => {
        this.snackBar.open('Error: Pool configuration could not be sent', 'ok');
      }
    );
  }
}
