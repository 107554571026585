import { Injectable } from '@angular/core';
import { RecipientsState } from './recipients.state';
import { filter, first, switchMap } from 'rxjs/operators';
import { Recipient } from '../../../../app/shared/recipient/recipient';
import { Store } from '@ngxs/store';
import { DeleteRecipientCommand, LoadRecipientsCommand } from './recipients.actions';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecipientsFacade {
  constructor(private store: Store) {}

  recipients$ = this.store.select(RecipientsState.isBusy).pipe(
    filter((a) => !a),
    first(),
    switchMap(() => this.store.select(RecipientsState.getRecipients))
  );

  delete(recipient: Recipient): Observable<any> {
    return this.store.dispatch(new DeleteRecipientCommand(recipient));
  }

  init(): void {
    this.store.dispatch(new LoadRecipientsCommand());
  }
}
