import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, first, switchMap } from 'rxjs/operators';
import { LoadDeviceCommand } from '../device/device.actions';
import { HideDeviceDetailsCommand, LoadDevicesCommand, ShowDeviceDetailsCommand } from './devices.actions';
import { DevicesState } from './devices.state';

@Injectable({
  providedIn: 'root'
})
export class DevicesFacade {
  constructor(private store: Store) {}

  devices$ = this.store.select(DevicesState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(DevicesState.getDevices))
  );

  expandedDeviceId$ = this.store.select(DevicesState.getExpandedDeviceId);
  selectedCustomerId$ = this.store.select(DevicesState.getSelectedCustomerId);

  init(): void {
    this.loadDevices();
  }

  loadDevice(deviceId: string, showForm: boolean = false): void {
    this.store.dispatch(new LoadDeviceCommand(deviceId, showForm));
  }

  loadDevices(): void {
    this.store.dispatch(new LoadDevicesCommand());
  }

  toggleShowDeviceContacts(deviceId: string, expandedDeviceId: string): void {
    if (expandedDeviceId !== deviceId) {
      this.showDeviceDetails(deviceId);
    } else {
      this.hideDeviceDetails();
    }
  }

  showDeviceDetails(deviceId: string): void {
    this.store.dispatch(new ShowDeviceDetailsCommand(deviceId));
  }

  hideDeviceDetails(): void {
    this.store.dispatch(new HideDeviceDetailsCommand());
  }
}
