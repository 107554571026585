import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import moment from 'moment';
import { getLocalTime, isShouldDecreaseDate, isShouldIncreaseDate, makeTwoDigits } from './timepicker.utils';
import { BaseControlValueAccessor } from 'flex-app-shared';

@Component({
  selector: 'app-timepicker',
  templateUrl: './timepicker.component.html',
  styleUrls: ['./timepicker.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimepickerComponent), multi: true }
    // { provide: NG_VALIDATORS, useExisting: forwardRef(() => TimepickerComponent), multi: true }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimepickerComponent extends BaseControlValueAccessor<string> implements OnInit, OnDestroy {
  private readonly date: Date = new Date();
  private readonly ngUnsubscribe = new Subject<void>();
  public form: UntypedFormGroup;

  @Input()
  public readonly formControlName: string = '';

  @Input()
  public readonly separator: string = ':';

  @Output()
  dateChanged = new EventEmitter<DateChangeDirection>();

  constructor(private fb: UntypedFormBuilder) {
    super();
    this.form = this.fb.group({
      hours: ['', [Validators.pattern('\\d{1,2}')]],
      minutes: ['', [Validators.pattern('\\d{1,2}')]]
    });
  }

  public writeValue(time: string | undefined): void {
    const result = isNil(time) ? '' : time;
    if (Number.isNaN(Number(time.replace(this.separator, '')))) return;

    this.form.patchValue({ ...getLocalTime(result, this.separator) });
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ hours, minutes }) => {
      this.onChange(`${hours}:${minutes}`);
    });
  }

  increase(unit: 'hours' | 'minutes'): void {
    const { hours, minutes } = this.form.getRawValue();
    const timeStr = moment(this.date).hours(hours).minutes(minutes).add(1, unit).format('HH:mm');

    if (isShouldIncreaseDate({ hours, minutes }, unit)) this.dateChanged.emit(DateChangeDirection.increase);
    this.onTimeChange({ ...getLocalTime(timeStr) });
  }

  decrease(unit: 'hours' | 'minutes'): void {
    const { hours, minutes } = this.form.getRawValue();
    const timeStr = moment(this.date).hours(hours).minutes(minutes).subtract(1, unit).format('HH:mm');

    if (isShouldDecreaseDate({ hours, minutes }, unit)) this.dateChanged.emit(DateChangeDirection.decrease);
    this.onTimeChange({ ...getLocalTime(timeStr) });
  }

  private onTimeChange(time: LocalTime): void {
    const hours = makeTwoDigits(time.hours);
    const minutes = makeTwoDigits(time.minutes);

    this.form.patchValue({ hours, minutes });
  }

  // validateFn: any = () => {};
  //
  // validate(c: FormControl) {
  //   return this.validateFn(c);
  // }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

export interface LocalTime {
  readonly hours: string;
  readonly minutes: string;
}

export enum DateChangeDirection {
  increase = 'increase',
  decrease = 'decrease'
}
