import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UnavailabilityFacade } from '../../store/unavailabilites/states/unavailability/unavailability.facade';
import { Capacity, DateAndTime, PeriodView, ServiceAgreement } from 'flex-app-shared';
import moment from 'moment';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-unavailability-detail',
  templateUrl: './unavailability-detail.component.html',
  styleUrls: ['./unavailability-detail.component.scss']
})
export class UnavailabilityDetailComponent implements OnInit, OnDestroy {
  serviceAgreements$: Observable<ServiceAgreement[]>;
  serviceAgreements: ServiceAgreement[];
  form = this.builder.group({
    customerId: ['', Validators.required],
    serviceAgreementId: [
      '',
      [
        Validators.required,
        (control: UntypedFormGroup): ValidationErrors | null => {
          if (this.serviceAgreements && this.serviceAgreements.length === 0) {
            return {
              invalidServiceAgreement: true
            };
          }
        }
      ]
    ],
    direction: ['', Validators.required],
    power: this.builder.group({
      value: ['0', [Validators.required, Validators.min(0.01), Validators.pattern('^[0-9]+(\\.[0-9]{1,2})?')]],
      unit: 'MW'
    }),
    period: this.builder.group({
      startDateTime: this.builder.group({
        date: ['', Validators.required],
        time: ['', [Validators.required, Validators.pattern('\\d{1,2}:\\d{1,2}')]]
      }),
      toDateTime: this.builder.group({
        date: ['', Validators.required],
        time: ['', [Validators.required, Validators.pattern('\\d{1,2}:\\d{1,2}')]]
      })
    })
  });
  saveButtonDisabled$ = this.unavailabilityFacade.isDisabled$;
  private readonly ngUnsubscribe = new Subject<void>();

  constructor(private location: Location, private builder: UntypedFormBuilder, private unavailabilityFacade: UnavailabilityFacade) {}

  ngOnInit(): void {
    this.unavailabilityFacade.init();
    this.serviceAgreements$ = this.unavailabilityFacade.serviceAgreements$;
    this.serviceAgreements$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((serviceAgreements) => {
      this.serviceAgreements = serviceAgreements;
      this.form.get('serviceAgreementId').updateValueAndValidity({ onlySelf: true });
    });
    this.unavailabilityFacade.unavailability$.pipe(first()).subscribe((unavailability) => {
      this.form.patchValue({
        ...unavailability,
        period: unavailability.period || this.initialPeriod(),
        power: unavailability.power || Capacity.MW(0)
      });
      if (unavailability.id) {
        this.form.get('serviceAgreementId').markAsTouched({ onlySelf: true });
      }
    });

    this.form.valueChanges.subscribe((value) => {
      if (this.form.get('customerId').valid) {
        this.findServiceAgreements(value);
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onSubmit(): void {
    const serviceAgreementId = this.form.get('serviceAgreementId').value;
    if (serviceAgreementId && !this.serviceAgreements.some((it) => it.id === serviceAgreementId)) {
      this.form.get('serviceAgreementId').setValue(null);
    }
    this.unavailabilityFacade.save(this.form.value);
  }

  goBack(): void {
    this.location.back();
  }

  private findServiceAgreements(value: { customerId: string; period: PeriodView }): void {
    this.unavailabilityFacade.findServiceAgreements(value);
  }

  private initialPeriod(): PeriodView {
    const now = moment();
    const periodView = new PeriodView();
    periodView.startDateTime = DateAndTime.fromMoment(now.clone());
    periodView.toDateTime = DateAndTime.fromMoment(now.endOf('month'));
    return periodView;
  }
}
