import { Customer, CustomerService, EntityStateModel, FetchEntity } from 'flex-app-shared';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { DeleteCustomerCommand, ResetCustomersCommand, UpdateCustomersCommand } from './customers.actions';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export class CustomersStateModel extends EntityStateModel<Customer[]> {
  model: Customer[] = [];
  allCustomers: Customer[] = [];
}

@State({
  name: 'customers',
  defaults: new CustomersStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class CustomersState {
  constructor(private customerService: CustomerService) {}

  @Selector()
  public static isBusy(state: CustomersStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  static getCustomers(state: CustomersStateModel): Array<Customer> {
    return state.model;
  }

  @FetchEntity()
  @Action(UpdateCustomersCommand, { cancelUncompleted: true })
  updateCustomers(
    { setState, getState, dispatch }: StateContext<CustomersStateModel>,
    { userNameFilter }: UpdateCustomersCommand
  ): Observable<Customer[]> {
    // eslint-disable-next-line no-param-reassign
    userNameFilter = userNameFilter && userNameFilter.toLowerCase();

    if (userNameFilter) {
      setState({
        ...getState(),
        model: getState().allCustomers.filter((customer) => customer.name.toLowerCase().includes(userNameFilter))
      });
      return;
    }

    return this.customerService.getAll().pipe(
      tap((customers) => {
        setState({
          ...getState(),
          model: customers,
          allCustomers: customers,
          isInitialized: true
        });
      })
    );
  }

  @Action(ResetCustomersCommand)
  resetCustomers({ setState }: StateContext<CustomersStateModel>): void {
    setState(new CustomersStateModel());
  }

  @Action(DeleteCustomerCommand)
  deleteCustomer({ dispatch }: StateContext<CustomersStateModel>, event: DeleteCustomerCommand): Observable<any> {
    return this.customerService.delete(event.customer).pipe(tap(() => dispatch(new UpdateCustomersCommand())));
  }
}
