<mat-card>
  <mat-card-header>
    <mat-card-title>Measurement configuration</mat-card-title>
    <mat-card-subtitle>{{ gridPoint.ean }} - {{ gridPoint.description }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="flex-row">
      <app-pulse-measurement-configuration class="col-1 segment-block"></app-pulse-measurement-configuration>
      <div class="col-1 segment-block">
        <h3>External measurement configuration</h3>
        <form [formGroup]="externalMeasurementConfigurationForm" class="single-column-form">
          <div>
            <mat-label id="measurement-method-radio-label"><strong>Measurement method:</strong></mat-label>
            <mat-radio-group
              formControlName="measurement"
              aria-labelledby="measurement-method-radio-label"
              class="vertical"
              (change)="setFiveMinuteDataAvailableDisabled()"
            >
              <mat-radio-button value="{{ MeasurementMethodType.MANUAL }}">Manual</mat-radio-button>
              <mat-radio-button value="{{ MeasurementMethodType.AUTOMATIC }}">Automatic</mat-radio-button>
            </mat-radio-group>
            <mat-error *ngIf="externalMeasurementConfigurationForm.errors?.mustSelectACompanyWhenMeasurementIsAutomatic">
              Must select a measurement company when measurement method is automatic
            </mat-error>
          </div>
          <mat-form-field>
            <mat-label>Company</mat-label>
            <mat-select formControlName="companyId" (selectionChange)="companyChanged()">
              <mat-option *ngFor="let company of measurementCompanies" [value]="company.id">
                {{ company.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Provider</mat-label>
            <input matInput formControlName="provider" />
          </mat-form-field>
          <mat-error *ngIf="externalMeasurementConfigurationForm.errors?.mustHaveAtLeastOneChannelWhenCompanyIsSelected">
            Must select at least one channel when company is selected and measurement method is set to 'automatic'
          </mat-error>
          <mat-error *ngIf="externalMeasurementConfigurationForm.errors?.noChannelsAllowedWhenProviderIsNull">
            No channels allowed when no company is selected or the selected company does not have a provider.
          </mat-error>

          <mat-label class="channels-label">Consumption channels:</mat-label>

          <mat-error *ngIf="externalMeasurementConfigurationForm.errors?.noDuplicateConsumptionChannelsAllowed">
            Not allowed to select the same channel twice for consumption.
          </mat-error>

          <mat-form-field *ngFor="let channelFormGroup of consumptionChannelsForm?.controls; index as index" [formGroup]="channelFormGroup">
            <mat-label>Consumption (LDN) channel {{ index + 1 }}</mat-label>
            <mat-select formControlName="channel" [compareWith]="compareChannels" [errorStateMatcher]="consumptionErrorStateMatcher">
              <mat-option *ngFor="let channel of consumptionChannels" [value]="channel">
                {{ channel.description }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="channelFormGroup.get('channel').errors?.invalidChannelDirection">
              Invalid direction (consumption). Please choose a channel with a valid direction (consumption)
            </mat-error>
            <button matSuffix mat-icon-button color="warn" (click)="deleteChannel(index, Direction.CONSUMPTION)">
              <mat-icon svgIcon="ic-old:trash"></mat-icon>
            </button>
          </mat-form-field>

          <div class="add-channel-button">
            <button (click)="addChannel(Direction.CONSUMPTION)" mat-stroked-button>Add consumption channel</button>
          </div>

          <mat-label class="channels-label">Production channels:</mat-label>

          <mat-error *ngIf="externalMeasurementConfigurationForm.errors?.noDuplicateProductionChannelsAllowed">
            Not allowed to select the same channel twice for production.
          </mat-error>

          <mat-form-field *ngFor="let channelFormGroup of productionChannelsForm?.controls; index as index" [formGroup]="channelFormGroup">
            <mat-label>Production (LDN) channel {{ index + 1 }}</mat-label>
            <mat-select formControlName="channel" [compareWith]="compareChannels" [errorStateMatcher]="productionErrorStateMatcher">
              <mat-option *ngFor="let channel of productionChannels" [value]="channel">
                {{ channel.description }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="channelFormGroup.get('channel').errors?.invalidChannelDirection">
              Invalid direction (production). Please choose a channel with a valid direction (production)
            </mat-error>
            <button matSuffix mat-icon-button color="warn" (click)="deleteChannel(index, Direction.PRODUCTION)">
              <mat-icon svgIcon="ic-old:trash"></mat-icon>
            </button>
          </mat-form-field>

          <div class="add-channel-button">
            <button (click)="addChannel(Direction.PRODUCTION)" mat-stroked-button>Add production channel</button>
          </div>

          <div class="checkbox-group">
            <mat-checkbox formControlName="fiveMinuteDataAvailable">5 Min data available</mat-checkbox>
          </div>
        </form>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-stroked-button (click)="verifyData()" [disabled]="isNoChannelSelected()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:verify"></mat-icon>
        <span>Verify measurement data</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="cta" (click)="onSubmit()" [disabled]="!externalMeasurementConfigurationForm.valid">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:save"></mat-icon>
        <span>Save</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>

<mat-card *ngIf="validationResult">
  <mat-card-header>
    <mat-card-subtitle>Verify measurement data results:</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-list *ngFor="let result of validationResult.results">
      <mat-list-item>
        {{ validateMessage(result) }}
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
