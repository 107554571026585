import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-service-agreements',
  templateUrl: './dashboard-service-agreements.component.html',
  styleUrls: ['./dashboard-service-agreements.component.scss']
})
export class DashboardServiceAgreementsComponent {
  @Input()
  public readonly isForbidden: boolean = false;

  @Input()
  public readonly isInitialized: boolean = false;

  @Input()
  private readonly customerId: string | undefined = undefined;

  private getUrl(): string {
    if (!this.customerId) return `/service-agreements/new`;
    return `/dashboard/${this.customerId}/service-agreements/new`;
  }
}
