import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ApplyPoolPeriodCommand } from '../pool/pool.actions';
import { PoolPeriodView } from '../../../../app/shared/pool/pool.service';
import { ApplyPoolPeriodDialogValueCommand, ClosePoolPeriodDialogCommand, RegisterPoolPeriodDialogIdCommand } from './pool-period.actions';
import { PoolPeriodState } from './pool-period.state';

@Injectable({
  providedIn: 'root'
})
export class PoolPeriodFacade {
  isEclipsed$ = this.store.select(PoolPeriodState.isEclipsed);

  constructor(private store: Store) {}

  save(poolPeriod: PoolPeriodView): void {
    this.store.dispatch([new ApplyPoolPeriodCommand(poolPeriod), new ClosePoolPeriodDialogCommand()]);
  }

  init(dialogRefId: string): void {
    this.store.dispatch(new RegisterPoolPeriodDialogIdCommand(dialogRefId));
  }

  setPeriod(period: PoolPeriodView): void {
    this.store.dispatch(new ApplyPoolPeriodDialogValueCommand(period));
  }

  close(): void {
    this.store.dispatch(new ClosePoolPeriodDialogCommand());
  }
}
