import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, switchMap } from 'rxjs/operators';
import { UserView } from '../../../../app/shared/user/user.service';
import {
  CreateUserCommand,
  ResetPasswordCommand,
  SendActivationEmailCommand,
  ToggleUserActiveCommand,
  UpdateUserDetailsCommand
} from './user.actions';
import { UserState } from './user.state';

@Injectable({
  providedIn: 'root'
})
export class UserFacade {
  constructor(private store: Store) {}

  isBusySendingEmail$ = this.store.select(UserState.isBusySendingEmail);
  activationEmailResult$ = this.store.select(UserState.getActivationEmailResult);
  isBusyResettingPassword$ = this.store.select(UserState.isBusyResettingPassword);
  resetPasswordResult$ = this.store.select(UserState.getResetPasswordResult);
  isBusyActivatingUser$ = this.store.select(UserState.isBusyActivatingUser);
  isBusyDeactivatingUser$ = this.store.select(UserState.isBusyDeactivatingUser);
  toggleUserActiveResult$ = this.store.select(UserState.getToggleUserActiveResult);
  isBusy$ = this.store.select(UserState.isBusy);

  user$ = this.store.select(UserState.isBusy).pipe(
    filter((a) => !a),
    switchMap(() => this.store.select(UserState.getUser))
  );

  updateDetails(user: UserView): void {
    this.store.dispatch(new UpdateUserDetailsCommand(user));
  }

  create(user: UserView, sendActivationEmail: boolean): void {
    this.store.dispatch(new CreateUserCommand(user, sendActivationEmail));
  }

  sendActivationEmail(userView: UserView): void {
    this.store.dispatch(new SendActivationEmailCommand(userView.id));
  }

  resetPassword(userView: UserView): void {
    this.store.dispatch(new ResetPasswordCommand(userView.id));
  }

  toggleUserActive(userView: UserView, active: boolean): void {
    this.store.dispatch(new ToggleUserActiveCommand(userView.id, active));
  }
}
