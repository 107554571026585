<h2 mat-dialog-title>Add contact</h2>
<mat-dialog-content>
  <form [formGroup]="form" ngxsForm="deviceStateModule.deviceContact" class="single-column-form">
    <mat-form-field>
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" />
      <mat-error *ngIf="form.get('description').errors?.required"> Description is required.</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>ExternalId</mat-label>
      <input matInput formControlName="externalId" />
      <mat-error *ngIf="form.get('externalId').errors?.required"> ExternalId is required.</mat-error>
    </mat-form-field>
    <div>
      <mat-checkbox formControlName="invertedSchedules">Inverted schedules</mat-checkbox>
    </div>
    <mat-form-field readonly>
      <mat-label>Switch cooldown time</mat-label>
      <input matInput readonly formControlName="switchCooldownTime" />
      <span matTextSuffix>Minutes</span>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button (click)="close()" type="button">
    <ph-flex-vertical-center>
      <mat-icon svgIcon="ic-old:close"></mat-icon>
      <span>Cancel</span>
    </ph-flex-vertical-center>
  </button>
  <ph-flex-entity-save-button
    [entityFacade]="deviceContactFacade"
    [disabled]="form.invalid"
    (click)="onSubmit()"
    [buttonLabel]="(isEdit$ | async) ? 'Save' : 'Add'"
    [buttonIcon]="(isEdit$ | async) ? 'ic-old:save' : 'ic-old:add'"
    mat-stroked-button
  >
  </ph-flex-entity-save-button>
</mat-dialog-actions>
