import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DayAheadPricesInfo } from './day-ahead-prices-info';
import { CalendarPeriod } from 'flex-app-shared';

@Injectable({
  providedIn: 'root'
})
export class DayAheadServiceService {
  constructor(private http: HttpClient) {}

  retrieveDayAheadDeals(period: CalendarPeriod, customerId?: string, gridPointId?: string): Observable<any> {
    const bodyData: any = { startDate: period.startDate, endDate: period.endDate };

    if (customerId) {
      bodyData.customerId = customerId;
    }

    if (gridPointId) {
      bodyData.gridPointId = gridPointId;
    }

    return this.http.post<any>('/api/v1/retrieve-day-ahead-deals', bodyData);
  }

  retrieveDayAheadPrices(period: CalendarPeriod): Observable<DayAheadPricesInfo> {
    return this.http.post<DayAheadPricesInfo>('/api/v1/day-ahead-prices', { startDate: period.startDate, endDate: period.endDate });
  }
}
