import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, first, switchMap } from 'rxjs/operators';
import { DownloadGridPointsCommand, GridPointsState, LoadGridPointCommand, LoadGridPointsCommand } from 'flex-app-shared';

@Injectable({
  providedIn: 'root'
})
export class GridPointsFacade {
  constructor(private store: Store) {}

  gridPoints$ = this.store.select(GridPointsState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(GridPointsState.getGridPoints))
  );

  selectedCustomerId$ = this.store.select(GridPointsState.getSelectedCustomerId);

  isBusyDownloading$ = this.store.select(GridPointsState.isBusyDownloading);

  init(): void {
    this.loadGridPoints();
  }

  loadGridPoint(gridPointId: string, showForm: boolean = false): void {
    this.store.dispatch(new LoadGridPointCommand(gridPointId, showForm));
  }

  loadGridPoints(): void {
    this.store.dispatch(new LoadGridPointsCommand());
  }

  downloadGridPointOverview(): void {
    this.store.dispatch(new DownloadGridPointsCommand());
  }
}
