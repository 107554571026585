<mat-card class="widget mat-card dashboard-component -gridpoints" *ngIf="isInitialized">
  <mat-card-title> Gridpoints </mat-card-title>
  <mat-card-content>
    <app-grid-points [isStandalone]="true" [isDashboard]="true" [defaultPageSize]="10" *ngIf="!isForbidden"></app-grid-points>
    <app-forbidden-message [isDisabled]="isForbidden"></app-forbidden-message>
  </mat-card-content>
  <mat-card-footer class="fixed-to-bottom-actions-helper">
    <mat-card-actions class="fixed-to-bottom-actions" *ngIf="!isForbidden">
      <button mat-stroked-button routerLink="/grid-points/">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:overview"></mat-icon>
          <span>Gridpoints overview</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="primary" [routerLink]="getUrl()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:add"></mat-icon>
          <span>New Gridpoint</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card-footer>
</mat-card>
