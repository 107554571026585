import { AfterViewInit, Component, HostListener, Injectable, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PoolFacade } from '../../store/pool/states/pool/pool.facade';
import { DatePipe, Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PoolPeriodDialogComponent } from '../pool-period-dialog/pool-period-dialog.component';
import { Pool, PoolPeriodView } from '../shared/pool/pool.service';

import { Subscription } from 'rxjs';
import { get } from 'lodash-es';
import { UntypedFormBuilder } from '@angular/forms';

@Injectable()
export class CanDeactivateGuard {
  canDeactivate(component: PoolsComponent): boolean {
    if (component && component.hasUnsavedData()) {
      return confirm('You have unsaved changes! If you leave, your changes will be lost.');
    }
    return true;
  }
}

@Component({
  selector: 'app-pools',
  templateUrl: './pools.component.html',
  styleUrls: ['./pools.component.scss'],
  providers: [DatePipe]
})
export class PoolsComponent implements OnInit, OnDestroy, AfterViewInit {
  displayedColumns = ['period', 'gridPoints', 'sent', 'actions'];
  dataSource: MatTableDataSource<PoolPeriodView>;
  dirty = false;
  dirtySubscription: Subscription;
  pool: Pool = null;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private poolFacade: PoolFacade,
    private location: Location,
    private matDialog: MatDialog,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource();
    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (data, sortHeaderId: string) => {
      switch (sortHeaderId) {
        case 'gridPoints':
          return data.gridPointIds.length;
        case 'period':
          return data.period.startDate.date.toISOString() + data.period.endDate.date.toISOString();
      }
      return get(data, sortHeaderId);
    };

    this.poolFacade.pool$.subscribe((pool) => {
      setTimeout(() => {
        this.dataSource.data = pool.poolPeriods;
      });
    });
    this.dirtySubscription = this.poolFacade.dirty$.subscribe((dirty) => (this.dirty = dirty));

    this.setFilterPredicate();
  }

  ngOnDestroy(): void {
    if (this.dirtySubscription) {
      this.dirtySubscription.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  onSubmit(): void {
    this.poolFacade.save();
  }

  addPoolSchedule(): void {
    this.matDialog.open(PoolPeriodDialogComponent, { width: '600px' });
  }

  edit(poolPeriod: any): void {
    this.matDialog.open(PoolPeriodDialogComponent, { data: poolPeriod, width: '600px' });
  }

  hasUnsavedData(): boolean {
    return this.dirty;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: { returnValue: boolean }): void {
    if (this.hasUnsavedData()) {
      $event.returnValue = true;
    }
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }

  setFilterPredicate(): void {
    this.dataSource.filterPredicate = (data: PoolPeriodView, filter: string) => {
      const { period, gridPointIds, sent } = data;
      const startDate = this.datePipe.transform(period.startDate.date.toDate(), 'shortDate');
      const endDate = this.datePipe.transform(period.endDate.date.toDate(), 'shortDate');
      const isSent = sent ? 'sent' : '';
      return `${startDate} ${endDate} ${isSent} ${gridPointIds.join(' ')}`.toLowerCase().includes(filter.trim().toLowerCase());
    };
  }

  sendPool(): void {
    this.poolFacade.sendPool();
  }
}
