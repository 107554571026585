import { Component, Inject, OnInit } from '@angular/core';
import { R3InvoiceDataFacade } from '../../../store/r3-invoice-data/r3-invoice-data.facade';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface R3InvoiceDataRecalculateConfirmDialogData {
  serviceAgreementId: string;
  customerName: string;
  customerSaReference: number;
}

@Component({
  selector: 'app-r3-invoice-data-recalculate-confirm-dialog',
  templateUrl: './r3-invoice-data-recalculate-confirm-dialog.component.html',
  styleUrls: ['./r3-invoice-data-recalculate-confirm-dialog.component.scss']
})
export class R3InvoiceDataRecalculateConfirmDialogComponent {
  constructor(
    public facade: R3InvoiceDataFacade,
    public dialogRef: MatDialogRef<R3InvoiceDataRecalculateConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: R3InvoiceDataRecalculateConfirmDialogData
  ) {}

  confirm(): void {
    if (this.data) {
      this.facade.recalculateForServiceAgreement(this.data.serviceAgreementId, this.dialogRef);
    } else {
      this.facade.recalculate(this.dialogRef);
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
