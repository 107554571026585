import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationStart, Router } from '@angular/router';
import { ControlSchedule, DateAndTime, findNotNullParameterValue, FnErrorMatcher, PeriodView } from 'flex-app-shared';
import moment from 'moment';
import { min, Moment } from 'moment';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, first, takeUntil } from 'rxjs/operators';
import { ControlFacade } from '../../store/controls/control.facade';
import { CombinedErrorMatcher, CrossFieldErrorMatcher, SelectionModelControlAdapter } from '../shared/common/common';
import { FlatDeviceContact } from '../shared/device/device.service';
import { ControlDetailSendToDevicesConfirmationDialogComponent } from './control-detail-send-to-devices-confirmation-dialog.component';

export const startTimeLowerThanEndTimeValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const startDateTime = control.get('startDateTime').value.time;

  const toDateTime = control.get('toDateTime').value.time;
  const startDate: Moment = control.root.get('period.startDateTime.date')?.value;
  const toDate: Moment = control.root.get('period.toDateTime.date')?.value;

  if (!startDate || !toDate) {
    return null;
  }

  const startDateTimeHours = startDateTime.split(':')[0];
  const startDateTimeMinutes = startDateTime.split(':')[1];
  const toDateTimeHours = toDateTime.split(':')[0];
  const toDateTimeMinutes = toDateTime.split(':')[1];

  const startDateTimeMoment: Moment = moment(startDate)
    .startOf('day')
    .add(startDateTimeHours, 'hours')
    .add(startDateTimeMinutes, 'minutes');
  const toDateTimeMoment: Moment = moment(toDate).startOf('day').add(toDateTimeHours, 'hours').add(toDateTimeMinutes, 'minutes');

  return toDateTimeMoment.isBefore(startDateTimeMoment) ? { timesWrong: true } : null;
};

export class GridPointSelection {
  public gridPointId: string;
  public ean: string;
  public description: string;
  public ownedByCustomer: boolean;
  public incidentReservePreQualified: boolean;
}

@Component({
  selector: 'app-control-unit-detail',
  templateUrl: './control-detail.component.html',
  styleUrls: ['./control-detail.component.scss']
})
export class ControlDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  shouldShowError = false;

  fnErrorMatcher = new FnErrorMatcher(() => this.shouldShowError);
  timePeriodCrossFieldErrorMatcher = new CombinedErrorMatcher([new CrossFieldErrorMatcher('period'), this.fnErrorMatcher]);
  @Input()
  isStandalone = false;
  @Input()
  defaultPageSize = 20;

  form = this.builder.group({
    id: '',
    description: ['', [Validators.required, Validators.maxLength(50)]],
    customerId: ['', [Validators.required]],
    customerName: '',
    gridPointIds: [],
    direction: ['PRODUCTION', Validators.required],
    power: this.builder.group({
      value: ['', [Validators.required, Validators.min(0), Validators.max(99_999)]],
      unit: ['kW']
    }),
    deviceContactId: ['', [Validators.required]],
    incidentReserveOnly: [false]
  });

  testControlForm = this.builder.group({
    period: this.builder.group(
      {
        startDateTime: this.builder.group({
          date: ['', Validators.required],
          time: ['', [Validators.required, Validators.pattern('\\d{1,2}:\\d{1,2}')]]
        }),
        toDateTime: this.builder.group({
          date: ['', Validators.required],
          time: ['', [Validators.required, Validators.pattern('\\d{1,2}:\\d{1,2}')]]
        })
      },
      { validators: startTimeLowerThanEndTimeValidator }
    ),
    state: 'ON'
  });

  today = moment().startOf('day');
  tomorrow = moment().add(1, 'day').endOf('day');

  get toDateTimeMin(): Moment {
    return moment.max(this.today, this.testControlForm.get('period.startDateTime.date').value);
  }

  showTestControlPanel = false;
  flatDeviceContacts$ = this.controlFacade.flatDeviceContacts$;

  displayedGridPointColumns = ['select', 'ean', 'description'];
  selection: SelectionModel<string> = new SelectionModel<string>(true, []);
  dataSource: MatTableDataSource<GridPointSelection> = new MatTableDataSource<GridPointSelection>([]);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  selectionAdapter = new SelectionModelControlAdapter(this.selection);
  private readonly ngUnsubscribe = new Subject<void>();
  private customerIdChangedSubscription: Subscription;
  private gridPointsSubscription: Subscription;
  private controlDeviceContactId: string = null;

  constructor(
    private builder: UntypedFormBuilder,
    public controlFacade: ControlFacade,
    private location: Location,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (!this.isStandalone) {
      this.controlFacade.init();
    }
    this.selectionAdapter.setControl(this.form.get('gridPointIds'));
    this.controlFacade.control$.pipe(first()).subscribe((control) => {
      const { customerId, gridPoints } = control;
      this.controlDeviceContactId = control.deviceContactId;
      if (customerId) {
        this.controlFacade.customerChanged(customerId);
        const ids = gridPoints ? gridPoints.map((it) => it.gridPointId) : [];
        this.selection.select(...ids);
      }
    });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.updateCustomerIdFromRoute();
      });
    this.updateCustomerIdFromRoute();

    this.controlFacade.isDisabled$.subscribe((isDisabled) => {
      this.updateControlFormState(isDisabled);
      this.updateTestFormState(isDisabled);
    });

    this.customerIdChangedSubscription = this.form
      .get('customerId')
      .valueChanges.pipe(distinctUntilChanged())
      .subscribe((value) => {
        const customerIdFormField = this.form.get('customerId');
        if (customerIdFormField.pristine || customerIdFormField.invalid) {
          return;
        }
        this.controlFacade.customerChanged(value);
        this.updateControlFormState();

        // Clear device contacts and grid point selection
        this.form.get('deviceContactId').setValue('');
        this.selection.clear();
      });

    if (!this.isStandalone) {
      this.initTestControlForm();
    }
    this.updateEndDateAndTimeValidityOnStartTimeChange();
    this.controlFacade.gridPointSelection$.subscribe((it) => (this.dataSource.data = it));
  }

  private updateCustomerIdFromRoute(): void {
    const customerId = findNotNullParameterValue(this.router.routerState.snapshot, 'customerId');
    this.form.get('customerId').setValue(customerId);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  goBack(): void {
    this.location.back();
  }

  onSubmit(): void {
    this.shouldShowError = true;
    if (this.canSaveControl()) {
      this.controlFacade.save();
    }
  }

  onSendToDevice(): void {
    const dialogRef = this.dialog.open(ControlDetailSendToDevicesConfirmationDialogComponent, {
      data: this.testControlForm.value as ControlSchedule
    });
    dialogRef.afterClosed().subscribe((pressedOk) => {
      if (pressedOk) {
        this.snackBar.open('Sending schedule to device', null, { duration: 5000 });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.customerIdChangedSubscription) {
      this.customerIdChangedSubscription.unsubscribe();
    }
    if (this.gridPointsSubscription) {
      this.gridPointsSubscription.unsubscribe();
    }

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row) => this.selection.select(row.gridPointId));
    }
  }

  updateControlFormState(disableAll: boolean = false): void {
    if (disableAll) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    if (!this.form.get('customerId').value) {
      this.form.get('deviceContactId').disable();
    }
  }

  canSaveControl(): boolean {
    return this.form.valid && this.isGridPointSelectionValid();
  }

  submitButtonColor(): string {
    if ((!this.isGridPointSelectionValid() || this.form.invalid) && this.shouldShowError) {
      return 'warn';
    }
    return 'cta';
  }

  isGridPointSelectionValid(): boolean {
    return this.selection.selected.length > 0;
  }

  shouldShowGridPointError(): boolean {
    return this.shouldShowError && !this.isGridPointSelectionValid();
  }

  updateTestFormState(disableAll: boolean = false): void {
    disableAll ? this.testControlForm.disable() : this.testControlForm.enable();
  }

  isDeviceContactSelectable(flatDeviceContact: FlatDeviceContact): boolean {
    return (
      this.isDeviceContactOfCurrentControl(flatDeviceContact.deviceContactId) ||
      !this.isDeviceContactForOneOrMoreControls(flatDeviceContact)
    );
  }

  private initTestControlForm(): void {
    this.controlFacade.control$.subscribe((control) => (this.showTestControlPanel = control.id != null));
    const start = moment().add(15, 'minutes').startOf('minute');
    const end = min(start.clone().add(1, 'hours'), start.clone().endOf('day'));
    const periodView = new PeriodView();
    periodView.startDateTime = DateAndTime.fromMoment(start);
    periodView.toDateTime = DateAndTime.fromMoment(end);
    this.testControlForm.patchValue({ period: periodView });
  }

  private updateEndDateAndTimeValidityOnStartTimeChange(): void {
    this.testControlForm
      .get('period.startDateTime.time')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.testControlForm.get('period.toDateTime.time').markAsTouched();
        this.testControlForm.get('period.toDateTime.time').updateValueAndValidity();
      });

    this.testControlForm
      .get('period.startDateTime.date')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.testControlForm.get('period.toDateTime.date').markAsTouched();
        this.testControlForm.get('period.toDateTime.date').updateValueAndValidity();
      });
  }

  private isDeviceContactOfCurrentControl(deviceContactId: string): boolean {
    return this.controlDeviceContactId === deviceContactId;
  }

  private isDeviceContactForOneOrMoreControls(flatDeviceContact: FlatDeviceContact): boolean {
    return !!flatDeviceContact.firstControlDescription;
  }
}
