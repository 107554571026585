import { AppInjector, FrontendPaginationHelper, OnDestroyProvider, PaginatedDataSource, R3Activation } from 'flex-app-shared';
import { combineLatest, ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MeasurementDataUploadFacade } from '../../store/measurement-data-upload/states/measurement-data-upload.facade';
import { get } from 'lodash-es';
import { Measurement } from '../shared/measurement-data/measurement-data.service';

export class UploadedDataDatasource extends PaginatedDataSource<any> {
  loadDataSubject = new ReplaySubject(1);
  facade = AppInjector.get(MeasurementDataUploadFacade);

  paginationHelper = new FrontendPaginationHelper<Measurement>((data, sortHeaderId) => {
    switch (sortHeaderId) {
      case 'start':
        return data.period.startDateTime;
      case 'end':
        return data.period.toDateTime;
      case 'consumption':
        return data.consumption && data.consumption.value;
      case 'production':
        return data.production && data.production.value;
      default:
        return get(data, sortHeaderId);
    }
  });

  constructor(onDestroyProvider: OnDestroyProvider) {
    super();

    onDestroyProvider.onDestroy$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.ngOnDestroy());

    combineLatest([this.facade.previewData$, this.loadDataSubject])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([newResult, loadDataInfo]) => {
        const { pageIndex, pageSize, searchTerm, sort } = loadDataInfo as any;

        this.paginationHelper.updateData(newResult);
        this.dataSubject.next(this.paginationHelper.handleLoadData(pageIndex, pageSize, searchTerm, sort));
        this.updatePaginator(pageIndex, pageSize, this.paginationHelper.filteredData.length);
      });
  }

  loadData(pageIndex: number | undefined, pageSize: number | undefined, searchTerm: string | undefined, sort: string | undefined): void {
    this.loadDataSubject.next({
      pageIndex,
      pageSize,
      searchTerm,
      sort
    });
  }
}
