import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  Measurement,
  MeasurementDataGridPointSelection,
  MeasurementDataService
} from '../shared/measurement-data/measurement-data.service';
import { Location } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { get } from 'lodash-es';

@Component({
  selector: 'app-measurement-data-download',
  templateUrl: './measurement-data-download.component.html',
  styleUrls: ['./measurement-data-download.component.scss']
})
export class MeasurementDataDownloadComponent implements OnInit {
  filterFormGroup: UntypedFormGroup = new UntypedFormGroup({});
  form: UntypedFormGroup;
  selection: SelectionModel<MeasurementDataGridPointSelection>;

  readonly gridPointsDataSource = new MatTableDataSource<MeasurementDataGridPointSelection>([]);
  readonly measurementsDataSource = new MatTableDataSource<Measurement>([]);
  readonly displayedGridPointColumns: ReadonlyArray<string> = ['select', 'ean', 'description', 'customer'];
  readonly displayedMeasurementColumns: ReadonlyArray<string> = ['customer', 'ean', 'start', 'end', 'consumption', 'production', 'sum'];

  @ViewChild('gridPointPaginator', { static: true }) private readonly gridPointPaginator: MatPaginator;
  @ViewChild('gridPointSort', { static: true }) private readonly gridPointSort: MatSort;
  @ViewChild('measurementPaginator', { static: false }) private readonly measurementPaginator: MatPaginator;
  @ViewChild('measurementSort', { static: false }) private readonly measurementSort: MatSort;

  constructor(private location: Location, private formBuilder: UntypedFormBuilder, private service: MeasurementDataService) {}

  _showData: boolean;

  get showData(): boolean {
    return this._showData;
  }

  set showData(val: boolean) {
    this._showData = val;

    if (val) {
      setTimeout(() => {
        // Workaround for *ngIf
        // TODO extract measurements to its own component
        this.measurementsDataSource.sort = this.measurementSort;
        this.measurementsDataSource.paginator = this.measurementPaginator;
      });
    }
  }

  get date(): AbstractControl | null {
    return this.form.get('date');
  }

  ngOnInit(): void {
    this.showData = false;
    this.service.getGridPoints().subscribe((gridPoints) => (this.gridPointsDataSource.data = gridPoints));

    this.gridPointsDataSource.sort = this.gridPointSort;
    this.gridPointsDataSource.paginator = this.gridPointPaginator;
    this.gridPointsDataSource.sortingDataAccessor = (data, sortHeaderId) => {
      if (sortHeaderId === 'customer') {
        return data.customerName;
      }
      return get(data, sortHeaderId);
    };

    this.measurementsDataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'customer':
          return data.customerName;
        case 'start':
          return data.period.startDateTime;
        case 'end':
          return data.period.toDateTime;
        case 'consumption':
          return data.consumption && data.consumption.value;
        case 'production':
          return data.production && data.production.value;
        case 'sum':
          return data.sum && data.sum.value;
        default:
          return get(data, sortHeaderId);
      }
    };

    const initialSelection = [];
    const allowMultiSelect = true;
    this.selection = new SelectionModel<MeasurementDataGridPointSelection>(allowMultiSelect, initialSelection);

    this.form = this.formBuilder.group({
      date: ['', Validators.required]
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.gridPointsDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.gridPointsDataSource.data.forEach((row) => this.selection.select(row));
    }
  }

  viewData(): void {
    this.showData = true;
    this.service.getMeasurementData(this.date.value, this.getSelectedEANs()).subscribe((it) => (this.measurementsDataSource.data = it));
  }

  downloadData(): void {
    this.service.downloadMeasurementData(this.date.value, this.getSelectedEANs()).subscribe();
  }

  getFormattedSelectedEANs(): string {
    return `EANs: (${this.getSelectedEANs().join(', ')})`;
  }

  goBack(): void {
    this.showData = false;
  }

  applyFilter(filterValue: string): void {
    this.gridPointsDataSource.filter = filterValue || '';
  }

  private getSelectedEANs(): ReadonlyArray<string> {
    return this.selection.selected.map((gridPoint) => gridPoint.ean);
  }
}
