<h2 mat-dialog-title>Add new availability</h2>
<mat-dialog-content>
  <form [formGroup]="form" class="flex-grid cols-2">
    <ng-container formGroupName="period">
      <mat-form-field class="col-1">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="startDateDatepicker" formControlName="startDate" />
        <mat-datepicker-toggle matSuffix [for]="startDateDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #startDateDatepicker></mat-datepicker>
        <mat-error *ngIf="form.get('period.startDate').errors?.required"> Start date is required. </mat-error>
      </mat-form-field>
      <mat-form-field class="col-1">
        <mat-label>End Date</mat-label>
        <input matInput [matDatepicker]="endDateDatepicker" formControlName="endDate" [errorStateMatcher]="periodErrorFieldMatcher" />
        <mat-datepicker-toggle matSuffix [for]="endDateDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #endDateDatepicker></mat-datepicker>
        <mat-error *ngIf="form.get('period.endDate').errors?.required"> End date is required. </mat-error>
        <mat-error *ngIf="form.get('period').errors?.periodWrong"> End date must be after start date. </mat-error>
      </mat-form-field>
    </ng-container>
    <ng-container formGroupName="timePeriod">
      <mat-form-field class="col-1">
        <mat-label>Start time</mat-label>
        <input matInput formControlName="startTime" />
        <mat-hint>Example: 13:00</mat-hint>
        <mat-error *ngIf="form.get('timePeriod.startTime').errors?.required">
          Start date is required.
          <span *ngIf="form.get('timePeriod.startTime').errors?.wholeHours">
            Start time should be a round hour (so minutes must be 0).
          </span>
        </mat-error>
        <mat-error *ngIf="form.get('timePeriod.startTime').errors?.pattern"> Start time should be formatted as hour:minute </mat-error>
      </mat-form-field>
      <mat-form-field class="col-1">
        <mat-label>End Time</mat-label>
        <input matInput formControlName="endTime" [errorStateMatcher]="timePeriodErrorFieldMatcher" />
        <mat-hint>Example: 13:00</mat-hint>
        <mat-error *ngIf="form.get('timePeriod.endTime').errors?.required"> End date is required. </mat-error>
        <mat-error *ngIf="form.get('timePeriod.endTime').errors?.wholeHours">
          End time should be a round hour (so minutes must be 0).
        </mat-error>
        <mat-error *ngIf="form.get('timePeriod').errors?.timeWrong"> End time should be after start time </mat-error>
        <mat-error *ngIf="form.get('timePeriod.endTime').errors?.pattern"> Start time should be formatted as hour:minute </mat-error>
      </mat-form-field>
    </ng-container>
    <mat-form-field class="col-2">
      <mat-label>Direction</mat-label>
      <mat-select formControlName="direction">
        <mat-option value="UPWARDS">Upwards</mat-option>
        <mat-option value="DOWNWARDS">Downwards</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="col-2">
      <mat-label>Type</mat-label>
      <mat-select formControlName="availabilityType">
        <mat-option value="OFF">Off</mat-option>
        <mat-option value="ASSURED">Assured</mat-option>
        <mat-option value="VOLUNTARY">Voluntary</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field formGroupName="power" class="col-2" *ngIf="isAssuredAvailability()">
      <mat-label>Capacity</mat-label>
      <ph-flex-number-input formControlName="value"></ph-flex-number-input>
      <span matTextSuffix>{{ form.get('power.unit').value }}</span>
      <mat-error *ngIf="form.get('power.value').errors?.required"> Capacity is required </mat-error>
      <mat-error *ngIf="form.get('power.value').errors?.min"> Capacity must be a at least 0,01 MW. </mat-error>
      <mat-error *ngIf="form.get('power.value').errors?.max"> Capacity can be at most 999,99 MW. </mat-error>
      <mat-error *ngIf="form.get('power.value').errors?.invalidNumber"> Not a valid number </mat-error>
      <mat-error *ngIf="form.get('power.value').errors?.invalidNumberOfDecimals"> Only two decimals are allowed </mat-error>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>
    <ph-flex-vertical-center>
      <mat-icon svgIcon="ic-old:close"></mat-icon>
      <span>Cancel</span>
    </ph-flex-vertical-center>
  </button>
  <button
    mat-flat-button
    [color]="isPeriodSensible() ? 'cta' : 'warn'"
    [class]="isPeriodSensible() ? 'save' : ''"
    [disabled]="form.invalid"
    [mat-dialog-close]="form.value"
    matTooltip="Period has a non-standard duration."
    [matTooltipDisabled]="isPeriodSensible()"
    [ngSwitch]="isEdit"
  >
    <ph-flex-vertical-center>
      <ng-container *ngSwitchCase="true"><mat-icon svgIcon="ic-old:save"></mat-icon><span>Save</span></ng-container>
      <ng-container *ngSwitchCase="false"><mat-icon svgIcon="ic-old:add"></mat-icon><span>Add</span></ng-container>
    </ph-flex-vertical-center>
  </button>
</mat-dialog-actions>
