import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ServiceAgreementState } from './service-agreement.state';
import { SaveServiceAgreementCommand } from './service-agreement.actions';
import { EntityFacade, ServiceAgreement } from 'flex-app-shared';
import { filter, first, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServiceAgreementFacade implements EntityFacade {
  serviceAgreement$ = this.store.select(ServiceAgreementState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(ServiceAgreementState.getServiceAgreement))
  );
  isDisabled$ = this.store.select(ServiceAgreementState.isBusy);
  saveButtonPending$ = this.store.select(ServiceAgreementState.isBusySending);
  saveButtonError$ = this.store.select(ServiceAgreementState.error);

  constructor(private store: Store) {}

  save(serviceAgreement: ServiceAgreement): void {
    this.store.dispatch(new SaveServiceAgreementCommand(serviceAgreement));
  }
}
