import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MessageEntity, MessageOverviewService, SearchPeriod } from '../shared/message-overview/message-overview.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { defaultTo } from 'lodash-es';
import { CollectionViewer } from '@angular/cdk/collections';
import { map, merge, Observable, Subscription } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { MatSelect } from '@angular/material/select';
import { ControlFilterProvider, normalizeToDate, PaginatedDataSource, TableFilterComponent } from 'flex-app-shared';
import { UntypedFormControl, Validators } from '@angular/forms';
import { startWith } from 'rxjs/operators';

class MessageOverviewDataSource extends PaginatedDataSource<MessageEntity> {
  private selectedCategoriesSubscription: Subscription;
  private searchPeriodSubscription: Subscription;
  private startDateTimeFilterProvider = new ControlFilterProvider(this);
  private toDateTimeFilterProvider = new ControlFilterProvider(this);

  constructor(private messageOverviewService: MessageOverviewService) {
    super();
  }

  private _selectedCategories: MatSelect;

  get selectedCategories(): MatSelect {
    return this._selectedCategories;
  }

  set selectedCategories(value: MatSelect) {
    this.registerSelect(value);
  }

  private _searchPeriod: MatSelect;

  get searchPeriod(): MatSelect {
    return this._searchPeriod;
  }

  set searchPeriod(value: MatSelect) {
    this._searchPeriod = value;
    if (this.searchPeriodSubscription) {
      this.searchPeriodSubscription.unsubscribe();
    }
    this.searchPeriodSubscription = this.searchPeriod.stateChanges.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.internalLoadData();
    });
  }

  private _messageType: MatSelect;

  get messageType(): MatSelect {
    return this._messageType;
  }

  set messageType(value: MatSelect) {
    this._messageType = value;
  }

  set startDateTimeFilter(value: UntypedFormControl) {
    this.startDateTimeFilterProvider.register(value);
  }

  set toDateTimeFilter(value: UntypedFormControl) {
    this.toDateTimeFilterProvider.register(value);
  }

  loadData(pageIndex?: number, pageSize?: number, searchTerm?: string, sort?: string): void {
    const index = defaultTo(pageIndex, this.paginator ? this.paginator.pageIndex : undefined);
    const size = defaultTo(pageSize, this.paginator ? this.paginator.pageSize : undefined);

    this.isLoadingSubject.next(true);

    const categories = this.selectedCategories.value as string[];
    const period = this.searchPeriod.value as SearchPeriod;
    const periodStart =
      period === SearchPeriod.CUSTOM && this.startDateTimeFilterProvider.item.valid
        ? normalizeToDate(this.startDateTimeFilterProvider.item.value).toISOString()
        : null;
    const periodTo =
      period === SearchPeriod.CUSTOM && this.toDateTimeFilterProvider.item.valid
        ? normalizeToDate(this.toDateTimeFilterProvider.item.value).toISOString()
        : null;
    let type = this.messageType.value as string;
    if (type === 'ALL') {
      type = null;
    }

    this.messageOverviewService.get(index, size, sort, searchTerm, categories, period, periodStart, periodTo, type).subscribe(
      (messages) => {
        this.isLoadingSubject.next(false);
        this.dataSubject.next(messages.content);
        this.updatePaginatorFromPaginatedResponse(messages);
      },
      (error) => {
        this.isLoadingSubject.next(false);
        throw error;
      }
    );
  }

  disconnect(collectionViewer: CollectionViewer): void {
    super.disconnect(collectionViewer);
    if (this.selectedCategoriesSubscription) {
      this.selectedCategoriesSubscription.unsubscribe();
    }
    if (this.searchPeriodSubscription) {
      this.searchPeriodSubscription.unsubscribe();
    }
  }

  protected registerSelect(value: MatSelect): void {
    this._selectedCategories = value;
    if (this.selectedCategoriesSubscription) {
      this.selectedCategoriesSubscription.unsubscribe();
    }
    this.selectedCategoriesSubscription = this.selectedCategories.stateChanges.subscribe(() => {
      this.paginator.pageIndex = 0;
      this.internalLoadData();
    });
  }
}

@Component({
  selector: 'app-messages-overview-dialog',
  templateUrl: './message-overview-dialog.component.html',
  styleUrls: ['./message-overview-dialog.component.scss']
})
export class MessageOverviewDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: MessageEntity) {}
}

@Component({
  selector: 'app-messages-overview',
  templateUrl: './message-overview.component.html',
  styleUrls: ['./message-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessageOverviewComponent implements OnInit, AfterViewInit {
  startDateTimeControl = new UntypedFormControl(null, [Validators.required]);
  toDateTimeControl = new UntypedFormControl(null, [Validators.required]);

  displayedColumns = ['timestamp', 'category', 'type', 'userId', 'context', 'text'];

  dataSource: MessageOverviewDataSource = new MessageOverviewDataSource(this.messageOverviewService);

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(TableFilterComponent, { static: false }) filter: TableFilterComponent;
  @ViewChild('categorySelect', { static: false }) selectedCategories: MatSelect;
  @ViewChild('searchPeriod', { static: false }) searchPeriod: MatSelect;
  @ViewChild('messageType', { static: false }) messageType: MatSelect;
  categories: ReadonlyArray<string> = [];
  isSearchDisabled$: Observable<boolean>;
  @ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

  constructor(private messageOverviewService: MessageOverviewService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.sort.active = 'timestamp';
    this.sort.direction = 'desc';
    this.messageOverviewService.getCategories().subscribe((data) => (this.categories = data));
  }

  ngAfterViewInit(): void {
    this.isSearchDisabled$ = merge(
      this.startDateTimeControl.statusChanges,
      this.toDateTimeControl.statusChanges,
      this.searchPeriod.stateChanges
    ).pipe(
      startWith(false),
      // Listen for all possible state changes
      map(() => {
        if (!this.isCustomPeriod()) {
          return false;
        }

        return this.startDateTimeControl.invalid || this.toDateTimeControl.invalid || false;
      })
    );

    this.dataSource.startDateTimeFilter = this.startDateTimeControl;
    this.dataSource.toDateTimeFilter = this.toDateTimeControl;
    this.dataSource.updateDataWhenFilterChanges = false;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filter = this.filter;
    this.dataSource.sort = this.sort;
    this.dataSource.selectedCategories = this.selectedCategories;
    this.dataSource.searchPeriod = this.searchPeriod;
    this.dataSource.messageType = this.messageType;
  }

  openDialog(row: any): void {
    this.dialog.open(MessageOverviewDialogComponent, {
      width: '70vw',
      data: row
    });
  }

  doSearch(): void {
    this.dataSource.doLoadData();
  }

  isCustomPeriod(): boolean {
    return this.searchPeriod?.value === SearchPeriod.CUSTOM;
  }
}
