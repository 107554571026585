import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Subscription } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { get } from 'lodash-es';
import { AnalogInputSignalType, AnalogInputType, InputKind } from '../analog-input-detail/analog-input-detail.component';
import { AnalogInput, AnalogInputService } from 'flex-app-shared';

@Component({
  selector: 'app-analog-inputs',
  templateUrl: './analog-inputs.component.html',
  styleUrls: ['./analog-inputs.component.scss']
})
export class AnalogInputsComponent implements OnInit, OnDestroy {
  InputKind = InputKind;
  AnalogInputType = AnalogInputType;
  AnalogInputSignalType = AnalogInputSignalType;

  signalTypes = {
    analogInputSignalType: {
      TYPE_0_5V: '0 - 5V',
      TYPE_0_10V: '0 - 10V',
      TYPE_0_20MA: '0 - 20mA'
    }
  };
  readonly displayedColumns: ReadonlyArray<string> = ['kind', 'description', 'type', 'customer', 'inputSignalType', 'provider', 'actions'];
  readonly dataSource: MatTableDataSource<AnalogInput> = new MatTableDataSource<AnalogInput>([]);

  @ViewChild(MatPaginator, { static: true }) private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) private readonly sort: MatSort;
  subscription: Subscription;

  constructor(private builder: UntypedFormBuilder, private service: AnalogInputService) {}

  ngOnInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'customer':
          return data.customerName;
        case 'inputSignalType':
          return data.type;
        default:
          return get(data, sortHeaderId);
      }
    };
    this.loadData();
  }

  private loadData(): void {
    this.subscription = this.service.getAll().subscribe((inputs) => {
      this.dataSource.data = inputs;
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }

  delete(input: AnalogInput): void {
    this.service.delete(input.id).subscribe(() => this.loadData());
  }
}
