import { Action, Selector, State, StateContext } from '@ngxs/store';
import { EntityStateModel, FetchEntity, GridPointsLoadedEvent, GridPointsStateModel } from 'flex-app-shared';
import {
  DeleteTsoAgreementCommand,
  DownloadTsoAgreementsCommand,
  LoadTsoAgreementsCommand,
  TsoAgreementsLoadedEvent
} from './tso-agreements.actions';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TsoAgreement, TsoAgreementService } from '../../app/shared/tso-agreement/tso-agreement.service';
import { Injectable } from '@angular/core';

class TsoAgreementsModel extends EntityStateModel<ReadonlyArray<TsoAgreement>> {
  model: ReadonlyArray<TsoAgreement> = [];
  isBusyDownloading: boolean = false;
  isBusyDeleting: boolean = false;
}

@State({
  name: 'tsoAgreements',
  defaults: new TsoAgreementsModel()
})
@Injectable({
  providedIn: 'root'
})
export class TsoAgreementsState {
  constructor(private tsoAgreementService: TsoAgreementService) {}

  @Selector()
  public static getTsoAgreements(state: TsoAgreementsModel): any {
    return state.model;
  }

  @Selector()
  public static isBusyDownloading(state: TsoAgreementsModel): boolean {
    return state.isBusyDownloading;
  }

  @Selector()
  public static isBusyDeleting(state: TsoAgreementsModel): boolean {
    return state.isBusyDeleting;
  }

  @FetchEntity()
  @Action(LoadTsoAgreementsCommand)
  loadTsoAgreements({ setState, getState, dispatch }: StateContext<TsoAgreementsModel>): Observable<ReadonlyArray<TsoAgreement>> {
    setState({ ...getState() });

    return this.tsoAgreementService.getAll().pipe(
      tap((tsoAgreements) => {
        dispatch(new TsoAgreementsLoadedEvent(tsoAgreements));
      })
    );
  }

  @Action(TsoAgreementsLoadedEvent)
  setTsoAgreements({ setState, getState }: StateContext<TsoAgreementsModel>, { payload }: TsoAgreementsLoadedEvent): void {
    const state = getState();
    setState({
      ...state,
      model: payload
    });
  }

  @Action(DownloadTsoAgreementsCommand)
  downloadTsoAgreements({ setState, getState }: StateContext<TsoAgreementsModel>): Observable<any> {
    setState({
      ...getState(),
      isBusyDownloading: true
    });

    return this.tsoAgreementService.downloadTsoAgreements().pipe(
      tap({
        complete: () =>
          setState({
            ...getState(),
            isBusyDownloading: false
          })
      })
    );
  }

  @Action(DeleteTsoAgreementCommand)
  deleteTsoAgreement({ setState, getState }: StateContext<TsoAgreementsModel>, { agreement }: DeleteTsoAgreementCommand): Observable<any> {
    setState({
      ...getState(),
      isBusyDeleting: true
    });

    return this.tsoAgreementService.delete(agreement).pipe(
      tap({
        complete: () =>
          setState({
            ...getState(),
            isBusyDeleting: false
          }),
        error: () =>
          setState({
            ...getState(),
            isBusyDeleting: false
          })
      })
    );
  }
}
