import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterStateSnapshot } from '@angular/router';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  AdjustmentDirection,
  AsyncMessage,
  AsyncMessagingService,
  EntityState,
  EntityStateModel,
  FetchEntity,
  findParameterValue,
  MessageService,
  MessageType,
  Operation,
  OperationService,
  OperationView,
  PeriodView,
  RouterInitializer,
  SaveEntity
} from 'flex-app-shared';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { v4 } from 'uuid';
import { OperationMeasurementsSnackbarComponent } from '../../../../app/shared/operation/operation-measurements-snackbar/operation-measurements-snackbar.component';
import {
  DownloadOperationPoolDataCommand,
  LoadOperationCommand,
  OperationLoadedEvent,
  ResetOperationCommand,
  RetrieveMeasurementsCommand,
  SaveOperationCommand,
  SendActivatedPoolToTennetCommand,
  SendEndActivationMessageCommand,
  SendOperationToDevicesCommand,
  SendStartActivationMessageCommand
} from './operation.actions';

export class OperationStateModel extends EntityStateModel<Operation> {
  model: Operation = {
    active: false,
    direction: AdjustmentDirection.UPWARDS,
    ended: null,
    cancelled: null,
    id: null,
    manual: true,
    notificationTime: null,
    period: null,
    reference: null,
    requestedPower: null,
    initialPoolSentSuccessfully: null,
    operationPool: []
  };
  measurementRetrievalBusy = false;
}

@State<OperationStateModel>({
  name: 'operation',
  defaults: new OperationStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class OperationState extends EntityState<Operation> implements RouterInitializer<OperationStateModel> {
  constructor(
    store: Store,
    private operationService: OperationService,
    private messageService: MessageService,
    private location: Location,
    private snackBar: MatSnackBar,
    private asyncMessagingService: AsyncMessagingService
  ) {
    super(store);
  }

  @Selector()
  public static getOperation(state: OperationStateModel): OperationView {
    return {
      ...state.model,
      period: PeriodView.deserialize(state.model.period),
      operationPool: state.model.operationPool.map((operationPool) => {
        return {
          ...operationPool,
          period: PeriodView.deserialize(operationPool.period)
        };
      })
    };
  }

  @Selector()
  public static isBusy(state: OperationStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isInitialized(state: OperationStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static isBusySending(state: OperationStateModel): boolean {
    return state.isBusySending;
  }

  @Selector()
  public static error(state: OperationStateModel): string | null {
    return state.sendError;
  }

  @Selector()
  public static isMeasurementRetrievalBusy(state: OperationStateModel): boolean {
    return state.measurementRetrievalBusy;
  }

  @Action(RouterNavigation)
  routerInit({ getState, dispatch }: StateContext<OperationStateModel>, { routerState }: RouterNavigation<RouterStateSnapshot>): void {
    const operationId = findParameterValue(routerState, 'operationId');
    if (operationId && operationId !== 'new') {
      // Fetch customer data for id
      dispatch(new LoadOperationCommand(operationId));
    } else if (getState().model.id) {
      // If we had customer data, remove it
      dispatch(new ResetOperationCommand());
    }
  }

  @Action(LoadOperationCommand)
  @FetchEntity()
  initOperationData(ctx: StateContext<OperationStateModel>, { id }: LoadOperationCommand): Observable<Operation> {
    return this.operationService.getById(id).pipe(tap((operation) => ctx.dispatch(new OperationLoadedEvent(operation))));
  }

  @Action(OperationLoadedEvent)
  setOperationData({ setState, getState }: StateContext<OperationStateModel>, { payload }: OperationLoadedEvent): void {
    const state = getState();
    setState({
      ...state,
      model: {
        ...state.model,
        ...payload
      }
    });
  }

  @Action(SaveOperationCommand)
  @SaveEntity()
  saveOperation(ctx: StateContext<OperationStateModel>, action: SaveOperationCommand): Observable<Operation> {
    const operation = {
      ...action.payload,
      id: ctx.getState().model.id
    };

    const saveObservable = operation.id ? this.operationService.update(operation) : this.operationService.add(operation);

    return saveObservable.pipe(tap(() => this.location.back()));
  }

  @Action(ResetOperationCommand)
  resetOperation(ctx: StateContext<OperationStateModel>): void {
    ctx.setState(new OperationStateModel());
  }

  @Action(SendOperationToDevicesCommand)
  sendOperationToDevices({ getState, dispatch }: StateContext<OperationStateModel>): Observable<AsyncMessage> {
    return this.operationService.sendOperationToDevices(getState().model.id).pipe(
      tap((v) => {
        if (!this.asyncMessagingService.isConnected) {
          this.snackBar.open('Send operation to devices started, check messages for confirmation.', 'ok', { duration: 5000 });
          return;
        }
        switch (v.type) {
          case MessageType.Response:
            this.snackBar.open('Send operation to devices started', 'ok', { duration: 5000 });
            break;
          case MessageType.InitialPoolSentToTsoSseEvent:
            this.snackBar.open('Initial pool for operation send to TenneT', 'ok', { duration: 5000 });
            break;
          case MessageType.FailedToSendInitialPoolToTsoSseEvent:
            this.snackBar.open('Initial pool for operation was NOT send to TenneT. See messages for details.', 'ok');
            break;
          case MessageType.OperationStartedOnAllDevicesSseEvent:
            this.snackBar.open('Started operation on devices', 'ok', { duration: 5000 });
            break;
          case MessageType.OperationEndedOnAllDevicesSseEvent:
            this.snackBar.open('Ended operation on devices', 'ok', { duration: 5000 });
            break;
          case MessageType.AllStartActivationMessagesSentSseEvent:
            this.snackBar.open('Start operation messages sent', 'ok', { duration: 5000 });
            break;
          case MessageType.AllEndActivationMessagesSentSseEvent:
            this.snackBar.open('End operation messages sent', 'ok', { duration: 5000 });
            break;
          case MessageType.AllActivationCancelledMessagesSentSseEvent:
            this.snackBar.open('Operation cancelled messages sent', 'ok', { duration: 5000 });
            break;
        }
        if (getState().model.id) {
          // The operationId could have been removed from the state at this point, so
          // only load the operation if we still have it.
          // (If we do not have it anymore, the user probably pressed 'save' or another action that caused the operationId
          // to be cleared from the state.)
          dispatch(new LoadOperationCommand(getState().model.id));
        }
      })
    );
  }

  @Action(SendActivatedPoolToTennetCommand)
  sendActivatedPoolToTennet({ getState }: StateContext<OperationStateModel>): Observable<object> {
    return this.operationService.sendActivatedPoolToTennet(getState().model.id);
  }

  @Action(SendStartActivationMessageCommand)
  sendStartActivationMessage({ getState }: StateContext<OperationStateModel>): Observable<AsyncMessage> {
    return this.operationService.sendStartActivationMessage(getState().model.id).pipe(
      tap((v) => {
        if (!this.asyncMessagingService.isConnected) {
          this.snackBar.open('Sending start activation messages', null, { duration: 5000 });
        }
        switch (v.type) {
          case MessageType.Response:
            this.snackBar.open('Sending start activation messages', 'ok', { duration: 5000 });
            break;
          case MessageType.AllStartActivationMessagesSentSseEvent:
            this.snackBar.open('Operation started messages sent', 'ok', { duration: 5000 });
            break;
        }
      })
    );
  }

  @Action(SendEndActivationMessageCommand)
  sendEndActivationMessage({ getState }: StateContext<OperationStateModel>): Observable<AsyncMessage> {
    return this.operationService.sendEndActivationMessage(getState().model.id).pipe(
      tap((v) => {
        if (!this.asyncMessagingService.isConnected) {
          this.snackBar.open('Sending end activation messages', null, { duration: 5000 });
        }
        switch (v.type) {
          case MessageType.Response:
            this.snackBar.open('Sending end activation messages', 'ok', { duration: 5000 });
            break;
          case MessageType.AllStartActivationMessagesSentSseEvent:
            this.snackBar.open('Operation ended messages sent', 'ok', { duration: 5000 });
            break;
        }
      })
    );
  }

  @Action(RetrieveMeasurementsCommand)
  retrieveMeasurements({ getState, dispatch, patchState }: StateContext<OperationStateModel>): Observable<object> {
    if (getState().measurementRetrievalBusy) {
      console.warn('Ignoring retrieveMeasurement request, already busy');
      return;
    }

    patchState({ measurementRetrievalBusy: true });

    const operationId = getState().model.id;
    const conversationId = v4();
    const retrieveOperationMeasurementRequest = this.operationService.retrieveOperationMeasurementData(operationId, conversationId);

    const matSnackBarRef = this.snackBar.openFromComponent(OperationMeasurementsSnackbarComponent, {
      data: {
        conversationId
      }
    });
    matSnackBarRef.afterDismissed().subscribe(() => {
      dispatch(new LoadOperationCommand(operationId));
      patchState({ measurementRetrievalBusy: false });
    });

    retrieveOperationMeasurementRequest.subscribe(
      () => {},
      (response) => {
        console.warn('Error retrieving measurement data ', response);
        matSnackBarRef.dismiss();
      }
    );
  }

  @Action(DownloadOperationPoolDataCommand)
  handleDownloadOperationPoolDataCommand({ getState }: StateContext<OperationStateModel>): any {
    const operationId = getState().model.id;

    return this.operationService.downloadOperationPoolData(operationId);
  }
}
