<div [formGroup]="form" class="timepicker">
  <div class="timepicker__block">
    <button mat-icon-button class="timepicker__button -above" (click)="increase('hours')">
      <mat-icon svgIcon="ic-old:chevron-up"></mat-icon>
    </button>
    <mat-form-field class="timepicker__input-container">
      <input matInput class="timepicket__input" formControlName="hours" />
    </mat-form-field>
    <button mat-icon-button class="timepicker__button -below" (click)="decrease('hours')">
      <mat-icon svgIcon="ic-old:chevron-down"></mat-icon>
    </button>
  </div>

  <div class="timepicker__block">
    <span class="timepicker__divider"> {{ separator }} </span>
  </div>

  <div class="timepicker__block">
    <button mat-icon-button class="timepicker__button -above" (click)="increase('minutes')">
      <mat-icon svgIcon="ic-old:chevron-up"></mat-icon>
    </button>
    <mat-form-field class="timepicker__input-container">
      <input matInput class="timepicket__input" formControlName="minutes" />
    </mat-form-field>
    <button mat-icon-button class="timepicker__button -below" (click)="decrease('minutes')">
      <mat-icon svgIcon="ic-old:chevron-down"></mat-icon>
    </button>
  </div>

  <div class="timepicker__block">
    <span class="timepicker__divider">&nbsp;</span>
  </div>
</div>
