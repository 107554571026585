import { User } from '../../../../app/shared/user/user.service';

export class ResetUsersCommand {
  public static readonly type = '[Users] Reset users action';
}

export class LoadUsersCommand {
  public static readonly type = '[Users] Load users data';

  constructor() {}
}

export class DownloadUsersDataCommand {
  public static readonly type = '[Users] Download users data';

  constructor() {}
}

export class UsersLoadedEvent {
  public static readonly type = '[Users] Users loaded event';

  constructor(public payload: User[]) {}
}

export class DeleteUserCommand {
  public static readonly type = '[Users] Delete a user';

  constructor(public payload: User) {}
}
