import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  EntityStateModel,
  FetchEntity,
  MessageType,
  PeriodView,
  SaveEntity,
  AsyncMessageReceivedEvent,
  AsyncMessagingService
} from 'flex-app-shared';
import { Observable } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import {
  AggregatedUnavailabilities,
  AggregatedUnavailabilitiesView
} from '../../../../app/shared/unavailability/aggregated-unavailability';
import { UnavailabilityService } from '../../../../app/shared/unavailability/unavailability.service';
import { LoadUnavailabilitiesCommand } from '../unavailabilities/unavailabilities.actions';
import {
  AggregatedUnavailabilitiesLoadedEvent,
  AggregatedUnavailabilitiesResetCommand,
  ClearSentUnavailabilitiesCommand,
  LoadAggregatedUnavailabilitiesCommand,
  SendUnavailabilitiesCommand
} from './aggregated-unavailabilities.actions';

class AggregatedUnavailabilitiesStateModel extends EntityStateModel<AggregatedUnavailabilities> {
  model: AggregatedUnavailabilities = {
    unavailabilities: []
  };
}

@State<AggregatedUnavailabilitiesStateModel>({
  name: 'aggregatedUnavailabilitiesState',
  defaults: new AggregatedUnavailabilitiesStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class AggregatedUnavailabilitiesState {
  constructor(
    private unavailabilityService: UnavailabilityService,
    protected store: Store,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    private asyncMessagingService: AsyncMessagingService
  ) {}

  @Selector()
  public static getAggregatedUnavailabilities(state: AggregatedUnavailabilitiesStateModel): AggregatedUnavailabilitiesView {
    return {
      ...state.model,
      unavailabilities: state.model.unavailabilities.map((a) => {
        return {
          ...a,
          period: PeriodView.deserialize(a.period)
        };
      })
    };
  }

  @Selector()
  public static isBusy(state: AggregatedUnavailabilitiesStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isInitialized(state: AggregatedUnavailabilitiesStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static isBusySending(state: AggregatedUnavailabilitiesStateModel): boolean {
    return state.isBusySending;
  }

  @Selector()
  public static error(state: AggregatedUnavailabilitiesStateModel): string | null {
    return state.sendError;
  }

  @Action(AggregatedUnavailabilitiesResetCommand)
  resetAggregatedUnavailabilities({ setState }: StateContext<AggregatedUnavailabilitiesStateModel>): void {
    setState(new AggregatedUnavailabilitiesStateModel());
  }

  @FetchEntity()
  @Action(LoadAggregatedUnavailabilitiesCommand)
  initAggregatedUnavailabilities({ dispatch }: StateContext<AggregatedUnavailabilitiesStateModel>): Observable<AggregatedUnavailabilities> {
    return this.unavailabilityService
      .show()
      .pipe(tap((aggregatedUnavailabilities) => dispatch(new AggregatedUnavailabilitiesLoadedEvent(aggregatedUnavailabilities))));
  }

  @Action(AggregatedUnavailabilitiesLoadedEvent)
  setAggregatedUnavailabilities(
    { setState, getState }: StateContext<AggregatedUnavailabilitiesStateModel>,
    { payload }: AggregatedUnavailabilitiesLoadedEvent
  ): void {
    const state = getState();
    setState({
      ...state,
      model: {
        ...state.model,
        ...payload
      }
    });
  }

  @SaveEntity()
  @Action(SendUnavailabilitiesCommand)
  sendUnavailabilities(): Observable<AggregatedUnavailabilities> {
    return this.unavailabilityService.send().pipe(
      tap((v) => {
        if (!this.asyncMessagingService.isConnected) {
          this.snackBar.open('Sent unavailabilities to TenneT.', 'ok', { duration: 5000 });
          return;
        }
      }),
      delay(1000),
      tap(() => this.store.dispatch(new LoadUnavailabilitiesCommand()))
    );
  }

  @SaveEntity()
  @Action(ClearSentUnavailabilitiesCommand)
  clearSentUnavailabilities(): Observable<any> {
    return this.unavailabilityService.clear().pipe(
      tap((v) => {
        if (!this.asyncMessagingService.isConnected) {
          this.snackBar.open('Cleared sent unavailabilities.', 'ok', { duration: 5000 });
          return;
        }
      }),
      delay(1000),
      tap(() => this.store.dispatch(new LoadUnavailabilitiesCommand()))
    );
  }

  @Action(AsyncMessageReceivedEvent)
  handleSseMessageReceived(
    { getState, dispatch, patchState }: StateContext<AggregatedUnavailabilitiesStateModel>,
    action: AsyncMessageReceivedEvent
  ): void {
    switch (action.message?.type) {
      case MessageType.UnavailabilitiesDeclaredWithTsoSseEvent:
        const declaredEvent: { ok: boolean; reasons: string[] } = JSON.parse(action.message.payload);
        if (declaredEvent.ok) {
          this.snackBar.open('Unavailabilities declared with TenneT.', 'ok', { duration: 5000 });
        } else {
          this.snackBar.open(`Errors while declaring unavailabilities with TenneT: ${declaredEvent.reasons}`, 'ok', { duration: 5000 });
        }
        break;
      case MessageType.UnavailabilitiesClearedWithTsoSseEvent:
        const clearedEvent: { ok: boolean; reasons: string[] } = JSON.parse(action.message.payload);
        if (clearedEvent.ok) {
          this.snackBar.open('Unavailabilities cleared with TenneT.', 'ok', { duration: 5000 });
        } else {
          this.snackBar.open(`Errors while clearing unavailabilities with TenneT: ${clearedEvent.reasons}`, 'ok', { duration: 5000 });
        }
        break;
    }
  }
}
