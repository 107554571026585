import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { CustomValidators, FnErrorMatcher, GridPointService, Product, ServiceAgreement, validPeriodValidator } from 'flex-app-shared';
import { isNil } from 'lodash-es';
import moment, { Moment } from 'moment';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, startWith, takeUntil, withLatestFrom } from 'rxjs/operators';
import { ServiceAgreementFacade } from '../../store/service-agreements/states/service-agreement/service-agreement.facade';
import { GridPointSelection } from '../control-detail/control-detail.component';
import { CrossFieldErrorMatcher, SelectionModelControlAdapter } from '../shared/common/common';

@Component({
  selector: 'app-service-agreement-detail',
  templateUrl: './service-agreement-detail.component.html',
  styleUrls: ['./service-agreement-detail.component.scss']
})
export class ServiceAgreementDetailComponent implements OnInit, OnDestroy, AfterViewInit {
  showAllErrors = false;
  isGridPointsControlTouched = false;
  fnErrorMatcher = new FnErrorMatcher(() => this.showAllErrors);
  Product = Product;
  commonFormGroup = this.builder.group({
    id: '',
    customerId: ['', [Validators.required]],
    systemReference: '',
    reference: '',
    product: Product.R3_EMERGENCY_POWER,
    period: this.builder.group(
      {
        startDate: ['', [Validators.required]],
        endDate: ['', [Validators.required]]
      },
      { validators: validPeriodValidator }
    ),
    gridPointIds: []
  });

  emergencyPowerFormGroup = this.builder.group({
    maxCapacityUpward: this.builder.group({
      value: ['0', [Validators.required, Validators.min(0), CustomValidators.numberOfDecimals(0, 2)]],
      unit: 'MW'
    }),
    maxCapacityDownward: this.builder.group({
      value: ['0', [Validators.required, Validators.min(0), CustomValidators.numberOfDecimals(0, 2)]],
      unit: 'MW'
    }),
    availabilityFeePercBaseLoad: ['0', [Validators.required, Validators.min(0), Validators.max(100), Validators.pattern('[0-9]{1,3}')]],
    availabilityFeePercTimeTable: ['0', [Validators.required, Validators.min(0), Validators.max(100), Validators.pattern('[0-9]{1,3}')]]
  });

  crossFieldErrorMatcher = new CrossFieldErrorMatcher('period');
  defaultPageSize = 10;
  displayedGridPointColumns$: Observable<string[]>;
  selection: SelectionModel<string> = new SelectionModel<string>(true, []);
  selectionAdapter = new SelectionModelControlAdapter(this.selection);
  dataSource: MatTableDataSource<GridPointSelection> = new MatTableDataSource<GridPointSelection>([]);
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  private readonly ngUnsubscribe$ = new Subject<void>();
  busySaving: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private builder: UntypedFormBuilder,
    private gridPointService: GridPointService,
    public serviceAgreementFacade: ServiceAgreementFacade
  ) {}

  private prevCustomerId: string | undefined = undefined;

  isEmergencyPowerServiceAgreement(): boolean {
    return ServiceAgreement.isEmergencyPowerServiceAgreement(this.commonFormGroup.getRawValue());
  }

  ngOnInit(): void {
    this.selectionAdapter.setControl(this.commonFormGroup.get('gridPointIds'));
    this.serviceAgreementFacade.serviceAgreement$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((serviceAgreement) => {
      if (
        (isNil(this.dataSource.data) || this.dataSource.data.length === 0) &&
        serviceAgreement.customerId &&
        serviceAgreement.customerId !== this.prevCustomerId
      ) {
        this.getGridPoints(serviceAgreement);
        this.prevCustomerId = serviceAgreement.customerId;
      }

      if (!this.busySaving) {
        this.commonFormGroup.patchValue(serviceAgreement);
      }
      if (this.isEmergencyPowerServiceAgreement()) {
        this.emergencyPowerFormGroup.patchValue(serviceAgreement);
      }

      if (this.isNew) {
        this.commonFormGroup.get(`product`).enable();
      } else {
        this.commonFormGroup.get(`product`).disable();
      }
    });
    this.displayedGridPointColumns$ = this.commonFormGroup.valueChanges.pipe(
      startWith(this.commonFormGroup.value),
      map(() => this.commonFormGroup.get('product').value === Product.R3_EMERGENCY_POWER),
      map((isIncidentReserveProduct) => {
        if (isIncidentReserveProduct) {
          return ['select', 'ean', 'description', 'incidentReservePreQualified'];
        } else {
          return ['select', 'ean', 'description'];
        }
      })
    );

    this.commonFormGroup
      .get('customerId')
      .valueChanges.pipe(
        distinctUntilChanged(),
        withLatestFrom(this.serviceAgreementFacade.serviceAgreement$),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe(([customerId, serviceAgreement]) => {
        if (this.commonFormGroup.get('customerId').invalid || isNil(customerId)) return;
        if (this.prevCustomerId !== customerId) {
          this.selection.clear();
        }
        this.getGridPoints({ ...serviceAgreement, customerId });
      });

    const startDateSubscr$ = this.startDate.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((value) => {
      const id = this.route.snapshot.paramMap.get('serviceAgreementId');
      if (id !== 'new') return startDateSubscr$.unsubscribe();
      if (!this.endDate.pristine) return startDateSubscr$.unsubscribe();

      if (value) this.endDate.setValue(this.getCalculatedEndDate(value));
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getGridPoints(serviceAgreement: ServiceAgreement): void {
    this.gridPointService
      .getByCustomerId(serviceAgreement.customerId)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((gridPoints) => {
        const mappedGridPoints = gridPoints.map(({ id, ean, description, preQualified }) => ({
          gridPointId: id,
          ean,
          description,
          ownedByCustomer: true,
          incidentReservePreQualified: preQualified
        }));
        this.dataSource.data = this.constructGridPointsData(mappedGridPoints, serviceAgreement).concat();
        const selectedGridPoints = serviceAgreement.gridPoints.map((gp) => gp.gridPointId);
        this.selection.select(...selectedGridPoints);
      });
  }

  constructGridPointsData(
    customerGridPoints: ReadonlyArray<GridPointSelection>,
    { gridPoints }: ServiceAgreement
  ): ReadonlyArray<GridPointSelection> {
    const customerGridPointIds = customerGridPoints.map((gp) => gp.gridPointId);
    const list = !isNil(gridPoints) ? gridPoints : [];
    const otherGridPoints = list
      .filter((gp) => !customerGridPointIds.includes(gp.gridPointId))
      .map(({ gridPointId, ean, description, incidentReservePreQualified }) => ({
        gridPointId,
        ean,
        description,
        ownedByCustomer: false,
        incidentReservePreQualified
      }));

    return customerGridPoints.concat(otherGridPoints);
  }

  onSubmit(): void {
    if (this.hasRelevantErrors()) {
      this.showAllErrors = true;
    } else {
      this.busySaving = true;
      this.customerId.enable(); // make sure it shows up in the form.value
      const emergencyPowerFormValue = this.isEmergencyPowerServiceAgreement() ? this.emergencyPowerFormGroup.value : {};
      const serviceAgreement = {
        ...this.commonFormGroup.getRawValue(),
        ...emergencyPowerFormValue
      };
      this.serviceAgreementFacade.save(serviceAgreement);
    }
  }

  isNoGridPointsSelected(): boolean {
    return this.selection.selected.length === 0;
  }

  showNoGridPointsSelectedErrorMessage(): boolean {
    return (this.showAllErrors || this.isGridPointsControlTouched) && this.isNoGridPointsSelected();
  }

  goBack(): void {
    this.location.back();
  }

  get customerId(): AbstractControl | null {
    return this.commonFormGroup.get('customerId');
  }

  get systemReference(): AbstractControl | null {
    return this.commonFormGroup.get('systemReference');
  }

  get reference(): AbstractControl | null {
    return this.commonFormGroup.get('reference');
  }

  get product(): AbstractControl | null {
    return this.commonFormGroup.get('product');
  }

  get period(): AbstractControl | null {
    return this.commonFormGroup.get('period') as UntypedFormGroup;
  }

  get startDate(): AbstractControl | null {
    return this.period.get('startDate');
  }

  get endDate(): AbstractControl | null {
    return this.period.get('endDate');
  }

  get maxCapacityUpward(): AbstractControl | null {
    return this.emergencyPowerFormGroup.get('maxCapacityUpward') as UntypedFormGroup;
  }

  get maxCapacityDownward(): AbstractControl | null {
    return this.emergencyPowerFormGroup.get('maxCapacityDownward') as UntypedFormGroup;
  }

  get isNew(): boolean {
    return !this.commonFormGroup.get('id').value;
  }

  /** Is this row selected? */
  isRowSelected(gridPointId: string): boolean {
    return this.selection.isSelected(gridPointId);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  private hasRelevantErrors(): boolean {
    let hasErrors = !this.commonFormGroup.valid || this.isNoGridPointsSelected();

    if (this.isEmergencyPowerServiceAgreement()) {
      hasErrors = hasErrors || this.emergencyPowerFormGroup.invalid;
    }

    return hasErrors;
  }

  private getCalculatedEndDate(value: Moment): Moment {
    const endDate = moment(value);
    endDate.add(1, 'year');
    endDate.subtract(1, 'month');
    endDate.endOf('month');
    return endDate;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(event: Event): void {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data.forEach((row) => this.selection.select(row.gridPointId));
    }
  }

  /** Returns true if all values of the selection are checked (master toggle should have a check mark as well) */
  get isMasterToggleChecked(): boolean {
    return this.selection.hasValue() && this.isAllSelected();
  }

  /** Returns true if any, but no all values of the selection are checked (master toggle should have a indeterminate mark (a hyphen/dash)) */
  get isMasterToggleIndeterminate(): boolean {
    return this.selection.hasValue() && !this.isAllSelected();
  }

  /** Toggle selection of a row in the selection */
  toggleRow(gridPointId: string): void {
    this.selection.toggle(gridPointId);
  }
}
