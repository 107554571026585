import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TennetIsAliveService {
  constructor(private http: HttpClient) {}

  isIncidentReserveAlive(): Observable<object> {
    return this.http.get('/api/v1/tennet/is-incident-reserve-alive');
  }

  isAncillaryServicesAlive(): Observable<object> {
    return this.http.get('/api/v1/tennet/is-ancillary-services-alive');
  }

  isMmcHubAlive(): Observable<object> {
    return this.http.get('/api/v1/tennet/is-mmchub-alive');
  }

  getAuctionResults(): Observable<object> {
    return this.http.get('/api/v1/tennet/auction-results');
  }

  requestStatuses(): Observable<object> {
    return this.http.get('/api/v1/tennet/request-statuses');
  }
}
