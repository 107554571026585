<mat-card-header>
  <mat-card-title>Incident reserve activation contacts</mat-card-title>
</mat-card-header>

<form cdkDropList (cdkDropListDropped)="drop($event)" class="contact-list">
  <div *ngFor="let contactFormGroup of formArray.controls; index as index" cdkDrag [formGroup]="contactFormGroup">
    <span>{{ index + 1 }}.</span>
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" />
    </mat-form-field>
    <mat-form-field subscriptSizing="dynamic">
      <mat-label>Phone</mat-label>
      <input matInput formControlName="phone" />
    </mat-form-field>
    <button mat-icon-button color="warn" (click)="delete(index)">
      <mat-icon svgIcon="ic-old:trash"></mat-icon>
    </button>
  </div>
</form>

<mat-card-footer>
  <mat-card-actions>
    <button (click)="add()" mat-stroked-button>Add</button>
    <ph-flex-save-button (clickChanged)="saveAll()" [pending]="pending" [error]="error" color="primary">Save</ph-flex-save-button>
  </mat-card-actions>
</mat-card-footer>
