import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { MessageType, AsyncMessageReceivedEvent, AsyncMessagingService } from 'flex-app-shared';
import { DayAheadDealsReceivedInfo, ResetDayAheadDealsReceivedInfo, SseDayAheadDealsReceivedEvent } from './sse-day-ahead.actions';

class SseDayAheadStateModel {
  dayAheadDealsReceivedInfo: DayAheadDealsReceivedInfo = null;
}

@State({
  name: 'sseDayAhead',
  defaults: new SseDayAheadStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class SseDayAheadState {
  constructor(private asyncMessagingService: AsyncMessagingService, private store: Store, private snackBar: MatSnackBar) {
    asyncMessagingService.onMessageOfType(SseDayAheadDealsReceivedEvent.sseType).subscribe((event) => {
      store.dispatch(new SseDayAheadDealsReceivedEvent(event));
    });
  }

  @Selector()
  static dayAheadDealsReceivedInfo(state: SseDayAheadStateModel): DayAheadDealsReceivedInfo {
    return state.dayAheadDealsReceivedInfo;
  }

  @Action(ResetDayAheadDealsReceivedInfo)
  resetState({ patchState }: StateContext<SseDayAheadStateModel>): void {
    patchState({
      dayAheadDealsReceivedInfo: null
    });
  }

  @Action(AsyncMessageReceivedEvent)
  handleSseMessageReceived(
    { getState, dispatch, patchState }: StateContext<SseDayAheadStateModel>,
    action: AsyncMessageReceivedEvent
  ): void {
    switch (action.message?.type) {
      case MessageType.DayAheadDealsReceivedSseEvent:
        const info = JSON.parse(action.message.payload);
        patchState({
          dayAheadDealsReceivedInfo: info
        });
        if (info.done) {
          if (info.success) {
            this.snackBar.open(`Retrieved ${info.dealsReceived} day ahead deals, for ${info.eansProcessed} grid points.`, 'ok', {
              duration: 5000
            });
          } else {
            this.snackBar.open(`Error while trying to retrieve day ahead deals: ${info.message}`, 'ok');
          }
        }
        break;
    }
  }
}
