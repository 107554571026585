import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { RestClientService, RestEntity } from 'flex-app-shared';

export enum DeviceProviderType {
  SERCOM = 'SERCOM',
  OTHER = 'OTHER'
}

export class Device extends RestEntity {
  customerId: string;
  providerType: DeviceProviderType;
  externalId: string;
  description?: string;
}

export class DeviceContact extends RestEntity {
  deviceId: string;
  externalId: string;
  invertedSchedules: boolean;
  description?: string;
  switchCooldownTime: number;
}

export class FlatDeviceContact {
  deviceId: string;
  customerId: string;
  customerName: string;
  deviceExternalId: string;
  deviceContactId: string;
  contactExternalId: string;
  contactDescription: string;
  contactInvertedSchedules: boolean;
  firstControlDescription: string;
}

@Injectable({
  providedIn: 'root'
})
export class DeviceService extends RestClientService<Device> {
  constructor() {
    super('/api/v1/devices');
  }

  add(value: Device): Observable<Device> {
    let newValue = value;
    if (value.id) {
      const { id, ...rest } = value;
      newValue = rest;
    }

    return this.http.post<Device>(this.endpoint, newValue);
  }

  getByCustomerId(customerId: string): Observable<Device[]> {
    return this.http.get<Device[]>(this.endpoint + `/customer/${customerId}`);
  }

  getDeviceContactsByCustomerId(customerId: string): Observable<FlatDeviceContact[]> {
    const url = `${this.endpoint}/device-contacts`;
    return this.http.get<FlatDeviceContact[]>(url, {
      params: { customerId }
    });
  }

  getDeviceContactById(deviceId: string, deviceContactId: string): Observable<DeviceContact> {
    const id = `${this.endpoint}/${deviceId}/contacts/${deviceContactId}`;
    return this.http.get<DeviceContact>(id);
  }

  getAllDeviceContacts(deviceId: string): Observable<DeviceContact[]> {
    const id = `${this.endpoint}/${deviceId}/contacts`;
    return this.http.get<DeviceContact[]>(id);
  }

  addDeviceContact(value: DeviceContact): Observable<DeviceContact> {
    return this.http.post<DeviceContact>(`${this.endpoint}/${value.deviceId}/contacts`, value);
  }

  updateDeviceContact(value: DeviceContact): Observable<DeviceContact> {
    return this.http.put<DeviceContact>(`${this.endpoint}/${value.deviceId}/contacts/${value.id}`, value);
  }

  deleteDeviceContact(value: DeviceContact): Observable<object> {
    return this.http.delete(`${this.endpoint}/${value.deviceId}/contacts/${value.id}`);
  }
}
