import { Component, Inject, ViewChild } from '@angular/core';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { OperationContactPersonService } from '../../shared/operation-contactperson/operation-contactperson-service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormArray, NgForm, UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { OperationContactPerson } from 'flex-app-shared';

@Component({
  selector: 'app-edit-incident-reserve-contact-information',
  templateUrl: './edit-incident-reserve-contact-information.component.html',
  styleUrls: ['./edit-incident-reserve-contact-information.component.scss']
})
export class EditIncidentReserveContactInformationComponent {
  pending: boolean;
  error: string;

  formArray: UntypedFormArray;

  constructor(
    private operationContactPersonService: OperationContactPersonService,
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public contacts: OperationContactPerson[],
    private fb: UntypedFormBuilder
  ) {
    this.formArray = fb.array(contacts.map((contact) => this.createFormGroup(contact)));
  }

  createFormGroup(contact: any = {}): UntypedFormGroup {
    return this.fb.group({
      name: this.fb.control(contact.name, [Validators.required, Validators.maxLength(255)]),
      phone: this.fb.control(contact.phone, [Validators.required, Validators.maxLength(15)])
    });
  }

  drop(event: any): void {
    moveItemInArray(this.contacts, event.previousIndex, event.currentIndex);
    const currentControl = this.formArray.controls[event.previousIndex];
    this.formArray.removeAt(event.previousIndex);
    this.formArray.insert(event.currentIndex, currentControl);
  }

  add(): void {
    this.formArray.push(this.createFormGroup());
  }

  delete(formArrayIndex: number): void {
    this.formArray.removeAt(formArrayIndex);
  }

  saveAll(): void {
    if (this.formArray.invalid) {
      this.error = 'Form validations';
      return;
    }

    this.pending = true;
    this.operationContactPersonService.saveAll(this.formArray.value).subscribe({
      complete: () => (this.pending = false),
      error: (err) => {
        this.error = err.message;
        this.pending = false;
      },
      next: () => this.dialogRef.close()
    });
  }
}
