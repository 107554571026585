<mat-dialog-content>
  <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
    <ng-container matColumnDef="period">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Period</th>
      <td mat-cell *matCellDef="let unavailability">
        {{ unavailability.period.startDateTime.date | date : 'short' }} - {{ unavailability.period.toDateTime.date | date : 'short' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="direction">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Direction</th>
      <td mat-cell *matCellDef="let unavailability">{{ unavailability.direction }}</td>
    </ng-container>
    <ng-container matColumnDef="power">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Power (MW)</th>
      <td mat-cell *matCellDef="let unavailability">{{ unavailability.capacity | capacityNumber : 'MW' }}</td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button class="primary" mat-dialog-close>
    <ph-flex-vertical-center>
      <mat-icon svgIcon="ic-old:close"></mat-icon>
      <span>Cancel</span>
    </ph-flex-vertical-center>
  </button>
  <button mat-flat-button color="cta" (click)="send()">
    <ph-flex-vertical-center>
      <mat-icon svgIcon="ic-old:paper-plane"></mat-icon>
      <span>Send</span>
    </ph-flex-vertical-center>
  </button>
</mat-dialog-actions>
