<ng-container [ngSwitch]="page">
  <ng-container *ngSwitchCase="'/dashboard'">
    <mat-icon
      [color]="type === 'sidebar' ? '' : 'accent'"
      [ngStyle]="{ display: type === 'sidebar' ? 'inherit' : 'none' }"
      svgIcon="ic-old:dashboard"
    >
    </mat-icon>
    <span>{{ type === 'sidebar' ? 'Dashboard' : '' }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/customer-stepper'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:user-add"></mat-icon>
    <span>Customer Wizard</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/customers'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:users-o"></mat-icon>
    <span>Customers</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/grid-points'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:location"></mat-icon>
    <span>Gridpoints</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/devices'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:devices"></mat-icon>
    <span>Devices</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/balance-responsible-parties'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:party"></mat-icon>
    <span>BRPs</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/tso-agreements'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:portfolio"></mat-icon>
    <span>TSO agreements</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/tso-auctions'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:portfolio"></mat-icon>
    <span>TSO auctions</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/service-agreements'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:sign-o"></mat-icon>
    <span>Service agreements</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/unavailabilities'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:circle-minus-o"></mat-icon>
    <span>Unavailabilities</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/measurement-companies'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:meter-o"></mat-icon>
    <span>Measurement companies</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/measurement-data-download'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:measurement"></mat-icon>
    <span>Measurement data download</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/measurement-data-upload'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:measurement"></mat-icon>
    <span>Measurement data upload</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/operations'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:flow-switch"></mat-icon>
    <span>Operations</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/messages'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:chat-bubbles"></mat-icon>
    <span>Messages</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/controls'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:cogs"></mat-icon>
    <span>Controls</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/pools'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:products"></mat-icon>
    <span>Pools</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/recipients'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:recipients"></mat-icon>
    <span>Recipients</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/users'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:recipients"></mat-icon>
    <span>Users</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/admin'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:utils"></mat-icon>
    <span>Admin</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/incident-reserve-notice'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:noodvermogen"></mat-icon>
    <span>Afroep noodvermogen</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/r3-invoice-data'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:invoice-orders"></mat-icon>
    <span>Incident reserve invoice data</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/incident-reserve-invoice-results'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:invoice-orders"></mat-icon>
    <span>Incident reserve invoice results</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/aggregated-pool'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:volume"></mat-icon>
    <span>Aggregated pool</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/pool-availability'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:availability"></mat-icon>
    <span>Pool availability</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/prototype'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:noodvermogen"></mat-icon>
    <span>Imbalance bidschedule</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/batteries'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:charging"></mat-icon>
    <span>Batteries</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/battery-measurement-data'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:battery2"></mat-icon>
    <span>Battery Measurement Data</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/analog-inputs'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:inputs"></mat-icon>
    <span>Analog Inputs</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/intraday/imbalance-correction'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:correction"></mat-icon>
    <span>Intraday Imbalance Correction</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/intraday/invoice-results'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:intraday"></mat-icon>
    <span>Intraday Invoice Results</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/intraday/deal-reporting'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:intraday"></mat-icon>
    <span>Intraday Deal Reporting</span>
  </ng-container>
  <ng-container *ngSwitchCase="'/group-authorities'">
    <mat-icon [color]="type === 'sidebar' ? '' : 'accent'" svgIcon="ic-old:party"></mat-icon>
    <span>Group authorities</span>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <span>Flex application - Powerhouse</span>
  </ng-container>
</ng-container>
