import { Injectable } from '@angular/core';
import {
  AdjustmentDirection,
  Capacity,
  DownloadService,
  formatMomentAsLocalDate,
  Period,
  Product,
  RestClientService,
  RestEntity
} from 'flex-app-shared';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

export class TsoAgreement extends RestEntity {
  product: Product;
  period: Period;
  direction: AdjustmentDirection;
  power: Capacity;
  hourlyFee: number;
  maxOperationTime: number;
  operationRampUpTime: number;
  operationRampDownTime: number;
  fromAuctionResult: boolean;
  auctionDescription: string;
}

@Injectable({
  providedIn: 'root'
})
export class TsoAgreementService extends RestClientService<TsoAgreement> {
  constructor(private downloadService: DownloadService) {
    super('/api/v1/tso-agreements');
  }

  add(value: TsoAgreement): Observable<TsoAgreement> {
    formatMomentAsLocalDate(value, ['period.startDate', 'period.endDate']);
    return super.add(value);
  }

  update(value: TsoAgreement): Observable<TsoAgreement> {
    formatMomentAsLocalDate(value, ['period.startDate', 'period.endDate']);
    return super.update(value);
  }

  downloadTsoAgreements(): Observable<HttpResponse<Blob>> {
    return this.downloadService.downloadExcel(this.endpoint);
  }
}
