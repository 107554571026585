import { Operation, OperationView } from 'flex-app-shared';

export class ResetOperationCommand {
  public static readonly type = '[Operation] Reset operation action';
}

export class LoadOperationCommand {
  public static readonly type = '[Operation] Load operation data';

  constructor(public id: string) {}
}

export class OperationLoadedEvent {
  public static readonly type = '[Operation] Operation loaded event';

  constructor(public payload: Operation) {}
}

export class SaveOperationCommand {
  public static readonly type = '[Operation] Save operation data';

  constructor(public payload: OperationView) {}
}

export class SendOperationToDevicesCommand {
  public static readonly type = '[Operation] Send operation to devices';
}

export class SendActivatedPoolToTennetCommand {
  public static readonly type = '[Operation] Send pool to TenneT';
}

export class SendStartActivationMessageCommand {
  public static readonly type = '[Operation] Send start activation message';
}

export class SendEndActivationMessageCommand {
  public static readonly type = '[Operation] Send end activation message';
}

export class RetrieveMeasurementsCommand {
  public static readonly type = '[Retrieve Measurements] Retrieve measurements for gridpoints in operation';
}

export class DownloadOperationPoolDataCommand {
  public static readonly type = '[Operation] Download operation pool data';
}
