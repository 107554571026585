import { DateAndTime, OperationPoolGridPoint, PeriodView } from 'flex-app-shared';

export class ResetOperationPoolCandidatesCommand {
  public static readonly type = '[Operation Pool Candidates] Reset operation pool candidates action';
}

export class LoadOperationPoolCandidatesCommand {
  public static readonly type = '[Operation Pool Candidates] Load operation pool candidates data';

  constructor(public direction: string, public period: PeriodView, public notificationTime: DateAndTime, public requestedPower: number) {}
}

export class OperationPoolCandidatesLoadedEvent {
  public static readonly type = '[Operation Pool Candidates] Operation pool candidates loaded event';

  constructor(public payload: ReadonlyArray<OperationPoolGridPoint>) {}
}
