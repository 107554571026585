<mat-card>
  <mat-card-header>
    <mat-card-title>Recipient Details</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="recipientForm" class="single-column-form">
      <mat-form-field>
        <mat-label>Pronoun</mat-label>
        <mat-select formControlName="pronoun">
          <mat-option *ngFor="let pronoun of pronouns" [value]="pronoun">
            {{ pronoun }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" />
        <mat-error *ngIf="recipientForm.get('firstName').errors?.required"> First Name is required.</mat-error>
        <mat-error *ngIf="recipientForm.get('firstName').errors?.maxlength"> First Name must not be longer than 50 characters.</mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Last Name Prefix</mat-label>
        <input matInput formControlName="lastNamePrefix" />
        <mat-error *ngIf="recipientForm.get('lastNamePrefix').errors?.maxlength">
          Last Name Prefix must not be longer than 50 characters.
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" />
        <mat-error *ngIf="recipientForm.get('lastName').errors?.required"> Last Name is required.</mat-error>
        <mat-error *ngIf="recipientForm.get('lastName').errors?.maxlength"> Last must not be longer than 50 characters.</mat-error>
      </mat-form-field>
      <div class="group-related-form-elements">
        <div class="flex-grid cols-2">
          <mat-form-field class="col-1">
            <mat-label>Mobile Number</mat-label>
            <input matInput formControlName="mobileNumber" />
            <mat-error *ngIf="recipientForm.get('mobileNumber').errors?.required"> Mobile Number is required. </mat-error>
            <mat-error *ngIf="recipientForm.get('mobileNumber').errors?.invalidInternationalPhoneNumber">
              Mobile Number should be in following formats '+12345567' or '06123213123'
            </mat-error>
            <mat-hint *ngIf="isDutchLocalNumber()">
              Your phone number will be saved as
              <em>{{ getPhonePreview() }}</em
              >. If it's incorrect please input your phone in international format.
            </mat-hint>
          </mat-form-field>

          <div class="checkbox-group list col-1">
            <mat-checkbox formControlName="useMobileNumber">Use mobile number for notifications</mat-checkbox>
          </div>
        </div>
      </div>

      <div class="group-related-form-elements">
        <div class="flex-grid cols-2">
          <mat-form-field class="col-1">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" />
            <mat-error *ngIf="recipientForm.get('email').errors?.required"> Email is required.</mat-error>
            <mat-error *ngIf="recipientForm.get('email').errors?.email">
              Email should have following format: 'name&#64;somemail.com'
            </mat-error>
          </mat-form-field>

          <div class="checkbox-group list col-1">
            <mat-checkbox formControlName="isMonthlyParticipationEmail">Use email for monthly participation updates</mat-checkbox>
            <mat-checkbox formControlName="useEmail">Use email for notifications</mat-checkbox>
          </div>
        </div>
      </div>

      <div class="group-related-form-elements">
        <div class="flex-grid cols-1">
          <mat-checkbox formControlName="isEmployee">Employee</mat-checkbox>
          <mat-form-field class="col-1">
            <mat-label>Customer</mat-label>
            <ph-flex-autocomplete
              phFlexCustomerDataSource
              [shouldEmitNull]="true"
              [shouldOnlyEmitIds]="true"
              formControlName="customerId"
              [shouldAutoSelectIfOnlyOneResult]="false"
              placeholder="Select customer"
            ></ph-flex-autocomplete>
            <mat-error *ngIf="recipientForm.get('customerId').errors?.required"> Customer is required. </mat-error>
            <mat-error *ngIf="recipientForm.get('customerId').errors?.invalidCustomer"> Select a customer from the list. </mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <ph-flex-entity-save-button
      (clickChanged)="onSubmit()"
      [entityFacade]="recipientFacade"
      [disabled]="!recipientForm.valid"
    ></ph-flex-entity-save-button>
  </mat-card-actions>
</mat-card>
