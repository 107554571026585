import * as Highcharts from 'highcharts';
import {
  ChartOptions,
  CreditsOptions,
  PlotOptions,
  SeriesOptionsType,
  TimeOptions,
  TitleOptions,
  XAxisOptions,
  YAxisOptions
} from 'highcharts';

export enum BatteryMeasurementSeries {
  activePowerIn = 0,
  activePowerOut = 1,
  activePowerConverter = 2,
  stateOfCharge = 3,
  frequencyIn = 4,
  frequencyOut = 5
}

const chart: ChartOptions = {
  animation: true
};

const plotOptions: PlotOptions = {
  column: {},
  series: {
    pointPadding: 0,
    groupPadding: 0,
    marker: {
      enabled: false
    }
  } as any
};

const tooltip = {
  pointFormat: '<span> {point.y}</span>',
  headerFormat: '<strong>{series.name}:</strong>',
  valueDecimals: 2
};

const legend: any = {};

const xAxis: XAxisOptions = {
  title: {
    text: 'Time'
  },
  type: 'datetime',
  gridLineWidth: 1,
  tickInterval: 5 * 60 * 1000 // 5 minutes in milliseconds
};

const yAxis: YAxisOptions | Array<YAxisOptions> = [
  {
    title: {
      text: 'Power (kW)'
    },
    startOnTick: false,
    endOnTick: false
  },
  {
    title: {
      text: 'State of Charge (%)'
    },
    min: 0,
    max: 100,
    opposite: true
  },
  {
    title: {
      text: 'Frequency (Hz)'
    },
    opposite: true
  }
];

const title: TitleOptions = {
  text: null
};

const series: Array<SeriesOptionsType> = [];

series[BatteryMeasurementSeries.activePowerIn] = {
  showInLegend: true,
  yAxis: 0,
  name: 'Active In',
  type: 'line',
  step: 'center',
  color: '#579cd2',
  lineWidth: 2,
  tooltip: {
    valueSuffix: ' kW'
  }
};

series[BatteryMeasurementSeries.activePowerOut] = {
  showInLegend: true,
  visible: false,
  yAxis: 0,
  name: 'Active Out',
  type: 'line',
  step: 'center',
  color: '#09273f',
  lineWidth: 2,
  tooltip: {
    valueSuffix: ' kW'
  }
};

series[BatteryMeasurementSeries.activePowerConverter] = {
  showInLegend: true,
  visible: false,
  yAxis: 0,
  name: 'Active Converter',
  type: 'line',
  step: 'center',
  color: '#99273f',
  lineWidth: 2,
  tooltip: {
    valueSuffix: ' kW'
  }
};

series[BatteryMeasurementSeries.stateOfCharge] = {
  showInLegend: true,
  yAxis: 1,
  name: 'State of Charge',
  type: 'line',
  color: '#09973f',
  lineWidth: 2,
  tooltip: {
    valueSuffix: ' %'
  }
};

series[BatteryMeasurementSeries.frequencyIn] = {
  showInLegend: true,
  yAxis: 2,
  name: 'Frequency In',
  type: 'line',
  step: 'center',
  color: 'orange',
  lineWidth: 2,
  tooltip: {
    valueSuffix: ' Hz'
  }
};

series[BatteryMeasurementSeries.frequencyOut] = {
  showInLegend: true,
  visible: false,
  yAxis: 2,
  name: 'Frequency Out',
  type: 'line',
  step: 'center',
  color: 'red',
  lineWidth: 2,
  tooltip: {
    valueSuffix: ' Hz'
  }
};

const credits: CreditsOptions = {
  enabled: false
};

const time: TimeOptions = {
  useUTC: false
};

export const chartOptions: Highcharts.Options = {
  chart,
  plotOptions,
  tooltip,
  legend,
  xAxis,
  yAxis,
  title,
  series,
  credits,
  time
};
