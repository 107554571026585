<mat-card>
  <mat-card-content>
    <form [formGroup]="form" class="flex-row filter-bar">
      <mat-form-field class="col-1">
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="dateDatepicker" formControlName="date" />
        <mat-datepicker-toggle matSuffix [for]="dateDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #dateDatepicker></mat-datepicker>
        <mat-error *ngIf="form.get('date').errors?.required"> Date is required. </mat-error>
      </mat-form-field>

      <mat-form-field class="col-1">
        <mat-label>Battery</mat-label>
        <mat-select formControlName="batteryId">
          <mat-option *ngFor="let batteryId of batteryIds" [value]="batteryId">
            {{ batteryId }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>

<mat-card>
  <highcharts-chart
    class="measurements-chart"
    [Highcharts]="Highcharts"
    [constructorType]="chartConstructor"
    [options]="chartOptions"
    [(update)]="updateFlag"
    (chartInstance)="chartInstanceCallback($event)"
    style="width: 100%; height: 400px; display: block"
  ></highcharts-chart>
</mat-card>

<mat-card>
  <mat-card-content>
    <ph-flex-loading-context [isLoading]="dataSource.isLoading$ | async">
      <table mat-table [dataSource]="dataSource" matSort #sort="matSort">
        <ng-container matColumnDef="timestamp">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Timestamp</th>
          <td mat-cell *matCellDef="let measurement">{{ measurement.timestamp | date: 'dd-MM-yyyy HH:mm:ss' }}</td>
        </ng-container>
        <ng-container matColumnDef="activePowerIn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Active In (kW)</th>
          <td mat-cell *matCellDef="let measurement">{{ measurement.activePowerIn | capacityNumber: 'kW':'1.2-2' }}</td>
        </ng-container>
        <ng-container matColumnDef="activePowerOut">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Active Out (kW)</th>
          <td mat-cell *matCellDef="let measurement">{{ measurement.activePowerOut | capacityNumber: 'kW':'1.2-2' }}</td>
        </ng-container>
        <ng-container matColumnDef="activePowerConverter">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Active Converter (kW)</th>
          <td mat-cell *matCellDef="let measurement">{{ measurement.activePowerConverter | capacityNumber: 'kW':'1.2-2' }}</td>
        </ng-container>
        <ng-container matColumnDef="stateOfCharge">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>State of Charge (%)</th>
          <td mat-cell *matCellDef="let measurement">{{ measurement.stateOfCharge | number: '1.2-2' }}</td>
        </ng-container>
        <ng-container matColumnDef="frequencyIn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Frequency In (Hz)</th>
          <td mat-cell *matCellDef="let measurement">{{ measurement.frequencyIn | number: '1.2-2' }}</td>
        </ng-container>
        <ng-container matColumnDef="frequencyOut">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Frequency Out (Hz)</th>
          <td mat-cell *matCellDef="let measurement">{{ measurement.frequencyOut | number: '1.2-2' }}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="measurementDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: measurementDisplayedColumns"></tr>
      </table>

      <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]" #paginator></mat-paginator>
    </ph-flex-loading-context>
  </mat-card-content>
</mat-card>
