import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';

import { EntityStateModel, FetchEntity, findParameterValue, RouterInitializer, SaveEntity } from 'flex-app-shared';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { B2CResultType, User, UserB2CResult, UserService, UserView } from '../../../../app/shared/user/user.service';
import {
  CreateUserCommand,
  LoadUserCommand,
  ResetPasswordCommand,
  ResetUserCommand,
  SendActivationEmailCommand,
  ToggleUserActiveCommand,
  UpdateUserDetailsCommand,
  UserLoadedEvent
} from './user.actions';

class UserStateModel extends EntityStateModel<User> {
  model: User = new User();
  isBusySendingEmail: boolean = false;
  isBusyResettingPassword: boolean = false;
  isBusyActivatingUser: boolean = false;
  isBusyDeactivatingUser: boolean = false;
  activationEmailResult: UserB2CResult = null;
  resetPasswordResult: UserB2CResult = null;
  toggleUserActiveResult: UserB2CResult = null;
}

@State<UserStateModel>({
  name: 'userState',
  defaults: new UserStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class UserState implements RouterInitializer<UserStateModel> {
  private allClear = {
    isBusySendingEmail: false,
    isBusyResettingPassword: false,
    isBusyActivatingUser: false,
    isBusyDeactivatingUser: false,
    activationEmailResult: null,
    resetPasswordResult: null,
    toggleUserActiveResult: null
  };

  constructor(private userService: UserService, protected store: Store, private location: Location) {}

  @Selector()
  public static getUser(state: UserStateModel): UserView {
    return {
      ...state.model,
      groupIds: state.model.groups.map((g) => g.id)
    };
  }

  @Selector()
  public static getActivationEmailResult(state: UserStateModel): UserB2CResult {
    return state.activationEmailResult;
  }

  @Selector()
  public static getResetPasswordResult(state: UserStateModel): UserB2CResult {
    return state.resetPasswordResult;
  }

  @Selector()
  public static getToggleUserActiveResult(state: UserStateModel): UserB2CResult {
    return state.toggleUserActiveResult;
  }

  @Selector()
  public static isBusy(state: UserStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isBusySendingEmail(state: UserStateModel): boolean {
    return state.isBusySendingEmail;
  }

  @Selector()
  public static isBusyResettingPassword(state: UserStateModel): boolean {
    return state.isBusyResettingPassword;
  }

  @Selector()
  public static isBusyActivatingUser(state: UserStateModel): boolean {
    return state.isBusyActivatingUser;
  }

  @Selector()
  public static isBusyDeactivatingUser(state: UserStateModel): boolean {
    return state.isBusyDeactivatingUser;
  }

  @Selector()
  public static isInitialized(state: UserStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static isBusySending(state: UserStateModel): boolean {
    return state.isBusySending;
  }

  @Selector()
  public static error(state: UserStateModel): string | null {
    return state.sendError;
  }

  @Action(RouterNavigation)
  routerInit({ getState, dispatch }: StateContext<UserStateModel>, { routerState }: RouterNavigation<RouterStateSnapshot>): void {
    const userId = findParameterValue(routerState, 'userId');
    if (userId && userId !== 'new') {
      dispatch(new LoadUserCommand(userId));
    } else {
      dispatch(new ResetUserCommand());
    }
  }

  @Action(ResetUserCommand)
  resetUser({ setState }: StateContext<UserStateModel>): void {
    setState(new UserStateModel());
  }

  @FetchEntity()
  @Action(LoadUserCommand)
  initUserData({ dispatch }: StateContext<UserStateModel>, { id }: LoadUserCommand): Observable<User> {
    return this.userService.getById(id).pipe(tap((user) => dispatch(new UserLoadedEvent(user))));
  }

  @Action(UserLoadedEvent)
  setUserData({ setState, getState }: StateContext<UserStateModel>, { payload }: UserLoadedEvent): void {
    const state = getState();
    setState({
      ...state,
      model: {
        ...state.model,
        ...payload
      }
    });
  }

  @SaveEntity()
  @Action(UpdateUserDetailsCommand)
  updateUserGroups(ctx: StateContext<UserStateModel>, { payload }: UpdateUserDetailsCommand): Observable<User> {
    const observable = this.userService.updateDetails(payload.id, payload.employee, payload.groupIds, payload.customerIds);
    return observable.pipe(tap(() => this.location.back()));
  }

  @SaveEntity()
  @Action(CreateUserCommand)
  createUser(ctx: StateContext<UserStateModel>, { payload, sendActivationEmail }: CreateUserCommand): Observable<UserView> {
    const observable = this.userService.create(
      payload.name,
      payload.nickName,
      payload.firstName,
      payload.surname,
      payload.phoneNumber,
      payload.employee,
      payload.groupIds,
      payload.customerIds,
      sendActivationEmail
    );
    return observable.pipe(tap(() => this.location.back()));
  }

  @Action(SendActivationEmailCommand)
  sendActivationEmail(
    { getState, setState, dispatch, patchState }: StateContext<UserStateModel>,
    command: SendActivationEmailCommand
  ): any {
    patchState(this.allClear);

    const state = getState();
    setState({
      ...state,
      isBusySendingEmail: true,
      activationEmailResult: null
    });
    return this.userService.sendActivationEmail(command.userId).pipe(
      tap({
        next: (result) => {
          state.model.activationEmailLastSent = new Map(Object.entries(result.info)).get('activationEmailLastSent');
          setState({
            ...state,
            activationEmailResult: result,
            isBusySendingEmail: false
          });
        },
        error: (error) => {
          console.error(error);
          setState({
            ...state,
            activationEmailResult: {
              success: false,
              type: B2CResultType.ACTIVATION_EMAIL,
              message: 'Could not send email (see error above)',
              info: null
            },
            isBusySendingEmail: false
          });
        }
      })
    );
  }

  @Action(ResetPasswordCommand)
  resetPassword({ getState, setState, dispatch, patchState }: StateContext<UserStateModel>, command: ResetPasswordCommand): any {
    patchState(this.allClear);

    const state = getState();
    setState({
      ...state,
      isBusyResettingPassword: true,
      resetPasswordResult: null
    });
    return this.userService.resetPassword(command.userId).pipe(
      tap({
        next: (result) => {
          setState({
            ...state,
            resetPasswordResult: result,
            isBusyResettingPassword: false
          });
        },
        error: (error) => {
          console.error(error);
          setState({
            ...state,
            resetPasswordResult: {
              success: false,
              type: B2CResultType.RESET_PASSWORD,
              message: 'Could not reset password (see error above)',
              info: null
            },
            isBusyResettingPassword: false
          });
        }
      })
    );
  }

  @Action(ToggleUserActiveCommand)
  toggleUserActive({ getState, setState, dispatch, patchState }: StateContext<UserStateModel>, command: ToggleUserActiveCommand): any {
    patchState(this.allClear);

    const state = getState();
    setState({
      ...state,
      isBusyActivatingUser: command.active,
      isBusyDeactivatingUser: !command.active,
      toggleUserActiveResult: null
    });
    return this.userService.toggleUserActive(command.userId, command.active).pipe(
      tap({
        next: (result) => {
          setState({
            ...state,
            toggleUserActiveResult: result,
            isBusyActivatingUser: false,
            isBusyDeactivatingUser: false
          });
          dispatch(new LoadUserCommand(command.userId));
        },
        error: (error) => {
          console.error(error);
          setState({
            ...state,
            toggleUserActiveResult: {
              type: command.active ? B2CResultType.ACTIVATE_USER : B2CResultType.DEACTIVATE_USER,
              success: false,
              message: `Could not activate/deactivate user (see error above)`,
              info: null
            },
            isBusyActivatingUser: false,
            isBusyDeactivatingUser: false
          });
        }
      })
    );
  }
}
