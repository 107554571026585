import { Component, OnInit, ViewChild } from '@angular/core';
import { MeasurementCompany, MeasurementCompanyService } from '../shared/measurement-company/measurement-company.service';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-measurement-companies',
  templateUrl: './measurement-companies.component.html',
  styleUrls: ['./measurement-companies.component.scss']
})
export class MeasurementCompaniesComponent implements OnInit {
  readonly displayedColumns: ReadonlyArray<string> = ['name', 'measurementProvider', 'actions'];
  readonly dataSource: MatTableDataSource<MeasurementCompany> = new MatTableDataSource<MeasurementCompany>([]);

  @ViewChild(MatPaginator, { static: true })
  private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

  constructor(private service: MeasurementCompanyService) {}

  ngOnInit(): void {
    this.getAll();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getAll(): void {
    this.service.getAll().subscribe((companies) => (this.dataSource.data = companies));
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }

  delete(company: MeasurementCompany): void {
    this.service.delete(company).subscribe(() => this.getAll());
  }
}
