import { AfterContentChecked, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ControlFacade } from '../../store/controls/control.facade';
import { CustomValidators } from 'flex-app-shared';

@Component({
  selector: 'app-control-configuration',
  templateUrl: './control-configuration.component.html',
  styleUrls: ['./control-configuration.component.scss']
})
export class ControlConfigurationComponent implements AfterContentChecked {
  @Input()
  isStandalone = false;
  form = this.builder.group({
    id: '',
    description: '',
    customerId: '',
    direction: '',
    power: this.builder.group({
      value: '',
      unit: ''
    }),
    upwardsR3Participation: false,
    downwardsR3Participation: false,
    switchCooldownTime: [0, [Validators.required, Validators.min(0), Validators.max(60), CustomValidators.numberOfDecimals(0, 0)]]
  });

  upwardsLabel: string;
  downwardsLabel: string;
  directionLabel: string;
  controlSubtitle: string;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private builder: UntypedFormBuilder,
    private controlFacade: ControlFacade
  ) {}

  goBack(): void {
    this.location.back();
  }

  onSubmit(): void {
    this.controlFacade.saveConfiguration();
  }

  ngAfterContentChecked(): void {
    const direction = this.form.get('direction').value;
    this.upwardsLabel =
      direction === 'PRODUCTION'
        ? 'Control switched &nbsp;<span class="chip status-box-on">ON</span> &nbsp;during operation'
        : 'Control switched OFF during operation';
    this.downwardsLabel =
      direction === 'PRODUCTION'
        ? 'Control switched &nbsp;<span class="chip status-box-off">OFF</span> &nbsp;during operation'
        : 'Control switched ON during operation';
    this.directionLabel = direction === 'PRODUCTION' ? 'Production' : 'Consumption';
    this.controlSubtitle = `${this.form.get('description').value} (${this.directionLabel}, ${this.form.get('power.value').value} ${
      this.form.get('power.unit').value
    })`;
  }
}
