import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import * as Highcharts from 'highcharts';
import { PoolAvailability, PoolAvailabilityDetail, PoolAvailabilityService } from '../shared/pool-availability/pool-availability.service';
import {
  AdjustmentDirection,
  AuthorityService,
  Capacity,
  ChartComponent,
  ChartData,
  ChartPoint,
  ChartReflowMixin,
  VIEW_CUSTOMER
} from 'flex-app-shared';
import { chartOptions, PoolAvailabilitySeries } from './chart-config';
import moment from 'moment';
import { Moment } from 'moment';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { get } from 'lodash-es';
import { environment } from '../../environments/environment';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-pool-availability',
  templateUrl: './pool-availability.component.html',
  styleUrls: ['./pool-availability.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PoolAvailabilityComponent extends ChartReflowMixin() implements ChartComponent, OnInit, OnDestroy, AfterViewInit {
  isLoading = true;
  public readonly chartConstructor: string = 'chart';
  public updateFlag: boolean = false;
  public chartOptions: Highcharts.Options = {};
  form = this.builder.group({
    selectedDate: [moment(), [Validators.required]],
    direction: [AdjustmentDirection.UPWARDS, [Validators.required]]
  });
  displayedColumns: ReadonlyArray<string> = ['sourceType', 'detail', 'period', 'direction', 'power', 'gridPoints'];
  readonly dataSource: MatTableDataSource<PoolAvailabilityDetail> = new MatTableDataSource<PoolAvailabilityDetail>([]);
  @Input()
  public readonly defaultPageSize: number = 10;
  private readonly ngUnsubscribe = new Subject<void>();
  @ViewChild(MatSort, { static: false })
  private readonly sort: MatSort;

  @ViewChild(MatPaginator, { static: false })
  private readonly paginator: MatPaginator;

  constructor(
    private poolAvailabilityService: PoolAvailabilityService,
    private builder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private authorityService: AuthorityService
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit(): void {
    this.initChartData();
    this.updateChart(moment(), AdjustmentDirection.UPWARDS);
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      const { selectedDate, direction } = value;
      this.updateChart(selectedDate, direction);
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      if (sortHeaderId === 'period') {
        return data.period.startDateTime;
      }
      if (sortHeaderId === 'power') {
        return Capacity.asMW(data.power);
      } else {
        return get(data, sortHeaderId);
      }
    };
  }

  initChartData(): void {
    this.chartOptions = chartOptions;
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }

  public chartInstanceCallback(chartInstance: Highcharts.Chart): void {
    this.chart = chartInstance;
  }

  processData(data: PoolAvailability): PoolAvailabilityChartData {
    return {
      tsoAgreementPower: this.getChartPoints(data.firstMinute, data.tsoAgreementPower),
      serviceAgreementPower: this.getChartPoints(data.firstMinute, data.serviceAgreementPower),
      pulsePower: this.getChartPoints(data.firstMinute, data.pulsePower),
      controlPulsePower: this.getChartPoints(data.firstMinute, data.controlPulsePower)
    };
  }

  refreshChartData({ tsoAgreementPower, serviceAgreementPower, pulsePower, controlPulsePower }: PoolAvailabilityChartData): void {
    if (!this.chart || !this.chart.series) return;
    this.chart.series[PoolAvailabilitySeries.tsoAgreementPower].setData(tsoAgreementPower);
    this.chart.series[PoolAvailabilitySeries.serviceAgreementPower].setData(serviceAgreementPower);
    this.chart.series[PoolAvailabilitySeries.pulsePower].setData(pulsePower);
    this.chart.series[PoolAvailabilitySeries.controlPulsePower].setData(controlPulsePower);
    this.updateFlag = true;
    this.changeDetectorRef.detectChanges();
  }

  clearChartData(): void {
    if (this.chart && this.chart.series) {
      this.chart.series.forEach((series) => series.setData([]));
    }
  }

  showDetails(): Observable<boolean> {
    const isNoAutEnv = !environment.requireAuthentication;
    return isNoAutEnv ? of(true) : this.authorityService.hasAuthorities(VIEW_CUSTOMER);
  }

  private updateChart(selectedDate: Moment, direction: AdjustmentDirection): void {
    if (selectedDate && direction) {
      this.isLoading = true;
      this.poolAvailabilityService.getByDayAndDirection(selectedDate, direction).subscribe((data) => {
        this.refreshChartData(this.processData(data));
        this.dataSource.data = data.details;
        this.isLoading = false;
      });
    }
  }

  private getChartPoints(startDateTime: string, values: number[]): Array<ChartPoint> {
    const startMoment = moment(startDateTime);
    return values.map((value) => ({
      x: startMoment.add(1, 'minutes').toDate().getTime(),
      y: value
    }));
  }
}

interface PoolAvailabilityChartData extends ChartData {
  tsoAgreementPower: Array<ChartPoint>;
  serviceAgreementPower: Array<ChartPoint>;
  pulsePower: Array<ChartPoint>;
  controlPulsePower: Array<ChartPoint>;
}
