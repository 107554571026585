<ng-container *ngIf="!isStandalone">
  <mat-card>
    <mat-card-header>
      <mat-card-title>Grid point details</mat-card-title>
    </mat-card-header>
    <mat-card-content [ngTemplateOutlet]="pageContents"></mat-card-content>
    <mat-card-actions>
      <span>{{ message }}</span>
      <button mat-stroked-button (click)="goBack()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
          <span>Go back</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="primary" (click)="retrieveContracts()" *ngIf="!isNew()">Retrieve contracts</button>
      <button mat-flat-button color="cta" (click)="onSubmit()" [disabled]="!gridPointForm.valid">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:save"></mat-icon>
          <span>Save</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>

<ng-container *ngIf="isStandalone" [ngTemplateOutlet]="pageContents"></ng-container>

<ng-template #pageContents>
  <form [formGroup]="gridPointForm" ngxsForm="gridPointStateModule.gridPointState" class="single-column-form">
    <mat-form-field>
      <mat-label>EAN</mat-label>
      <input matInput formControlName="ean" />
      <mat-error *ngIf="gridPointForm.get('ean').errors?.required"> EAN is required. </mat-error>
      <mat-error *ngIf="gridPointForm.get('ean').errors?.maxlength || gridPointForm.get('ean').errors?.minlength">
        EAN should be 18 characters long.
      </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Description</mat-label>
      <input matInput formControlName="description" />
      <mat-error *ngIf="gridPointForm.get('description').errors?.required"> Description is required. </mat-error>
      <mat-error *ngIf="gridPointForm.get('description').errors?.maxlength">
        Description must not be longer than 100 characters.
      </mat-error>
    </mat-form-field>
    <mat-form-field *ngIf="!isStandalone">
      <mat-label>Customer</mat-label>
      <ph-flex-autocomplete
        phFlexCustomerDataSource
        [shouldEmitNull]="true"
        [shouldOnlyEmitIds]="true"
        formControlName="customerId"
        [shouldAutoSelectIfOnlyOneResult]="true"
        placeholder="Select customer"
      ></ph-flex-autocomplete>
      <mat-error *ngIf="gridPointForm.get('customerId').errors?.required"> Customer is required. </mat-error>
      <mat-error *ngIf="gridPointForm.get('customerId').errors?.invalidCustomer"> Select a customer from the list. </mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>BRP</mat-label>
      <mat-select formControlName="balanceResponsiblePartyId">
        <mat-option *ngFor="let party of parties" [value]="party.id">
          {{ party.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="gridPointForm.get('balanceResponsiblePartyId').errors?.required"> BRP is required. </mat-error>
    </mat-form-field>
    <div class="checkbox-group list">
      <mat-checkbox formControlName="preQualified">Grid point is pre-qualified for Incident reserve</mat-checkbox>
      <mat-checkbox formControlName="gopacsPreQualified">Grid point is pre-qualified for Gopacs</mat-checkbox>
    </div>
  </form>
</ng-template>
