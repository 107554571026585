import { AppInjector, FrontendPaginationHelper, OnDestroyProvider, PaginatedDataSource } from 'flex-app-shared';
import { IncidentReserveInvoiceData } from '../shared/r3-invoice-data/r3-invoice-data';
import { R3InvoiceDataFacade } from '../../store/r3-invoice-data/r3-invoice-data.facade';
import { takeUntil } from 'rxjs/operators';
import { combineLatest, ReplaySubject } from 'rxjs';

export class R3InvoiceDataDatasource extends PaginatedDataSource<IncidentReserveInvoiceData> {
  loadDataSubject = new ReplaySubject(1);

  paginationHelper = new FrontendPaginationHelper<IncidentReserveInvoiceData>(undefined, {
    keys: ['customerName', 'customerLegalName', 'customerBillingId', 'serviceAgreementSystemReference']
  });
  facade = AppInjector.get(R3InvoiceDataFacade);

  constructor(onDestroyProvider: OnDestroyProvider) {
    super();

    onDestroyProvider.onDestroy$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.ngOnDestroy());

    combineLatest([this.facade.data$, this.loadDataSubject])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([newResult, loadDataInfo]) => {
        const { pageIndex, pageSize, searchTerm, sort } = loadDataInfo as any;

        this.paginationHelper.updateData(newResult);

        this.dataSubject.next(this.paginationHelper.handleLoadData(pageIndex, pageSize, searchTerm, sort));
        this.updatePaginator(pageIndex, pageSize, this.paginationHelper.filteredData.length);
      });
  }

  loadData(pageIndex: number | undefined, pageSize: number | undefined, searchTerm: string | undefined, sort: string | undefined): void {
    this.loadDataSubject.next({
      pageIndex,
      pageSize,
      searchTerm,
      sort
    });
  }
}
