import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { AdjustmentDirection, FlexNgXsFormSync, MixinBase, OnDestroyMixin, TableFilterComponent } from 'flex-app-shared';
import { IncidentReserveInvoiceResultsFacade } from '../../store/incident-reserve-invoice-results/incident-reserve-invoice-results.facade';
import { IncidentReserveInvoiceResultsStatus } from '../shared/incident-reserve-invoice-results/incident-reserve-invoice-results.service';
import { IncidentReserveInvoiceResultsDatasource } from './incident-reserve-invoice-results.datasource';

@Component({
  selector: 'app-incident-reserve-invoice-results',
  templateUrl: './incident-reserve-invoice-results.component.html',
  styleUrls: ['./incident-reserve-invoice-results.component.scss']
})
export class IncidentReserveInvoiceResultsComponent extends OnDestroyMixin(MixinBase) implements AfterViewInit {
  AdjustmentDirection = AdjustmentDirection;

  @FlexNgXsFormSync('incidentReserveInvoiceResults.filter', { initialSyncOnlyTruthyFormValues: true })
  form = this.builder.group({
    selectedDate: ['', [Validators.required]],
    sendAll: false
  });

  sendAllControl: UntypedFormControl = this.form.get('sendAll') as UntypedFormControl;

  readonly displayedColumns: ReadonlyArray<string> = [
    'customerName',
    'customerLegalName',
    'customerBillingId',
    'serviceAgreementSystemReference',
    'day',
    'downwardsAmount',
    'upwardsAmount',
    'status'
  ];

  dataSource = new IncidentReserveInvoiceResultsDatasource(this);
  ResultsStatus = IncidentReserveInvoiceResultsStatus;

  @ViewChild(TableFilterComponent)
  private readonly tableFilter: TableFilterComponent;

  @ViewChild(MatPaginator, { static: true })
  private readonly paginator: MatPaginator;

  @ViewChild(MatSort, { static: true })
  private readonly sort: MatSort;

  constructor(
    private builder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    public facade: IncidentReserveInvoiceResultsFacade,
    public dialog: MatDialog
  ) {
    super();
  }

  publish(): void {
    this.facade.publish();
    this.snackBar.open(`Starting sending ${this.form.get('sendAll').value ? 'all' : ''} invoice results to infinity`, 'ok', {
      duration: 3000
    });
  }

  recalculate(): void {
    this.snackBar.open('Incident reserve invoice results recalculation started', 'ok', {
      duration: 3000
    });
    this.facade.recalculate();
  }

  chosenDateHandler(selectedMoment: Date, datepicker: MatDatepicker<any>): void {
    this.form.get('selectedDate').setValue(selectedMoment);
    datepicker.close();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.filter = this.tableFilter;
  }
}
