import { Component } from '@angular/core';
import { OperationFacade } from '../../store/operations/states/operation/operation.facade';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-operation-detail-send-start-operation-message-confirmation-dialog',
  template: `
    <h1 mat-dialog-title>Send start operation message</h1>
    <div mat-dialog-content>
      <p>Are you sure you want to send a start operation message to every customer that has grid points that will be activated?</p>
    </div>
    <div mat-dialog-actions>
      <button mat-flat-button mat-dialog-close>Cancel</button>
      <button mat-flat-button color="primary" (click)="confirm()" cdkFocusInitial>Ok</button>
    </div>
  `
})
export class OperationDetailSendStartOperationMessageConfirmationDialogComponent {
  constructor(
    private operationFacade: OperationFacade,
    public dialogRef: MatDialogRef<OperationDetailSendStartOperationMessageConfirmationDialogComponent>
  ) {}

  confirm(): void {
    this.operationFacade.sendStartActivationMessage();
    this.dialogRef.close();
  }
}
