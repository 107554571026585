<mat-card>
  <mat-card-header>
    <mat-card-title>Battery FCR Period details</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="fcrPeriodForm">
      <div class="flex-row">
        <mat-form-field class="col-1">
          <mat-label>Grid point</mat-label>
          <ph-flex-autocomplete
            phFlexGridPointDataSource
            formControlName="gridPointId"
            [shouldOnlyEmitIds]="true"
            [shouldEmitNull]="true"
            placeholder="Select grid point"
          ></ph-flex-autocomplete>
          <mat-error *ngIf="fcrPeriodForm.get('gridPointId').errors?.required"> Grid point is required. </mat-error>
        </mat-form-field>
      </div>
      <div formGroupName="startTime" class="flex-row">
        <mat-form-field class="col-1">
          <mat-label>Start date</mat-label>
          <input matInput [matDatepicker]="startDatepicker" formControlName="date" />
          <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatepicker></mat-datepicker>
          <mat-error *ngIf="fcrPeriodForm.get('startTime.date').errors?.required"> Start date is required </mat-error>
        </mat-form-field>
        <mat-form-field class="col-1">
          <mat-label>Start time</mat-label>
          <input matInput formControlName="time" />
          <mat-hint>Example: 13:00</mat-hint>
          <mat-error *ngIf="fcrPeriodForm.get('startTime.time').errors?.required"> Start time is required </mat-error>
          <mat-error *ngIf="fcrPeriodForm.get('startTime.time').errors?.invalidTime">
            Start time should be formatted as hour:minute (hh:mm), for example 01:20
          </mat-error>
        </mat-form-field>
      </div>

      <div formGroupName="endTime" class="flex-row">
        <mat-form-field class="col-1">
          <mat-label>End date</mat-label>
          <input matInput [matDatepicker]="endDatepicker" formControlName="date" />
          <mat-datepicker-toggle matSuffix [for]="endDatepicker"></mat-datepicker-toggle>
          <mat-datepicker #endDatepicker></mat-datepicker>
          <mat-error *ngIf="fcrPeriodForm.get('endTime.date').errors?.required"> End date is required </mat-error>
        </mat-form-field>
        <mat-form-field class="col-1">
          <mat-label>End time</mat-label>
          <input matInput formControlName="time" />
          <mat-hint>Example: 13:00</mat-hint>
          <mat-error *ngIf="fcrPeriodForm.get('endTime.time').errors?.required"> End time is required </mat-error>
          <mat-error *ngIf="fcrPeriodForm.get('endTime.time').errors?.invalidTime">
            End time should be formatted as hour:minute (hh:mm), for example 01:20
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="cta" (click)="onSubmit()" [disabled]="!fcrPeriodForm.valid">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:save"></mat-icon>
        <span>Save</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
