import { State } from '@ngxs/store';
import { PoolState } from './states/pool/pool.state';
import { PoolPeriodState } from './states/pool-period/pool-period.state';
import { Injectable } from '@angular/core';

export const PoolStates = [PoolState, PoolPeriodState];

@State({
  name: 'poolStateModule',
  children: PoolStates
})
@Injectable({
  providedIn: 'root'
})
export class PoolStateModule {}
