import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {
  BalanceResponsibleParty,
  BalanceResponsiblePartyService
} from '../shared/balance-responsible-party/balance-responsible-party.service';

@Component({
  selector: 'app-balance-responsible-parties',
  templateUrl: './balance-responsible-parties.component.html',
  styleUrls: ['./balance-responsible-parties.component.scss']
})
export class BalanceResponsiblePartiesComponent implements OnInit {
  readonly displayedColumns: ReadonlyArray<string> = ['name', 'code', 'ean', 'actions'];
  readonly dataSource: MatTableDataSource<BalanceResponsibleParty> = new MatTableDataSource<BalanceResponsibleParty>([]);

  @ViewChild(MatPaginator, { static: true }) private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

  constructor(private balanceResponsiblePartyService: BalanceResponsiblePartyService) {}

  ngOnInit(): void {
    this.getAll();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  getAll(): void {
    this.balanceResponsiblePartyService.getAll().subscribe((parties) => (this.dataSource.data = parties));
  }

  setDefault(party: BalanceResponsibleParty): void {
    this.balanceResponsiblePartyService.setDefault(party.id).subscribe(() => this.getAll());
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }
}
