import { State } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { MeasurementDataUploadState } from './states/measurement-data-upload.state';

export const MeasurementDataUploadStates = [MeasurementDataUploadState];

@State({
  name: 'measurementDataUpload',
  children: MeasurementDataUploadStates
})
@Injectable({
  providedIn: 'root'
})
export class MeasurementDataUploadStateModule {}
