import { STEPPER_GLOBAL_OPTIONS, StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomerStepperFacade } from '../../store/customer-stepper/states/customer-stepper.facade';
import { CustomerStepperStep, StepperFormType, StepState } from './customer-stepper-enums';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridPointsFacade } from '../../store/grid-points/grid-points.facade';
import { isNil } from 'lodash-es';
import { findParameterValue } from 'flex-app-shared';
import { ActivatedRoute } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-customer-stepper',
  templateUrl: './customer-stepper.component.html',
  styleUrls: ['./customer-stepper.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false, showError: true }
    }
  ]
})
export class CustomerStepperComponent implements OnInit, OnDestroy {
  CustomerStepperStep = CustomerStepperStep; // To be able to use this enum in the component template
  FormType = StepperFormType; // idem

  @ViewChild('stepper', { static: false }) stepper: MatStepper;

  public showForm = false;
  public formType = StepperFormType.edit;
  currentStepNo = 0;
  public isCustomerFormValid$ = this.customerStepperFacade.isCustomerFormValid$;
  public isGridPointFormValid$ = this.customerStepperFacade.isGridPointFormValid$;
  public isDeviceFormValid$ = this.customerStepperFacade.isDeviceFormValid$;
  public isControlFormValid$ = this.customerStepperFacade.isControlValid$;
  public isControlConfigurationFormValid$ = this.customerStepperFacade.isControlValid$;
  public isAddGridPointBlockShown: boolean = false;
  public isAddControlBlockShown: boolean = false;
  public isAddDeviceBlockShown: boolean = false;
  private stepStates = new Map<CustomerStepperStep, StepState>();
  private currentStepNo$ = this.customerStepperFacade.currentStepNo$;
  private isSelectedCustomerId: boolean = false;
  private ngUnsubscribe$: Subject<void> = new Subject();

  constructor(
    private customerStepperFacade: CustomerStepperFacade,
    private gridPointsFacade: GridPointsFacade,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.customerStepperFacade.init();
    this.customerStepperFacade.stepStates$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((it) => (this.stepStates = it));
    this.customerStepperFacade.showForm$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((it) => (this.showForm = it));
    this.customerStepperFacade.formType$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((it) => (this.formType = it));
    this.currentStepNo$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((it) => (this.currentStepNo = it));
    this.gridPointsFacade.selectedCustomerId$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((customerId) => (this.isSelectedCustomerId = !isNil(customerId)));

    const selectedCustomerId = findParameterValue(this.activatedRoute.snapshot, 'customerId');
    if (selectedCustomerId) this.gridPointsFacade.loadGridPoints();
  }

  ngOnDestroy(): void {
    this.customerStepperFacade.destroy();
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  getStep1Label(): string {
    return this.customerStepperFacade.getStep1Label();
  }

  hideForm(): void {
    this.customerStepperFacade.hideForm();
    this.isAddGridPointBlockShown = false;
    this.isAddDeviceBlockShown = false;
    this.isAddControlBlockShown = false;
  }

  setStepperIndex(event: StepperSelectionEvent, stepper: MatStepper): void {
    stepper.selected.interacted = false; // See: https://stackoverflow.com/questions/51368437/angular-material-stepper-causes-mat-formfield-to-validate-for-dynamic-forms-when
    this.customerStepperFacade.setStepperIndex(event.selectedIndex);
  }

  isStepCompleted(step: CustomerStepperStep): boolean {
    return this.stepStates.get(step) === StepState.done;
  }

  saveCustomer(): void {
    this.customerStepperFacade.saveCustomer();
  }

  addGridPoint(): void {
    this.customerStepperFacade.addGridPoint();
    this.isAddGridPointBlockShown = true;
  }

  saveGridPoint(): void {
    this.customerStepperFacade
      .saveGridPoint()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => (this.isAddGridPointBlockShown = false));
  }

  addDevice(): void {
    this.customerStepperFacade.addDevice();
    this.isAddDeviceBlockShown = true;
  }

  saveDevice(): void {
    this.customerStepperFacade
      .saveDevice()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => (this.isAddDeviceBlockShown = false));
  }

  addControl(): void {
    this.customerStepperFacade.addControl();
    this.isAddControlBlockShown = true;
  }

  saveControl(): void {
    this.customerStepperFacade
      .saveControl()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => (this.isAddControlBlockShown = false));
  }

  configureControl(): void {
    this.customerStepperFacade
      .configureControl()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(() => (this.isAddControlBlockShown = false));
  }

  saveAndComplete(): void {
    this.customerStepperFacade.saveAndComplete();
  }

  shouldShowGridPoints(): boolean {
    return this.isStepCompleted(CustomerStepperStep.customerStep) && this.isSelectedCustomerId;
  }
}
