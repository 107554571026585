import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TsoAuctionsActivate, TsoAuctionsDeactivate } from './tso-auctions.actions';

@Injectable({ providedIn: 'root' })
export class ActivateTsoAuctionsState {
  constructor(private store: Store) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.store.dispatch(new TsoAuctionsActivate());
    return true;
  }
}

@Injectable({ providedIn: 'root' })
export class DeactivateTsoAuctionsState {
  constructor(private store: Store) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.store.dispatch(new TsoAuctionsDeactivate());
    return true;
  }
}
