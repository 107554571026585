<mat-card>
  <mat-card-header>
    <mat-card-title>Tennet TSO-Pool</mat-card-title>
  </mat-card-header>
  <mat-card-actions class="imbalance-prices-actions">
    <button mat-stroked-button (click)="autoSendPool()">
      <ph-flex-vertical-center *ngIf="sentResult && sentResult.success" matTooltip="{{ sentResult?.message }}" class="status-success">
        <mat-icon svgIcon="ic-old:check-circle-o"></mat-icon>
        <span>Sent Pool OK!</span>
      </ph-flex-vertical-center>
      <ph-flex-vertical-center *ngIf="!sentResult && !sending" matTooltip="Last sent on: {{ lastAutoSendDateTime }}.">
        <mat-icon svgIcon="ic-old:source-external"></mat-icon>
        <span>Auto-send pool now</span>
      </ph-flex-vertical-center>
      <ph-flex-vertical-center *ngIf="sending" class="spinner-button">
        <mat-spinner diameter="19"></mat-spinner>
        <span>Auto-sending...</span>
      </ph-flex-vertical-center>
      <ph-flex-vertical-center
        *ngIf="!sending && sentResult && !sentResult.success && !error"
        matTooltip="{{ sentResult.message }}"
        class="status-warn"
      >
        <mat-icon svgIcon="ic-old:alert-warning"></mat-icon>
        <span>Not sent</span>
      </ph-flex-vertical-center>
      <ph-flex-vertical-center
        *ngIf="!sending && sentResult && !sentResult.success && error"
        matTooltip="{{ sentResult.message }}"
        class="status-alert"
      >
        <mat-icon svgIcon="ic-old:cancel-circle-o" class="status-alert"></mat-icon>
        <span>Error</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
