import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CustomerStepperStep, StepperFormType, StepState } from '../../../app/customer-stepper/customer-stepper-enums';
import {
  ControlState,
  EntityFacade,
  GridPointState,
  SaveControlCommand,
  SaveControlConfigurationCommand,
  SaveGridPointCommand
} from 'flex-app-shared';
import { SaveCustomerCommand } from '../../customers/states/customer/customer.actions';
import { CustomerState } from '../../customers/states/customer/customer.state';
import { SaveDeviceCommand } from '../../devices/states/device/device.actions';
import { DeviceState } from '../../devices/states/device/device.state';
import { HideDeviceDetailsCommand } from '../../devices/states/devices/devices.actions';
import {
  AddControlCommand,
  AddDeviceCommand,
  AddGridPointCommand,
  CustomerStepperHideFormCommand,
  DestroyStepperCommand,
  InitializeStepperCommand,
  SaveAndCompleteCommand,
  SetCustomerStepperCurrentStepCommand,
  SetCustomerStepperStepStateCommand,
  SetFormTypeCommand
} from './customer-stepper.actions';
import { CustomerStepperState } from './customer-stepper.state';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomerStepperFacade implements EntityFacade {
  isDisabled$ = this.store.select(CustomerState.isBusy);
  currentStepNo$ = this.store.select(CustomerStepperState.getCurrentStepNo);
  stepStates$ = this.store.select(CustomerStepperState.getStepStates);
  saveButtonPending$ = this.store.select(CustomerState.isBusySending);
  saveButtonError$ = this.store.select(CustomerState.error);

  customer$ = this.store.select(CustomerState.getCustomer);
  customerId$ = this.store.select(CustomerState.getCustomerId);
  showForm$ = this.store.select(CustomerStepperState.showForm);
  formType$ = this.store.select(CustomerStepperState.formType);

  isCustomerFormValid$ = this.store.select(CustomerState.isFormValid);
  isGridPointFormValid$ = this.store.select(GridPointState.isFormValid);
  isDeviceFormValid$ = this.store.select(DeviceState.isFormValid);
  isControlValid$ = this.store.select(ControlState.isFormValid);

  constructor(private store: Store) {}

  init(): void {
    this.store.dispatch(new InitializeStepperCommand());
  }

  destroy(): void {
    this.store.dispatch(new DestroyStepperCommand());
  }

  getStep1Label(): string {
    let label = 'Add new customer';
    this.customer$.subscribe(customer => (label = customer.name ? `${label} - ${customer.name}` : label));
    return label;
  }

  hideForm(): void {
    this.store.dispatch(new CustomerStepperHideFormCommand());
  }

  setStepperIndex(index: number): void {
    if (index !== CustomerStepperStep.devicesStep) {
      this.store.dispatch(new HideDeviceDetailsCommand());
    }
    this.store.dispatch(new SetCustomerStepperCurrentStepCommand(index));
  }

  saveCustomer(): void {
    this.store.dispatch([
      new SetCustomerStepperStepStateCommand(CustomerStepperStep.customerStep, StepState.done),
      new SaveCustomerCommand(false)
    ]);
  }

  addGridPoint(): void {
    this.store.dispatch(new AddGridPointCommand());
  }

  saveGridPoint(): Observable<any> {
    return this.store.dispatch(new SaveGridPointCommand(false));
  }

  addDevice(): void {
    this.store.dispatch(new AddDeviceCommand());
  }

  saveDevice(): Observable<any> {
    return this.store.dispatch(new SaveDeviceCommand(false));
  }

  saveControl(): Observable<any> {
    return this.store.dispatch(new SaveControlCommand(false));
  }

  configureControl(): Observable<any> {
    return this.store.dispatch(new SaveControlConfigurationCommand(false));
  }

  addControl(): void {
    this.store.dispatch(new SetFormTypeCommand(StepperFormType.edit));
    this.store.dispatch(new AddControlCommand());
  }

  saveAndComplete(): void {
    this.store.dispatch(new SaveAndCompleteCommand());
  }
}
