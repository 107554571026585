import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DeviceContactsFacade } from '../../store/devices/states/device-contacts/device-contacts.facade';
import { Location } from '@angular/common';
import { DeviceContact } from '../shared/device/device.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MixinBase, OnDestroyMixin } from 'flex-app-shared';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactsComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  @Input() isStandalone = false;
  dataSource: MatTableDataSource<DeviceContact> = new MatTableDataSource<DeviceContact>([]);

  contacts$ = this.deviceContactsFacade.deviceContacts$;
  device$ = this.deviceContactsFacade.device$;
  displayedColumns = ['description', 'externalId', 'invertedSchedules', 'actions'];
  @ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

  constructor(private deviceContactsFacade: DeviceContactsFacade, private location: Location) {
    super();
  }

  goBack(): void {
    this.location.back();
  }

  hideDeviceDetails(): void {
    this.deviceContactsFacade.hideDeviceDetails();
  }

  add(): void {
    this.deviceContactsFacade.new();
  }

  edit(contact: DeviceContact): void {
    this.deviceContactsFacade.edit(contact);
  }

  delete(contact: DeviceContact): void {
    this.deviceContactsFacade.delete(contact);
  }

  ngOnInit(): void {
    this.deviceContactsFacade.deviceContacts$.pipe(takeUntil(this.onDestroy$)).subscribe((result) => {
      this.dataSource.data = result;
    });
    this.dataSource.sort = this.sort;
  }

  trackBy(index: number, item: DeviceContact): any {
    return item?.id ?? index;
  }
}
