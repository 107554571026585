import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BatteryMeasurement, BatteryMeasurementService } from '../shared/battery-measurement/battery-measurement.service';
import { UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BatteryMeasurementSeries, chartOptions } from './chart-config';
import * as Highcharts from 'highcharts';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import moment from 'moment';
import { Moment } from 'moment';
import { Capacity, ChartComponent, ChartData, ChartPoint, ChartReflowMixin, PaginatedDataSource, PaginatedResponse } from 'flex-app-shared';

class BatteryMeasurementDataSource extends PaginatedDataSource<BatteryMeasurement> {
  private date: Moment;
  private batteryId: string;

  constructor(private service: BatteryMeasurementService) {
    super();
  }

  loadMeasurementData(date: Moment, batteryId: string): void {
    this.date = date;
    this.batteryId = batteryId;
    this.internalLoadData();
  }

  loadData(pageIndex?: number, pageSize?: number, searchTerm?: string, sort?: string): void {
    if (this.date && this.batteryId) {
      this.isLoadingSubject.next(true);
      this.service.getMeasurements(pageIndex, pageSize, sort, this.date, this.batteryId).subscribe(
        (measurements) => {
          this.isLoadingSubject.next(false);
          this.dataSubject.next(measurements.content);
          this.updatePaginatorFromPaginatedResponse(measurements);
        },
        (error) => {
          this.isLoadingSubject.next(false);
          throw error;
        }
      );
    } else {
      this.dataSubject.next([]);
      this.updatePaginatorFromPaginatedResponse({
        totalElements: 0,
        pageable: { pageNumber: 0, pageSize: 30 }
      } as PaginatedResponse<BatteryMeasurement>);
    }
  }
}

@Component({
  selector: 'app-battery-measurement-data',
  templateUrl: './battery-measurement-data.component.html',
  styleUrls: ['./battery-measurement-data.component.scss']
})
export class BatteryMeasurementDataComponent extends ChartReflowMixin() implements ChartComponent, OnInit, AfterViewInit {
  public readonly chartConstructor: string = 'chart';
  public chartOptions: Highcharts.Options = {};
  public updateFlag: boolean = false;

  readonly measurementDisplayedColumns: ReadonlyArray<string> = [
    'timestamp',
    'activePowerIn',
    'activePowerOut',
    `activePowerConverter`,
    'stateOfCharge',
    'frequencyIn',
    'frequencyOut'
  ];
  dataSource: BatteryMeasurementDataSource;
  form = this.builder.group({
    date: '',
    batteryId: ''
  });
  batteryIds: string[];
  @ViewChild('paginator', { static: false }) private readonly paginator: MatPaginator;
  @ViewChild('sort', { static: true }) private readonly sort: MatSort;
  private readonly ngUnsubscribe$ = new Subject();

  constructor(
    private builder: UntypedFormBuilder,
    private service: BatteryMeasurementService
  ) {
    super();
    this.dataSource = new BatteryMeasurementDataSource(service);
  }

  ngOnInit(): void {
    this.form
      .get('date')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((date) => {
        this.service.getBatteries(date).subscribe((batteryIds) => {
          if (batteryIds) {
            this.form.get('batteryId').setValue(batteryIds[0]);
          }
          this.batteryIds = batteryIds;
        });
      });

    this.form
      .get('batteryId')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((batteryId) => {
        const date = this.form.get('date').value;
        this.service.getAggregatedMeasurements(date, batteryId).subscribe((measurements) => {
          this.refreshChartData(this.processData(measurements));
        });
        this.dataSource.loadMeasurementData(date, batteryId);
      });
    this.form.get('date').setValue(moment());
    this.initChartData();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  public chartInstanceCallback(chartInstance: Highcharts.Chart): void {
    this.chart = chartInstance;
  }

  initChartData(): void {
    this.chartOptions = chartOptions;
  }

  processData(data: Array<BatteryMeasurement>): BatteryMeasurementChartData {
    return {
      stateOfCharge: data.map((value) => ({ x: new Date(value.timestamp).getTime(), y: value.stateOfCharge })),
      activePowerIn: data.map((value) => ({ x: new Date(value.timestamp).getTime(), y: Capacity.asKW(value.activePowerIn) })),
      activePowerOut: data.map((value) => ({ x: new Date(value.timestamp).getTime(), y: Capacity.asKW(value.activePowerOut) })),
      activePowerConverter: data.map((value) => ({
        x: new Date(value.timestamp).getTime(),
        y: Capacity.asKW(value.activePowerConverter)
      })),
      frequencyIn: data.map((value) => ({ x: new Date(value.timestamp).getTime(), y: value.frequencyIn })),
      frequencyOut: data.map((value) => ({ x: new Date(value.timestamp).getTime(), y: value.frequencyOut }))
    };
  }

  clearChartData(): void {
    if (this.chart && this.chart.series) {
      this.chart.series.forEach((series) => series.setData([]));
    }
  }

  refreshChartData({
    stateOfCharge,
    activePowerIn,
    activePowerOut,
    activePowerConverter,
    frequencyIn,
    frequencyOut
  }: BatteryMeasurementChartData): void {
    if (!this.chart || !this.chart.series) return;
    this.chart.series[BatteryMeasurementSeries.stateOfCharge].setData(stateOfCharge);
    this.chart.series[BatteryMeasurementSeries.activePowerIn].setData(activePowerIn);
    this.chart.series[BatteryMeasurementSeries.activePowerOut].setData(activePowerOut);
    this.chart.series[BatteryMeasurementSeries.activePowerConverter].setData(activePowerConverter);
    this.chart.series[BatteryMeasurementSeries.frequencyIn].setData(frequencyIn);
    this.chart.series[BatteryMeasurementSeries.frequencyOut].setData(frequencyOut);
  }
}

interface BatteryMeasurementChartData extends ChartData {
  stateOfCharge: Array<ChartPoint>;
  activePowerIn: Array<ChartPoint>;
  activePowerOut: Array<ChartPoint>;
  activePowerConverter: Array<ChartPoint>;
  frequencyIn: Array<ChartPoint>;
  frequencyOut: Array<ChartPoint>;
}
