<!--<app-websocket-test></app-websocket-test>-->

<mat-card>
  <mat-card-header>
    <mat-card-title>Tennet Connectivity</mat-card-title>
  </mat-card-header>
  <mat-card-actions>
    <button mat-stroked-button (click)="isIncidentReserveAlive()">
      <ph-flex-vertical-center *ngIf="isIrAliveResult?.alive" matTooltip="{{ isIrAliveResult.okMessage | json }}" class="status-success">
        <mat-icon svgIcon="ic-old:check-circle-o"></mat-icon>
        <span>Tennet Incident Reserve works!</span>
      </ph-flex-vertical-center>

      <ph-flex-vertical-center
        *ngIf="isIrAliveResult && !isIrAliveResult.alive"
        matTooltip="{{ isIrAliveResult.notOkMessage | json }}"
        class="status-alert"
      >
        <mat-icon svgIcon="ic-old:cancel-circle-o"></mat-icon>
        <span>Tennet Incident Reserve not working!</span>
      </ph-flex-vertical-center>

      <ph-flex-vertical-center *ngIf="!isIrAliveResult">
        <mat-icon svgIcon="ic-old:icon-tennet"></mat-icon>
        <span>Check Tennet Incident Reserve</span>
      </ph-flex-vertical-center>
    </button>

    <button mat-stroked-button (click)="isAncillaryServicesAlive()">
      <ph-flex-vertical-center
        *ngIf="isAncillaryServicesAliveResult?.alive"
        matTooltip="{{ isAncillaryServicesAliveResult.okMessage | json }}"
        class="status-success"
      >
        <mat-icon svgIcon="ic-old:check-circle-o"></mat-icon>
        <span>Tennet Ancillary Services (FCR) works!</span>
      </ph-flex-vertical-center>

      <ph-flex-vertical-center
        *ngIf="isAncillaryServicesAliveResult && !isAncillaryServicesAliveResult.alive"
        matTooltip="{{ isAncillaryServicesAliveResult.notOkMessage | json }}"
        class="status-alert"
      >
        <mat-icon svgIcon="ic-old:cancel-circle-o"></mat-icon>
        <span>Tennet Ancillary Services (FCR) not working!</span>
      </ph-flex-vertical-center>

      <ph-flex-vertical-center *ngIf="!isAncillaryServicesAliveResult">
        <mat-icon svgIcon="ic-old:icon-tennet"></mat-icon>
        <span>Check Tennet Ancillary Services (FCR)</span>
      </ph-flex-vertical-center>
    </button>

    <button mat-stroked-button (click)="isMmcHubAlive()">
      <ph-flex-vertical-center
        *ngIf="isMmcHubAliveResult?.alive"
        matTooltip="{{ isMmcHubAliveResult.okMessage | json }}"
        class="status-success"
      >
        <mat-icon svgIcon="ic-old:check-circle-o"></mat-icon>
        <span>Tennet MMC Hub (FCR) works!</span>
      </ph-flex-vertical-center>

      <ph-flex-vertical-center
        *ngIf="isMmcHubAliveResult && !isMmcHubAliveResult.alive"
        matTooltip="{{ isMmcHubAliveResult.notOkMessage | json }}"
        class="status-alert"
      >
        <mat-icon svgIcon="ic-old:cancel-circle-o"></mat-icon>
        <span>Tennet MMC Hub (FCR) not working!</span>
      </ph-flex-vertical-center>

      <ph-flex-vertical-center *ngIf="!isMmcHubAliveResult">
        <mat-icon svgIcon="ic-old:icon-tennet"></mat-icon>
        <span>Check Tennet MMC Hub (FCR)</span>
      </ph-flex-vertical-center>
    </button>

    <button mat-stroked-button (click)="getAuctionResults()">
      <ph-flex-vertical-center *ngIf="auctionResults?.success" matTooltip="{{ auctionResults.message }}" class="status-success">
        <mat-icon svgIcon="ic-old:check-circle-o"></mat-icon>
        <span> mFRRda Auction Results fetched</span>
      </ph-flex-vertical-center>

      <ph-flex-vertical-center
        *ngIf="auctionResults && !auctionResults.success"
        matTooltip="{{ auctionResults.message }}"
        class="status-alert"
      >
        <mat-icon svgIcon="ic-old:cancel-circle-o"></mat-icon>
        <span>mFRRda Auction Results not fetched.</span>
      </ph-flex-vertical-center>

      <ph-flex-vertical-center *ngIf="!auctionResults">
        <mat-icon svgIcon="ic-old:icon-tennet"></mat-icon>
        <span>Fetch mFRRda Auction Results</span></ph-flex-vertical-center
      >
    </button>

    <button mat-stroked-button (click)="sendStatusRequest()">
      <ph-flex-vertical-center
        *ngIf="requestStatusesResult?.success"
        matTooltip="{{ requestStatusesResult.message }}"
        class="status-success"
      >
        <mat-icon svgIcon="ic-old:check-circle-o"></mat-icon>
        <span>Request for Auction MRD and specs sent</span>
      </ph-flex-vertical-center>

      <ph-flex-vertical-center
        *ngIf="requestStatusesResult && !requestStatusesResult.success"
        matTooltip="{{ requestStatusesResult.message }}"
        class="status-alert"
      >
        <mat-icon svgIcon="ic-old:cancel-circle-o"></mat-icon>
        <span>Request for Auction MRD and specs not sent</span>
      </ph-flex-vertical-center>

      <ph-flex-vertical-center *ngIf="!requestStatusesResult">
        <mat-icon svgIcon="ic-old:icon-tennet"></mat-icon>
        <span>Request for Auction MRD and specs</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>

<app-tso-pool></app-tso-pool>
<app-imbalance-prices></app-imbalance-prices>
<app-day-ahead-prices></app-day-ahead-prices>
<app-day-ahead-deals></app-day-ahead-deals>
<app-incident-reserve-unprocessed-invoice-results></app-incident-reserve-unprocessed-invoice-results>
<app-fetch-measurements></app-fetch-measurements>

<mat-card>
  <mat-card-header>
    <mat-card-title>Mandrill Connectivity</mat-card-title>
  </mat-card-header>
  <mat-card-actions>
    <button mat-stroked-button (click)="pingMandrill()">
      <ph-flex-vertical-center *ngIf="pingMandrillResult?.ok" matTooltip="{{ pingMandrillResult?.message }}" class="status-success">
        <mat-icon svgIcon="ic-old:check-circle-o"></mat-icon>
        <span>Mandrill Connection works!</span>
      </ph-flex-vertical-center>

      <ph-flex-vertical-center
        *ngIf="pingMandrillResult && !pingMandrillResult.ok"
        matTooltip="{{ pingMandrillResult?.message }}"
        class="status-alert"
      >
        <mat-icon svgIcon="ic-old:cancel-circle-o"></mat-icon>
        <span>Mandrill Connection not working!</span>
      </ph-flex-vertical-center>

      <ph-flex-vertical-center *ngIf="!pingMandrillResult">
        <mat-icon svgIcon="ic-old:connection"></mat-icon>
        <span>Check Mandrill Connection</span></ph-flex-vertical-center
      >
    </button>
  </mat-card-actions>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Mandrill Templates</mat-card-title>
  </mat-card-header>
  <mat-card-content *ngIf="availableEmailTemplateOptions">
    <table
      mat-table
      [dataSource]="mandrillEmailTemplatesDataSource"
      matSort
      matSortActive="identifier"
      matSortDirection="desc"
      class="has-actions"
    >
      <ng-container matColumnDef="identifier">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Identifier</th>
        <td mat-cell *matCellDef="let templateOption">
          {{ templateOption.identifier }}
        </td>
      </ng-container>
      <ng-container matColumnDef="templateName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let templateOption">
          <a
            *ngIf="getAvailableTemplate(templateOption); let template; else: noLink"
            href="https://mandrillapp.com/templates/code?id={{ template.slug }}"
            target="_blank"
          >
            {{ templateOption.templateName }}
          </a>
          <ng-template #noLink>{{ templateOption.templateName }}</ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Subject</th>
        <td mat-cell *matCellDef="let templateOption">
          {{ templateOption.subject }}
        </td>
      </ng-container>
      <ng-container matColumnDef="publishedAt">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Published At</th>
        <td mat-cell *matCellDef="let templateOption">
          {{ getAvailableTemplate(templateOption)?.publishedAt | date : 'short' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let templateOption">
          <ph-flex-vertical-center>
            <button mat-icon-button matTooltip="Edit" (click)="editTemplateOption(templateOption)">
              <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
            </button>
          </ph-flex-vertical-center>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedMandrillTemplateOptionsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedMandrillTemplateOptionsColumns"></tr>
    </table>
  </mat-card-content>
</mat-card>

<mat-card>
  <mat-card-header>
    <mat-card-title>Application settings</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <table mat-table [dataSource]="applicationSettingsDataSource" matSort matSortActive="name" matSortDirection="desc" class="has-actions">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let setting">
          {{ setting.name }}
        </td>
      </ng-container>
      <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Value</th>
        <td mat-cell *matCellDef="let setting">
          {{ displayValue(setting) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let setting">
          <ph-flex-vertical-center>
            <button mat-icon-button matTooltip="Edit" (click)="editApplicationSetting(setting)">
              <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
            </button>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedApplicationSettingsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedApplicationSettingsColumns"></tr>
    </table>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="appInfo$ | async as appInfo">
  <mat-card-header>
    <mat-card-title>Application Info</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <table class="application-info">
      <tr class="mat-header-row">
        <th class="mat-header-cell">Property</th>
        <th class="mat-header-cell">Value</th>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell">Environment</td>
        <td class="mat-cell">{{ appInfo.env }}</td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell">Backend version</td>
        <td class="mat-cell">{{ appInfo.backendVersion }}</td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell">Backend commit id</td>
        <td class="mat-cell">{{ appInfo.backendCommitId }}</td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell">Frontend version</td>
        <td class="mat-cell">{{ appInfo.frontendVersion }}</td>
      </tr>
      <tr class="mat-row">
        <td class="mat-cell">Frontend commit id</td>
        <td class="mat-cell">{{ appInfo.frontendCommitId }}</td>
      </tr>
    </table>
  </mat-card-content>
</mat-card>

<mat-card [formGroup]="announcementForm" class="announcement-form">
  <mat-card-header>
    <mat-card-title>Announcement</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="flex-grid cols-2">
      <mat-slide-toggle formControlName="enabled" class="col-2"> Enabled</mat-slide-toggle>
      <mat-form-field class="col-1">
        <mat-label>Valid from date</mat-label>
        <input
          matInput
          [matDatepicker]="announcementValidFromDate"
          formControlName="validFromDate"
          [errorStateMatcher]="announcementErrorStateMatcher"
        />
        <mat-datepicker-toggle matSuffix [for]="announcementValidFromDate"></mat-datepicker-toggle>
        <mat-datepicker #announcementValidFromDate></mat-datepicker>
        <mat-error *ngIf="announcementForm.get('validFromDate').errors?.required"> Date is required.</mat-error>
      </mat-form-field>
      <mat-form-field class="col-1">
        <mat-label>Valid from time</mat-label>
        <input matInput formControlName="validFromTime" [errorStateMatcher]="announcementErrorStateMatcher" />
        <mat-hint>Example: 13:00</mat-hint>
        <mat-error *ngIf="announcementForm.get('validFromTime').errors?.required"> Time is required.</mat-error>
        <mat-error *ngIf="announcementForm.get('validFromTime').errors?.invalidTime"> Time should be formatted as hour:minute.</mat-error>
      </mat-form-field>
      <mat-form-field class="col-2">
        <mat-label>Title</mat-label>
        <input matInput formControlName="title" [errorStateMatcher]="announcementErrorStateMatcher" />
        <mat-error *ngIf="announcementForm.get('title').errors?.required"> Title is required.</mat-error>
      </mat-form-field>
      <mat-form-field class="col-2">
        <mat-label>Body</mat-label>
        <input matInput formControlName="body" [errorStateMatcher]="announcementErrorStateMatcher" />
        <mat-error *ngIf="announcementForm.get('body').errors?.required"> Body is required.</mat-error>
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button type="button" (click)="previewAnnouncement()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:view"></mat-icon>
        <span>Preview announcement</span></ph-flex-vertical-center
      >
    </button>
    <button mat-flat-button color="cta" type="button" (click)="saveAnnouncement()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:save"></mat-icon>
        <span>Save announcement</span></ph-flex-vertical-center
      >
    </button>
  </mat-card-actions>
</mat-card>

