import { AfterViewInit, Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { EmailTemplate, TemplateConfiguration, TemplateConfigurationType, TemplateService } from '../../shared/template/template.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

export class EditTemplateOptionComponentData {
  templateConfiguration?: TemplateConfiguration;
  type?: TemplateConfigurationType;
}

@Component({
  selector: 'app-edit-template-option',
  templateUrl: './edit-template-option.component.html',
  styleUrls: ['./edit-template-option.component.scss']
})
export class EditTemplateOptionComponent implements OnInit, AfterViewInit {
  templateSelectionModel = new SelectionModel(false);
  availableEmailTemplatesDataSource = new MatTableDataSource<EmailTemplate>();
  displayedEmailTemplateTableColumns = ['select', 'name', 'publishedAt'];
  emailForm = this.fb.group({
    subject: ['', [Validators.required]]
  });
  @ViewChild(MatSort, { static: false }) private readonly sort: MatSort;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: EditTemplateOptionComponentData,
    private templateService: TemplateService,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<EditTemplateOptionComponent>
  ) {}

  get templateConfigurationType(): TemplateConfigurationType | null {
    if (!this.data) {
      return null;
    }
    return (this.data.templateConfiguration && this.data.templateConfiguration.type) || this.data.type || null;
  }

  ngAfterViewInit(): void {
    this.availableEmailTemplatesDataSource.sort = this.sort;
  }

  ngOnInit(): void {
    this.templateService.getAvailableMailTemplates().subscribe((mailTemplates) => {
      this.availableEmailTemplatesDataSource.data = mailTemplates.concat();
    });
    if (this.data.templateConfiguration) {
      this.templateSelectionModel.select(this.data.templateConfiguration.templateName);
      this.emailForm.patchValue({
        subject: this.data.templateConfiguration.subject
      });
    }
  }

  save(): void {
    const newTemplateConfiguration: TemplateConfiguration = {
      ...this.data.templateConfiguration,
      ...this.emailForm.value,
      templateName: this.templateSelectionModel.selected.length > 0 ? this.templateSelectionModel.selected[0] : null
    };
    this.dialogRef.close(newTemplateConfiguration);
  }
}
