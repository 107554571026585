import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Highcharts from 'highcharts';
import { AxisPlotBandsOptions } from 'highcharts';
import { chartOptions, OperationSeries } from './chart-config';
import { Subject } from 'rxjs';
import {
  Capacity,
  ChartComponent,
  ChartData,
  ChartPoint,
  ChartReflowMixin,
  OperationMeasurement,
  OperationMeasurements,
  OperationService,
  TimeSlot
} from 'flex-app-shared';

@Component({
  selector: 'app-operation-measurements',
  templateUrl: './operation-measurements.component.html',
  styleUrls: ['./operation-measurements.component.scss']
})
export class OperationMeasurementsComponent extends ChartReflowMixin() implements ChartComponent, OnInit, OnDestroy {
  public readonly chartConstructor: string = 'chart';
  public updateFlag: boolean = false;
  public chartOptions: Highcharts.Options = {};
  private readonly ngUnsubscribe = new Subject<void>();
  busy: boolean = false;

  constructor(private operationService: OperationService, private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.initChartData();
    const operationId = this.route.snapshot.paramMap.get('operationId');
    this.operationService.getPoolMeasurements(operationId).subscribe((data) => this.refreshChartData(this.processData(data)));
  }

  initChartData(): void {
    this.chartOptions = chartOptions;
  }

  public chartInstanceCallback(chartInstance: Highcharts.Chart): void {
    this.chart = chartInstance;
  }

  processData(data: OperationMeasurements): MeasurementChartData {
    const { period, measurements, baselinePower, baselinePeriod, targetPower, targetPeriod, rampUpPeriod, rampDownPeriod } = data;

    return {
      capacity: this.getMeasurementsPoints(measurements),
      baselinePower: this.getBaselinePowerPoints(baselinePower, period),
      baselinePeriod,
      operationPeriod: targetPeriod,
      target: this.getTargetPoints(targetPower, targetPeriod),
      rampUp: rampUpPeriod,
      rampDown: rampDownPeriod
    };
  }

  refreshChartData({ capacity, baselinePower, baselinePeriod, operationPeriod, target, rampUp, rampDown }: MeasurementChartData): void {
    if (!this.chart || !this.chart.series) return;
    this.chart.series[OperationSeries.measurements].setData(capacity);
    this.chart.series[OperationSeries.baselinePower].setData(baselinePower);
    this.chart.series[OperationSeries.target].setData(target);

    this.chart.xAxis[0].addPlotBand(this.getBand(baselinePeriod, 'rgba(84, 107, 123, 0.4)', 'baseline'));
    this.chart.xAxis[0].addPlotBand(this.getBand(rampUp, 'rgba(222, 181, 51, 0.5)', 'ramp up'));
    this.chart.xAxis[0].addPlotBand(this.getBand(operationPeriod, 'rgba(163,176,186, 0.5)', 'operation'));
    this.chart.xAxis[0].addPlotBand(this.getBand(rampDown, 'rgba(157, 127, 49, 0.5)', 'ramp down'));

    if (Array.isArray(this.chartOptions.xAxis)) {
      throw new Error(`Chart's "xAxis" setting should be an object, not an array`);
    }
    this.updateFlag = true;
  }

  getBand(period: TimeSlot, color: string, label: string): AxisPlotBandsOptions {
    return {
      from: new Date(period.startDateTime).getTime(),
      to: new Date(period.toDateTime).getTime(),
      color,
      label: {
        text: label,
        style: {
          color: 'white'
        }
      }
    };
  }

  flipValues(): void {
    this.chart.series.forEach((series) => {
      const newValues = series.data.map((p) => ({ x: p.x, y: p.y * -1 }));
      series.setData(newValues);
    });
    this.updateFlag = true;
  }

  downloadData(): void {
    this.busy = true;
    const operationId = this.route.snapshot.paramMap.get('operationId');
    this.operationService.downloadMeasurementData(operationId).subscribe(() => (this.busy = false));
  }

  downloadDataButtonDisabled(): boolean {
    return this.busy;
  }

  getMeasurementsPoints(measurements: ReadonlyArray<OperationMeasurement>): Array<ChartPoint> {
    return measurements
      .map(({ period, capacity }) => [
        { x: new Date(period.startDateTime).getTime(), y: Capacity.asMW(capacity) },
        { x: new Date(period.toDateTime).getTime(), y: Capacity.asMW(capacity) }
      ])
      .reduce((a, b) => a.concat(b), []);
  }

  getBaselinePowerPoints(capacity: Capacity, period: TimeSlot): Array<ChartPoint> {
    const value = Capacity.asMW(capacity);
    return [
      { x: new Date(period.startDateTime).getTime(), y: value },
      { x: new Date(period.toDateTime).getTime(), y: value }
    ];
  }

  getTargetPoints(targetPower: Capacity, period: TimeSlot): Array<ChartPoint> {
    const { startDateTime, toDateTime } = period;
    const value = Capacity.asMW(targetPower);

    return [
      { x: new Date(startDateTime).getTime(), y: value },
      { x: new Date(toDateTime).getTime(), y: value }
    ];
  }

  clearChartData(): void {
    if (this.chart && this.chart.series) {
      this.chart.series.forEach((series) => series.setData([]));
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.clearChartData();
  }
}

interface MeasurementChartData extends ChartData {
  capacity: Array<ChartPoint>;
  baselinePower: Array<ChartPoint>;
  baselinePeriod: TimeSlot;
  operationPeriod: TimeSlot;
  target: Array<ChartPoint>;
  rampUp: TimeSlot;
  rampDown: TimeSlot;
}
