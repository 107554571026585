import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, first, switchMap } from 'rxjs/operators';
import { DeviceContact } from '../../../../app/shared/device/device.service';
import { DeviceState } from '../device/device.state';
import { DevicesFacade } from '../devices/devices.facade';
import { DeleteDeviceContactCommand, EditDeviceContactCommand } from './device-contacts.actions';
import { DeviceContactsState } from './device-contacts.state';

@Injectable({
  providedIn: 'root'
})
export class DeviceContactsFacade {
  deviceContacts$ = this.store.select(DeviceContactsState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(DeviceContactsState.getDeviceContacts))
  );
  device$ = this.store.select(DeviceState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(DeviceState.getDevice))
  );

  constructor(private store: Store, private devicesFacade: DevicesFacade) {}

  edit(contact: DeviceContact): void {
    this.store.dispatch(new EditDeviceContactCommand(contact));
  }

  new(): void {
    this.store.dispatch(new EditDeviceContactCommand());
  }

  delete(contact: DeviceContact): void {
    this.store.dispatch(new DeleteDeviceContactCommand(contact));
  }

  hideDeviceDetails(): void {
    this.devicesFacade.hideDeviceDetails();
  }
}
