import { State } from '@ngxs/store';
import { OperationPoolCandidatesState } from './states/operation-pool-candidates/operation-pool-candidates.state';
import { OperationState } from './states/operation/operation.state';
import { Injectable } from '@angular/core';

export const OperationStates = [OperationState, OperationPoolCandidatesState];

@State({
  name: 'operationStateModule',
  children: OperationStates
})
@Injectable({
  providedIn: 'root'
})
export class OperationStateModule {}
