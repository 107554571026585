import { MatDialog } from '@angular/material/dialog';
import { RouterStateSnapshot } from '@angular/router';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { EntityStateModel, FetchEntity, findParameterValue, RouterInitializer, SaveEntity } from 'flex-app-shared';
import { DeviceContact, DeviceService } from '../../../../app/shared/device/device.service';
import { LoadDeviceContactsCommand } from '../device-contacts/device-contacts.actions';
import {
  CloseDeviceContactDialogCommand,
  DeviceContactDialogOpenedEvent,
  DeviceContactLoadedEvent,
  DeviceIdSelectedEvent,
  LoadDeviceContactCommand,
  ResetDeviceContactCommand,
  SaveDeviceContactCommand
} from './device-contact.action';
import { Observable } from 'rxjs';
import { isNil } from 'lodash-es';
import { Injectable } from '@angular/core';

export class DeviceContactStateModel extends EntityStateModel<DeviceContact> {
  model: DeviceContact = {
    deviceId: null,
    externalId: '',
    description: '',
    invertedSchedules: false,
    switchCooldownTime: 0
  };
  currentDeviceId = null;
  dialogId: string = null;
}

@State({
  name: 'deviceContact',
  defaults: new DeviceContactStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class DeviceContactState implements RouterInitializer<DeviceContactStateModel> {
  @Selector()
  public static getDeviceContact(state: DeviceContactStateModel): DeviceContact {
    return state.model;
  }

  @Selector()
  public static isInitialized(state: DeviceContactStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static isBusySending(state: DeviceContactStateModel): boolean {
    return state.isBusySending;
  }

  @Selector()
  public static error(state: DeviceContactStateModel): string | null {
    return state.sendError;
  }

  constructor(private deviceService: DeviceService, private matDialogService: MatDialog) {}

  @Action(RouterNavigation)
  routerInit(
    { getState, setState, dispatch }: StateContext<DeviceContactStateModel>,
    { routerState }: RouterNavigation<RouterStateSnapshot>
  ): void {
    const deviceId = findParameterValue(routerState, 'deviceId');
    const deviceContactId = findParameterValue(routerState, 'deviceContactId');
    if (deviceId) {
      dispatch(new DeviceIdSelectedEvent(deviceId));
    }
    if (deviceContactId) {
      if (isNil(deviceId)) throw new Error('Cannot find deviceId');
      dispatch(new LoadDeviceContactCommand(deviceId, deviceContactId));
    } else if (getState().model.id) {
      dispatch(new ResetDeviceContactCommand());
    }
  }

  @Action(DeviceIdSelectedEvent)
  setCurrentDeviceId({ getState, setState }: StateContext<DeviceContactStateModel>, { deviceId }: DeviceIdSelectedEvent): void {
    setState({
      ...getState(),
      currentDeviceId: deviceId
    });
  }

  @FetchEntity()
  @Action(LoadDeviceContactCommand)
  loadDeviceContact(
    { getState, setState, dispatch }: StateContext<DeviceContactStateModel>,
    { deviceId, deviceContactId }: LoadDeviceContactCommand
  ): Observable<DeviceContact> {
    return this.deviceService.getDeviceContactById(deviceId, deviceContactId).pipe(
      tap((result) => {
        dispatch(new DeviceContactLoadedEvent(result));
      })
    );
  }

  @Action(DeviceContactLoadedEvent)
  setDeviceContact({ getState, setState }: StateContext<DeviceContactStateModel>, { payload }: DeviceContactLoadedEvent): void {
    setState({
      ...getState(),
      model: {
        ...getState().model,
        ...payload
      }
    });
  }

  @Action(ResetDeviceContactCommand)
  resetDeviceContactData({ getState, setState }: StateContext<DeviceContactStateModel>): void {
    setState({
      ...new DeviceContactStateModel(),
      busySendingCount: getState().busySendingCount,
      dialogId: getState().dialogId,
      currentDeviceId: getState().currentDeviceId
    });
  }

  @Action(DeviceContactDialogOpenedEvent)
  deviceContactDialogOpened(
    { getState, setState, dispatch }: StateContext<DeviceContactStateModel>,
    { dialogId, deviceContactId, deviceId }: DeviceContactDialogOpenedEvent
  ): void {
    setState({
      ...getState(),
      dialogId
    });
    if (deviceContactId && deviceId) {
      dispatch(new LoadDeviceContactCommand(deviceId, deviceContactId));
    } else {
      dispatch(new ResetDeviceContactCommand());
    }
  }

  @Action(CloseDeviceContactDialogCommand, {})
  closeDeviceContactDialog({ getState, setState, dispatch }: StateContext<DeviceContactStateModel>): void {
    if (getState().dialogId) {
      this.matDialogService.getDialogById(getState().dialogId).close();
    }
    setState({
      ...getState(),
      dialogId: null
    });

    dispatch(new ResetDeviceContactCommand());
  }

  @SaveEntity()
  @Action(SaveDeviceContactCommand)
  saveDeviceContact(
    { getState, dispatch }: StateContext<DeviceContactStateModel>,
    action: SaveDeviceContactCommand
  ): Observable<DeviceContact> {
    let deviceContact = getState().model;
    if (!deviceContact.deviceId) {
      deviceContact = {
        ...deviceContact,
        deviceId: getState().currentDeviceId
      };
    }

    if (getState().model.id) {
      deviceContact = {
        ...deviceContact,
        id: getState().model.id
      };
    }

    let newDeviceContact: any = deviceContact;
    if (!deviceContact.externalId) {
      const { externalId, ...rest } = deviceContact;
      // TODO (S.Panfilov) this is weird, cause "externalId"is a mandatory field
      newDeviceContact = rest;
    }

    const saveObservable =
      newDeviceContact.id && newDeviceContact.id !== 'new'
        ? this.deviceService.updateDeviceContact(newDeviceContact)
        : this.deviceService.addDeviceContact(newDeviceContact);
    return saveObservable.pipe(
      tap(() => {
        dispatch(new CloseDeviceContactDialogCommand());
        dispatch(
          new LoadDeviceContactsCommand({
            deviceId: newDeviceContact.deviceId
          })
        );
      })
    );
  }
}
