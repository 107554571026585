import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-forbidden-message',
  templateUrl: './forbidden-message.component.html',
  styleUrls: ['./forbidden-message.component.scss']
})
export class ForbiddenMessageComponent {
  @Input()
  public isDisabled: boolean = false;
}
