import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import {
  BalanceResponsibleParty,
  BalanceResponsiblePartyService
} from '../shared/balance-responsible-party/balance-responsible-party.service';

@Component({
  selector: 'app-balance-responsible-party-detail',
  templateUrl: './balance-responsible-party-detail.component.html',
  styleUrls: ['./balance-responsible-party-detail.component.scss']
})
export class BalanceResponsiblePartyDetailComponent implements OnInit {
  partyForm = this.builder.group({
    id: '',
    name: ['', [Validators.required, Validators.maxLength(50)]],
    code: ['', [Validators.required, Validators.maxLength(8)]],
    ean: ['', [Validators.required, Validators.maxLength(13), Validators.maxLength(13)]]
  });

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private builder: UntypedFormBuilder,
    private service: BalanceResponsiblePartyService
  ) {}

  ngOnInit(): void {
    this.getParty();
  }

  getParty(): void {
    const id = this.route.snapshot.paramMap.get('brpId');
    if (id === 'new') {
      this.initForm(id, new BalanceResponsibleParty());
    } else {
      this.service.getById(id).subscribe((party) => this.initForm(id, party));
    }
  }

  onSubmit(): void {
    if (this.partyForm.value.id === 'new') {
      this.service.add(this.partyForm.value).subscribe(() => {
        this.partyForm.reset();
        this.goBack();
      });
    } else {
      this.service.update(this.partyForm.value).subscribe(() => {
        this.partyForm.reset();
        this.goBack();
      });
    }
  }

  goBack(): void {
    this.location.back();
  }

  private initForm(id: string, party: BalanceResponsibleParty): void {
    this.partyForm.get('id').setValue(id);
    this.partyForm.get('name').setValue(party.name);
    this.partyForm.get('code').setValue(party.code);
    this.partyForm.get('ean').setValue(party.ean);
  }
}
