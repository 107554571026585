import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DatePeriod,
  DatePeriodView,
  LocalDate,
  MessageType,
  RestClientService,
  RestEntity,
  AsyncMessage,
  AsyncMessagingService
} from 'flex-app-shared';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

export class PoolPeriod {
  period: DatePeriod;
  gridPointIds: string[];
  sent: boolean;
}

export class PoolPeriodView {
  period: DatePeriodView;
  gridPointIds: string[];
  sent: boolean;

  static serialize(poolPeriod: PoolPeriodView): any {
    return {
      ...poolPeriod,
      period: {
        startDate: LocalDate.serialize(poolPeriod.period.startDate),
        endDate: LocalDate.serialize(poolPeriod.period.endDate)
      }
    };
  }

  static deserialize(poolPeriod: PoolPeriod): any {
    return {
      ...poolPeriod,
      period: {
        startDate: LocalDate.deserialize(poolPeriod.period.startDate),
        endDate: LocalDate.deserialize(poolPeriod.period.endDate)
      }
    };
  }
}

export class Pool extends RestEntity {
  poolPeriods: PoolPeriod[];
}

export class PoolView extends RestEntity {
  poolPeriods: PoolPeriodView[];
}

export class PoolAvailabilityGridPoint extends RestEntity {
  ean: string;
  customerName: string;
  available: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PoolService extends RestClientService<Pool> {
  constructor(private asyncMessagingService: AsyncMessagingService) {
    super('/api/v1/pools');
  }

  getMainPool(): Observable<Pool> {
    const url = `${this.endpoint}/main-pool`;
    return this.http.get<Pool>(url).pipe(
      tap(() => {
        this.log(`fetched main pool1`);
      })
    );
  }

  add(pool: Pool): Observable<Pool> {
    return this.http.post<Pool>(this.endpoint, pool.poolPeriods);
  }

  update(pool: Pool): Observable<Pool> {
    const url = `${this.endpoint}/${pool.id}`;
    return this.http.put<Pool>(url, pool.poolPeriods);
  }

  findAvailability(period: DatePeriodView): Observable<PoolAvailabilityGridPoint[]> {
    let params = new HttpParams();
    params = params.set('startDate', LocalDate.serialize(period.startDate));
    params = params.set('endDate', LocalDate.serialize(period.endDate));

    return this.http.get<PoolAvailabilityGridPoint[]>(`${this.endpoint}/grid-point-availability`, { params });
  }

  sendPool(): Observable<AsyncMessage> {
    const request = this.http.put(`${this.endpoint}/send-pool`, {}, { observe: 'response' });

    return this.asyncMessagingService.handleSubscriptionRequest(request, [
      MessageType.TsoMonthlyPoolUpdatedSseEvent,
      MessageType.TsoMonthlyPoolUpdateFailedSseEvent
    ]);
  }
}
