import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable, of } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';
import {
  EntityState,
  EntityStateModel,
  FetchEntity,
  OperationPoolGridPoint,
  OperationPoolGridPointView,
  OperationService,
  PeriodView
} from 'flex-app-shared';

import {
  LoadOperationPoolCandidatesCommand,
  OperationPoolCandidatesLoadedEvent,
  ResetOperationPoolCandidatesCommand
} from './operation-pool-candidates.actions';
import { Injectable } from '@angular/core';

export class OperationPoolCandidatesStateModel extends EntityStateModel<ReadonlyArray<OperationPoolGridPoint>> {
  model: ReadonlyArray<OperationPoolGridPoint> = [];
}

@State<OperationPoolCandidatesStateModel>({
  name: 'OperationPoolCandidates',
  defaults: new OperationPoolCandidatesStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class OperationPoolCandidatesState extends EntityState<OperationPoolGridPoint[]> {
  @Selector()
  public static getPoolCandidates(state: OperationPoolCandidatesStateModel): OperationPoolGridPointView[] {
    return state.model.map((poolCandidate) => {
      return {
        ...poolCandidate,
        period: PeriodView.deserialize(poolCandidate.period)
      };
    });
  }

  @Selector()
  public static isBusy(state: OperationPoolCandidatesStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isInitialized(state: OperationPoolCandidatesStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static error(state: OperationPoolCandidatesStateModel): string | null {
    return state.sendError;
  }

  constructor(store: Store, private operationService: OperationService) {
    super(store);
  }

  @Action(LoadOperationPoolCandidatesCommand, { cancelUncompleted: true })
  @FetchEntity()
  initOperationData(
    ctx: StateContext<OperationPoolCandidatesStateModel>,
    { direction, period, notificationTime, requestedPower }: LoadOperationPoolCandidatesCommand
  ): Observable<ReadonlyArray<OperationPoolGridPoint>> {
    return of(null).pipe(
      delay(100), // wait 100ms, and debounce calls that take place in quick succession (cancelUncompleted: true).
      switchMap(() => {
        return this.operationService.getPoolCandidates(direction, period, notificationTime, requestedPower);
      }),
      tap((poolCandidates) => ctx.dispatch(new OperationPoolCandidatesLoadedEvent(poolCandidates)))
    );
  }

  @Action(OperationPoolCandidatesLoadedEvent)
  SetOperationPoolCandidatesEventData(
    { setState, getState }: StateContext<OperationPoolCandidatesStateModel>,
    { payload }: OperationPoolCandidatesLoadedEvent
  ): void {
    const state = getState();
    setState({
      ...state,
      model: payload
    });
  }

  @Action(ResetOperationPoolCandidatesCommand)
  reSetOperationPoolCandidatesEvent(ctx: StateContext<OperationPoolCandidatesStateModel>): void {
    ctx.setState(new OperationPoolCandidatesStateModel());
  }
}
