import { Measurement } from 'projects/flex-app-admin/src/app/shared/measurement-data/measurement-data.service';

export class MeasurementDataUploadDownloadTemplateCommand {
  public static readonly type = '[Measurement Data Upload] Download template command';
}

export class MeasurementDataUploadTemplateCommand {
  public static readonly type = '[Measurement Data Upload] Upload template command';

  constructor(public data: Blob) {}
}

export class MeasurementDataUploadUpdatePreviewCommand {
  public static readonly type = '[Measurement Data Upload] Update preview';

  constructor(public id: string) {}
}

export class MeasurementDataUploadPreviewUpdatedEvent {
  public static readonly type = '[Measurement Data Upload] Preview updated';

  constructor(public data: Measurement[]) {}
}

export class MeasurementDataUploadConfirmCommand {
  public static readonly type = '[Measurement Data Upload] Confirm uploaded data';

  constructor(public afterConfirmFn: () => void) {}
}

export class MeasurementDataUploadCancelCommand {
  public static readonly type = '[Measurement Data Upload] Cancel uploaded data';

  constructor(public id: string) {}
}

export class MeasurementDataUploadActivate {
  public static readonly type = '[Measurement Data Upload] Activate';
}

export class MeasurementDataUploadDeactivate {
  public static readonly type = '[Measurement Data Upload] Deactivate';
}
