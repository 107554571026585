import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Customer, EntityFacade } from 'flex-app-shared';
import { SaveCustomerCommand } from './customer.actions';
import { CustomerState } from './customer.state';
import { Observable } from 'rxjs';
import { AccountManager } from '../../../../app/shared/account-manager/account-manager.service';
import { AccountManagersState } from '../../../account-managers/states/account-managers/account-managers.state';
import { FetchAccountManagersCommand } from '../../../account-managers/states/account-managers/account-managers.actions';

@Injectable({
  providedIn: 'root'
})
export class CustomerFacade implements EntityFacade {
  isDisabled$ = this.store.select(CustomerState.isBusy);
  saveButtonPending$ = this.store.select(CustomerState.isBusySending);
  saveButtonError$ = this.store.select(CustomerState.error);

  @Select(CustomerState.getCustomer) customer$: Observable<Customer>;

  @Select(AccountManagersState.getAccountManagers) accountManagers$: Observable<AccountManager[]>;

  constructor(private store: Store) {}

  init(): void {
    this.store.dispatch(new FetchAccountManagersCommand());
  }

  save(): void {
    this.store.dispatch(new SaveCustomerCommand());
  }
}
