import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { MessageType, AsyncMessagingService } from 'flex-app-shared';

@Component({
  selector: 'app-operation-measurements-snackbar',
  templateUrl: './operation-measurements-snackbar.component.html',
  styleUrls: ['./operation-measurements-snackbar.component.scss']
})
export class OperationMeasurementsSnackbarComponent {
  conversationId: string;
  currentEvent;
  timeoutHandle;
  isListeningForEvents = true;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) private data: any,
    private snackBarRef: MatSnackBarRef<OperationMeasurementsSnackbarComponent>,
    private asyncMessagingService: AsyncMessagingService
  ) {
    this.conversationId = data.conversationId;

    if (!this.asyncMessagingService.isConnected) {
      this.dismissAfterDelay();
      this.isListeningForEvents = false;
      return;
    }

    const subscription = this.asyncMessagingService
      .onConversationMessageOfType(this.conversationId, MessageType.MeasurementsRequestedSseEvent)
      .subscribe(
        (event) => {
          if (!event.payload) {
            this.dismissAfterDelay();
            return;
          }

          try {
            this.currentEvent = JSON.parse(event.payload);
          } catch (e) {
            this.dismissAfterDelay();
            console.error('Error parsing message from SSE', e);
            return;
          }
        },
        () => {
          this.dismissAfterDelay();
        }
      );

    this.snackBarRef.afterDismissed().subscribe(() => {
      subscription.unsubscribe();
    });
  }

  private dismissAfterDelay(): void {
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }

    this.timeoutHandle = setTimeout(() => {
      this.timeoutHandle = null;
      this.snackBarRef.dismiss();
    }, 5000);
  }
}
