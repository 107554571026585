import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { format } from 'date-fns';
import { CalendarPeriod, DateFnsParseFormatters } from 'flex-app-shared';

export class ImbalancePricesInfo {
  daysRetrieved: number = 0;
  errorMessage: string | null = null;
}

@Injectable({
  providedIn: 'root'
})
export class ImbalancePricesService {
  constructor(private http: HttpClient) {}

  private static getDateRangeObject(startDate: Date, endDate: Date): object {
    return {
      startDate: format(startDate, DateFnsParseFormatters.DAY),
      endDate: format(endDate, DateFnsParseFormatters.DAY)
    };
  }

  retrievePrices(period: CalendarPeriod): Observable<ImbalancePricesInfo> {
    return this.http.post<ImbalancePricesInfo>('/api/v1/imbalance-prices', { startDate: period.startDate, endDate: period.endDate });
  }
}
