<ng-container *ngIf="!isStandalone">
  <mat-card class="not-standalone">
    <mat-card-content>
      <ng-container [ngTemplateOutlet]="pageContents"></ng-container>
    </mat-card-content>
    <mat-card-actions>
      <button *ngIf="!isStandalone" mat-stroked-button (click)="goBack()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
          <span>Go back</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-stroked-button [disabled]="gridPointsFacade.isBusyDownloading$ | async" (click)="downloadGridPointsOverview()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:download"></mat-icon>
          <span>Download data</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="primary" routerLink="/grid-points/new">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:add"></mat-icon>
          <span>New grid point</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>

<ng-container *ngIf="isStandalone" [ngTemplateOutlet]="pageContents"></ng-container>

<ng-template #pageContents>
  <mat-toolbar class="filter-bar">
    <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
  </mat-toolbar>
  <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
    <ng-container matColumnDef="ean">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>EAN</th>
      <td mat-cell *matCellDef="let gridPoint" [ngSwitch]="isStandalone && !isDashboard" [matTooltip]="gridPoint.description">
        <a *ngSwitchCase="true" class="pointer" (click)="edit(gridPoint.id)">{{ gridPoint.ean }}</a>
        <a *ngSwitchCase="false" routerLink="/grid-points/{{ gridPoint.id }}">{{ gridPoint.ean }}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="description" [ngSwitch]="isDashboard">
      <ng-container *ngSwitchCase="true">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let gridPoint">
          <a routerLink="/grid-points/{{ gridPoint.id }}">{{ gridPoint.description }}</a>
        </td>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
        <td mat-cell *matCellDef="let gridPoint">{{ gridPoint.description }}</td>
      </ng-container>
    </ng-container>
    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
      <td mat-cell *matCellDef="let gridPoint">
        {{ gridPoint.customerName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="brp">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>BRP</th>
      <td mat-cell *matCellDef="let gridPoint">
        {{ gridPoint.balanceResponsiblePartyName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="measurementCompany">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Measurement company</th>
      <td mat-cell *matCellDef="let gridPoint">
        {{ gridPoint.measurementCompanyName }}
      </td>
    </ng-container>
    <ng-container matColumnDef="fiveMinuteDataAvailable">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>5-min data</th>
      <td mat-cell *matCellDef="let gridPoint" [ngSwitch]="gridPoint.fiveMinuteDataAvailable">
        <ph-flex-vertical-center>
          <ng-container *ngSwitchCase="true">
            <mat-icon class="status-success" svgIcon="ic-old:check"> </mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <mat-icon class="status-passive" svgIcon="ic-old:close"> </mat-icon>
          </ng-container>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <ng-container matColumnDef="pulseProductionPeak" [ngSwitch]="isDashboard">
      <ng-container *ngSwitchCase="true">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="compressed">
          <ph-flex-vertical-center>
            <mat-icon class="status-up" matTooltip="Production peak (kW)" svgIcon="ic-old:arrow-up"></mat-icon>
          </ph-flex-vertical-center>
        </th>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Production peak (kW)</th>
      </ng-container>
      <td mat-cell *matCellDef="let gridPoint">
        {{ gridPoint.pulseProductionPeak | capacityNumber: 'kW' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="pulseConsumptionPeak" [ngSwitch]="isDashboard">
      <ng-container *ngSwitchCase="true">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <mat-icon class="status-down" matTooltip="Consumption peak (kW)" svgIcon="ic-old:arrow-down"></mat-icon>
        </th>
      </ng-container>
      <ng-container *ngSwitchCase="false">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Consumption peak (kW)</th>
      </ng-container>
      <td mat-cell *matCellDef="let gridPoint">
        {{ gridPoint.pulseConsumptionPeak | capacityNumber: 'kW' }}
      </td>
    </ng-container>
    <ng-container matColumnDef="automatic">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Auto</th>
      <td mat-cell *matCellDef="let gridPoint" [ngSwitch]="gridPoint.measurementMethodAutomatic">
        <ph-flex-vertical-center>
          <ng-container *ngSwitchCase="true">
            <mat-icon class="status-success" svgIcon="ic-old:check"> </mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <mat-icon class="status-passive" svgIcon="ic-old:close"> </mat-icon>
          </ng-container>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <ng-container matColumnDef="preQualified">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Incident reserve PQ</th>
      <td mat-cell *matCellDef="let gridPoint" [ngSwitch]="gridPoint.preQualified">
        <ph-flex-vertical-center>
          <ng-container *ngSwitchCase="true">
            <mat-icon class="status-success" svgIcon="ic-old:check"> </mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <mat-icon class="status-passive" svgIcon="ic-old:close"> </mat-icon>
          </ng-container>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <ng-container matColumnDef="gopacsPreQualified">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Gopacs PQ</th>
      <td mat-cell *matCellDef="let gridPoint" [ngSwitch]="gridPoint.gopacsPreQualified">
        <ph-flex-vertical-center>
          <ng-container *ngSwitchCase="true">
            <mat-icon class="status-success" svgIcon="ic-old:check"> </mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <mat-icon class="status-passive" svgIcon="ic-old:close"> </mat-icon>
          </ng-container>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let gridPoint" [ngSwitch]="isStandalone && !isDashboard">
        <ph-flex-vertical-center>
          <button *ngSwitchCase="true" class="pointer" mat-icon-button matTooltip="Edit" (click)="edit(gridPoint.id)">
            <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
          </button>
          <button *ngSwitchCase="false" mat-icon-button matTooltip="Edit" routerLink="/grid-points/{{ gridPoint.id }}">
            <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
          </button>
          <button mat-icon-button [matMenuTriggerFor]="tableActions" ngClass="more-menu">
            <mat-icon svgIcon="ic-old:more-menu-v"></mat-icon>
          </button>
          <mat-menu #tableActions="matMenu">
            <button mat-menu-item (click)="editMeasurementConfig(gridPoint)">
              <mat-icon svgIcon="ic-old:volume"></mat-icon>
              Measurement configuration
            </button>
            <button mat-menu-item (click)="retrieveMeasurementData(gridPoint.id)" [disabled]="!gridPoint.measurementMethodAutomatic">
              <mat-icon svgIcon="ic-old:source-external"></mat-icon>
              Retrieve measurement data
            </button>
            <button mat-menu-item class="remove" (click)="delete(gridPoint)">
              <mat-icon svgIcon="ic-old:trash-o"></mat-icon>
              Delete
            </button>
          </mat-menu>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [showFirstLastButtons]="true" [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
</ng-template>
