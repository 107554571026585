import { AppInjector, FrontendPaginatedDataSource, FrontendPaginationHelper, OnDestroyProvider } from 'flex-app-shared';
import { takeUntil } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { IncidentReserveInvoiceResultsFacade } from '../../store/incident-reserve-invoice-results/incident-reserve-invoice-results.facade';
import { IncidentReserveInvoiceResults } from '../shared/incident-reserve-invoice-results/incident-reserve-invoice-results.service';
import { get } from 'lodash-es';

export class IncidentReserveInvoiceResultsDatasource extends FrontendPaginatedDataSource<IncidentReserveInvoiceResults> {
  paginationHelper = new FrontendPaginationHelper<IncidentReserveInvoiceResults>(
    (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'downwardsAmount':
          return data.downwardsAmount.amount;
        case 'upwardsAmount':
          return data.upwardsAmount.amount;
        case 'day':
          return data.day.day;
        default:
          return get(data, sortHeaderId);
      }
    },
    {
      keys: ['customerName', 'customerLegalName', 'customerBillingId', 'serviceAgreementSystemReference']
    }
  );
  facade = AppInjector.get(IncidentReserveInvoiceResultsFacade);

  constructor(onDestroyProvider: OnDestroyProvider) {
    super(onDestroyProvider);

    combineLatest([this.facade.data$, this.loadDataSubject])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([newResult, loadDataInfo]) => {
        const { pageIndex, pageSize, searchTerm, sort } = loadDataInfo as any;

        this.paginationHelper.updateData(newResult);

        this.dataSubject.next(this.paginationHelper.handleLoadData(pageIndex, pageSize, searchTerm, sort));
        this.updatePaginator(pageIndex, pageSize, this.paginationHelper.filteredData.length);
      });
  }
}
