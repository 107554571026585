import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  AuthorityService,
  Customer,
  CustomerService,
  CustomValidators,
  findParameterValue,
  FnErrorMatcher,
  MANAGE_EXTERNAL_USERS,
  MANAGE_USERS,
  MixinBase,
  OnDestroyMixin
} from 'flex-app-shared';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { UserFacade } from '../../store/user/states/user/user.facade';
import { B2CResultType, Group, GroupService, UserB2CResult, UserView } from '../shared/user/user.service';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent extends OnDestroyMixin(MixinBase) implements OnInit, AfterViewInit {
  eMailRegex = /^[a-zA-Z0-9.!#$%&'^_`{}~+-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  MANAGE_USERS = MANAGE_USERS;
  ResultType = B2CResultType;
  user: UserView = new UserView();
  activationEmailResult$: Observable<UserB2CResult> = this.userFacade.activationEmailResult$;
  resetPasswordResult$: Observable<UserB2CResult> = this.userFacade.resetPasswordResult$;
  toggleUserActiveResult$: Observable<UserB2CResult> = this.userFacade.toggleUserActiveResult$;

  deactivateUserError$: Observable<string | null> = this.toggleUserActiveResult$.pipe(
    filter((result) => result?.type === B2CResultType.DEACTIVATE_USER),
    map((result) => (result.success ? null : result.message))
  );

  activateUserError$: Observable<string | null> = this.toggleUserActiveResult$.pipe(
    filter((result) => result?.type === B2CResultType.ACTIVATE_USER),
    map((result) => (result.success ? null : result.message))
  );

  resetPasswordError$: Observable<string | null> = this.resetPasswordResult$.pipe(
    filter((result) => result?.success === false),
    map((result) => result.message)
  );

  onboadingEmailError$ = this.activationEmailResult$.pipe(map((result) => (result?.success ? null : result?.message)));

  isBusy$: Observable<boolean>;
  isBusySendingEmail$: Observable<boolean>;
  isBusyResettingPassword$: Observable<boolean>;
  isBusyActivatingUser$: Observable<boolean>;
  isBusyDeactivatingUser$: Observable<boolean>;

  minButtonDisabledMillis = 500;

  readonly groupDataSource: MatTableDataSource<Group> = new MatTableDataSource<Group>([]);
  groupDisplayedColumns$: Observable<ReadonlyArray<string>>;
  groupSelection = new SelectionModel<string>(true, []);
  readonly customerDataSource: MatTableDataSource<Customer> = new MatTableDataSource<Customer>([]);
  customerDisplayedColumns = ['select', 'name', 'legalName'];
  customerSelection = new SelectionModel<string>(true, []);
  showAllErrors: boolean = false;
  errorMatcher = new FnErrorMatcher(() => this.showAllErrors);
  form = this.builder.group({
    nickName: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
    firstName: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
    surname: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),
    phoneNumber: new UntypedFormControl('', [Validators.required, Validators.maxLength(50), CustomValidators.phoneNumberValidator()]),
    name: new UntypedFormControl('', [Validators.required, Validators.maxLength(100), Validators.pattern(this.eMailRegex)])
  });
  @Input()
  public readonly defaultPageSize: number = 10;
  sendActivationEmailOnCreate = true;
  @ViewChild('groupPaginator', { static: false }) private readonly groupPaginator: MatPaginator;
  @ViewChild('groupSort', { static: false }) private readonly groupSort: MatSort;
  @ViewChild('customerPaginator', { static: false }) private readonly customerPaginator: MatPaginator;
  @ViewChild('customerSort', { static: false }) private readonly customerSort: MatSort;

  constructor(
    private userFacade: UserFacade,
    private location: Location,
    private router: Router,
    private builder: UntypedFormBuilder,
    private groupService: GroupService,
    private customerService: CustomerService,
    private authorityService: AuthorityService
  ) {
    super();
    this.isBusy$ = this.userFacade.isBusy$;
    this.isBusySendingEmail$ = this.userFacade.isBusySendingEmail$;
    this.isBusyResettingPassword$ = this.userFacade.isBusyResettingPassword$;
    this.isBusyActivatingUser$ = this.userFacade.isBusyActivatingUser$;
    this.isBusyDeactivatingUser$ = this.userFacade.isBusyDeactivatingUser$;
    this.groupDisplayedColumns$ = combineLatest([
      authorityService.hasAuthorities(MANAGE_USERS),
      authorityService.hasAuthorities(MANAGE_EXTERNAL_USERS)
    ]).pipe(
      map(([hasManageUsers, hasManageExternalUsers]) => {
        if (hasManageUsers) {
          return ['select', 'groupName', 'groupType', 'description'];
        }
        if (hasManageExternalUsers) {
          return ['select', 'groupName', 'description'];
        }
      })
    );
  }

  get isNew(): boolean {
    return findParameterValue(this.router.routerState.snapshot, 'userId') === 'new';
  }

  ngOnInit(): void {
    const customerId = findParameterValue(this.router.routerState.snapshot, 'customerId');

    this.userFacade.user$.pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      if (customerId) {
        user.customerIds.push(customerId);
      }
      this.user = user;
      this.groupSelection.select(...user.groupIds);
      this.customerSelection.select(...user.customerIds);
      this.customerService
        .getAll()
        .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
        .subscribe((customers) => {
          const sorted = customers.sort((c1: Customer, c2: Customer) => c1.name.localeCompare(c2.name));
          const selected = sorted.filter((it) => this.customerSelection.selected.includes(it.id));
          const unselected = sorted.filter((it) => !this.customerSelection.selected.includes(it.id));
          this.customerDataSource.data = selected.concat(unselected);
        });
    });

    this.groupService
      .getAll()
      .pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe((groups) => {
        this.groupDataSource.data = groups;
      });

    this.customerSelection.changed.pipe(takeUntil(this.onDestroy$)).subscribe((it) => {
      if (it.source.selected.length > 0) {
        this.user.employee = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.groupDataSource.sort = this.groupSort;
    this.groupDataSource.paginator = this.groupPaginator;
    this.customerDataSource.sort = this.customerSort;
    this.customerDataSource.paginator = this.customerPaginator;
  }

  goBack(): void {
    this.location.back();
  }

  isGroupAllSelected(): boolean {
    const numSelected = this.groupSelection.selected.length;
    const numRows = this.groupDataSource.data.length;
    return numSelected === numRows;
  }

  groupMasterToggle(): void {
    this.isGroupAllSelected()
      ? this.groupSelection.clear()
      : this.groupDataSource.data.forEach((row) => this.groupSelection.select(row.id));
  }

  isCustomerAllSelected(): boolean {
    const numSelected = this.customerSelection.selected.length;
    const numRows = this.customerDataSource.data.length;
    return numSelected === numRows;
  }

  customerMasterToggle(): void {
    this.isCustomerAllSelected()
      ? this.customerSelection.clear()
      : this.customerDataSource.data.forEach((row) => this.customerSelection.select(row.id));
  }

  onSubmit(): void {
    if (this.isNew) {
      if (this.form.invalid) {
        this.showAllErrors = true;
        return;
      }
      this.user = {
        ...this.form.value,
        employee: this.user.employee,
        phoneNumber: this.privateGetFormattedPhoneNumber(this.form.value.phoneNumber)
      };
      this.user.groupIds = this.groupSelection.selected;
      this.user.customerIds = this.customerSelection.selected;
      this.userFacade.create(this.user, this.sendActivationEmailOnCreate);
    } else {
      this.user.groupIds = this.groupSelection.selected;
      this.user.customerIds = this.customerSelection.selected;
      this.userFacade.updateDetails(this.user);
    }
  }

  privateGetFormattedPhoneNumber(input: string): string {
    const instance = PhoneNumberUtil.getInstance();
    return instance.format(instance.parseAndKeepRawInput(input, 'nl'), PhoneNumberFormat.E164);
  }

  applyGroupFilter(filterValue: string): void {
    this.groupDataSource.filter = filterValue || '';
  }

  applyCustomerFilter(filterValue: string): void {
    this.customerDataSource.filter = filterValue || '';
  }

  sendOnboardingEmail(): void {
    this.userFacade.sendActivationEmail(this.user);
  }

  resetPassword(): void {
    this.userFacade.resetPassword(this.user);
  }

  activate(): void {
    this.userFacade.toggleUserActive(this.user, true);
  }

  deactivate(): void {
    this.userFacade.toggleUserActive(this.user, false);
  }
}
