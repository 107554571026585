import { State } from '@ngxs/store';
import { CustomerStepperState } from './states/customer-stepper.state';
import { Injectable } from '@angular/core';

export const CustomerStepperStates = [CustomerStepperState];

@State({
  name: 'customerStepperStateModule',
  children: CustomerStepperStates
})
@Injectable({
  providedIn: 'root'
})
export class CustomerStepperStateModule {}
