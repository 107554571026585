import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ResetDayAheadDealsReceivedInfo } from './sse-day-ahead.actions';
import { SseDayAheadState } from './sse-day-ahead.state';

@Injectable({
  providedIn: 'root'
})
export class SseDayAheadDealsFacade {
  dayAheadDealsReceivedInfo$ = this.store.select(SseDayAheadState.dayAheadDealsReceivedInfo);

  constructor(private store: Store) {}

  resetInfo(): void {
    this.store.dispatch(new ResetDayAheadDealsReceivedInfo());
  }
}
