import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { IncidentReserveNoticeDialogComponent } from './incident-reserve-notice-dialog/incident-reserve-notice-dialog.component';
import { AdjustmentDirection, MixinBase, OnDestroyMixin, OperationService, VIEW_ADMIN } from 'flex-app-shared';
import { MatDialog } from '@angular/material/dialog';
import { first, map, shareReplay, takeUntil } from 'rxjs/operators';
import { EditIncidentReserveContactInformationComponent } from './incident-reserve-contact-information/edit-incident-reserve-contact-information.component';
import { cloneDeep } from 'lodash-es';

@Component({
  selector: 'app-incident-reserve-notice',
  templateUrl: './incident-reserve-notice.component.html',
  styleUrls: ['./incident-reserve-notice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncidentReserveNoticeComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  public form: UntypedFormGroup;

  public readonly direction = AdjustmentDirection;

  public readonly operationType = {
    start: 'START',
    end: 'END'
  };
  private manualActivationData$ = this.operationService.manualActivationData$().pipe(shareReplay(1), takeUntil(this.onDestroy$));
  currentActiveIncidentReserveDirection$ = this.manualActivationData$.pipe(map((result) => result.currentActiveIncidentReserveDirection));
  contacts$ = this.manualActivationData$.pipe(map((result) => result.contacts));
  lastIncidentReserveNotificationTime$ = this.manualActivationData$.pipe(map((result) => result.lastIncidentReserveNotificationTime));

  VIEW_ADMIN = VIEW_ADMIN;

  constructor(
    private fb: UntypedFormBuilder,
    private location: Location,
    public dialog: MatDialog,
    private operationService: OperationService
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      direction: [this.direction.UPWARDS, Validators.required],
      operationType: [this.operationType.start, Validators.required]
    });
  }

  goBack(): void {
    this.location.back();
  }

  showConfirmPopup(): void {
    this.dialog.open(IncidentReserveNoticeDialogComponent, { data: { ...this.form.getRawValue() } });
  }

  openEditContacts(): void {
    this.manualActivationData$
      .pipe(first())
      .subscribe((data) => this.dialog.open(EditIncidentReserveContactInformationComponent, { data: cloneDeep(data.contacts) }));
  }
}
