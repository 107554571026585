<h3 mat-dialog-title>
  Message: {{ data.timestamp | date : 'dd-MM-yyyy HH:mm:ss.SSS' }} - {{ data.category }} - {{ data.type }} - {{ data.context }}
</h3>
<mat-dialog-content class="mat-typography overflow-auto">
  <p *ngIf="data.contentType === 'TEXT'">{{ data.text }}</p>
  <pre *ngIf="data.contentType !== 'TEXT'">{{ data.text }}</pre>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button mat-dialog-close>Close</button>
</mat-dialog-actions>
