import { Location } from '@angular/common';
import { AfterViewInit, Component, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NavigationEnd, Router } from '@angular/router';
import { MANAGE_USERS, MixinBase, ObservableInputsMixin, OnDestroyMixin } from 'flex-app-shared';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { UsersFacade } from '../../store/user/states/users/users.facade';
import { User } from '../shared/user/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends ObservableInputsMixin(OnDestroyMixin(MixinBase)) implements OnInit, AfterViewInit {
  MANAGE_USERS = MANAGE_USERS;
  readonly displayedColumnsDashboard = ['userName', 'active', 'name', 'nickName', 'actions'];
  readonly displayedColumnsStandalone = ['userName', 'active', 'name', 'nickName', 'tenant', 'lastBackendCall', 'actions'];
  readonly dataSource: MatTableDataSource<User> = new MatTableDataSource<User>([]);

  @ViewChild(MatPaginator, { static: false }) private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) private readonly sort: MatSort;

  @Input()
  public readonly defaultPageSize = 30;

  @Input()
  public readonly isStandalone = false;

  @Input()
  @HostBinding('class.is-dashboard')
  public readonly isDashboard = false;

  isDashboard$ = this.oi.observe(() => this.isDashboard);
  displayedColumns$ = this.isDashboard$.pipe(
    startWith(this.isDashboard),
    map((isDashboard) => {
      if (isDashboard) {
        return this.displayedColumnsDashboard;
      } else {
        return this.displayedColumnsStandalone;
      }
    })
  );
  busyDownloading$ = this.usersFacade.busyDownloading$;
  canDownload$ = this.usersFacade.canDownload$;

  constructor(private router: Router, private builder: UntypedFormBuilder, private usersFacade: UsersFacade, private location: Location) {
    super();
  }

  goBack(): void {
    this.location.back();
  }

  ngOnInit(): void {
    this.usersFacade.init();
    this.usersFacade.users$.pipe(takeUntil(this.onDestroy$)).subscribe((users) => {
      this.dataSource.data = users;
    });
    this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.usersFacade.init();
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }

  delete(user: User): void {
    this.usersFacade.delete(user);
  }

  downloadData(): void {
    this.usersFacade.downloadData();
  }
}
