<div>
  <section class="page-title">
    <form [formGroup]="formGroup">
      <mat-icon svgIcon="ic-old:dashboard"> </mat-icon>
      <div class="title-text">Dashboard of</div>
      <div>
        <ph-flex-autocomplete
          class="customer-input"
          *ngIf="!isForbidden"
          phFlexCustomerDataSource
          [shouldEmitNull]="true"
          [shouldOnlyEmitIds]="true"
          formControlName="customerId"
          [shouldAutoSelectIfOnlyOneResult]="true"
          placeholder="Select customer"
        ></ph-flex-autocomplete>
        <app-forbidden-message [isDisabled]="isForbidden"></app-forbidden-message>
      </div>
    </form>
  </section>

  <mat-toolbar color="primary" class="top-bar-menu with-spacing">
    <ng-container *ngIf="!isForbidden">
      <button mat-flat-button color="primary" routerLink="/customers/">
        <ph-flex-vertical-center [iconSpacing]="iconSpacing$ | async">
          <mat-icon svgIcon="ic-old:users-o"></mat-icon>
          <span>Customers</span>
        </ph-flex-vertical-center>
      </button>
      <mat-divider [vertical]="true"></mat-divider>
      <button mat-flat-button color="primary" routerLink="/grid-points/">
        <ph-flex-vertical-center [iconSpacing]="iconSpacing$ | async">
          <mat-icon svgIcon="ic-old:location"></mat-icon>
          <span>Gridpoints</span>
        </ph-flex-vertical-center>
      </button>
      <mat-divider [vertical]="true"></mat-divider>
      <button mat-flat-button color="primary" routerLink="/service-agreements/">
        <ph-flex-vertical-center [iconSpacing]="iconSpacing$ | async">
          <mat-icon svgIcon="ic-old:sign-o"></mat-icon>
          <span>Service Agreements</span>
        </ph-flex-vertical-center>
      </button>
      <mat-divider [vertical]="true"></mat-divider>
      <button mat-flat-button color="primary" routerLink="/controls/">
        <ph-flex-vertical-center [iconSpacing]="iconSpacing$ | async">
          <mat-icon svgIcon="ic-old:cogs"></mat-icon>
          <span>Controls</span>
        </ph-flex-vertical-center>
      </button>
      <mat-divider [vertical]="true"></mat-divider>
      <button mat-flat-button color="primary" routerLink="/devices/">
        <ph-flex-vertical-center [iconSpacing]="iconSpacing$ | async">
          <mat-icon svgIcon="ic-old:devices"></mat-icon>
          <span>Devices</span>
        </ph-flex-vertical-center>
      </button>
      <mat-divider [vertical]="true"></mat-divider>
      <button mat-flat-button color="primary" routerLink="/recipients/">
        <ph-flex-vertical-center [iconSpacing]="iconSpacing$ | async">
          <mat-icon svgIcon="ic-old:users-o"></mat-icon>
          <span>Recipients</span>
        </ph-flex-vertical-center>
      </button>
      <mat-divider [vertical]="true"></mat-divider>
      <button mat-flat-button color="primary" routerLink="/unavailabilities/">
        <ph-flex-vertical-center [iconSpacing]="iconSpacing$ | async">
          <mat-icon svgIcon="ic-old:circle-minus-o"></mat-icon>
          <span>Unavailabilities</span>
        </ph-flex-vertical-center>
      </button>
      <mat-divider [vertical]="true"></mat-divider>
      <button mat-flat-button color="primary" routerLink="/users/">
        <ph-flex-vertical-center [iconSpacing]="iconSpacing$ | async">
          <mat-icon svgIcon="ic-old:users-o"></mat-icon>
          <span>Users</span>
        </ph-flex-vertical-center>
      </button>
      <div class="spacer"></div>
      <button mat-flat-button color="accent" routerLink="/customer-stepper">
        <ph-flex-vertical-center [iconSpacing]="iconSpacing$ | async">
          <mat-icon svgIcon="ic-old:user-add"></mat-icon>
          <span>New customer</span>
        </ph-flex-vertical-center>
      </button>
    </ng-container>
  </mat-toolbar>
  <form [formGroup]="formGroup">
    <div *ngIf="selectedCustomer" class="customer-info">
      <div class="labeled-data">
        <span class="label">Legal Name:</span>
        <span>{{ selectedCustomer.legalName }}</span>
      </div>

      <div class="labeled-data">
        <span class="label">Billing ID:</span>
        <span>{{ selectedCustomer.billingId }}</span>
      </div>
    </div>
  </form>
</div>
