import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ImbalancePricesInfo, ImbalancePricesService } from '../../shared/imbalance-prices/imbalance-prices.service';
import { CalendarPeriod, DateFnsParseFormatters, normalizeToDate } from 'flex-app-shared';
import { format } from 'date-fns';

@Component({
  selector: 'app-imbalance-prices',
  templateUrl: './imbalance-prices.component.html',
  styleUrls: ['./imbalance-prices.component.scss']
})
export class ImbalancePricesComponent {
  retrievingPrices: boolean = false;
  imbalancePricesError: boolean = false;
  imbalancePricesResult: ImbalancePricesInfo;

  form = this.fb.group({
    period: CalendarPeriod.toCalendarPeriod(new Date(), new Date())
  });

  constructor(
    private fb: UntypedFormBuilder,
    private imbalancePricesService: ImbalancePricesService
  ) {}

  getImbalancePrices(): void {
    this.retrievingPrices = true;
    this.imbalancePricesResult = null;
    this.imbalancePricesError = false;
    this.imbalancePricesService.retrievePrices(this.form.get('period').value).subscribe(
      (result) => {
        this.imbalancePricesResult = result;
        this.retrievingPrices = false;
      },
      (error) => {
        this.retrievingPrices = false;
        this.imbalancePricesError = true;
        this.imbalancePricesResult = { daysRetrieved: 0, errorMessage: error.error.message };
      }
    );
  }
}
