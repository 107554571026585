import { Injectable } from '@angular/core';
import { UnavailabilitiesState } from './unavailabilities.state';
import { filter, first, switchMap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { DeleteUnavailabilityCommand, LoadUnavailabilitiesCommand } from './unavailabilities.actions';
import { UnavailabilityView } from '../../../../app/shared/unavailability/unavailability';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UnavailabilitiesFacade {
  constructor(private store: Store) {}

  unavailabilities$ = this.store.select(UnavailabilitiesState.isBusy).pipe(
    filter((a) => !a),
    first(),
    switchMap(() => this.store.select(UnavailabilitiesState.getUnavailabilities))
  );

  delete(unavailability: UnavailabilityView): Observable<any> {
    return this.store.dispatch(new DeleteUnavailabilityCommand(unavailability));
  }

  init(): void {
    this.store.dispatch(new LoadUnavailabilitiesCommand());
  }
}
