import { Injectable } from '@angular/core';
import { filter, switchMap } from 'rxjs/operators';
import { PoolState } from './pool.state';
import { Store } from '@ngxs/store';
import { LoadPoolAvailabilitiesCommand, SavePoolCommand, SendPoolCommand } from './pool.actions';
import { DatePeriodView } from 'flex-app-shared';

@Injectable({
  providedIn: 'root'
})
export class PoolFacade {
  constructor(private store: Store) {}

  pool$ = this.store.select(PoolState.isBusy).pipe(
    filter((a) => !a),
    switchMap(() => this.store.select(PoolState.getPool))
  );

  gridPoints$ = this.store.select(PoolState.isInitialized).pipe(
    filter((a) => a),
    switchMap(() => this.store.select(PoolState.getAvailableGridPoints))
  );

  dirty$ = this.store.select(PoolState.isDirty);

  save(): void {
    this.store.dispatch(new SavePoolCommand());
  }

  initPoolPeriod(period: DatePeriodView): void {
    this.store.dispatch(new LoadPoolAvailabilitiesCommand(period));
  }

  sendPool(): void {
    this.store.dispatch(new SendPoolCommand());
  }
}
