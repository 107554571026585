<mat-card>
  <mat-card-header>
    <mat-card-title>Battery details</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="batteryForm">
      <mat-form-field>
        <mat-label>External Id</mat-label>
        <mat-select formControlName="externalId">
          <mat-option *ngFor="let batteryId of batteryIds" [value]="batteryId">
            {{ batteryId }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="batteryForm.get('externalId').errors?.required"> External Id is required. </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" />
        <mat-error *ngIf="batteryForm.get('name').errors?.required"> Name is required. </mat-error>
        <mat-error *ngIf="batteryForm.get('name').errors?.maxlength"> Name must not be longer than 250 characters. </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Description</mat-label>
        <input matInput formControlName="description" />
        <mat-error *ngIf="batteryForm.get('description').errors?.maxlength">
          Description must not be longer than 500 characters.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Capacity</mat-label>
        <ph-flex-number-input formControlName="capacity"></ph-flex-number-input>
        <span matTextSuffix>kWh</span>
        <mat-error *ngIf="batteryForm.get('capacity').errors?.min"> Capacity must be at least 0 kWh. </mat-error>
        <mat-error *ngIf="batteryForm.get('capacity').errors?.max"> Capacity can not be more than 100.000 kWh. </mat-error>
        <mat-error *ngIf="batteryForm.get('capacity').errors?.invalidNumber"> Not a valid number </mat-error>
        <mat-error *ngIf="batteryForm.get('capacity').errors?.invalidNumberOfDecimals"> No decimals are allowed </mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Maximum power</mat-label>
        <ph-flex-number-input formControlName="maximumPower"></ph-flex-number-input>
        <span matTextSuffix>kW</span>
        <mat-error *ngIf="batteryForm.get('maximumPower').errors?.min"> Capacity must be at least 0 kW. </mat-error>
        <mat-error *ngIf="batteryForm.get('maximumPower').errors?.max"> Capacity can not be more than 100.000 kW. </mat-error>
        <mat-error *ngIf="batteryForm.get('maximumPower').errors?.invalidNumber"> Not a valid number </mat-error>
        <mat-error *ngIf="batteryForm.get('maximumPower').errors?.invalidNumberOfDecimals"> No decimals are allowed </mat-error>
      </mat-form-field>
    </form>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button (click)="goBack()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
        <span>Go back</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="cta" (click)="onSubmit()" [disabled]="!batteryForm.valid">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:save"></mat-icon>
        <span>Save</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
