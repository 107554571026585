import { MessageType } from 'flex-app-shared';

export class SseTsoMonthlyPoolUpdateFailedEvent {
  public static readonly type = '[SSE] Tso monthly pool update failed';
  public static readonly sseType = MessageType.TsoMonthlyPoolUpdateFailedSseEvent;
}

export class SseTsoMonthlyPoolUpdatedEvent {
  public static readonly type = '[SSE] Tso monthly pool updated successfully';
  public static readonly sseType = MessageType.TsoMonthlyPoolUpdatedSseEvent;
}
