<mat-card>
  <mat-card-content>
    <mat-toolbar class="filter-bar">
      <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
    </mat-toolbar>

    <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
      <ng-container matColumnDef="externalId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>External ID</th>
        <td mat-cell *matCellDef="let battery">
          <a routerLink="/batteries/{{ battery.id }}">{{ battery.externalId }}</a>
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let battery">{{ battery.name }}</td>
      </ng-container>
      <ng-container matColumnDef="capacity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Capacity</th>
        <td mat-cell *matCellDef="let battery">{{ battery.capacity?.value }} kWh</td>
      </ng-container>
      <ng-container matColumnDef="maximumPower">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Power</th>
        <td mat-cell *matCellDef="let battery">
          <ng-container *ngIf="battery.maximumPower?.value">{{ battery.maximumPower | capacity: 'kW' }}</ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let battery">
          <ph-flex-vertical-center>
            <button mat-icon-button matTooltip="Edit" routerLink="/batteries/{{ battery.id }}">
              <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
            </button>
            <button mat-icon-button matTooltip="FCR periods" routerLink="/batteries/{{ battery.id }}/fcr-periods">
              <mat-icon svgIcon="ic-old:settings"></mat-icon>
            </button>
            <button mat-icon-button class="remove" (click)="delete(battery)">
              <mat-icon svgIcon="ic-old:trash-o"></mat-icon>
            </button>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
  </mat-card-content>
  <mat-card-actions>
    <button mat-flat-button color="primary" routerLink="/batteries/new">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:add"></mat-icon>
        <span>New Battery</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>

<ng-template let-dialogRef="dialogRef" #confirmDeleteDialog>
  <mat-dialog-content>
    <strong>Are you sure you want to delete this battery?</strong>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button (click)="dialogRef.close()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:close"></mat-icon>
        <span>Cancel</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="warn" (click)="dialogRef.close(true)">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:trash"></mat-icon>
        <span>Delete</span>
      </ph-flex-vertical-center>
    </button>
  </mat-dialog-actions>
</ng-template>
