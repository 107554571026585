import { DeviceContact } from '../../../../app/shared/device/device.service';

export class ResetDeviceContactCommand {
  public static readonly type = 'Device Contact] Reset control unit action';
}

export class LoadDeviceContactCommand {
  public static readonly type = '[Device Contact] Load device contact data';

  constructor(public deviceId: string, public deviceContactId: string) {}
}

export class DeviceContactLoadedEvent {
  public static readonly type = '[Device Contact] Device contact loaded event';

  constructor(public payload: DeviceContact) {}
}

export class SaveDeviceContactCommand {
  public static readonly type = '[Device Contact] Save device contact data';
}

export class DeviceContactDialogOpenedEvent {
  public static readonly type = '[Device Contact] Device contact dialog opened';

  constructor(public dialogId: string, public deviceId?: string, public deviceContactId?: string, public invertedSchedules?: boolean) {}
}

export class CloseDeviceContactDialogCommand {
  public static readonly type = '[Device Contact] Close Device contact dialog';
}

export class DeviceIdSelectedEvent {
  public static readonly type = '[Device Contact] Current DeviceId selected';

  constructor(public deviceId: string) {}
}
