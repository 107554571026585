import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { filter, first } from 'rxjs/operators';
import { DeviceContactFacade } from '../../store/devices/states/device-contact/device-contact.facade';

@Component({
  selector: 'app-contacts-dialog',
  templateUrl: './contacts-dialog.component.html',
  styleUrls: ['./contacts-dialog.component.scss']
})
export class ContactsDialogComponent implements OnInit {
  form = this.formBuilder.group({
    id: '',
    deviceId: '',
    description: ['', Validators.required],
    externalId: ['', Validators.maxLength(10)],
    invertedSchedules: false,
    switchCooldownTime: 0
  });

  deviceContact$ = this.deviceContactFacade.deviceContact$;
  isEdit$ = this.deviceContactFacade.isEdit$;

  constructor(private formBuilder: UntypedFormBuilder, public deviceContactFacade: DeviceContactFacade) {}

  ngOnInit(): void {
    this.form.disable();

    this.deviceContact$.subscribe((deviceContact) => {
      if (deviceContact.id) {
        this.form.get('externalId').setValidators([Validators.required, Validators.maxLength(10)]);
      }
      this.form.patchValue(deviceContact);
      this.form.enable();
    });

    this.isEdit$
      .pipe(
        filter((a) => !a),
        first()
      )
      .subscribe(() => this.form.enable());
  }

  onSubmit(): void {
    this.deviceContactFacade.save();
  }

  close(): void {
    this.deviceContactFacade.closeDialog();
  }
}
