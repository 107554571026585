import { Component, OnInit } from '@angular/core';
import { DayAheadPricesInfo } from '../../shared/deal/dayahead/day-ahead-prices-info';
import { DayAheadServiceService } from '../../shared/deal/dayahead/day-ahead-service.service';
import { UntypedFormBuilder } from '@angular/forms';
import { CalendarPeriod } from 'flex-app-shared';

@Component({
  selector: 'app-day-ahead-prices',
  templateUrl: './day-ahead-prices.component.html',
  styleUrls: ['./day-ahead-prices.component.scss']
})
export class DayAheadPricesComponent {
  retrievingPrices: boolean = false;
  dayAheadPricesError: boolean = false;
  dayAheadPricesResult: DayAheadPricesInfo;

  form = this.fb.group({
    period: CalendarPeriod.toCalendarPeriod(new Date(), new Date())
  });

  constructor(
    private fb: UntypedFormBuilder,
    private dayAheadDealService: DayAheadServiceService
  ) {}

  getDayAheadPrices(): void {
    this.retrievingPrices = true;
    this.dayAheadPricesResult = null;
    this.dayAheadPricesError = false;
    this.dayAheadDealService.retrieveDayAheadPrices(this.form.get('period').value).subscribe(
      (result) => {
        this.dayAheadPricesResult = result;
        this.retrievingPrices = false;
      },
      (error) => {
        this.retrievingPrices = false;
        this.dayAheadPricesError = true;
        this.dayAheadPricesResult = { daysRetrieved: 0, errorMessage: error.error.message };
      }
    );
  }
}
