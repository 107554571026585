import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdjustmentDirection, OperationService } from 'flex-app-shared';
import { Observable } from 'rxjs';

export interface IncidentReserveNoticeDialogData {
  readonly direction: AdjustmentDirection;
  readonly operationType: string;
}

@Component({
  selector: 'app-incident-reserve-notice',
  templateUrl: './incident-reserve-notice-dialog.component.html',
  styleUrls: ['./incident-reserve-notice-dialog.component.scss']
})
export class IncidentReserveNoticeDialogComponent {
  public adjustmentDirection = AdjustmentDirection;
  pending = false;

  constructor(
    public dialogRef: MatDialogRef<IncidentReserveNoticeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IncidentReserveNoticeDialogData,
    private operationService: OperationService,
    private snackBar: MatSnackBar
  ) {}

  cancel(): void {
    this.pending = false;
    this.dialogRef.close();
  }

  confirm(): void {
    this.pending = true;
    const { direction, operationType } = this.data;

    let obs$: Observable<any> | undefined;
    if (operationType === 'START') {
      obs$ = this.operationService.manuallyStartOperation({ direction });
    } else {
      obs$ = this.operationService.manuallyEndOperation();
    }

    obs$.subscribe(
      (v) => {
        this.cancel();
        this.snackBar.open('Registered', null, { duration: 3000 });
      },
      (err) => {
        this.cancel();
        this.snackBar.open(
          `Er is iets fout gegaan. Neem a.u.b. onmiddelijk contact op met een medewerker van Powerhouse: ${err.error.message}`,
          'OK'
        );
      }
    );
  }
}
