<mat-card>
  <mat-card-content>
    <mat-toolbar class="filter-bar">
      <form [formGroup]="form">
        <div class="ph-flex-mat-form-field-group-inline compact">
          <mat-form-field phFlexDateAsMonth class="ph-flex-mat-form-field-slim">
            <mat-label>Month</mat-label>
            <input matInput [matDatepicker]="selectedDateDatepicker" formControlName="selectedDate" />
            <mat-datepicker-toggle matSuffix [for]="selectedDateDatepicker"></mat-datepicker-toggle>
            <mat-datepicker
              #selectedDateDatepicker
              startView="year"
              (monthSelected)="chosenDateHandler($event, selectedDateDatepicker)"
              panelClass="datepicker-disable-control-button datepicker-month"
            ></mat-datepicker>
          </mat-form-field>
          <mat-form-field phFlexDateAsMonth class="ph-flex-mat-form-field-slim">
            <mat-label>Direction</mat-label>
            <mat-select formControlName="direction">
              <mat-option [value]="AdjustmentDirection.UPWARDS">Upwards</mat-option>
              <mat-option [value]="AdjustmentDirection.DOWNWARDS">Downwards</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
      <ph-flex-table-filter placeholder="Search"></ph-flex-table-filter>
      <div class="minimize">
        <ph-flex-save-button
          [pending]="facade.busyDownloading$ | async"
          (clickChanged)="facade.downloadData()"
          [disabled]="!(facade.canDownload$ | async)"
          type="stroked"
          color="secondary"
        >
          <ph-flex-vertical-center>
            <mat-icon svgIcon="ic-old:download"></mat-icon>
            <span>Download invoice data</span>
          </ph-flex-vertical-center>
        </ph-flex-save-button>
        <button mat-flat-button color="primary" [disabled]="!(facade.canRecalculate$ | async)" (click)="openConfirmDialog()">
          <ph-flex-vertical-center>
            <mat-icon svgIcon="ic-old:calc"></mat-icon>
            <span>Recalculate</span>
          </ph-flex-vertical-center>
        </button>
      </div>
    </mat-toolbar>
    <ph-flex-loading-context [isLoading]="facade.busy$ | async">
      <div class="overflow-table">
        <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
          <ng-container matColumnDef="customerName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer name</th>
            <td mat-cell *matCellDef="let row">{{ row.customerName }}</td>
          </ng-container>
          <ng-container matColumnDef="customerLegalName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer legal name</th>
            <td mat-cell *matCellDef="let row">{{ row.customerLegalName }}</td>
          </ng-container>
          <ng-container matColumnDef="customerBillingId">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer billing id</th>
            <td mat-cell *matCellDef="let row">{{ row.customerBillingId }}</td>
          </ng-container>
          <ng-container matColumnDef="serviceAgreementSystemReference">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>SA system reference</th>
            <td mat-cell *matCellDef="let row">
              {{ row.serviceAgreementSystemReference }}
            </td>
          </ng-container>
          <ng-container *ngFor="let day of dateColumns$ | async; let i = index" matColumnDef="{{ day }}">
            <th mat-header-cell *matHeaderCellDef>{{ day }}</th>
            <td mat-cell *matCellDef="let row">
              {{ row.days[day - 1] | money }}
            </td>
          </ng-container>
          <ng-container matColumnDef="recalculate">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row">
              <ph-flex-vertical-center>
                <button mat-icon-button (click)="openConfirmDialog(row)" [disabled]="!(facade.canRecalculate$ | async)">
                  <mat-icon svgIcon="ic-old:calc"></mat-icon>
                </button>
              </ph-flex-vertical-center>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns$ | async"></tr>
        </table>
      </div>
      <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
    </ph-flex-loading-context>
  </mat-card-content>
</mat-card>
