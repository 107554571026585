import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { RecipientFacade } from '../../store/recipients/states/recipient/recipient.facade';
import { first, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isNil } from 'lodash-es';
import { ValidatorUtil } from '../shared/common/common';
import { CustomValidators } from 'flex-app-shared';

export const internationalPhoneNumberValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  if (control.value && !new RegExp('^\\+[1-9]\\d{1,14}$').test(control.value) && !control.value.startsWith('06')) {
    return {
      invalidInternationalPhoneNumber: true
    };
  }
};

@Component({
  selector: 'app-recipient-detail',
  templateUrl: './recipients-detail.component.html',
  styleUrls: ['./recipients-detail.component.scss']
})
export class RecipientsDetailComponent implements OnInit, OnDestroy {
  readonly pronouns: ReadonlyArray<string> = ['Mr', 'Ms'];
  recipientForm = this.builder.group({
    id: '',
    pronoun: ['', Validators.required],
    firstName: ['', [Validators.required, Validators.maxLength(50)]],
    lastNamePrefix: ['', [Validators.maxLength(50)]],
    lastName: ['', [Validators.required, Validators.maxLength(50)]],
    mobileNumber: [
      '',
      [
        CustomValidators.conditionalRequired(() => this.recipientForm && this.recipientForm.get('useMobileNumber').value),
        internationalPhoneNumberValidator
      ]
    ],
    email: [
      '',
      [CustomValidators.conditionalRequired(() => this.recipientForm && this.recipientForm.get('useEmail').value), Validators.email]
    ],
    useMobileNumber: [true],
    useEmail: [false],
    isEmployee: [false],
    isMonthlyParticipationEmail: [false],
    customerId: ['']
  });
  private readonly ngUnsubscribe = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private builder: UntypedFormBuilder,
    public recipientFacade: RecipientFacade
  ) {}

  private static setContractIdControlState(recipientForm: UntypedFormGroup, isEmployee: boolean): void {
    const customerIdControl = recipientForm.get('customerId');
    if (isEmployee) {
      customerIdControl.reset();
      customerIdControl.disable();
      customerIdControl.setValidators(ValidatorUtil.notRequired(customerIdControl.validator));
    } else {
      customerIdControl.enable();
      customerIdControl.setValidators(ValidatorUtil.required(customerIdControl.validator));
    }
    customerIdControl.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.subscribeIsEmployeeField();

    // TODO this entire block can be removed if @ngxs/forms is used
    this.recipientFacade.recipient$.pipe(first()).subscribe((recipient) => {
      this.recipientForm.patchValue(recipient);
    });

    this.recipientFacade.isDisabled$
      .pipe(withLatestFrom(this.recipientFacade.recipient$), takeUntil(this.ngUnsubscribe))
      .subscribe(([isDisabled, recipient$]) => {
        if (this.recipientForm) {
          if (isDisabled) {
            this.recipientForm.disable();
          } else {
            this.recipientForm.enable();
          }
          RecipientsDetailComponent.setContractIdControlState(this.recipientForm, recipient$.isEmployee);
        }
      });
  }

  onSubmit(): void {
    this.recipientFacade.save({
      ...this.recipientForm.value,
      lastNamePrefix: this.emptyStringAsNull(this.recipientForm.value.lastNamePrefix),
      mobileNumber: this.emptyStringAsNull(this.formatMobileNumber(this.recipientForm.value.mobileNumber)),
      email: this.emptyStringAsNull(this.recipientForm.value.email)
    });
  }

  goBack(): void {
    this.location.back();
  }

  isDutchLocalNumber(): boolean {
    const { mobileNumber } = this.recipientForm.getRawValue();
    return String(mobileNumber).startsWith('06');
  }

  getPhonePreview(): string {
    const { mobileNumber } = this.recipientForm.getRawValue();
    if (isNil(mobileNumber)) return '';

    return String(mobileNumber).replace(/\s?-?/g, '').replace(/^06/, '+316');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  formatMobileNumber(value: string): string {
    if (this.isDutchLocalNumber()) {
      return value.replace(/\s?-?/g, '').replace(/^06/, '+316');
    }
    return value;
  }

  private subscribeIsEmployeeField(): void {
    this.recipientForm
      .get('isEmployee')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((isEmployee) => RecipientsDetailComponent.setContractIdControlState(this.recipientForm, isEmployee));
    this.recipientForm
      .get('useMobileNumber')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.recipientForm.get('mobileNumber').updateValueAndValidity());
    this.recipientForm
      .get('useEmail')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => this.recipientForm.get('email').updateValueAndValidity());
  }

  private emptyStringAsNull(value: string): string {
    if (String(value).trim().length === 0) {
      return null;
    }
    return value;
  }
}
