import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Recipient } from '../shared/recipient/recipient';
import { RecipientsFacade } from '../../store/recipients/states/recipients/recipients.facade';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-recipients',
  templateUrl: './recipients.component.html'
})
export class RecipientsComponent implements OnDestroy, OnInit, AfterViewInit {
  displayedColumns: ReadonlyArray<string> = [
    'pronoun',
    'firstName',
    'lastNamePrefix',
    'lastName',
    'mobileNumber',
    'email',
    'isEmployee',
    'isMonthlyParticipationEmail',
    'actions'
  ];
  readonly dataSource: MatTableDataSource<Recipient> = new MatTableDataSource<Recipient>([]);

  @ViewChild(MatPaginator, { static: false }) private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) private readonly sort: MatSort;

  private readonly ngUnsubscribe = new Subject<void>();

  @Input()
  public readonly defaultPageSize: number = 30;

  @Input()
  public readonly isStandalone: boolean = false;

  @Input()
  public readonly isDashboard: boolean = false;

  constructor(private router: Router, private recipientsFacade: RecipientsFacade, private location: Location) {}

  goBack(): void {
    this.location.back();
  }

  ngOnInit(): void {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.recipientsFacade.init();
      }
    });
    if (this.isDashboard) {
      this.displayedColumns = this.displayedColumns.filter((it) => it !== 'pronoun');
      this.displayedColumns = this.displayedColumns.filter((it) => it !== 'lastNamePrefix');
      this.displayedColumns = this.displayedColumns.filter((it) => it !== 'email');
    }
  }

  delete(recipient: Recipient): void {
    this.recipientsFacade.delete(recipient);
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {
    this.recipientsFacade.init();
    this.recipientsFacade.recipients$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((recipients) => {
      this.dataSource.data = recipients;
    });
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
