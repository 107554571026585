import { State, Store } from '@ngxs/store';
import { AsyncMessagingService } from 'flex-app-shared';
import { SseTsoMonthlyPoolUpdatedEvent, SseTsoMonthlyPoolUpdateFailedEvent } from './sse-pool.actions';
import { LoadPoolCommand } from '../../../pool/states/pool/pool.actions';
import { Injectable } from '@angular/core';

@State({
  name: 'ssePool',
  defaults: {}
})
@Injectable({
  providedIn: 'root'
})
export class SsePoolState {
  constructor(private asyncMessagingService: AsyncMessagingService, private store: Store) {
    asyncMessagingService.onMessageOfType(SseTsoMonthlyPoolUpdatedEvent.sseType).subscribe(() => {
      store.dispatch(new SseTsoMonthlyPoolUpdatedEvent());
      store.dispatch(new LoadPoolCommand());
    });

    asyncMessagingService.onMessageOfType(SseTsoMonthlyPoolUpdateFailedEvent.sseType).subscribe(() => {
      store.dispatch(new SseTsoMonthlyPoolUpdateFailedEvent());
    });
  }
}
