import { Component, OnInit } from '@angular/core';
import { Device, DeviceProviderType } from '../../shared/device/device.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { GridPointFacade } from '../../../store/grid-points/grid-point.facade';
import { merge, Observable } from 'rxjs';
import { CustomValidators, DEFAULT_PULSE_WEIGHT, FnErrorMatcher, MixinBase, OnDestroyMixin } from 'flex-app-shared';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pulse-measurement-configuration',
  templateUrl: './pulse-measurement-configuration.component.html',
  styleUrls: ['./pulse-measurement-configuration.component.scss']
})
export class PulseMeasurementConfigurationComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  customerDevices$: Observable<readonly Device[]>;
  pulseConsumptionRequired = false;
  pulseConsumptionErrorStateMatcher = new FnErrorMatcher(() => this.pulseConsumptionRequired);
  pulseProductionRequired = false;
  pulseProductionErrorStateMatcher = new FnErrorMatcher(() => this.pulseProductionRequired);
  form = this.builder.group({
    pulseEnabled: false,
    pulseDeviceId: [null, [Validators.required]],
    pulseConsumptionChannelName: ['', [CustomValidators.conditionalRequired(() => this.pulseConsumptionRequired)]],
    pulseConsumptionWeight: [
      '',
      [
        CustomValidators.conditionalRequired(() => this.pulseConsumptionRequired),
        CustomValidators.minExclusive(0),
        Validators.max(100),
        CustomValidators.numberOfDecimals(0, 3)
      ]
    ],
    pulseConsumptionPeak: this.builder.group({
      value: [
        '',
        [
          CustomValidators.conditionalRequired(() => this.pulseConsumptionRequired),
          CustomValidators.numberOfDecimals(0, 3),
          Validators.min(0)
        ]
      ],
      unit: 'kW'
    }),
    pulseConsumptionDataAvailableForLastHour: { value: false, disabled: true },
    pulseProductionChannelName: ['', [CustomValidators.conditionalRequired(() => this.pulseProductionRequired)]],
    pulseProductionWeight: [
      '',
      [
        CustomValidators.conditionalRequired(() => this.pulseProductionRequired),
        CustomValidators.minExclusive(0),
        Validators.max(100),
        CustomValidators.numberOfDecimals(0, 3)
      ]
    ],
    pulseProductionPeak: this.builder.group({
      value: [
        '',
        [
          CustomValidators.conditionalRequired(() => this.pulseProductionRequired),
          CustomValidators.numberOfDecimals(0, 3),
          Validators.min(0)
        ]
      ],
      unit: 'kW'
    }),
    pulseProductionDataAvailableForLastHour: { value: false, disabled: true }
  });

  constructor(private builder: UntypedFormBuilder, private gridPointFacade: GridPointFacade) {
    super();
  }

  ngOnInit(): void {
    this.gridPointFacade.initDevices();
    this.customerDevices$ = this.gridPointFacade
      .getAvailablePulseDevices()
      .pipe(map((devices) => devices.filter((device) => device.providerType === DeviceProviderType.SERCOM)));

    this.registerValueChangesListener(
      'pulseConsumptionRequired',
      'pulseConsumptionChannelName',
      'pulseConsumptionWeight',
      'pulseConsumptionPeak'
    );
    this.registerValueChangesListener(
      'pulseProductionRequired',
      'pulseProductionChannelName',
      'pulseProductionWeight',
      'pulseProductionPeak'
    );

    this.form
      .get('pulseEnabled')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.togglePulseData();
      });
  }

  togglePulseData(): void {
    Object.keys(this.form.controls)
      .filter(
        (key) => !['pulseConsumptionDataAvailableForLastHour', 'pulseEnabled', 'pulseProductionDataAvailableForLastHour'].includes(key)
      )
      .forEach((key) => {
        const control = this.form.controls[key];
        if (this.form.get('pulseEnabled').value) {
          control.enable();
        } else {
          control.disable();
        }
      });
  }

  private registerValueChangesListener(requiredKey: string, channelNameKey: string, weightKey: string, peakKey: string): void {
    merge(this.form.get(channelNameKey).valueChanges, this.form.get(weightKey).valueChanges)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        const channelName = this.form.get(channelNameKey).value;
        const weight = this.form.get(weightKey).value;
        const peak = this.form.get(peakKey).value;

        const weightHasValue = weight && weight !== DEFAULT_PULSE_WEIGHT;

        this[requiredKey] = channelName || weightHasValue || isNaN(weight) || isNaN(peak);
        this.form.get(channelNameKey).updateValueAndValidity({ emitEvent: false });
        this.form.get(weightKey).updateValueAndValidity({ emitEvent: false });
        this.form.get(peakKey).updateValueAndValidity({ emitEvent: false });
      });
  }
}
