<mat-card>
  <mat-card-header>
    <mat-card-title>Incident reserve unprocessed invoice results email</mat-card-title>
  </mat-card-header>
  <mat-card-actions class="day-ahead-market-actions">
    <ph-flex-date-range-fixed-period-month
      label="Select month"
      [control]="currentMonthPeriodControl"
      subscriptSizing="dynamic"
    ></ph-flex-date-range-fixed-period-month>

    <button mat-stroked-button (click)="sendEmails()">
      <ph-flex-vertical-center *ngIf="result && !error" class="status-success">
        <mat-icon svgIcon="ic-old:check-circle-o"></mat-icon>
        <span> Sent {{ result.successfullySent }} emails</span>
      </ph-flex-vertical-center>
      <ph-flex-vertical-center *ngIf="!result && !sending && !error"
        ><mat-icon svgIcon="ic-old:source-external"></mat-icon>
        <span>Send emails for unprocessed invoice results</span>
      </ph-flex-vertical-center>
      <ph-flex-vertical-center *ngIf="sending" class="spinner-button">
        <mat-spinner diameter="19"></mat-spinner>
        <span>Sending emails...</span>
      </ph-flex-vertical-center>
      <ph-flex-vertical-center *ngIf="error" matTooltip="{{ error | json }}" class="status-alert">
        <mat-icon svgIcon="ic-old:cancel-circle-o"></mat-icon>
        <span>Error sending emails!</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>
