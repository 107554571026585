import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Battery, BatteryService } from '../shared/battery/battery.service';
import { get } from 'lodash-es';
import { MatDialog } from '@angular/material/dialog';
import { Capacity } from 'flex-app-shared';

@Component({
  selector: 'app-batteries',
  templateUrl: './batteries.component.html',
  styleUrls: ['./batteries.component.scss']
})
export class BatteriesComponent implements OnInit {
  readonly displayedColumns: ReadonlyArray<string> = ['externalId', 'name', 'capacity', 'maximumPower', 'actions'];
  readonly dataSource: MatTableDataSource<Battery> = new MatTableDataSource<Battery>([]);

  @ViewChild(MatPaginator, { static: true }) private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

  @ViewChild('confirmDeleteDialog', { static: true })
  confirmDeleteDialogTemplate: TemplateRef<any>;

  constructor(private batteryService: BatteryService, public matDialog: MatDialog) {}

  ngOnInit(): void {
    this.getAll();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'capacity':
          return data.capacity.value;
        case 'maximumPower':
          return Capacity.asKW(data.maximumPower);
        default:
          return get(data, sortHeaderId);
      }
    };
  }

  getAll(): void {
    this.batteryService.getAll().subscribe((batteries) => (this.dataSource.data = batteries));
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }

  delete(battery: Battery): void {
    this.matDialog
      .open(this.confirmDeleteDialogTemplate)
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.batteryService.delete(battery).subscribe(() => this.getAll());
        }
      });
  }
}
