import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { UnavailabilityState } from './unavailability.state';
import { UnavailabilityView } from '../../../../app/shared/unavailability/unavailability';
import { SaveUnavailabilityCommand } from './unavailability.actions';
import { UpdateCustomersCommand } from '../../../customers/states/customers/customers.actions';
import { ServiceAgreementsState } from '../../../service-agreements/states/service-agreements/service-agreements.state';
import { FindServiceAgreementsByCustomerIdAndPeriodCommand } from '../../../service-agreements/states/service-agreements/service-agreements.actions';
import { combineLatest } from 'rxjs';

import { EntityFacade, PeriodView } from 'flex-app-shared';

@Injectable({
  providedIn: 'root'
})
export class UnavailabilityFacade implements EntityFacade {
  unavailability$ = this.store.select(UnavailabilityState.isInitialized).pipe(
    filter((a) => a),
    first(),
    switchMap(() => this.store.select(UnavailabilityState.getUnavailability))
  );

  serviceAgreements$ = this.store.select(ServiceAgreementsState.isBusy).pipe(
    filter((a) => !a),
    first(),
    switchMap(() => this.store.select(ServiceAgreementsState.getIncidentReserveServiceAgreements))
  );

  isDisabled$ = combineLatest([this.store.select(UnavailabilityState.isBusy), this.store.select(ServiceAgreementsState.isBusy)]).pipe(
    map(([a, b]) => {
      return a || b;
    })
  );
  saveButtonPending$ = this.store.select(UnavailabilityState.isBusySending);
  saveButtonError$ = this.store.select(UnavailabilityState.error);

  constructor(private store: Store) {}

  init(): void {
    this.store.dispatch(new UpdateCustomersCommand());
    this.store.dispatch(new FindServiceAgreementsByCustomerIdAndPeriodCommand());
  }

  save(unavailability: UnavailabilityView): void {
    this.store.dispatch(new SaveUnavailabilityCommand(unavailability));
  }

  findServiceAgreements({ customerId, period }: { customerId: string; period: PeriodView }): void {
    this.store.dispatch(new FindServiceAgreementsByCustomerIdAndPeriodCommand(customerId, period));
  }
}
