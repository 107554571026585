import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { get } from 'lodash-es';
import moment from 'moment';
import { OperationFacade } from '../../store/operations/states/operation/operation.facade';
import { Capacity, DateAndTime, MixinBase, OnDestroyMixin, OperationPoolGridPointView } from 'flex-app-shared';
import { takeUntil } from 'rxjs/operators';
import { AvailabilityType } from '../../../../flex-app-shared/src/lib/api-private/service-agreement/service-agreement';

@Component({
  selector: 'app-operation-pool',
  templateUrl: './operation-pool.component.html',
  styleUrls: ['./operation-pool.component.scss']
})
export class OperationPoolComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  displayedColumns = [
    'customerName',
    'ean',
    'gridPointDescription',
    'measurementCompanyName',
    'startDateTime',
    'toDateTime',
    'availabilityType',
    'gridPointPower',
    'measurementDataStatus'
  ];
  dataSource: MatTableDataSource<OperationPoolGridPointView> = new MatTableDataSource<OperationPoolGridPointView>([]);
  buttonSendToTennetDisabled = false;
  buttonRetrieveMeasurementsDisabled = false;
  operationHasPool$ = this.operationFacade.operationHasPool$;

  AvailabilityType = AvailabilityType;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private location: Location, private operationFacade: OperationFacade) {
    super();
  }

  ngOnInit(): void {
    this.operationFacade.operationPool$.pipe(takeUntil(this.onDestroy$)).subscribe((operationPool) => {
      this.dataSource.data = operationPool;
    });
    this.operationFacade.isMeasurementRetrievalBusy$.pipe(takeUntil(this.onDestroy$)).subscribe((disabled) => {
      this.buttonRetrieveMeasurementsDisabled = disabled;
    });
    this.operationFacade.operation$.pipe(takeUntil(this.onDestroy$)).subscribe((operation) => {
      this.buttonSendToTennetDisabled =
        operation.initialPoolSentSuccessfully ||
        (operation.period && moment().isAfter(DateAndTime.toMoment(operation.period.toDateTime).add(15, 'minutes'))) ||
        operation.id === null;
    });
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'ean':
          return data.gridPointEan;
        case 'startDateTime':
          return data.period.startDateTime.date;
        case 'toDateTime':
          return data.period.toDateTime.date;
        case 'gridPointPower':
          return Capacity.asMW(data.power);
        default:
          return get(data, sortHeaderId);
      }
    };
  }

  goBack(): void {
    this.location.back();
  }

  sendToTennet(): void {
    this.buttonSendToTennetDisabled = true;
    this.operationFacade.sendToTennet();
  }

  retrieveMeasurements(): void {
    this.operationFacade.retrieveMeasurements();
  }

  downloadData(): void {
    this.operationFacade.downloadOperationPoolData();
  }
}
