import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClearDefaultCustomerGuardService, DefaultCustomerGuardService, DummyComponent, LandingPageService } from 'flex-app-shared';
import {
  ActivateMeasurementDataUploadState,
  DeactivateMeasurementDataUploadState
} from '../store/measurement-data-upload/states/route-active';
import { ActivateTsoAuctionsState, DeactivateTsoAuctionsState } from '../store/tso-auctions/route-active';
import { AdminComponent } from './admin/admin.component';
import { AggregatedPoolComponent } from './aggregated-pool/aggregated-pool.component';
import { AnalogInputDetailComponent } from './analog-input-detail/analog-input-detail.component';
import { AnalogInputsComponent } from './analog-inputs/analog-inputs.component';
import { AvailabilityScheduleComponent } from './availability-schedule/availability-schedule.component';
import { BalanceResponsiblePartiesComponent } from './balance-responsible-parties/balance-responsible-parties.component';
import { BalanceResponsiblePartyDetailComponent } from './balance-responsible-party-detail/balance-responsible-party-detail.component';
import { BatteriesComponent } from './batteries/batteries.component';
import { BatteryDetailComponent } from './battery-detail/battery-detail.component';
import { BatteryFcrPeriodDetailComponent } from './battery-fcr-period-detail/battery-fcr-period-detail.component';
import { BatteryFcrPeriodsComponent } from './battery-fcr-periods/battery-fcr-periods.component';
import { BatteryMeasurementDataComponent } from './battery-measurement-data/battery-measurement-data.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ControlConfigurationComponent } from './control-configuration/control-configuration.component';
import { ControlDetailComponent } from './control-detail/control-detail.component';
import { ControlsComponent } from './controls/controls.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomerStepperComponent } from './customer-stepper/customer-stepper.component';
import { CustomersComponent } from './customers/customers.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DeviceDetailComponent } from './device-detail/device-detail.component';
import { DevicesComponent } from './devices/devices.component';
import { GridPointDetailComponent } from './grid-point-detail/grid-point-detail.component';
import { GridPointMeasurementConfigurationComponent } from './grid-point-measurement-configuration/grid-point-measurement-configuration.component';
import { GridPointRetrieveMeasurementDataComponent } from './grid-point-retrieve-measurement-data/grid-point-retrieve-measurement-data.component';
import { GridPointsComponent } from './grid-points/grid-points.component';
import { IncidentReserveNoticeComponent } from './incident-reserve-notice/incident-reserve-notice.component';
import { MeasurementCompaniesComponent } from './measurement-companies/measurement-companies.component';
import { MeasurementCompanyDetailComponent } from './measurement-company-detail/measurement-company-detail.component';
import { MeasurementDataDownloadComponent } from './measurement-data-download/measurement-data-download.component';
import { MeasurementDataUploadComponent } from './measurement-data-upload/measurement-data-upload.component';
import { MessageOverviewComponent } from './message-overview/message-overview.component';
import { OneToManyComponent } from './one-to-many/one-to-many.component';
import { OperationDetailComponent } from './operation-detail/operation-detail.component';
import { OperationsComponent } from './operations/operations.component';
import { PoolAvailabilityComponent } from './pool-availability/pool-availability.component';
import { CanDeactivateGuard, PoolsComponent } from './pools/pools.component';
import { R3InvoiceDataComponent } from './r3-invoice-data/r3-invoice-data.component';
import { RecipientsDetailComponent } from './recipient-detail/recipients-detail.component';
import { RecipientsComponent } from './recipients/recipients.component';
import { ServiceAgreementDetailComponent } from './service-agreement-detail/service-agreement-detail.component';
import { ServiceAgreementsComponent } from './service-agreements/service-agreements.component';
import { TsoAgreementDetailComponent } from './tso-agreement-detail/tso-agreement-detail.component';
import { TsoAgreementsComponent } from './tso-agreements/tso-agreements.component';
import { UnavailabilitiesComponent } from './unavailabilities/unavailabilities.component';
import { UnavailabilityDetailComponent } from './unavailability-detail/unavailability-detail.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UsersComponent } from './users/users.component';
import { IncidentReserveInvoiceResultsComponent } from './incident-reserve-invoice-results/incident-reserve-invoice-results.component';
import { GroupAuthoritiesComponent } from './group-authorities/group-authorities.component';

// Id parameters should be unique
// For example, customerId being present means that the customer state is updated automatically
const routes: Routes = [
  { path: '', component: DummyComponent, pathMatch: 'full', canActivate: [LandingPageService] },
  { path: 'admin', component: AdminComponent },
  { path: 'aggregated-pool', component: AggregatedPoolComponent },
  { path: 'analog-inputs', component: AnalogInputsComponent },
  {
    path: 'analog-inputs/:analogInputId',
    component: AnalogInputDetailComponent,
    children: [{ path: ':customerId', component: DummyComponent }]
  },
  { path: 'customer-stepper', component: CustomerStepperComponent, children: [{ path: ':customerId', component: DummyComponent }] },
  { path: 'balance-responsible-parties', component: BalanceResponsiblePartiesComponent },
  { path: 'balance-responsible-parties/:brpId', component: BalanceResponsiblePartyDetailComponent },
  { path: 'battery-measurement-data', component: BatteryMeasurementDataComponent },
  { path: 'callback', component: DummyComponent, pathMatch: 'full', canActivate: [LandingPageService] },
  { path: 'controls', component: ControlsComponent },
  { path: 'controls/:controlId', component: ControlDetailComponent },
  { path: 'controls/:controlId/configuration', component: ControlConfigurationComponent },
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      { path: '', component: DummyComponent, canActivate: [DefaultCustomerGuardService] },
      { path: 'all', component: DummyComponent, canActivate: [ClearDefaultCustomerGuardService] },
      { path: ':customerId', component: DummyComponent }
    ]
  },
  { path: 'dashboard/:customerId/service-agreements/:serviceAgreementId', component: ServiceAgreementDetailComponent },
  { path: 'dashboard/:customerId/grid-points/:gridPointId', component: GridPointDetailComponent },
  { path: 'dashboard/:customerId/devices/:deviceId', component: DeviceDetailComponent },
  { path: 'dashboard/:customerId/controls/:controlId', component: ControlDetailComponent },
  { path: 'dashboard/:customerId/recipients/:recipientId', component: RecipientsDetailComponent },
  { path: 'dashboard/:customerId/unavailabilities/:unavailabilityId', component: UnavailabilityDetailComponent },
  { path: 'dashboard/:customerId/users/:userId', component: UserDetailComponent },
  { path: 'batteries', component: BatteriesComponent },
  { path: 'batteries/:batteryId', component: BatteryDetailComponent },
  { path: 'batteries/:batteryId/fcr-periods', component: BatteryFcrPeriodsComponent },
  { path: 'batteries/:batteryId/fcr-periods/:fcrPeriodId', component: BatteryFcrPeriodDetailComponent },
  { path: 'customers', component: CustomersComponent },
  { path: 'customers/:customerId', component: CustomerDetailComponent },
  { path: 'devices', component: DevicesComponent },
  { path: 'devices/:deviceId', component: DeviceDetailComponent },
  { path: 'devices/:deviceId/contacts', component: ContactsComponent },
  { path: 'grid-points', component: GridPointsComponent },
  { path: 'grid-points/:gridPointId', component: GridPointDetailComponent },
  { path: 'grid-points/:gridPointId/measurement-configuration/:customerId', component: GridPointMeasurementConfigurationComponent },
  { path: 'grid-points/:gridPointId/retrieve-measurement-data', component: GridPointRetrieveMeasurementDataComponent },
  { path: 'incident-reserve-invoice-results', component: IncidentReserveInvoiceResultsComponent },
  { path: 'incident-reserve-notice', component: IncidentReserveNoticeComponent },
  { path: 'logout', redirectTo: '', pathMatch: 'full' },
  { path: 'measurement-companies', component: MeasurementCompaniesComponent },
  { path: 'measurement-companies/:measurementCompanyId', component: MeasurementCompanyDetailComponent },
  { path: 'measurement-data-download', component: MeasurementDataDownloadComponent },
  {
    path: 'measurement-data-upload',
    component: MeasurementDataUploadComponent,
    canActivate: [ActivateMeasurementDataUploadState],
    canDeactivate: [DeactivateMeasurementDataUploadState]
  },
  { path: 'messages', component: MessageOverviewComponent },
  { path: 'one-to-many', component: OneToManyComponent },
  { path: 'operations', component: OperationsComponent },
  { path: 'operations/:operationId', component: OperationDetailComponent },
  { path: 'r3-invoice-data', component: R3InvoiceDataComponent },
  { path: 'pool-availability', component: PoolAvailabilityComponent },
  { path: 'pools', component: PoolsComponent, canDeactivate: [CanDeactivateGuard] },
  { path: 'recipients', component: RecipientsComponent },
  { path: 'recipients/:recipientId', component: RecipientsDetailComponent },
  { path: 'service-agreements', component: ServiceAgreementsComponent },
  { path: 'service-agreements/:serviceAgreementId', component: ServiceAgreementDetailComponent },
  { path: 'service-agreements/:serviceAgreementId/availability-schedule', component: AvailabilityScheduleComponent },
  { path: 'tso-agreements', component: TsoAgreementsComponent },
  { path: 'tso-agreements/:tsoAgreementId', component: TsoAgreementDetailComponent },
  { path: 'unavailabilities', component: UnavailabilitiesComponent },
  { path: 'unavailabilities/:unavailabilityId', component: UnavailabilityDetailComponent },
  { path: 'users', component: UsersComponent },
  { path: 'users/:userId', component: UserDetailComponent },
  { path: 'group-authorities', component: GroupAuthoritiesComponent },
  { path: 'intraday', loadChildren: () => import('./intraday/intraday.module').then((mod) => mod.IntradayModule) },
  {
    path: 'tso-auctions',
    loadChildren: () => import('./tso-auctions/tso-auctions.module').then((mod) => mod.TsoAuctionsModule),
    canActivate: [ActivateTsoAuctionsState],
    canDeactivate: [DeactivateTsoAuctionsState]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), CommonModule],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {}
