import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Recipient } from './recipient';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class RecipientService {
  private endpoint = '/api/v1/recipients';

  constructor(private http: HttpClient) {}

  getAll(): Observable<Recipient[]> {
    return this.http.get<Recipient[]>(this.endpoint);
  }

  getById(id: string): Observable<Recipient> {
    const url = `${this.endpoint}/${id}`;
    return this.http.get<Recipient>(url);
  }

  add(value: Recipient): Observable<Recipient> {
    return this.http.post<Recipient>(this.endpoint, value, httpOptions);
  }

  update(value: Recipient): Observable<Recipient> {
    const url = `${this.endpoint}/${value.id}`;
    return this.http.put<Recipient>(url, value, httpOptions);
  }

  delete(value: Recipient): Observable<any> {
    const url = `${this.endpoint}/${value.id}`;
    return this.http.delete(url);
  }
}
