import * as Highcharts from 'highcharts';
import {
  ChartOptions,
  CreditsOptions,
  PlotOptions,
  SeriesOptionsType,
  TimeOptions,
  TitleOptions,
  XAxisOptions,
  YAxisOptions
} from 'highcharts';

export enum OperationSeries {
  measurements = 0,
  baselinePower = 1,
  target = 2
}

const chart: ChartOptions = {
  animation: true
};

const plotOptions: PlotOptions = {
  column: {},
  series: {
    pointPadding: 0,
    groupPadding: 0,
    marker: {
      enabled: false
    }
  } as any
};

const tooltip = {
  pointFormat: '<span> {point.y} MW</span>',
  headerFormat: '<strong>{series.name}:</strong>',
  valueDecimals: 2
};

const legend: any = {};

const xAxis: XAxisOptions = {
  title: {
    text: 'Time'
  },
  type: 'datetime',
  gridLineWidth: 1,
  tickInterval: 5 * 60 * 1000 // 5 minutes in milliseconds
};

const yAxis: YAxisOptions | Array<YAxisOptions> = {
  title: {
    text: 'Power'
  }
};

const title: TitleOptions = {
  text: null
};

const series: Array<SeriesOptionsType> = [];

series[OperationSeries.measurements] = {
  showInLegend: true,
  name: 'Power (MW)',
  type: 'line',
  step: 'left',
  color: '#579cd2',
  findNearestPointBy: 'xy',
  lineWidth: 3
};

series[OperationSeries.baselinePower] = {
  showInLegend: true,
  name: 'Baseline Power (MW)',
  type: 'line',
  step: 'center',
  dashStyle: 'longdash',
  color: '#09273f'
} as any;

series[OperationSeries.target] = {
  showInLegend: true,
  name: 'Target Power (MW)',
  type: 'line',
  step: 'center',
  color: '#eda212',
  lineWidth: 3
};

const credits: CreditsOptions = {
  enabled: false
};

const time: TimeOptions = {
  useUTC: false
};

export const chartOptions: Highcharts.Options = {
  chart,
  plotOptions,
  tooltip,
  legend,
  xAxis,
  yAxis,
  title,
  series,
  credits,
  time
};
