import { State } from '@ngxs/store';
import { RecipientState } from './states/recipient/recipient.state';
import { RecipientsState } from './states/recipients/recipients.state';
import { Injectable } from '@angular/core';

export const RecipientStates = [RecipientState, RecipientsState];

@State({
  name: 'recipientsStateModule',
  children: RecipientStates
})
@Injectable({
  providedIn: 'root'
})
export class RecipientStateModule {}
