<mat-card>
  <mat-card-header>
    <mat-card-title>Pool availability</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="form" class="filter-bar">
      <mat-form-field>
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="selectedDateDatepicker" formControlName="selectedDate" />
        <mat-datepicker-toggle matSuffix [for]="selectedDateDatepicker"></mat-datepicker-toggle>
        <mat-datepicker #selectedDateDatepicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Direction</mat-label>
        <mat-select formControlName="direction">
          <mat-option value="UPWARDS">Upwards</mat-option>
          <mat-option value="DOWNWARDS">Downwards</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <ph-flex-loading-context [isLoading]="isLoading">
      <highcharts-chart
        class="pool-availability-chart"
        [Highcharts]="Highcharts"
        [constructorType]="chartConstructor"
        [options]="chartOptions"
        [(update)]="updateFlag"
        (chartInstance)="chartInstanceCallback($event)"
        style="width: 100%; height: 400px; display: block"
      ></highcharts-chart>
    </ph-flex-loading-context>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="showDetails() | async">
  <mat-card-header>
    <mat-card-title>Pool availability details</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ph-flex-loading-context [isLoading]="isLoading">
      <mat-toolbar class="filter-bar">
        <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
      </mat-toolbar>

      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="sourceType">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Source type</th>
          <td mat-cell *matCellDef="let poolAvailability">
            {{ poolAvailability.sourceType }}
          </td>
        </ng-container>
        <ng-container matColumnDef="detail">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Detail</th>
          <td mat-cell *matCellDef="let poolAvailability">
            {{ poolAvailability.detail }}
          </td>
        </ng-container>
        <ng-container matColumnDef="period">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Period</th>
          <td mat-cell *matCellDef="let poolAvailability">
            {{ poolAvailability.period.startDateTime | date : 'short' }}
            - {{ poolAvailability.period.toDateTime | date : 'short' }}
          </td>
        </ng-container>
        <ng-container matColumnDef="direction">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Direction</th>
          <td mat-cell *matCellDef="let poolAvailability" [ngSwitch]="poolAvailability.direction">
            <ph-flex-vertical-center>
              <ng-container *ngSwitchCase="'UPWARDS'"
                ><mat-icon [matTooltip]="poolAvailability.direction" class="status-up" svgIcon="ic-old:arrow-up"></mat-icon
              ></ng-container>
              <ng-container *ngSwitchCase="'DOWNWARDS'"
                ><mat-icon [matTooltip]="poolAvailability.direction" class="status-down" svgIcon="ic-old:arrow-down"></mat-icon
              ></ng-container>
            </ph-flex-vertical-center>
          </td>
        </ng-container>
        <ng-container matColumnDef="power">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Power</th>
          <td mat-cell *matCellDef="let poolAvailability">{{ poolAvailability.power | capacity }}</td>
        </ng-container>
        <ng-container matColumnDef="gridPoints">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Grid points</th>
          <td mat-cell *matCellDef="let poolAvailability">
            <ul>
              <li *ngFor="let gp of poolAvailability.gridPoints">{{ gp }}</li>
            </ul>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </ph-flex-loading-context>
  </mat-card-content>

  <mat-paginator [showFirstLastButtons]="true" [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
</mat-card>
