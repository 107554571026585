import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Battery, BatteryFcrPeriod, BatteryService } from '../shared/battery/battery.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { get } from 'lodash-es';

@Component({
  selector: 'app-battery-fcr-periods',
  templateUrl: './battery-fcr-periods.component.html',
  styleUrls: ['./battery-fcr-periods.component.scss']
})
export class BatteryFcrPeriodsComponent implements OnInit {
  readonly displayedColumns: ReadonlyArray<string> = ['ean', 'gridPointDescription', 'startTime', 'endTime', 'actions'];
  readonly dataSource: MatTableDataSource<BatteryFcrPeriod> = new MatTableDataSource<BatteryFcrPeriod>([]);

  @ViewChild(MatPaginator, { static: true }) private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

  batteryId: string;
  battery: Battery;

  constructor(private route: ActivatedRoute, private location: Location, private batteryService: BatteryService) {}

  ngOnInit(): void {
    this.batteryId = this.route.snapshot.paramMap.get('batteryId');
    this.batteryService.getById(this.batteryId).subscribe((battery) => (this.battery = battery));
    this.getAll();
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      switch (sortHeaderId) {
        case 'startTime':
          return data.period.startDateTime;
        case 'endTime':
          return data.period.toDateTime;
        default:
          return get(data, sortHeaderId);
      }
    };
  }

  getAll(): void {
    this.batteryService.getFcrPeriods(this.batteryId).subscribe((fcrPeriods) => (this.dataSource.data = fcrPeriods));
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }

  delete(fcrPeriod: BatteryFcrPeriod): void {
    this.batteryService.deleteFcrPeriod(this.batteryId, fcrPeriod.id).subscribe(() => this.getAll());
  }

  goBack(): void {
    this.location.back();
  }
}
