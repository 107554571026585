import { IncidentReserveInvoiceData } from '../../app/shared/r3-invoice-data/r3-invoice-data';
import { AdjustmentDirection } from 'flex-app-shared';
import { MatDialogRef } from '@angular/material/dialog';

export class UpdateR3InvoiceDataCommand {
  public static readonly type = '[R3 invoice data] Load invoice data';

  constructor(public date: Date, public direction: AdjustmentDirection) {}
}

export class R3InvoiceDataUpdatedEvent {
  public static readonly type = '[R3 invoice data] Invoice data updated';

  constructor(public data: IncidentReserveInvoiceData[]) {}
}

export class R3InvoiceDownloadDataCommand {
  public static readonly type = '[R3 invoice data] Download data for current date and direction';
}

export class R3InvoiceDataRecalculateCommand {
  public static readonly type = '[R3 invoice data] Recalculate';

  constructor(public dialogRef: MatDialogRef<any>) {}
}

export class R3InvoiceDataRecalculateForServiceAgreementCommand {
  public static readonly type = '[R3 invoice data] Recalculate for service agreement';

  constructor(public serviceAgreementId: string, public dialogRef: MatDialogRef<any>) {}
}
