import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AggregatedPool,
  AggregatedPoolPeriod,
  AggregatedPoolService,
  AvailablePower,
  CalendarPeriod
} from '../shared/aggregated-pool/aggregated-pool.service';
import * as Highcharts from 'highcharts';
import { AdjustmentDirection, Capacity, ChartReflowMixin, Period, validPeriodValidator, VoluntaryR3Participation } from 'flex-app-shared';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CrossFieldErrorMatcher } from '../shared/common/common';
import moment from 'moment';
import { JsonSerializer } from 'typescript-json-serializer';
import { get } from 'lodash-es';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-aggregated-pool',
  templateUrl: './aggregated-pool.component.html',
  styleUrls: ['./aggregated-pool.component.scss']
})
export class AggregatedPoolComponent extends ChartReflowMixin() implements OnInit {
  VoluntaryR3Participation = VoluntaryR3Participation;
  readonly oneDayMillis = 1000 * 3600 * 24;
  jsonSerializer = new JsonSerializer();

  aggregatedPools: ReadonlyArray<AggregatedPool>;
  selectedPool: AggregatedPool;
  busy: boolean;
  updateFlag = false;

  readonly displayedColumns: ReadonlyArray<string> = [
    'customer',
    'serviceAgreement',
    'upwardsCapacity',
    'downwardsCapacity',
    'voluntaryR3Participation'
  ];
  readonly dataSource: MatTableDataSource<AvailablePower> = new MatTableDataSource<AvailablePower>([]);
  form = this.builder.group({
    period: this.builder.group(
      {
        startDate: ['', [Validators.required]],
        endDate: ['', [Validators.required]]
      },
      { validators: validPeriodValidator }
    ),
    direction: AdjustmentDirection.UPWARDS
  });
  crossFieldErrorMatcher = new CrossFieldErrorMatcher('period');
  chartConstructor = 'chart';
  overallChartOptions: any = {
    chart: {
      height: 300
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      formatter(): string {
        return `${Highcharts.dateFormat('%e %b %y', this.x)}: ${this.y} MW`;
      }
    },
    plotOptions: {
      series: {
        cursor: 'pointer',
        lineWidth: 0,
        events: {
          click(): void {
            // needed for dynamically assign function
          }
        },
        marker: {
          enabled: false
        }
      }
    },
    xAxis: {
      labels: {
        formatter(): string {
          return Highcharts.dateFormat('%e %b %y', this.value);
        }
      },
      type: 'datetime'
    },
    yAxis: {
      title: {
        text: 'Capacity'
      },
      min: 0
    },
    series: [
      {
        name: 'Minimal capacity',
        type: 'area',
        step: 'left',
        color: '#219fcf',
        fillOpacity: 0.7,
        allowPointSelect: true,
        zoneAxis: 'x',
        zones: null,
        data: null
      }
    ]
  };
  periodChartOptions: any = {
    chart: {
      height: 300
    },
    title: {
      text: ''
    },
    credits: {
      enabled: false
    },
    tooltip: {
      formatter(): string | boolean {
        function formatHour(hour: number): string {
          if (hour < 10) {
            return `0${hour}:00`;
          }
          return `${hour}:00`;
        }

        if (this.x < 24) {
          return formatHour(this.x) + '-' + formatHour(this.x + 1) + ': ' + this.y + ' MW';
        }
        return false;
      }
    },
    xAxis: {
      title: {
        text: 'Hours'
      },
      allowDecimals: false,
      gridLineWidth: 1
    },
    yAxis: {
      title: {
        text: 'Capacity'
      },
      min: 0
    },
    series: [
      {
        name: 'Hourly capacity',
        color: '#219fcf',
        fillOpacity: 0.7,
        type: 'area',
        lineWidth: 0,
        step: 'left',
        marker: {
          enabled: false
        },
        data: []
      },
      {
        name: 'Minimum',
        type: 'line',
        color: '#09273f',
        marker: {
          enabled: false
        },
        dataLabels: {
          formatter(): number {
            return this.y;
          }
        },
        data: []
      }
    ]
  };
  @ViewChild(MatPaginator, { static: true })
  private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) private readonly sort: MatSort;

  constructor(private builder: UntypedFormBuilder, private service: AggregatedPoolService) {
    super();
  }

  get period(): Period | null {
    return this.form.get('period').value;
  }

  get direction(): AdjustmentDirection {
    return this.form.get('direction').value;
  }

  private static calculateInitialPeriod(): Period {
    const startDate = moment();
    startDate.startOf('month');
    const endDate = moment(startDate);
    endDate.endOf('month');
    return new Period(startDate.toDate(), endDate.toDate());
  }

  public chartInstanceCallback(chartInstance: Highcharts.Chart): void {
    this.chart = chartInstance;
  }

  ngOnInit(): void {
    this.form.patchValue({
      period: AggregatedPoolComponent.calculateInitialPeriod()
    });
    this.updateData();
    this.form.valueChanges.subscribe(() => this.updateData());
    this.overallChartOptions.plotOptions.series.events.click = this.onOverallChartClicked.bind(this);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      let displayedCapacity;
      switch (sortHeaderId) {
        case 'downwardsCapacity':
          displayedCapacity = this.getDisplayedDownwardsCapacity(data);
          return displayedCapacity === 'TT' ? Number.POSITIVE_INFINITY : displayedCapacity;
        case 'upwardsCapacity':
          displayedCapacity = this.getDisplayedUpwardsCapacity(data);
          return displayedCapacity === 'TT' ? Number.POSITIVE_INFINITY : displayedCapacity;
        default:
          return get(data, sortHeaderId);
      }
    };
  }

  getDisplayedDownwardsCapacity(data: AvailablePower): string | number {
    if (data.downwardsTimetable) return 'TT';
    return data.downwardsCapacity ? Capacity.asMW(data.downwardsCapacity) : 0;
  }

  getDisplayedUpwardsCapacity(data: AvailablePower): string | number {
    if (data.upwardsTimetable) return 'TT';
    return data.upwardsCapacity ? Capacity.asMW(data.upwardsCapacity) : 0;
  }

  downloadData(): void {
    this.busy = true;
    this.service.downloadRecipientData(this.selectedPool.period).subscribe(() => (this.busy = false));
  }

  downloadDataButtonDisabled(): boolean {
    return !this.selectedPool || this.busy;
  }

  private updateData(): void {
    this.service.getAggregatedPool(this.period).subscribe((apArray) => {
      this.aggregatedPools = apArray.map((it) => this.jsonSerializer.deserialize(it, AggregatedPool)) as AggregatedPool[];
      this.updateOverallChart();
    });
  }

  private updateOverallChart(): void {
    this.selectedPool = this.aggregatedPools.filter((it) => it.direction === this.direction)[0];
    if (this.selectedPool) {
      this.overallChartOptions.title.text = `Direction: ${this.direction} period: ${this.format(this.selectedPool.period)}`;

      const data = [];

      this.selectedPool.poolPeriods.forEach((it) => {
        data.push([this.utc(it.period.startDate), Capacity.asMW(it.minimum)]);
        data.push([this.utc(it.period.endDate), Capacity.asMW(it.minimum)]);
      });
      const xAxis = {
        ...this.overallChartOptions.xAxis,
        min: this.utc(this.selectedPool.period.startDate),
        max: this.utc(this.selectedPool.period.endDate)
      };
      const series = [{ ...this.overallChartOptions.series[0], data }];
      this.overallChartOptions = { ...this.overallChartOptions, series, xAxis };
      if (this.selectedPool.poolPeriods) {
        this.onAggregatePoolPeriodSelected(this.selectedPool.poolPeriods[0]);
      }
    } else {
      this.overallChartOptions.title.text = 'No data';
      const series = [{ ...this.overallChartOptions.series[0], data: [] }];
      this.overallChartOptions = { ...this.overallChartOptions, series };
      this.onAggregatePoolPeriodSelected(null);
    }
  }

  private updatePeriodChart(poolPeriod: AggregatedPoolPeriod): void {
    if (poolPeriod) {
      this.periodChartOptions.title.text = `Hourly capacity: ${this.format(poolPeriod.period)}`;
      let hour = 0;
      const hourlyCapacity = poolPeriod.hourlyCapacity.map((it) => [hour++, Capacity.asMW(it)]);
      hourlyCapacity.push([24, Capacity.asMW(poolPeriod.hourlyCapacity[poolPeriod.hourlyCapacity.length - 1])]);

      const minimum = [
        [0, Capacity.asMW(poolPeriod.minimum)],
        [24, Capacity.asMW(poolPeriod.minimum)]
      ];
      const series = [
        { ...this.periodChartOptions.series[0], data: hourlyCapacity },
        { ...this.periodChartOptions.series[1], data: minimum }
      ];
      this.periodChartOptions = { ...this.periodChartOptions, series };
    } else {
      this.periodChartOptions.title.text = 'No data';
      const series = [
        { ...this.periodChartOptions.series[0], data: [] },
        { ...this.periodChartOptions.series[1], data: [] }
      ];
      this.periodChartOptions = { ...this.periodChartOptions, series };
    }
  }

  private onAggregatePoolPeriodSelected(poolPeriod: AggregatedPoolPeriod): void {
    const zones = [];
    if (poolPeriod) {
      this.selectedPool.poolPeriods.forEach((it) => {
        const color = it === poolPeriod ? '#cb4141' : null;
        zones.push({
          value: this.utc(it.period.endDate) + this.oneDayMillis,
          color
        });
      });
    }
    const series = { ...this.overallChartOptions.series[0], zones };
    this.overallChartOptions = { ...this.overallChartOptions, series };
    this.updatePeriodChart(poolPeriod);
    this.updateAvailablePowerTable(poolPeriod);
  }

  private onOverallChartClicked(e: any): void {
    const date = e.point.category;
    this.selectedPool.poolPeriods.forEach((it) => {
      const startMillis = this.utc(it.period.startDate);
      const endMillis = this.utc(it.period.endDate);
      if (date === startMillis || date === endMillis) {
        this.onAggregatePoolPeriodSelected(it);
      }
    });
  }

  private updateAvailablePowerTable(poolPeriod: AggregatedPoolPeriod): void {
    if (poolPeriod) {
      this.service.getAvailablePower(poolPeriod.period).subscribe((apArray) => {
        this.dataSource.data = apArray.map((it) => this.jsonSerializer.deserialize(it, AvailablePower)) as AvailablePower[];
      });
    } else {
      this.dataSource.data = [];
    }
  }

  private utc(date: Date): number {
    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate());
  }

  private format(period: CalendarPeriod): string {
    return `${moment(period.startDate).format('DD-MMM-YYYY')} - ${moment(period.endDate).format('DD-MMM-YYYY')}`;
  }
}
