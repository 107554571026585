<mat-card>
  <mat-card-content>
    <mat-toolbar class="filter-bar">
      <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
    </mat-toolbar>

    <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
      <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Product</th>
        <td mat-cell *matCellDef="let agreement">{{ agreement.product }}</td>
      </ng-container>
      <ng-container matColumnDef="period">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Period</th>
        <td mat-cell *matCellDef="let agreement" class="nowrap">
          {{ agreement.period.startDate | date: 'shortDate' }}
          <ng-container *ngIf="agreement.period.startDate !== agreement.period.endDate">
            - {{ agreement.period.endDate | date: 'shortDate' }}
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="direction">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Direction</th>
        <td mat-cell *matCellDef="let agreement" [ngSwitch]="agreement.direction">
          <ph-flex-vertical-center>
            <ng-container *ngSwitchCase="'UPWARDS'">
              <mat-icon class="status-up" svgIcon="ic-old:arrow-up"></mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="'DOWNWARDS'">
              <mat-icon class="status-down" svgIcon="ic-old:arrow-down"></mat-icon>
            </ng-container>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <ng-container matColumnDef="power">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Power (MW)</th>
        <td mat-cell *matCellDef="let agreement">
          {{ agreement.power | capacityNumber: 'MW' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="hourlyFee">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Hourly fee (€/MW)</th>
        <td mat-cell *matCellDef="let agreement">
          {{ agreement.hourlyFee | currency: 'EUR' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="maxOperationTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Maximum operation time (minutes)</th>
        <td mat-cell *matCellDef="let agreement">
          {{ agreement.maxOperationTime }}
        </td>
      </ng-container>
      <ng-container matColumnDef="operationRampUpTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ramp up time (minutes)</th>
        <td mat-cell *matCellDef="let agreement">
          {{ agreement.operationRampUpTime }}
        </td>
      </ng-container>
      <ng-container matColumnDef="operationRampDownTime">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Ramp down time (minutes)</th>
        <td mat-cell *matCellDef="let agreement">
          {{ agreement.operationRampDownTime }}
        </td>
      </ng-container>
      <ng-container matColumnDef="fromAuction">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>From auction</th>
        <td mat-cell *matCellDef="let agreement" [ngSwitch]="agreement.fromAuctionResult">
          <ph-flex-vertical-center>
            <ng-container *ngSwitchCase="true">
              <mat-icon class="status-success" svgIcon="ic-old:check"> </mat-icon>
            </ng-container>
            <ng-container *ngSwitchCase="false">
              <mat-icon class="status-passive" svgIcon="ic-old:close"> </mat-icon>
            </ng-container>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let agreement">
          <ph-flex-vertical-center>
            <button mat-icon-button matTooltip="Edit" routerLink="/tso-agreements/{{ agreement.id }}">
              <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
            </button>
            <ph-flex-dialog
              dialogTitle="Delete TSO agreement"
              dialogActionLabel="Delete TSO agreement"
              dialogModalSize="s"
              [dialogContent]="confirmDeleteDialogContent"
              dialogCancelIcon="ic-old:close"
              dialogActionIcon="ic-old:trash-o"
              [confirmAction$]="delete$(agreement)"
            >
              <button phFlexModalDialogTrigger mat-icon-button matTooltip="Delete" class="remove">
                <mat-icon svgIcon="ic-old:trash-o"></mat-icon>
              </button>
            </ph-flex-dialog>
          </ph-flex-vertical-center>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator [showFirstLastButtons]="true" [pageSize]="30" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button [disabled]="busyDownloading$ | async" (click)="downloadTsoAgreements()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:download"></mat-icon>
        <span>Download data</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="primary" routerLink="/tso-agreements/new">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:add"></mat-icon>
        <span>New TSO agreement</span>
      </ph-flex-vertical-center>
    </button>
  </mat-card-actions>
</mat-card>

<ng-template #confirmDeleteDialogContent>
  <ph-flex-notification type="warn">
    <p>Are you sure you want to delete this TSO agreement?</p>
  </ph-flex-notification>
</ng-template>
