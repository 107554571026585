<h2 mat-dialog-title>Select template for {{ data.templateConfiguration.identifier }}</h2>
<ng-container *ngIf="templateConfigurationType === 'SMS'; else emailTemplate" [ngTemplateOutlet]="smsTemplate"></ng-container>

<ng-template #emailTemplate>
  <mat-dialog-content>
    <form [formGroup]="emailForm" class="single-column-form">
      <mat-form-field>
        <mat-label>Subject</mat-label>
        <input matInput formControlName="subject" />
        <mat-hint
          >You can use the
          <a
            href="https://mandrill.zendesk.com/hc/en-us/articles/205582787-Mailchimp-Merge-Tags-Supported-in-Mandrill"
            target="_blank"
            referrerpolicy="no-referrer"
            rel="noopener noreferrer"
            >default</a
          >
          mandrill template variables, as well as *|DIRECTION|*, *|OPERATION_START|*, and *|OPERATION_END|*.
        </mat-hint>
        <mat-error *ngIf="emailForm.get('subject').errors?.required"> Subject is required. </mat-error>
      </mat-form-field>
      <table mat-table [dataSource]="availableEmailTemplatesDataSource" matSort matSortActive="name" matSortDirection="desc">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
          <td mat-cell *matCellDef="let emailTemplate">
            <mat-radio-button
              (click)="$event.stopPropagation()"
              (change)="$event ? templateSelectionModel.toggle(emailTemplate.name) : null"
              [checked]="templateSelectionModel.isSelected(emailTemplate.name)"
            ></mat-radio-button>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let emailTemplate">
            <a href="https://mandrillapp.com/templates/code?id={{ emailTemplate.slug }}" target="_blank">{{ emailTemplate.name }}</a>
          </td>
        </ng-container>
        <ng-container matColumnDef="publishedAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Published at</th>
          <td mat-cell *matCellDef="let emailTemplate">
            {{ emailTemplate.publishedAt | date: 'short' }}
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedEmailTemplateTableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedEmailTemplateTableColumns"></tr>
      </table>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:close"></mat-icon>
        <span>Cancel</span>
      </ph-flex-vertical-center>
    </button>
    <button mat-flat-button color="cta" [disabled]="emailForm.invalid" (click)="save()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:save"></mat-icon>
        <span>Save</span>
      </ph-flex-vertical-center>
    </button>
  </mat-dialog-actions>
</ng-template>

<ng-template #smsTemplate> sms </ng-template>
