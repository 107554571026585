<mat-card>
  <mat-card-content>
    <section class="table-container" tabindex="0">
      <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
        <ng-container matColumnDef="authority">
          <th mat-header-cell *matHeaderCellDef [attr.rowspan]="2">Authority</th>
          <td mat-cell *matCellDef="let authority">{{ authority.authority }}</td>
        </ng-container>
        <ng-container matColumnDef="internal">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="internalGroupCount" class="internal">Internal</th>
        </ng-container>
        <ng-container matColumnDef="external">
          <th mat-header-cell *matHeaderCellDef [attr.colspan]="externalGroupCount" class="external">External</th>
        </ng-container>
        <ng-container *ngFor="let group of groupAuthorities.groups; let i = index" matColumnDef="{{ group.groupName }}">
          <th mat-header-cell *matHeaderCellDef [class]="group.groupType.toLowerCase()">{{ group.groupName }}</th>
          <td
            mat-cell
            *matCellDef="let row"
            [class]="group.groupType.toLowerCase()"
            (click)="onCellClick(group, row)"
            [matTooltip]="group.groupName + ' (' + group.groupType.toLowerCase() + ')'"
          >
            <ng-container *ngIf="row.groups[group.groupName]">
              <mat-icon class="status-success" svgIcon="ic-old:check"> </mat-icon>
            </ng-container>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['authority', 'internal', 'external']; sticky: true"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: ['authority'].concat(displayedColumns)"></tr>
      </table>
    </section>
  </mat-card-content>
  <mat-card-actions>
    <ph-flex-save-button type="stroked" color="secondary" [disabled]="dirty" (clickChanged)="downloadSql()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:download"></mat-icon>
        <span>Download SQL script</span>
      </ph-flex-vertical-center>
    </ph-flex-save-button>
    <ph-flex-save-button type="stroked" color="secondary" [disabled]="dirty" (clickChanged)="downloadJson()">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:download"></mat-icon>
        <span>Download configuration JSON</span>
      </ph-flex-vertical-center>
    </ph-flex-save-button>
    <button mat-stroked-button (click)="reset()" [disabled]="!dirty || !groupAuthoritiesEditable">
      <ph-flex-vertical-center>
        <mat-icon svgIcon="ic-old:availability"></mat-icon>
        <span>Reset</span>
      </ph-flex-vertical-center>
    </button>
    <ph-flex-entity-save-button (click)="save()" [disabled]="!dirty || !groupAuthoritiesEditable"></ph-flex-entity-save-button>
  </mat-card-actions>
</mat-card>
