import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { CustomersFacade } from '../../../store/customers/states/customers/customers.facade';
import { Customer, findParameterValue, theme } from 'flex-app-shared';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { debounceTime, map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { isNil } from 'lodash-es';
import { Router } from '@angular/router';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-dashboard-customer',
  templateUrl: './dashboard-customer.component.html',
  styleUrls: ['./dashboard-customer.component.scss']
})
export class DashboardCustomerComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public readonly isForbidden: boolean = false;
  @Output()
  public readonly customerSelected: EventEmitter<Customer> = new EventEmitter<Customer>();
  public selectedCustomer: Customer | undefined = undefined;
  public readonly formGroup: UntypedFormGroup = this.fb.group({
    customerId: ['']
  });
  @Input()
  private readonly customerIdFromURL: string | undefined = undefined;
  private readonly ngUnsubscribe = new Subject<void>();

  iconSpacing$ = this.breakpointObserver.observe(`(max-width: ${theme.layout.width.l - 1}px)`).pipe(map((state) => !state.matches));

  constructor(
    private router: Router,
    private customersFacade: CustomersFacade,
    private fb: UntypedFormBuilder,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    const currentCustomerId = findParameterValue(this.router.routerState.snapshot, 'customerId');
    this.formGroup
      .get('customerId')
      .valueChanges.pipe(debounceTime(300), withLatestFrom(this.customersFacade.customers$), takeUntil(this.ngUnsubscribe))
      .subscribe(([customerId, customerList]) => {
        this.selectedCustomer = customerList.find((c) => c.id === customerId);
        if (!isNil(this.selectedCustomer)) {
          this.customerSelected.emit(this.selectedCustomer);
        }
        if (isNil(this.selectedCustomer) && !customerId) {
          this.customerSelected.emit(undefined);
        }
      });
    this.formGroup.patchValue({ customerId: currentCustomerId });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!isNil(this.customerIdFromURL)) {
      this.formGroup.setValue({ customerId: this.customerIdFromURL });
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
