import { Location } from '@angular/common';
import { RouterStateSnapshot } from '@angular/router';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { toUpper } from 'lodash-es';
import { tap } from 'rxjs/operators';
import {
  EntityState,
  EntityStateModel,
  FetchEntity,
  findParameterValue,
  FormStatus,
  MessageService,
  NgxsFormState,
  RouterInitializer,
  SaveEntity
} from 'flex-app-shared';
import { Device, DeviceProviderType, DeviceService } from '../../../../app/shared/device/device.service';
import { LoadDevicesCommand } from '../devices/devices.actions';
import { DeleteDeviceCommand, DeviceLoadedEvent, LoadDeviceCommand, ResetDeviceCommand, SaveDeviceCommand } from './device.actions';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export class DeviceStateModel extends EntityStateModel<Device> implements NgxsFormState<Device> {
  model: Device = {
    customerId: '',
    providerType: DeviceProviderType.SERCOM,
    externalId: '',
    description: '',
    id: null
  };
  dirty: boolean | null;
  status: FormStatus | null;
}

@State({
  name: 'device',
  defaults: new DeviceStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class DeviceState extends EntityState<Device> implements RouterInitializer<DeviceStateModel> {
  @Selector()
  public static getDevice(state: DeviceStateModel): Device {
    return state.model;
  }

  @Selector()
  public static isBusy(state: DeviceStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isInitialized(state: DeviceStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static error(state: DeviceStateModel): any {
    return state.sendError;
  }

  @Selector()
  public static isFormDirty(state: DeviceStateModel): boolean {
    return state.dirty;
  }

  @Selector()
  public static isFormValid(state: DeviceStateModel): boolean {
    return state.status === FormStatus.VALID;
  }

  constructor(
    private deviceService: DeviceService,
    private location: Location,
    private messageService: MessageService,
    protected store: Store
  ) {
    super(store);
  }

  @Action(RouterNavigation)
  routerInit(
    { getState, dispatch, setState }: StateContext<DeviceStateModel>,
    { routerState }: RouterNavigation<RouterStateSnapshot>
  ): void {
    const deviceId = findParameterValue(routerState, 'deviceId');
    const customerId = findParameterValue(routerState, 'customerId');
    const state = getState();

    if (deviceId && deviceId !== 'new') {
      dispatch(new LoadDeviceCommand(deviceId));
    } else if (getState().model.externalId || deviceId === 'new') {
      dispatch(new ResetDeviceCommand({ customerId }));
    } else if (state.model.customerId !== customerId) {
      setState({ ...state, model: { ...state.model, customerId } });
    }
  }

  @Action(ResetDeviceCommand)
  resetDeviceData({ setState }: StateContext<DeviceStateModel>, { payload }: ResetDeviceCommand): void {
    const newState = new DeviceStateModel();
    setState({ ...newState, model: { ...newState.model, ...payload } });
  }

  @FetchEntity()
  @Action(LoadDeviceCommand)
  initDeviceData({ dispatch }: StateContext<DeviceStateModel>, { id, showForm, showDetails }: LoadDeviceCommand): Observable<Device> {
    return this.deviceService.getById(id).pipe(tap((device) => dispatch(new DeviceLoadedEvent(device, showForm, showDetails))));
  }

  @Action(DeviceLoadedEvent)
  setDeviceData({ getState, setState }: StateContext<DeviceStateModel>, { payload }: DeviceLoadedEvent): void {
    setState({
      ...getState(),
      model: {
        ...getState().model,
        ...payload
      }
    });
  }

  @SaveEntity()
  @Action(SaveDeviceCommand)
  saveDevice({ getState, dispatch }: StateContext<DeviceStateModel>, action: SaveDeviceCommand): Observable<Device> {
    let device = getState().model as Device;
    device = { ...device, externalId: toUpper(device.externalId) };
    const isNewDevice = this.isNewDevice(device);
    const saveObservable = isNewDevice ? this.deviceService.add(device) : this.deviceService.update(device);
    return saveObservable.pipe(
      tap(
        (result) => (action.navigateAfterSave ? this.location.back() : dispatch(new DeviceLoadedEvent(result, null, isNewDevice))),
        (err) => this.messageService.error(err.message)
      )
    );
  }

  private isNewDevice(device: Device): boolean {
    return device.id === null || device.id === 'new';
  }

  @Action(DeleteDeviceCommand)
  deleteGridPoint({ dispatch }: StateContext<DeviceStateModel>, { device }: DeleteDeviceCommand): Observable<any> {
    return this.deviceService.delete(device).pipe(tap(() => dispatch(new LoadDevicesCommand())));
  }
}
