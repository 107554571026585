<ng-container *ngIf="!isStandalone">
  <mat-card>
    <mat-card-content>
      <ng-container [ngTemplateOutlet]="pageContents"></ng-container>
    </mat-card-content>
    <mat-card-actions>
      <button *ngIf="!isStandalone" mat-stroked-button (click)="goBack()">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:arrow_back"></mat-icon>
          <span>Go back</span>
        </ph-flex-vertical-center>
      </button>
      <button mat-flat-button color="primary" routerLink="/controls/new">
        <ph-flex-vertical-center>
          <mat-icon svgIcon="ic-old:add"></mat-icon>
          <span>New control</span>
        </ph-flex-vertical-center>
      </button>
    </mat-card-actions>
  </mat-card>
</ng-container>

<ng-container *ngIf="isStandalone" [ngTemplateOutlet]="pageContents"></ng-container>

<ng-template #pageContents>
  <mat-toolbar class="filter-bar">
    <ph-flex-table-filter (filterChanged)="applyFilter($event)" placeholder="Search"></ph-flex-table-filter>
  </mat-toolbar>

  <table mat-table [dataSource]="dataSource" class="has-actions" matSort>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
      <td mat-cell *matCellDef="let control" [ngSwitch]="isStandalone && !isDashboard">
        <a *ngSwitchCase="true" class="pointer" (click)="configure(control.id)">{{ control.description }}</a>
        <a *ngSwitchCase="false" routerLink="/controls/{{ control.id }}/configuration">{{ control.description }}</a>
      </td>
    </ng-container>
    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
      <td mat-cell *matCellDef="let control">{{ control.customerName }}</td>
    </ng-container>
    <ng-container matColumnDef="direction">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Direction">
        <ph-flex-vertical-center><mat-icon class="rotate-90" svgIcon="ic-old:transfer"></mat-icon></ph-flex-vertical-center>
      </th>
      <td mat-cell *matCellDef="let control" [ngSwitch]="control.direction">
        <ph-flex-vertical-center>
          <ng-container *ngSwitchCase="'PRODUCTION'">
            <mat-icon matTooltip="Production" class="status-up" svgIcon="ic-old:arrow-up"></mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="'CONSUMPTION'">
            <mat-icon matTooltip="Consumption" class="status-down" svgIcon="ic-old:arrow-down"></mat-icon>
          </ng-container>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <ng-container matColumnDef="power">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Power</th>
      <td mat-cell *matCellDef="let control">{{ control.power | capacity }}</td>
    </ng-container>
    <ng-container matColumnDef="enabled">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Enabled</th>
      <td mat-cell *matCellDef="let control" [ngSwitch]="control.enabled">
        <ph-flex-vertical-center>
          <ng-container *ngSwitchCase="true">
            <mat-icon class="status-success" svgIcon="ic-old:check"> </mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <mat-icon class="status-passive" svgIcon="ic-old:close"> </mat-icon>
          </ng-container>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <ng-container matColumnDef="upwardsR3Participation">
      <ng-container [ngSwitch]="isDashboard">
        <ng-container *matHeaderCellDef>
          <th mat-header-cell *ngSwitchCase="true" mat-sort-header matTooltip="Upwards incident reserve">
            <ph-flex-vertical-center>
              <mat-icon class="status-up" matTooltip="Upwards incident reserve" svgIcon="ic-old:arrow-up"></mat-icon>
            </ph-flex-vertical-center>
          </th>
          <th mat-header-cell *ngSwitchCase="false" mat-sort-header>Upwards incident reserve</th>
        </ng-container>
      </ng-container>
      <td mat-cell *matCellDef="let control" [ngSwitch]="control.upwardsR3Participation">
        <ph-flex-vertical-center>
          <ng-container *ngSwitchCase="true">
            <mat-icon
              class="status-up"
              [matTooltip]="
                control.direction === 'PRODUCTION' ? 'Control switched ON during operation' : 'Control switched OFF during operation'
              "
              svgIcon="ic-old:check"
            >
            </mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <mat-icon class="status-passive" matTooltip="Control UNCHANGED during operation" svgIcon="ic-old:close"> </mat-icon>
          </ng-container>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <ng-container matColumnDef="downwardsR3Participation">
      <ng-container [ngSwitch]="isDashboard">
        <ng-container *matHeaderCellDef>
          <th mat-header-cell *ngSwitchCase="true" mat-sort-header matTooltip="Downwards incident reserve">
            <ph-flex-vertical-center>
              <mat-icon class="status-down" svgIcon="ic-old:arrow-down"></mat-icon>
            </ph-flex-vertical-center>
          </th>
          <th mat-header-cell *ngSwitchCase="false" mat-sort-header>Downwards incident reserve</th>
        </ng-container>
      </ng-container>
      <td mat-cell *matCellDef="let control" [ngSwitch]="control.downwardsR3Participation">
        <ph-flex-vertical-center>
          <ng-container *ngSwitchCase="true">
            <mat-icon
              class="status-down"
              [matTooltip]="
                control.direction === 'PRODUCTION' ? 'Control switched OFF during operation' : 'Control switched ON during operation'
              "
              svgIcon="ic-old:check"
            >
            </mat-icon>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <mat-icon class="status-passive" matTooltip="Control UNCHANGED during operation" svgIcon="ic-old:close"> </mat-icon>
          </ng-container>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let control" [ngSwitch]="isStandalone && !isDashboard">
        <ph-flex-vertical-center>
          <ng-container *ngSwitchCase="true">
            <button mat-icon-button matTooltip="Edit" (click)="edit(control.id)">
              <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
            </button>
            <button mat-icon-button matTooltip="Configuration" (click)="configure(control.id)">
              <mat-icon svgIcon="ic-old:settings"></mat-icon>
            </button>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <button mat-icon-button matTooltip="Edit" routerLink="/controls/{{ control.id }}">
              <mat-icon svgIcon="ic-old:edit-o"></mat-icon>
            </button>
            <button mat-icon-button matTooltip="Configuration" routerLink="/controls/{{ control.id }}/configuration">
              <mat-icon svgIcon="ic-old:settings"></mat-icon>
            </button>
          </ng-container>
          <button mat-icon-button [matMenuTriggerFor]="tableActions" ngClass="more-menu">
            <mat-icon svgIcon="ic-old:more-menu-v"></mat-icon>
          </button>
          <mat-menu #tableActions="matMenu">
            <ng-container [ngSwitch]="control.enabled">
              <button mat-menu-item (click)="toggleState(control)" *ngSwitchCase="false" class="enable">
                <mat-icon svgIcon="ic-old:toggle-on"></mat-icon>
                Enable
              </button>
              <button mat-menu-item (click)="toggleState(control)" *ngSwitchCase="true">
                <mat-icon svgIcon="ic-old:toggle-off"></mat-icon>
                Disable
              </button>
            </ng-container>
            <button mat-menu-item class="remove" (click)="delete(control)">
              <mat-icon svgIcon="ic-old:trash-o"></mat-icon>
              Delete
            </button>
          </mat-menu>
        </ph-flex-vertical-center>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>

  <mat-paginator [showFirstLastButtons]="true" [pageSize]="defaultPageSize" [pageSizeOptions]="[10, 20, 30, 50, 100]"></mat-paginator>
</ng-template>
