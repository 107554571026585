import { TsoAgreement } from '../../app/shared/tso-agreement/tso-agreement.service';

export class LoadTsoAgreementsCommand {
  public static readonly type = '[TSO Agreements] Load tso agreements';
}

export class DownloadTsoAgreementsCommand {
  public static readonly type = '[TSO Agreements] Download tso agreements';
}

export class DeleteTsoAgreementCommand {
  public static readonly type = '[TSO Agreements] Delete tso agreement';
  constructor(public agreement: TsoAgreement) {}
}

export class TsoAgreementsLoadedEvent {
  public static readonly type = '[TSO Agreements] Tso agreements loaded event';

  constructor(public payload: ReadonlyArray<TsoAgreement>) {}
}
