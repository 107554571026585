import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AuthorityService,
  Customer,
  MANAGE_EXTERNAL_USERS,
  VIEW_CONTROL,
  VIEW_CUSTOMER,
  VIEW_DEVICE,
  VIEW_GRID_POINT,
  VIEW_RECIPIENT,
  VIEW_SERVICE_AGREEMENT,
  VIEW_UNAVAILABILITY
} from 'flex-app-shared';
import { isNil } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { CustomersFacade } from '../../store/customers/states/customers/customers.facade';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, AfterViewInit {
  public permissions: Permissions | undefined = undefined;

  public customerId: string | undefined = undefined;

  public isCardsInitialized: boolean = false;

  constructor(
    private authorityService: AuthorityService,
    private router: Router,
    private customersFacade: CustomersFacade,
    private activatedRoute: ActivatedRoute
  ) {
    const isNoAutEnv = !environment.requireAuthentication;
    this.permissions = {
      customer: isNoAutEnv ? of(true) : this.authorityService.hasAuthorities(VIEW_CUSTOMER),
      gridpoints: isNoAutEnv ? of(true) : this.authorityService.hasAuthorities(VIEW_GRID_POINT),
      devices: isNoAutEnv ? of(true) : this.authorityService.hasAuthorities(VIEW_DEVICE),
      controls: isNoAutEnv ? of(true) : this.authorityService.hasAuthorities(VIEW_CONTROL),
      recipients: isNoAutEnv ? of(true) : this.authorityService.hasAuthorities(VIEW_RECIPIENT),
      unavailabilities: isNoAutEnv ? of(true) : this.authorityService.hasAuthorities(VIEW_UNAVAILABILITY),
      serviceAgreements: isNoAutEnv ? of(true) : this.authorityService.hasAuthorities(VIEW_SERVICE_AGREEMENT),
      users: isNoAutEnv ? of(true) : this.authorityService.hasAuthorities(MANAGE_EXTERNAL_USERS)
    };
  }

  ngOnInit(): void {
    this.customersFacade.init();
    this.customerId = this.activatedRoute.snapshot.paramMap.get('customerId');
  }

  ngAfterViewInit(): void {
    setTimeout(() => (this.isCardsInitialized = true), 800);
  }

  onCustomerSelected(customer: Customer | undefined): void {
    if (!isNil(customer)) {
      if (this.customerId === customer.id) return;
      this.customerId = customer.id;
      this.router.navigate([`/dashboard/${customer.id}`]);
    } else {
      this.customerId = null;
      this.router.navigate([`/dashboard/all`]);
    }
  }
}

interface Permissions {
  readonly customer: Observable<boolean>;
  readonly gridpoints: Observable<boolean>;
  readonly devices: Observable<boolean>;
  readonly controls: Observable<boolean>;
  readonly recipients: Observable<boolean>;
  readonly unavailabilities: Observable<boolean>;
  readonly serviceAgreements: Observable<boolean>;
  readonly users: Observable<boolean>;
}
