import { ServiceAgreement } from 'flex-app-shared';
import { SaveAction } from 'flex-app-shared';

export class ResetServiceAgreementCommand {
  public static readonly type = '[ServiceAgreement] Reset Service Agreement action';

  constructor(public payload?: Partial<ServiceAgreement>) {}
}

export class LoadServiceAgreementCommand {
  public static readonly type = '[ServiceAgreement] Load Service Agreement data';

  constructor(public id: string) {}
}

export class ServiceAgreementLoadedEvent {
  public static readonly type = '[ServiceAgreement] Service Agreement loaded event';

  constructor(public payload: ServiceAgreement) {}
}

export class SaveServiceAgreementCommand implements SaveAction<ServiceAgreement> {
  public static readonly type = '[ServiceAgreement] Save Service Agreement data';

  constructor(public payload: Partial<ServiceAgreement>, public navigateAfterSave: boolean = true) {}
}
