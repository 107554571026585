import { Capacity, PeriodDomain, RestClientService, RestEntity, Volume } from 'flex-app-shared';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export class Battery extends RestEntity {
  name: string;
  description: string;
  capacity: Volume;
  maximumPower: Capacity;
  externalId: string;
}

export class BatteryFcrPeriod extends RestEntity {
  gridPointId: string;
  period: PeriodDomain;
  ean: string;
  gridPointDescription: string;
}

@Injectable({
  providedIn: 'root'
})
export class BatteryService extends RestClientService<Battery> {
  constructor() {
    super('/api/v1/batteries');
  }

  getAllBatteryIds(): Observable<string[]> {
    const url = `${this.endpoint}/batteryids`;
    return this.http.get<string[]>(url);
  }

  getFcrPeriods(batteryId: string): Observable<BatteryFcrPeriod[]> {
    const url = `${this.endpoint}/${batteryId}/fcr-periods`;
    return this.http.get<BatteryFcrPeriod[]>(url);
  }

  getFcrPeriod(batteryId: string, fcrPeriodId: string): Observable<BatteryFcrPeriod> {
    const url = `${this.endpoint}/${batteryId}/fcr-periods/${fcrPeriodId}`;
    return this.http.get<BatteryFcrPeriod>(url);
  }

  addFcrPeriod(batteryId: string, fcrPeriod: BatteryFcrPeriod): Observable<BatteryFcrPeriod> {
    const url = `${this.endpoint}/${batteryId}/fcr-periods`;
    return this.http.post<BatteryFcrPeriod>(url, fcrPeriod);
  }

  updateFcrPeriod(batteryId: string, fcrPeriod: BatteryFcrPeriod): Observable<BatteryFcrPeriod> {
    const url = `${this.endpoint}/${batteryId}/fcr-periods/${fcrPeriod.id}`;
    return this.http.put<BatteryFcrPeriod>(url, fcrPeriod);
  }

  deleteFcrPeriod(batteryId: string, fcrPeriodId: string): Observable<any> {
    const url = `${this.endpoint}/${batteryId}/fcr-periods/${fcrPeriodId}`;
    return this.http.delete(url);
  }
}
