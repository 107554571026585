<h3>Pulse measurement configuration</h3>
<form [formGroup]="form" ngxsForm="gridPointStateModule.gridPointState.pulseMeasurementConfiguration" class="single-column-form">
  <div>
    <mat-label id="pulse-measurement-enabled-label"><strong>Pulse data:</strong></mat-label>
    <mat-radio-group formControlName="pulseEnabled" aria-labelledby="pulse-measurement-enabled-label" class="vertical">
      <mat-radio-button [value]="false">Disabled</mat-radio-button>
      <mat-radio-button [value]="true">Enabled</mat-radio-button>
    </mat-radio-group>
  </div>
  <mat-form-field>
    <mat-label>Device</mat-label>
    <mat-select formControlName="pulseDeviceId">
      <mat-option *ngFor="let device of customerDevices$ | async" [value]="device.id">
        {{ device.externalId }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Consumption (LDN) channel</mat-label>
    <input matInput formControlName="pulseConsumptionChannelName" [errorStateMatcher]="pulseConsumptionErrorStateMatcher" />
    <mat-error *ngIf="form.get('pulseConsumptionChannelName').errors?.required">
      Channel is required if pulse weight is provided
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Consumption (LDN) pulse weight</mat-label>
    <ph-flex-number-input
      formControlName="pulseConsumptionWeight"
      [errorStateMatcher]="pulseConsumptionErrorStateMatcher"
    ></ph-flex-number-input>
    <span matTextSuffix>kWh/pulse</span>
    <mat-error *ngIf="form.get('pulseConsumptionWeight').errors?.invalidNumber"> Not a valid number </mat-error>
    <mat-error *ngIf="form.get('pulseConsumptionWeight').errors?.minExclusive"> Pulse weight should be more than 0 </mat-error>
    <mat-error *ngIf="form.get('pulseConsumptionWeight').errors?.max"> Pulse weight should be less than or equal to 100 </mat-error>
    <mat-error *ngIf="form.get('pulseConsumptionWeight').errors?.invalidNumberOfDecimals">
      Pulse weight can have up to 3 decimals
    </mat-error>
    <mat-error *ngIf="form.get('pulseConsumptionWeight').errors?.required"> Pulse weight is required if channel is provided </mat-error>
  </mat-form-field>
  <ng-container formGroupName="pulseConsumptionPeak">
    <mat-form-field>
      <mat-label>Consumption (LDN) peak (kW)</mat-label>
      <ph-flex-number-input formControlName="value" [errorStateMatcher]="pulseConsumptionErrorStateMatcher"></ph-flex-number-input>
      <span matTextSuffix>kW</span>
      <mat-error *ngIf="form.get('pulseConsumptionPeak.value').errors?.invalidNumber"> Not a valid number </mat-error>
      <mat-error *ngIf="form.get('pulseConsumptionPeak.value').errors?.invalidNumberOfDecimals">
        Pulse peak can have up to 3 decimals
      </mat-error>
      <mat-error *ngIf="form.get('pulseConsumptionPeak.value').errors?.required"> Pulse peak is required if channel is provided </mat-error>
      <mat-error *ngIf="form.get('pulseConsumptionPeak.value').errors?.min"> Pulse peak should be at least 0 </mat-error>
    </mat-form-field>
  </ng-container>
  <div class="checkbox-group">
    <mat-checkbox formControlName="pulseConsumptionDataAvailableForLastHour">Pulse data available</mat-checkbox>
  </div>

  <mat-form-field>
    <mat-label>Production (ODN) channel</mat-label>
    <input matInput formControlName="pulseProductionChannelName" [errorStateMatcher]="pulseProductionErrorStateMatcher" />
    <mat-error *ngIf="form.get('pulseProductionChannelName').errors?.required"> Channel is required if pulse weight is provided </mat-error>
  </mat-form-field>
  <mat-form-field>
    <mat-label>Production (ODN) pulse weight</mat-label>
    <ph-flex-number-input
      formControlName="pulseProductionWeight"
      [errorStateMatcher]="pulseProductionErrorStateMatcher"
    ></ph-flex-number-input>
    <span matTextSuffix>kWh/pulse</span>
    <mat-error *ngIf="form.get('pulseProductionWeight').errors?.invalidNumber"> Not a valid number </mat-error>
    <mat-error *ngIf="form.get('pulseProductionWeight').errors?.minExclusive"> Pulse weight should be more than 0 </mat-error>
    <mat-error *ngIf="form.get('pulseProductionWeight').errors?.max"> Pulse weight should be less than or equal to 100 </mat-error>
    <mat-error *ngIf="form.get('pulseProductionWeight').errors?.invalidNumberOfDecimals">
      Pulse weight can have up to 3 decimals
    </mat-error>
    <mat-error *ngIf="form.get('pulseProductionWeight').errors?.required"> Pulse weight is required if channel is provided </mat-error>
  </mat-form-field>
  <ng-container formGroupName="pulseProductionPeak">
    <mat-form-field>
      <mat-label>Production (ODN) peak (kW)</mat-label>
      <ph-flex-number-input formControlName="value" [errorStateMatcher]="pulseProductionErrorStateMatcher"></ph-flex-number-input>
      <span matTextSuffix>kW</span>
      <mat-error *ngIf="form.get('pulseProductionPeak.value').errors?.invalidNumber"> Not a valid number </mat-error>
      <mat-error *ngIf="form.get('pulseProductionPeak.value').errors?.invalidNumberOfDecimals">
        Pulse peak can have up to 3 decimals
      </mat-error>
      <mat-error *ngIf="form.get('pulseProductionPeak.value').errors?.required"> Pulse peak is required if channel is provided </mat-error>
      <mat-error *ngIf="form.get('pulseProductionPeak.value').errors?.min"> Pulse peak should be at least 0 </mat-error>
    </mat-form-field>
  </ng-container>
  <div class="checkbox-group">
    <mat-checkbox formControlName="pulseProductionDataAvailableForLastHour">Pulse data available</mat-checkbox>
  </div>
</form>
