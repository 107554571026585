import { Recipient } from '../../../../app/shared/recipient/recipient';

export class LoadRecipientsCommand {
  public static readonly type = '[Recipients] Fetch recipients';
}

export class ResetRecipientsCommand {
  public static readonly type = '[Recipients] Reset recipients';
}

export class DeleteRecipientCommand {
  public static readonly type = '[Recipients] Delete recipient';

  constructor(public recipient: Recipient) {}
}
