import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { CustomerFacade } from '../../store/customers/states/customer/customer.facade';
import { AccountManager } from '../shared/account-manager/account-manager.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.scss']
})
export class CustomerDetailComponent implements OnInit {
  @Input() isStandalone = false;
  accountManagerControl = new UntypedFormControl();
  accountManagers: AccountManager[];
  filteredAccountManagers$: Observable<AccountManager[]>;

  customerForm = this.builder.group({
    id: '',
    name: ['', [Validators.required, Validators.maxLength(50)]],
    legalName: ['', [Validators.required, Validators.maxLength(250)]],
    billingId: ['', [Validators.required, Validators.maxLength(50)]],
    accountManagerId: ''
  });

  constructor(private location: Location, private builder: UntypedFormBuilder, public customerFacade: CustomerFacade) {}

  ngOnInit(): void {
    this.customerFacade.init();
    this.customerFacade.isDisabled$.subscribe((isDisabled) => {
      if (this.customerForm) {
        if (isDisabled) {
          this.customerForm.disable();
        } else {
          this.customerForm.enable();
        }
      }
    });
    this.customerFacade.accountManagers$.subscribe((accountManagers) => {
      this.accountManagers = accountManagers;
      const accountManagerId = this.customerForm.get('accountManagerId').value;
      this.accountManagerControl.setValue(this.accountManagers.find((it) => it.id === accountManagerId));
    });
    this.filteredAccountManagers$ = this.accountManagerControl.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : this.displayAc(value))),
      map((name) => (name ? this.filter(name) : this.accountManagers.slice()))
    );
    this.accountManagerControl.valueChanges.subscribe((it) => {
      const accountManagerId = it && it.id ? it.id : null;
      this.customerForm.get('accountManagerId').setValue(accountManagerId);
    });
  }

  private filter(value: string): AccountManager[] {
    const filterValue = value.toLowerCase();
    return this.accountManagers.filter((ac) => `${ac.firstName} ${ac.lastNamePrefix} ${ac.lastName}`.toLowerCase().includes(filterValue));
  }

  displayAc(ac?: AccountManager): string | undefined {
    return ac ? ac.firstName + ' ' + (ac.lastNamePrefix ? `${ac.lastNamePrefix} ${ac.lastName}` : ac.lastName) : undefined;
  }

  onSubmit(): void {
    this.customerFacade.save();
  }

  goBack(): void {
    this.location.back();
  }
}
