import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RouterStateSnapshot } from '@angular/router';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContactsDialogComponent } from '../../../../app/contacts-dialog/contacts-dialog.component';
import { EntityStateModel, FetchEntity, findParameterValue, RouterInitializer } from 'flex-app-shared';
import { DeviceContact, DeviceService } from '../../../../app/shared/device/device.service';
import { DeviceContactDialogOpenedEvent, DeviceIdSelectedEvent } from '../device-contact/device-contact.action';
import {
  DeleteDeviceContactCommand,
  DeviceContactsLoadedEvent,
  EditDeviceContactCommand,
  LoadDeviceContactsCommand,
  ResetDeviceContactsCommand
} from './device-contacts.actions';

export class DeviceContactsStateModel extends EntityStateModel<DeviceContact[]> {
  model: DeviceContact[] = [];
  selectedDevice: string = null;
}

@State({
  name: 'deviceContacts',
  defaults: new DeviceContactsStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class DeviceContactsState implements RouterInitializer<DeviceContactsStateModel> {
  constructor(private deviceService: DeviceService, private dialog: MatDialog, private zone: NgZone) {}

  @Selector()
  public static getDeviceContacts(state: DeviceContactsStateModel): DeviceContact[] {
    return state.model;
  }

  @Selector()
  public static isInitialized(state: DeviceContactsStateModel): boolean {
    return state.isInitialized;
  }

  @Action(RouterNavigation)
  routerInit({ getState, dispatch }: StateContext<DeviceContactsStateModel>, { routerState }: RouterNavigation<RouterStateSnapshot>): void {
    const deviceId = findParameterValue(routerState, 'deviceId');
    if (deviceId && deviceId !== 'new') {
      dispatch(new LoadDeviceContactsCommand({ deviceId }));
    } else if (!deviceId && getState().selectedDevice) {
      dispatch(new ResetDeviceContactsCommand());
    }
  }

  @FetchEntity()
  @Action(LoadDeviceContactsCommand)
  loadDeviceContacts(
    { getState, setState, dispatch }: StateContext<DeviceContactsStateModel>,
    { deviceId, customerId }: LoadDeviceContactsCommand
  ): Observable<DeviceContact[]> {
    let deviceContacts$: Observable<DeviceContact[]>;
    if (deviceId) {
      deviceContacts$ = this.deviceService.getAllDeviceContacts(deviceId);
      setState({
        ...getState(),
        selectedDevice: deviceId
      });
      dispatch(new DeviceIdSelectedEvent(deviceId));
    } else if (customerId) {
      // TODO update service call
      deviceContacts$ = this.deviceService.getAllDeviceContacts(deviceId);
    }
    if (deviceContacts$) {
      return deviceContacts$.pipe(
        tap((result) => {
          dispatch(new DeviceContactsLoadedEvent(result));
        })
      );
    }
  }

  @Action(DeviceContactsLoadedEvent)
  setDeviceContacts({ getState, setState }: StateContext<DeviceContactsStateModel>, { payload }: DeviceContactsLoadedEvent): void {
    setState({
      ...getState(),
      model: payload,
      isInitialized: true
    });
  }

  @Action(EditDeviceContactCommand)
  editDeviceContact({ dispatch }: StateContext<DeviceContactsStateModel>, { contact }: EditDeviceContactCommand): void {
    this.zone.run(() => {
      const dialogRef = this.dialog.open(ContactsDialogComponent);

      if (contact) {
        dispatch(new DeviceContactDialogOpenedEvent(dialogRef.id, contact.deviceId, contact.id));
      } else {
        dispatch(new DeviceContactDialogOpenedEvent(dialogRef.id));
      }
    });
  }

  @Action(DeleteDeviceContactCommand)
  deleteDeviceContact({ dispatch }: StateContext<DeviceContactsStateModel>, { contact }: DeleteDeviceContactCommand): Observable<any> {
    return this.deviceService
      .deleteDeviceContact(contact)
      .pipe(tap(() => dispatch(new LoadDeviceContactsCommand({ deviceId: contact.deviceId }))));
  }
}
