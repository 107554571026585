import { State } from '@ngxs/store';
import { UsersState } from './users/users.state';
import { UserState } from './user/user.state';
import { Injectable } from '@angular/core';

export const UserStates = [UserState, UsersState];

@State({
  name: 'poolStateModule',
  children: UserStates
})
@Injectable({
  providedIn: 'root'
})
export class UserStateModule {}
