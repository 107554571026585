import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { pickBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { PaginatedResponse } from 'flex-app-shared';

export class MessageEntity {
  id: string;
  timestamp: Date;
  type: string;
  userId?: string;
  context?: string;
  text: string;
  contentType?: string;
  category?: string;
}

export enum SearchPeriod {
  PAST_30_MINUTES = 'PAST_30_MINUTES',
  PAST_HOUR = 'PAST_HOUR',
  PAST_4_HOURS = 'PAST_4_HOURS',
  PAST_12_HOURS = 'PAST_12_HOURS',
  PAST_24_HOURS = 'PAST_24_HOURS',
  PAST_48_HOURS = 'PAST_48_HOURS',
  PAST_3_DAYS = 'PAST_3_DAYS',
  PAST_7_DAYS = 'PAST_7_DAYS',
  PAST_30_DAYS = 'PAST_30_DAYS',
  CUSTOM = 'CUSTOM'
}

const url = '/api/v1/messages';

@Injectable({
  providedIn: 'root'
})
export class MessageOverviewService {
  constructor(private http: HttpClient) {}

  get(
    pageIndex: number,
    pageSize: number,
    sort: string,
    search: any,
    categories: string[],
    period: SearchPeriod,
    startDateTime: string,
    toDateTime: string,
    type: string
  ): Observable<PaginatedResponse<MessageEntity>> {
    return this.http.get<PaginatedResponse<MessageEntity>>(`${url}`, {
      params: pickBy(
        {
          page: pageIndex,
          pageSize,
          sort,
          search,
          categories: categories.toString(),
          period,
          startDateTime,
          toDateTime,
          type
        },
        (val) => !!val
      )
    });
  }

  getCategories(): Observable<ReadonlyArray<string>> {
    return this.http.get<ReadonlyArray<string>>(`${url}/categories`);
  }
}
