import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Customer, CustomerService, ServiceAgreement, ServiceAgreementService } from 'flex-app-shared';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { get } from 'lodash-es';
import { UntypedFormBuilder } from '@angular/forms';
import { DatePipe, Location } from '@angular/common';
import { ServiceAgreementsFacade } from '../../store/service-agreements/states/service-agreements/service-agreements.facade';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-service-agreements',
  templateUrl: './service-agreements.component.html',
  styleUrls: ['./service-agreements.component.scss'],
  providers: [DatePipe]
})
export class ServiceAgreementsComponent implements OnInit, OnDestroy, AfterViewInit {
  ServiceAgreement = ServiceAgreement;
  customers: ReadonlyArray<Customer>;
  displayedColumns: ReadonlyArray<string> = [
    'customerName',
    'systemReference',
    'reference',
    'product',
    'period',
    'incidentReservePreQualified',
    'actions'
  ];

  readonly dataSource: MatTableDataSource<ServiceAgreement> = new MatTableDataSource<ServiceAgreement>([]);
  private ngUnsubscribe = new Subject<void>();

  @Input()
  public readonly defaultPageSize: number = 30;

  @Input()
  public readonly isStandalone: boolean = false;

  @Input()
  public readonly isDashboard: boolean = false;

  @ViewChild(MatPaginator, { static: false })
  private readonly paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) private readonly sort: MatSort;

  constructor(
    private router: Router,
    private serviceAgreementService: ServiceAgreementService,
    private serviceAgreementsFacade: ServiceAgreementsFacade,
    private customerService: CustomerService,
    private fb: UntypedFormBuilder,
    private datePipe: DatePipe,
    private location: Location
  ) {}

  goBack(): void {
    this.location.back();
  }

  ngOnInit(): void {
    this.getAll();

    this.setFilterPredicate();

    if (this.isStandalone) {
      this.displayedColumns = this.displayedColumns.filter((it) => it !== 'customerName');
    }
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.serviceAgreementsFacade.init();
      }
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
      if (sortHeaderId === 'period') {
        return data.period.startDate;
      } else {
        return get(data, sortHeaderId);
      }
    };
  }

  getAll(): void {
    this.serviceAgreementsFacade.loadServiceAgreements();
    this.serviceAgreementsFacade.serviceAgreements$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((serviceAgreements) => (this.dataSource.data = serviceAgreements));
  }

  delete(serviceAgreement: ServiceAgreement): void {
    this.serviceAgreementService.delete(serviceAgreement).subscribe(() => this.getAll());
  }

  applyFilter(filterValue: string): void {
    this.dataSource.filter = filterValue || '';
  }

  setFilterPredicate(): void {
    this.dataSource.filterPredicate = (data: ServiceAgreement, _filter: string) => {
      const { product, period, customerName, systemReference, reference } = data;
      const startDate = this.datePipe.transform(period.startDate, 'shortDate');
      const endDate = this.datePipe.transform(period.endDate, 'shortDate');

      return `${customerName} ${systemReference} ${reference} ${product} ${startDate} ${endDate}`
        .toLowerCase()
        .includes(_filter.trim().toLowerCase());
    };
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  downloadServiceAgreementsOverview(): void {
    this.serviceAgreementsFacade.downloadServiceAgreementsOverview();
  }
}
