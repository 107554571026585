import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ControlFacade } from '../../store/controls/control.facade';
import { ControlSchedule } from 'flex-app-shared';

@Component({
  selector: 'app-operation-detail-send-to-devices-confirmation-dialog',
  template: `
    <h1 mat-dialog-title>Send to device</h1>
    <div mat-dialog-content>
      <p><strong>You are about to activate this control. Are you sure you want to continue?</strong></p>
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button mat-dialog-close>
        <ph-flex-vertical-center [iconSpacing]="true"
          ><mat-icon svgIcon="ic-old:close"></mat-icon> <span>Cancel</span></ph-flex-vertical-center
        >
      </button>
      <button mat-flat-button color="primary" (click)="confirm()" cdkFocusInitial>
        <ph-flex-vertical-center [iconSpacing]="true"><mat-icon svgIcon="ic-old:check"></mat-icon> <span>Ok</span></ph-flex-vertical-center>
      </button>
    </div>
  `
})
export class ControlDetailSendToDevicesConfirmationDialogComponent {
  constructor(
    private controlFacade: ControlFacade,
    public dialogRef: MatDialogRef<ControlDetailSendToDevicesConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ControlSchedule
  ) {}

  confirm(): void {
    this.controlFacade.sendSchedule(this.data);
    this.dialogRef.close(true);
  }
}
